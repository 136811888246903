import React from 'react';
import PropTypes from 'prop-types';

function TableWrapper({ children, mobileClass }) {
  return (
    <table
      role="table"
      className={`react_table ${mobileClass || ''}`}
      style={{ width: '100%' }}
    >
      {children}
    </table>
  );
}

TableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  mobileClass: PropTypes.string,
};

export default TableWrapper;
