import React from 'react';
import { useLoaderData, useFetcher, useNavigate } from 'react-router-dom';
import { transformDateHook } from '../Helpers/toDates';
import ActionMenu from '../UI/buttons/ActionMenu';
import ReactTablev8 from '../UI/Table/ReactTablev8';

function Vendors() {
  // HOOKS
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const loaderData = useLoaderData();

  const { vendors } = loaderData;

  // HANDLERS
  const handleRowClick = (rowId) => {
    navigate(`${rowId}`);
  };
  const handleDelete = (id) => {
    if (fetcher.state === 'submiting') return;
    const url = `/vendors/${id}/delete`;
    fetcher.submit({}, { method: 'post', action: url });
  };

  const handleEdit = (id) => {
    navigate(`${id}/update`);
  };
  // TABLE
  // table data
  const nextVendors = vendors.map((vendor) => ({
    id: vendor.id,
    insuranceExpiration: vendor?.insurance_expiration
      ? transformDateHook(vendor.insurance_expiration) // !FLAG not in db issue  leads-box-api#51
      : 'No insurance uploaded',
    name: vendor.label,
    more: (
      <ActionMenu
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        id={vendor.id}
      />
    ),
  }));

  const data = React.useMemo(() => nextVendors, [vendors]);

  // table scheme
  const tableSetup = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Insurance Expiration Date',
      accessorKey: 'insuranceExpiration',
      mobileLabel: true,
    },
    {
      header: 'Actions',
      accessorKey: 'more',
    },
  ];

  // Setup initial (default) sort
  const initialVendorSort = React.useMemo(() => {
    return [
      {
        id: 'name',
        desc: false,
      },
    ];
  }, []);

  return (
    <ReactTablev8
      dataTable={data}
      tableLayout={tableSetup}
      tableAction={handleRowClick}
      initialSortBy={initialVendorSort}
      mobileClass="vendors_table"
    />
  );
}

export default Vendors;
