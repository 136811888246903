import PropTypes from 'prop-types';
import useAppointmentContext from './Context/useAppointmentContext';

export default function AppointmentsHeader({ children }) {
  const { currentDate } = useAppointmentContext();
  return (
    <header className="appointments__header">
      <h2>{currentDate.toLocaleDateString('en-US', { dateStyle: 'full' })}</h2>
      {children}
    </header>
  );
}

AppointmentsHeader.propTypes = {
  children: PropTypes.node,
};
