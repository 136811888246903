/* eslint-disable no-return-assign */

// Remove all special characters, double spaces and replace
// spaces with "_", then lowercase everything
export function sanitize(header) {
  return header
    ?.replaceAll('[^a-zA-Z0-9]+', '')
    .replace(/  +/g, ' ')
    .replace('/', '_')
    .split(' ')
    .join('_')
    .toLowerCase();
}
export function createItem(columns) {
  const item = {};
  columns.map(({ columnDef }) => (item[columnDef.accessorKey] = ''));
  return item;
}

export default null;
