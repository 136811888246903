import { string } from 'yup';
import { formatISO } from 'date-fns';
import testEndDateGreaterThanStart from './testEndDate';

const getDateFields = ({ dateRange, reportType }) => [
  {
    name: 'date_from',
    label: 'From',
    initialValue: '',
    formType: 'date',
    type:
      reportType !== 'products_and_services_list'
        ? string().required()
        : string(),
    required: true,
    disabled: dateRange !== 'customRange',
    className: 'flex__span-3',
  },
  {
    name: 'date_to',
    label: 'To',
    initialValue: formatISO(new Date(), { representation: 'date' }),
    formType: 'date',
    type:
      reportType !== 'products_and_services_list'
        ? string().required().test(testEndDateGreaterThanStart)
        : string().required(),
    required: true,
    disabled: dateRange !== 'customRange',
    className: 'flex__span-3',
  },
];

export default getDateFields;
