import merchantsAPI from '../../API/routesAPI/merchantsAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

export const merchantsLoader = async () => {
  const response = await merchantsAPI.getAll();
  return handleResposeAbstraction({
    response,
  });
};

export const createMerchantAction = async ({ request }) => {
  const body = await request.formData();
  const response = await merchantsAPI.create(body);
  return handleResposeAbstraction({
    response,
    redirectTo: `/expenses/merchants`,
    showSuccess: true,
  });
};

export const updateMerchantAction = async ({ request }) => {
  const body = await request.formData();
  const modalRedirect = body.get('modal_redirect');
  const response = await merchantsAPI.update(body);
  return handleResposeAbstraction({
    response,
    redirectTo: modalRedirect || `/expenses/merchants`,
    showSuccess: true,
  });
};

export const merchantLoader = async ({ params }) => {
  const { merchantId } = params;
  const response = await merchantsAPI.get(merchantId);
  return handleResposeAbstraction({
    response,
  });
};

export const deleteMerchantAction = async ({ params }) => {
  const { merchantId } = params;
  const response = await merchantsAPI.delete(merchantId);
  return handleResposeAbstraction({
    response,
    redirectTo: `/expenses/merchants`,
    showSuccess: true,
  });
};
