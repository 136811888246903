/* eslint react/prop-types: 0 */
import React from 'react';
import { Button } from 'react-bootstrap';

function ExpandCell({ row }) {
  const isExpanded = row?.getIsExpanded();
  const isVehicles = !!row?.original?.mileages?.length;

  return (
    <Button
      variant="light"
      className="d-flex gap-2 align-items-center"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        row.toggleExpanded();
      }}
      style={{ width: '120px' }}
    >
      {isVehicles ? (
        <span
          style={{ fontSize: 10, whiteSpace: 'nowrap' }}
        >{`Mileages (${row?.original?.mileages?.length})`}</span>
      ) : (
        <span
          style={{ fontSize: 10, whiteSpace: 'nowrap' }}
        >{`Recurrent (${row.subRows.length})`}</span>
      )}
      {isExpanded ? '▾' : '▸'}{' '}
    </Button>
  );
}

export default ExpandCell;
