import React from 'react';
import * as Yup from 'yup';
import { useSubmit } from 'react-router-dom';
import TextTitle from '../UI/text/TextTitle';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import GenericForm from '../Forms/builders/GenericForm';

function ResetPassword() {
  const fields = [
    {
      name: 'password',
      label: 'New Password',
      initialValue: '',
      type: Yup.string().strict(true).required('Password is required!'),
      formType: 'password',
      required: true,
    },
  ];
  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post' });
  };
  return (
    <>
      <TextTitle>
        <h1>Enter a new password</h1>
      </TextTitle>
      <ActionErrorInformation />
      <GenericForm
        fields={fields}
        submitAction={submitAction}
        formId="resetPasswordForm"
        submitLabel="Reset password"
      />
    </>
  );
}

export default ResetPassword;
