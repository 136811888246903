/* eslint-disable react/prop-types */
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LBOffCanvas from '../UI/LBOffCanvas';

function ModalController({ selected }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <LBOffCanvas
      size="half"
      show={
        pathname.includes(`checks/${selected}`) ||
        pathname.includes(`tray/${selected}`)
      }
      handleCloseModal={() => navigate('.')}
    >
      <Outlet />
    </LBOffCanvas>
  );
}

export default ModalController;
