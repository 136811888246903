import React from 'react';
import { useRouteLoaderData, useParams } from 'react-router-dom';
import useFetcherOnce from '../../Invoices/useFetcherOnce';
import isPdfAllowed from '../../PDF/IsPdfAllowed';
import PDFTesting from '../../PDF/react-pdf/PDFTesting.tsx';

export function transformData({ payment, invoice, logoBase64 }) {
  const transformedData = {
    id: payment.id,
    amount: invoice.invoice.amount,
    customer: invoice.customer,
    dates: {
      dateCreated: payment.date,
    },
    items: invoice.invoice.items,
    notes: {
      statementNotes: payment.statement_notes,
    },
    number: payment.number,
    status: invoice.invoice.status,
    type: 'receipt',
    user: {
      ...invoice.user,
      company: {
        ...invoice.user.company,
        logo: logoBase64,
      },
    },
    paymentMethod: payment.method_id,
    checkNumber: payment.check,
    payementsTotal: payment.amount,
  };
  return transformedData;
}

export default function PaymentDocument() {
  const { customerId } = useParams();
  const routeLoaders = customerId ? 'customerPaymentData' : 'paymentData';
  const loaderData = useRouteLoaderData(routeLoaders);
  const {
    user: { company_logo_base64: logoBase64 },
  } = useRouteLoaderData('root');
  const { payment, invoice: invoiceP } = loaderData?.data || {};
  const invoiceFetcher = useFetcherOnce(`/invoices/${invoiceP.id}`);
  const invoiceData = invoiceFetcher?.data?.data || {};
  const preFillData = isPdfAllowed('To preview documents');
  if (!invoiceFetcher?.data?.data) return <div>Loading...</div>;
  const data = transformData({
    payment,
    invoice: invoiceData,
    logoBase64,
  });

  return preFillData[0] ? (
    <div className="pt-lg-5">
      <PDFTesting data={data} portalId="modalBottom" />
    </div>
  ) : (
    preFillData[1]
  );
}
