import SendTo from '../../components/Messages/SendTo';
import SendSuccess from '../../components/Messages/SendSuccess';
import SendEmail from '../../components/Messages/SendEmail';
import {
  messagesLoader,
  sendEmailAction,
  uploadFileAction,
} from './messagesLA';
import UploadBeforeSend from '../../components/Messages/UploadBeforeSend';

const messagesRoutes = [
  {
    path: 'send',
    element: <SendTo />,
    children: [
      {
        path: 'send-email',
        element: <SendEmail />,
        action: sendEmailAction,
      },
      {
        path: 'success',
        element: <SendSuccess />,
      },
      {
        path: 'save',
        element: <UploadBeforeSend />,
        action: uploadFileAction,
      },
    ],
  },
];

export const getMessagesRoutes = [
  {
    path: '/messages/:type/:module/:documentId',
    loader: messagesLoader,
  },
];

export default messagesRoutes;

// deleted code
// {
//   path: 'send-sms',
//   element: <SendMessage />,
//   action: sendSMSAction,
//   children: [
//     {
//       path: '',
//       element: <SMSForm />,
//       action: sendSMSAction,
//     },
//   ],
// },
// import SendMessage from '../../components/Messages/SendMessage';
// import SMSForm from '../../components/Messages/SMSForm';
