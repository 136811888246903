export const CHART_COLORS = [
  'var(--bs-blue)',
  'var(--bs-indigo)',
  'var(--bs-purple)',
  'var(--bs-pink)',
  'var(--bs-red)',
  'var(--bs-orange)',
  'var(--bs-yellow)',
  'var(--bs-green)',
  'var(--bs-teal)',
  'var(--bs-cyan)',
];

export const data01 = [
  {
    name: 'Group A',
    value: 400,
  },
  {
    name: 'Group B',
    value: 300,
  },
  {
    name: 'Group C',
    value: 300,
  },
  {
    name: 'Group D',
    value: 200,
  },
  {
    name: 'Group E',
    value: 278,
  },
  {
    name: 'Group F',
    value: 189,
  },
];
export const data02 = [
  {
    name: 'Group A',
    value: 2400,
  },
  {
    name: 'Group B',
    value: 4567,
  },
  {
    name: 'Group C',
    value: 1398,
  },
  {
    name: 'Group D',
    value: 9800,
  },
  {
    name: 'Group E',
    value: 3908,
  },
  {
    name: 'Group F',
    value: 4800,
  },
];
