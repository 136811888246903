/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CreateForm from '../Forms/CreateForm';

function CustomerInfoForm({ formik, structure }) {
  return (
    <>
      <CreateForm {...formik} structure={structure} />

      {formik.values.customer_email && formik.values.billing_address && (
        <div className="customer-info__container p-3 rounded container-fluid">
          <Row className="gy-3">
            <Col md={6} className="d-flex flex-column">
              <span className="customer-info__label">Email:</span>
              {formik.values.customer_email}
            </Col>
            <Col md={6} className="d-flex flex-column">
              <span className="customer-info__label">Address:</span>
              {formik.values.billing_address}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default CustomerInfoForm;
