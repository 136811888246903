import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';
import { I_Colors, I_Data } from '../propTypes';

const s = StyleSheet.create({
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 10,
    fontSize: 9,
    padding: '16px 12px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '30%',
  },
});

function TotalItem({ value }: { value: string }) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Text style={{ width: 7 }}>$</Text>
      <Text style={{ width: 70, textAlign: 'right', fontSize: 8 }}>
        {formatCurrency(value, 2, false)}
      </Text>
    </View>
  );
}

function TableFooter({ data, colors }: { data: I_Data; colors: I_Colors }) {
  return (
    <View wrap={false}>
      <View
        style={{
          ...s.col,
          paddingLeft: 40,
          paddingRight: 40,
          color: colors.bodyText,
        }}
        wrap={false}
      >
        <View style={s.row}>
          <View>
            <Text style={{ fontWeight: 600 }}>Subtotal</Text>
          </View>
          <TotalItem value={data.amount.subtotal} />
        </View>
        <View style={s.row}>
          <View>
            <Text style={{ fontWeight: 600 }}>Tax</Text>
          </View>
          <TotalItem value={data.amount.tax} />
        </View>
        <View style={s.row}>
          <View>
            <Text style={{ fontWeight: 600 }}>Payments</Text>
          </View>
          <TotalItem value={data.amount.paid} />
        </View>
        <View style={{ ...s.row, fontWeight: 600, marginTop: 4 }}>
          <View>
            <Text>Balance</Text>
          </View>
          <TotalItem value={data.amount.balance} />
        </View>
      </View>
    </View>
  );
}

export default TableFooter;
