import { useEffect } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';

export default function useInvoiceModalNavigation() {
  const [setIsExpanded] = useOutletContext();
  const { pathname } = useLocation();
  useEffect(() => {
    if (
      pathname.includes('pdf-preview') ||
      pathname.includes('send') ||
      pathname.includes('split') ||
      pathname.includes('manage')
    ) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [pathname]);
}
