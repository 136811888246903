import React from 'react';

// function ArrowPlaceholder({ ...delegated }) {
//   return (
//     <div
//       style={{
//         width: 48,
//         height: 48,
//         flex: '0 0 48px',
//         borderRadius: '4px',
//         backgroundColor: 'var(--bs-gray-300)',
//         ...delegated.style,
//       }}
//     />
//   );
// }

function LegendItem() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 12,
      }}
    >
      <span
        style={{
          display: 'inline-block',
          width: 32,
          height: 32,
          backgroundColor: 'var(--bs-gray-300)',
          borderRadius: '100px',
        }}
      />
      <span
        style={{
          display: 'inline-block',
          width: 90,
          height: 20,
          backgroundColor: 'var(--bs-gray-300)',
          borderRadius: '100px',
        }}
      />
    </div>
  );
}

function Legend({ ...delegated }) {
  return (
    <div
      style={{
        ...delegated.style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 20,
        width: '100%',
      }}
    >
      {[0, 1].map((item) => (
        <LegendItem key={item} />
      ))}
    </div>
  );
}

function Bar() {
  return (
    <div
      style={{ display: 'flex', alignItems: 'flex-end', gap: 8, width: '12%' }}
    >
      <div
        style={{
          backgroundColor: 'var(--bs-gray-300)',
          width: '40%',
          height: 240,
        }}
      />
      <div
        style={{
          backgroundColor: 'var(--bs-gray-300)',
          width: '40%',
          height: 160,
        }}
      />
    </div>
  );
}

function SimpleBarChartPlaceholder() {
  return (
    <div className="animations__breathe" style={{ height: '380px' }}>
      <div
        className="bar-chart__container"
        style={{ height: '100%', paddingBottom: 0 }}
      >
        <div
          style={{
            borderLeft: '1px solid var(--bs-gray-300)',
            borderBottom: '1px solid var(--bs-gray-300)',
            margin: '16px',
            marginBottom: '24px',
          }}
        >
          <div
            style={{
              width: '100%',
              height: 280,
              display: 'flex',
              justifyContent: 'space-around',
              gap: 8,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            {[0, 1, 2, 3, 4, 5].map((item) => (
              <Bar key={item} />
            ))}
          </div>
        </div>
        <div>
          <Legend />
        </div>
      </div>
    </div>
  );
}

export default SimpleBarChartPlaceholder;
