/* eslint-disable react/prop-types */
import React from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import planData from './planData';

import './ManageSubscription.scss';
import IconWrapper from '../../Customers/CustomersCsv/IconWrapper';
import { Checkmark } from '../../UI/Icons/Icons';

function InvalidSubscriptionPage({
  tiersAllowed = [],
  isAuthorized = true,
  limitReached = false,
}) {
  const {
    user: {
      subscription: { tier },
    },
  } = useRouteLoaderData('root');

  if (tier === 'unsubscribed' || tier === 'expired') {
    return (
      <div className="container-fluid p-4 p-sm-5 h-100 w-100 rounded lb-subscription__wrapper">
        <Row className="mb-4">
          <Col>
            <h2>You don&apos;t have an active subscription</h2>
          </Col>
        </Row>
        <Row>
          {tier === 'unsubscribed' && (
            <Col>
              <p className="mb-3">
                Please note that if you already started the payment process,
                your payment may still be pending. You can check your payment
                process on stripe.
              </p>
              <Button target="_blank" href="https://stripe.com">
                Go to Stripe
              </Button>
            </Col>
          )}
          {tier === 'expired' && (
            <Col>
              <p className="mb-3">
                If you need to re-subscribe after an expired subscription you
                can checkout our current offers here.
              </p>
              <Button
                style={{ marginTop: 'auto' }}
                target="_blank"
                href="https://leadsboxinvoice.com/subscription/"
              >
                Check subscription plans
              </Button>
            </Col>
          )}
        </Row>
      </div>
    );
  }

  return (
    <div className="container-fluid p-4 p-sm-5 h-100 w-100 rounded lb-subscription__wrapper">
      <Row className="mb-4">
        <Col>
          <h2>
            {limitReached
              ? 'Invoice limit reached'
              : "This feature isn't included in your subscription"}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {((tiersAllowed && !isAuthorized) || limitReached) && (
            <>
              {limitReached ? (
                <p className="mb-4">
                  You&apos;ve reached the{' '}
                  <span className="text-danger fw-bold">invoice limit</span> for
                  your current plan{' '}
                  <span className="text-danger fw-bold">(3 invoices)</span>,
                  please consider our other plans if you wish to create
                  unlimited invoices.
                </p>
              ) : (
                <p className="mb-4">
                  Your plan does not cover this feature, consider upgrading your
                  subscription to the following plans to gain access.
                </p>
              )}

              <div className="container-fluid gx-0">
                <Row className="g-3 mb-3">
                  {tiersAllowed.map((allowedTier) => (
                    <Col
                      lg={6}
                      key={planData.find((plan) => plan.id === allowedTier).id}
                    >
                      <div className="card p-4 h-100">
                        <h3 className="lb-subscription__plan-container__plan-name mb-3">
                          {
                            planData.find((plan) => plan.id === allowedTier)
                              .name
                          }
                        </h3>
                        <ul className="lb-subscription__feature-list">
                          <li className="lb-subscription__feature-pre">
                            <IconWrapper
                              strokeWidth={4}
                              stroke="var(--bs-primary)"
                            >
                              <Checkmark />
                            </IconWrapper>
                            Everything in
                            <a href="https://www.leadsboxinvoice.com/subscription">
                              {allowedTier === 'tier_2' ? 'STARTER' : 'BOOST'}
                            </a>{' '}
                            +
                          </li>
                          {planData
                            .find((plan) => plan.id === allowedTier)
                            .featureList.map((feature) => (
                              <li key={feature.toLowerCase().replace(' ', '')}>
                                <IconWrapper
                                  strokeWidth={4}
                                  stroke="var(--bs-primary)"
                                >
                                  <Checkmark />
                                </IconWrapper>
                                {feature}
                              </li>
                            ))}
                        </ul>
                        <div
                          style={{
                            marginTop: 'auto',
                            color: 'var(--bs-primary)',
                          }}
                        >
                          {planData.find((plan) => plan.id === allowedTier)
                            .cost ? (
                            <p className="text-end">
                              ${' '}
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: '1.4rem',
                                }}
                              >
                                {
                                  planData.find(
                                    (plan) => plan.id === allowedTier
                                  ).cost
                                }
                              </span>
                            </p>
                          ) : (
                            <p>Coming soon!</p>
                          )}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Button target="_blank" href="https://stripe.com">
            Manage subscription
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default InvalidSubscriptionPage;
