const ENV = process.env.NODE_ENV;

export const BASE_URL =
  ENV === 'development'
    ? 'https://staging.leadsboxinvoice.com/api'
    : 'https://api.leadsboxinvoice.com/api';
export const register = '/auth/register';
export const registerSuccess = '/auth/register-success';
export const login = '/auth';
export const resetPasswordRequest = '/auth/reset-password-request';
export const resetPasswordRequestSuccess =
  '/auth/reset-password-request-success';
export const forgotPasswordNew = '/auth/reset-password-new';
export const resetPasswordSuccess = '/auth/reset-password-success';
