import React from 'react';
import propTypes from 'prop-types';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    fontSize: 9.5,
    marginTop: '12px',
    marginLeft: '35px',
  },
  addressLine: {
    marginBottom: 0,
  },
});

function Address({ name, lastName, address }) {
  const {
    street_address_1: streetAddress1,
    street_address_2: streetAddress2,
    city,
    state,
    zipcode,
  } = address;

  return (
    <View style={styles.container}>
      <View style={styles.addressLine}>
        <Text>
          {name} {lastName || ''}
        </Text>
      </View>
      <View style={styles.addressLine}>
        <Text>{streetAddress1}</Text>
      </View>
      {streetAddress2 && (
        <View style={styles.addressLine}>
          <Text>{streetAddress2}</Text>
        </View>
      )}
      <View style={styles.addressLine}>
        <Text>{`${city}, ${state} ${zipcode}`}</Text>
      </View>
    </View>
  );
}

Address.propTypes = {
  name: propTypes.string,
  lastName: propTypes.string,
  address: propTypes.shape({
    zipcode: propTypes.string,
    street_address_1: propTypes.string,
    street_address_2: propTypes.string,
    city: propTypes.string,
    state: propTypes.string,
    country: propTypes.string,
    full_address: propTypes.string,
  }),
};

export default Address;
