/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSubmit } from 'react-router-dom';
import { string } from 'yup';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
// import CreateForm from '../../Forms/CreateForm';
import FormWrapper from '../../Forms/builders/FormWrapper';
import createFileForm from '../../Forms/helpers/createFileForm';
import CSVFileInput from '../../Forms/CSVFileInput/CSVFileInput';

function ImportCsv() {
  const [formData, setFormData] = React.useState({});

  const fields = [
    {
      name: 'file',
      formType: 'fileUpload',
      initialValue: '',
      required: true,
      type: string().required(),
    },
  ];

  const submit = useSubmit();
  const submitAction = async (values) => {
    const nextValues = createFileForm(values, formData);
    // console.log(nextValues);
    submit(nextValues, {
      action: '../result',
      method: 'post',
      encType: 'multipart/form-data',
    });
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  return (
    <>
      <div className="d-flex flex-column align-items-start gap-3">
        <h2 className="csv__heading">Submit your CSV file for proccessing</h2>
        <p className="csv__intro-p">
          Import your customer data from dolor sit amet, consectetur adipiscing
          elit, sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua.
        </p>
        <p className="csv__info-p">
          Important, the following fields are required, if any of them are
          missing for an entry, the customer won’t be added.
        </p>
        <ul className="csv__ul">
          <li>Name</li>
          <li>Email</li>
          <li>Phone*</li>
          <li>Street Address</li>
          <li>City</li>
          <li>State</li>
          <li>Zip code</li>
          <li>Country*</li>
        </ul>
        <small className="csv__disclaimer">
          Country will be defaulted as “United States” if the field is missing,
          and phone numbers will be converted to international format using +1
          as the prefix.
        </small>
        <button type="button" className="csv__link">
          Download Example CSV File
        </button>
      </div>

      <div className="d-flex flex-column h-100 justify-content-between">
        <FormWrapper
          id="import-csv"
          className="flex__form-wrapper"
          handleSubmit={formik.handleSubmit}
          isSubmitting={formik.isSubmitting}
          label="Load CSV File"
          portalId="csv-submit"
          dirty={formik.dirty}
          isValid={formik.isValid}
        >
          <CSVFileInput
            name="file"
            value={formik?.values.file}
            label="Drag or Browse CSV file"
            required
            setFieldValue={formik?.setFieldValue}
            setFormData={setFormData}
            errors={formik?.errors}
            touched={formik?.touched}
            setFieldTouched={formik?.setFieldTouched}
            setFieldError={formik?.setFieldError}
          />
        </FormWrapper>
        <div className="mt-4" id="csv-submit" />
      </div>
    </>
  );
}

export default ImportCsv;
