import React from 'react';

function SinglePlaceholder() {
  return (
    <div className="widget__placeholder-container">
      <div
        style={{
          display: 'grid',
          marginTop: '25px',
          placeContent: 'center',
          height: '240px',
          width: '240px',
          borderRadius: '1000px',
          backgroundColor: 'var(--bs-gray-300)',
        }}
      >
        <div
          style={{
            height: '180px',
            width: '180px',
            borderRadius: '1000px',
            backgroundColor: 'var(--bs-white)',
          }}
        />
      </div>
      <div
        style={{
          marginTop: '30px',
          display: 'flex',
          // marginLeft: '1rem',
          // marginRight: '1rem',
          gap: '8%',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100px',
        }}
      >
        {[0, 1, 2].map((item) => (
          <div
            style={{
              display: 'flex',
              gap: '8px',
              flexBasis: '30%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            key={item}
          >
            <span
              style={{
                display: 'inline-block',
                height: '40px',
                minWidth: '50px',
                width: '30%',
                borderRadius: '4px',
                backgroundColor: 'var(--bs-gray-300)',
              }}
            />
            <span
              style={{
                display: 'inline-block',
                minWidth: '80px',
                width: '60%',
                height: '20px',
                borderRadius: '4px',
                backgroundColor: 'var(--bs-gray-300)',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SinglePlaceholder;
