import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { I_Colors, I_Data } from '../propTypes';
import { LocationPin, Mail, Phone } from '../Icons';

const s = StyleSheet.create({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingRight: 40,
    paddingLeft: 40,
    height: 26,
  },
  col: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 3,
    fontSize: 8,
  },
});

function Footer({ data, colors }: { data: I_Data; colors: I_Colors }) {
  return (
    <View
      style={{
        ...s.container,
        backgroundColor: colors.color1,
        color: colors.color1text,
      }}
      fixed
    >
      <View style={s.col}>
        <LocationPin color={colors.color1text} />
        <Text>
          {data.user.contact.address.city}, {data.user.contact.address.state}
        </Text>
      </View>
      <View style={s.col}>
        <Phone color={colors.color1text} />
        <Text>{formatPhoneNumberIntl(data.user.contact.phone)}</Text>
      </View>
      <View style={s.col}>
        <Mail color={colors.color1text} />
        <Text>{data.user.contact.email}</Text>
      </View>
      <View style={s.col}>
        <Text
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </View>
  );
}

export default Footer;
