/* eslint react/forbid-prop-types: 0 */
import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import CreateFormTitle from './CreateFormTitle';
import CreateFormLabel from './CreateFormLabel';
import CreateFormSelect from './CreateFormSelect';
import CreateTimePicker from '../TimePicker/TimePicker';
import AutoCompleteAddress from '../AutoCompleteAddress/AutoCompleteAddress';
import CreateToggleGroup from '../ToggleGroup/ToggleGroup';
import CreatePhoneInput from '../PhoneInput/PhoneInput';

import '../Forms.scss';
import TextArea from '../TextArea/TextArea';
import LBInputGroup from '../LBInputGroup/LBInputGroup';
import LBFormControl from './LBFormControl';
import UnitInput from '../UnitInput/UnitInput';
import ImageCrop from '../ImageCrop';
import LBCheckInput from './LBCheckInput';

function CreateForm({
  errors = {},
  handleBlur = {},
  handleChange = () => null,
  setFieldValue = {},
  setTouched = () => null,
  setFieldTouched = () => null,
  structure = [],
  touched = {},
  values = {},
  width = 'form-two',
  validateField = () => null,
  validateForm = () => null,
}) {
  const formTypesSelector = (element) => {
    switch (element.formType) {
      case 'customer':
      case 'expenseCategory':
      case 'invoiceSelect':
      case 'merchantsAndVendors':
      case 'productCategory':
      case 'productCategoryCombo':
      case 'productCategoriesSelect':
      case 'productCombo':
      case 'select':
      case 'timezone':
      case 'trades':
      case 'toCheckSelect':
      case 'vehicleSelect':
        return (
          <CreateFormSelect
            categoryId={element?.categoryId}
            formType={element.formType}
            handleChange={element?.change}
            inputName={element.name}
            isInvalid={touched[element.name] && !!errors[element.name]}
            options={element.options}
            setFieldValue={setFieldValue}
            value={values[element.name]}
            isClearable={element.isClearable}
            isDisabled={element.disabled}
            hasAll={element.hasAll}
            config={element.config}
          />
        );
      case 'autocompleteAddress':
        return (
          <AutoCompleteAddress
            fieldNames={element.fieldNames}
            name={element.name}
            setFieldValue={setFieldValue}
            touched={touched}
            setTouched={setTouched}
            validateField={validateField}
            validateForm={validateForm}
            setFieldTouched={setFieldTouched}
          />
        );
      case 'time':
        return (
          <CreateTimePicker
            errors={errors[element.name]}
            name={element.name}
            setFieldValue={setFieldValue}
            setTouched={setTouched}
            touched={touched}
            value={values[element.name]}
          />
        );
      case 'toggleGroup':
        return (
          <CreateToggleGroup
            element={element}
            setFieldValue={setFieldValue}
            value={values[element.name]}
          />
        );
      case 'phone':
        return (
          <CreatePhoneInput
            errors={errors[element.name]}
            name={element.name}
            setFieldValue={setFieldValue}
            setTouched={setTouched}
            touched={touched}
            value={values[element.name]}
          />
        );
      case 'inputGroup':
        return (
          <LBInputGroup
            element={element}
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );
      case 'unit':
        return (
          <UnitInput
            element={element}
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        );
      case 'textarea':
        return (
          <TextArea
            element={element}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            values={values}
          />
        );
      case 'imageCrop':
        return (
          <ImageCrop
            element={element}
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setTouched={setTouched}
          />
        );
      case 'checkbox':
      case 'switch':
        return (
          <LBCheckInput
            element={element}
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );
      default:
        return (
          <LBFormControl
            element={element}
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );
    }
  };

  return structure.map((element) =>
    element?.formGroup ? (
      <CreateFormTitle
        formGroup={element.formGroup}
        key={element?.formGroup.replace(/ /g, '')}
        width={width}
        showTitle={element?.showTitle}
      >
        <CreateForm
          structure={element?.groupChildren}
          errors={errors}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          validateField={validateField}
          validateForm={validateForm}
          setFieldTouched={setFieldTouched}
        />
      </CreateFormTitle>
    ) : (
      element.formType !== 'ignore' && (
        <CreateFormLabel
          className={element.className}
          errors={errors[element.name] || ''}
          inputName={element.name}
          key={element?.name}
          label={element.label}
          labelAction={element?.labelAction}
          required={element?.required}
          type={element?.formType}
        >
          {formTypesSelector(element)}
        </CreateFormLabel>
      )
    )
  );
}

CreateForm.propTypes = {
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setTouched: PropTypes.func,
  structure: PropTypes.array.isRequired,
  touched: oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  values: PropTypes.object.isRequired,
  width: PropTypes.string,
  validateField: PropTypes.func,
  validateForm: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

CreateForm.defaultProps = {
  setTouched: () => null,
};

export default CreateForm;
