/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import proptypes from 'prop-types';
import Select from 'react-select';
import { getSelectedValue } from './findSelection';

export default function SelectWithDefault({
  handleChange,
  isInvalid,
  name,
  options,
  value,
  isClearable = true,
  isDisabled = false,
  ...delegated
}) {
  const selectedValue = getSelectedValue(options, value);
  return (
    <Select
      value={selectedValue}
      isClearable={isClearable}
      classNames={{
        control: () =>
          isInvalid ? 'form-control p-0 pe-4 is-invalid' : 'form-control p-0',
      }}
      options={options}
      placeholder="Select an option"
      aria-label={name}
      id={name}
      classNamePrefix="lb_select"
      onChange={(e, action) => {
        handleChange(e, action);
      }}
      menuPlacement="auto"
      isDisabled={isDisabled}
      {...delegated}
    />
  );
}

SelectWithDefault.propTypes = {
  options: proptypes.arrayOf(proptypes.object.isRequired),
  value: proptypes.oneOfType([proptypes.string, proptypes.number]),
  handleChange: proptypes.func.isRequired,
  isInvalid: proptypes.bool,
  name: proptypes.string.isRequired,
  isClearable: proptypes.bool,
  isDisabled: proptypes.bool,
};

SelectWithDefault.defaultProps = {
  value: '',
  options: [],
  isInvalid: false,
};
