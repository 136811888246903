import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './DropdownOptions.scss';

// const mockArray = [
//   {
//     id: 1,
//     name: 'Gustavo',
//   },
//   {
//     id: 2,
//     name: 'Joel',
//   },
//   {
//     id: 3,
//     name: 'Carolina',
//   },
//   {
//     id: 4,
//     name: 'Prasun',
//   },
// ];

function DropdownOptions({ options = [], setSelectOption, secondOptions }) {
  const [selectedValue, setSelectedValue] = useState('Select Option');
  const [enableDropdown, setEnableDropdown] = useState(false);
  const [query, setQuery] = useState('');
  const [searchedArray, setSearchedArray] = useState([]);

  useEffect(() => {
    setSearchedArray(options);
  }, []);

  useEffect(() => {
    if (secondOptions === 'none') {
      setSelectedValue('Select Option');
    }
  }, [secondOptions]);

  const handleSelectValue = (item) => {
    setSelectedValue(item.title);
    setSelectOption(item.id);
    setEnableDropdown(false);
    setQuery('');
    setSearchedArray(options);
  };

  const handleChangeInput = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setQuery(inputValue);

    const filteredResults = options.filter((obj) =>
      obj.title.toLowerCase().includes(inputValue)
    );
    setSearchedArray(filteredResults);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && searchedArray.length > 0) {
      setSelectedValue(searchedArray[0].name);
      setEnableDropdown(false);
      setQuery('');
      setSearchedArray(options);
    }
  };

  return (
    <div>
      <button
        type="button"
        className="dropdown-btn"
        onClick={() => setEnableDropdown(!enableDropdown)}
      >
        <p className="select-text">{selectedValue}</p>
      </button>
      {enableDropdown && (
        <div className="dropdown-container">
          <input
            className="dropdown-input"
            type="text"
            placeholder="Search..."
            value={query}
            onChange={(event) => handleChangeInput(event)}
            onKeyDown={handleKeyPress}
          />
          <button
            className="dropdown-options"
            type="button"
            onClick={() =>
              handleSelectValue({
                id: 0,
                title: 'All',
              })
            }
          >
            All
          </button>
          {searchedArray.map((item) => {
            return (
              <button
                className="dropdown-options"
                type="button"
                onClick={() => handleSelectValue(item)}
                key={item.id}
              >
                {item.title}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}

DropdownOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  setSelectOption: PropTypes.func,
  secondOptions: PropTypes.string,
};

export default DropdownOptions;
