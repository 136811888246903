import React from 'react';
import { useOutletContext } from 'react-router-dom';
import Map from '../Customers/Map';
import Card from '../UI/Card';
import InfoBox from '../UI/InfoBox';

import Delete from '../UI/Delete';

const marchantAPI = (API) => ({
  name: API.merchant_name,
  address: `${API.merchant_city} ${API.merchant_state} `,
  contact: API.merchant_contact_person,
  phone: API.merchant_phone,
  notes: API.merchant_notes,
  image: API.merchant_image,
  id: API.merchant_id,
});

export default function Merchant() {
  const data = useOutletContext();
  const merchant = marchantAPI(data);

  return (
    <div className="row">
      <div className="col-lg-8 ">
        <Card>
          <h2 className="lb-modal__title">Merchant information</h2>
          <hr />
          <div className="row pt-3 pb-3">
            <div className="col-lg-6">
              <InfoBox
                name={merchant.name}
                address={merchant.address}
                phone={merchant?.phone}
                mobile={merchant?.mobile}
              />
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              {data?.completeAddress ? (
                <Map address={data.completeAddress} />
              ) : (
                <p>Address no available</p>
              )}
            </div>
          </div>
        </Card>
      </div>
      <div className="col-lg-4 mt-4 mt-lg-0">
        <Delete
          route={`/marchants/${merchant.id}/delete`}
          label="Delete data"
        />
      </div>
    </div>
  );
}
