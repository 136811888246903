// Note: This is the modal content for the Invoice modal
// Path: src/components/Invoices/InvoiceModalContent.jsx
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Outlet, useRouteLoaderData, useParams, Link } from 'react-router-dom';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import ModalTitle from '../UI/text/ModalTitle';
import './InvoiceModalContent.scss';
import TextField from '../UI/text/TextField';
import FieldData from '../UI/text/FieldData';
import StatusBadge from '../UI/StatusBadge';
import ModalNavigation from '../UI/ModalNavigation';
import DatesSection from './InvoiceModal/DatesSection';
import BackButton from './InvoiceModal/BackButton';
import useInvoiceModalNavigation from './InvoiceModal/useInvoiceModalNavigation';
import InvoiceBalance from './InvoiceBalance';
import formatCurrency from '../Dashboard/Widgets/utils/formatCurrency';
import formatAddress from '../Appointments/utils/formatAddress';
import useIsAuthorized from '../../routes/protectedPages/useIsAuthorized';

function InvoiceModalContent() {
  const { customerId } = useParams();
  const routeLoaders = customerId ? 'customerInvoiceData' : 'invoiceData';
  const loaderData = useRouteLoaderData(routeLoaders);
  useInvoiceModalNavigation();
  const isAuthorized = useIsAuthorized({ tiersAllowed: ['tier_2', 'tier_3'] });

  const {
    data: {
      invoice,
      invoice: { amount },
      customer,
      current,
    },
  } = loaderData;

  const navigation = [
    {
      label: 'View preview',
      to: 'pdf-preview',
    },
    {
      label: 'Manage document',
      to: 'manage',
    },
    {
      label: 'Send Invoice',
      to: 'send',
    },
  ];

  return (
    <div className="modal-layout">
      <div className="main-top ">
        <section>
          <ModalTitle title="Invoice" subtitle={invoice.number} />
          <div className="d-flex">
            <FieldData variant="lb-modal__price">
              {formatCurrency(+amount.total + +amount.tax, 2) || 'missing'}
            </FieldData>
            <StatusBadge statusCode={invoice.status} type="invoice" />
          </div>
        </section>
        <DatesSection
          start={invoice.dates.dateCreated}
          end={invoice.dates.dateDue}
        />
        <InvoiceBalance isAuthorized={isAuthorized}>
          <InvoiceBalance.Entry label="Payments">
            {formatCurrency(current?.payments)}
          </InvoiceBalance.Entry>
          <InvoiceBalance.Entry label="Expenses">
            {formatCurrency(current?.expenses)}
          </InvoiceBalance.Entry>
          <InvoiceBalance.Entry
            label="Unpaid Balance"
            color="orange"
            highlight={invoice.status !== 3}
          >
            {formatCurrency(current?.unpaid)}
          </InvoiceBalance.Entry>
          <InvoiceBalance.Entry
            label="Profit"
            highlight={invoice.status === 3}
            color={current?.profit > 0 ? 'green' : 'red'}
          >
            {formatCurrency(current?.profit)}
          </InvoiceBalance.Entry>
        </InvoiceBalance>
        <section className="container-fluid pb-4 px-0 mx-0">
          <div className="row">
            <div className="col-12 col-sm-6 gy-4">
              <TextField
                label="Customer"
                dataVariant="lb-modal__data--bold text-primary"
              >
                <Link
                  className="modal__overflow-ellipsis"
                  to={`/customers/${customer?.id}`}
                >
                  {customer.name}
                </Link>
              </TextField>
            </div>
            <div className="col-12 col-sm-6 gy-4">
              <TextField className="modal__overflow-ellipsis" label="Mobile">
                <Link to={`tel:${customer?.mobile}`}>
                  {formatPhoneNumberIntl(customer?.mobile) || 'missing'}
                </Link>
              </TextField>
            </div>
            <div className="col-12 col-sm-6 gy-4">
              <TextField label="Email">
                <Link
                  className="modal__overflow-ellipsis"
                  to={`mailto:${customer?.email}`}
                >
                  {customer?.email || 'missing'}
                </Link>
              </TextField>
            </div>
            <div className="col-12 col-sm-6 gy-4">
              <TextField className="modal__overflow-ellipsis" label="Address">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                    formatAddress(customer?.address)
                  )}`}
                >
                  {customer?.address?.street_address_1 || 'missing'}
                </a>
              </TextField>
            </div>
          </div>
        </section>

        {navigation.map((item) => (
          <ModalNavigation setIsExpanded {...item} key={item.label} />
        ))}
      </div>
      <div className="side-top">
        <Outlet
          context={{
            status: invoice.status,
            phone: customer.phone,
            email: customer.email,
            subject: `Your invoice ${invoice.number} is ready to view`,
          }}
        />
      </div>
      <div
        className="side-footer d-flex justify-content-between"
        id="modalBottom"
      >
        <BackButton />
      </div>
    </div>
  );
}

export default InvoiceModalContent;
