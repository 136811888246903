import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';
import { I_Colors, I_Data } from '../propTypes';

const s = StyleSheet.create({
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 10,
    fontSize: 9,
    padding: '16px 12px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '30%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '10px 12px',
    marginTop: 'auto',
    fontSize: 9,
    fontWeight: 'bold',
  },
});

function TotalItem({ value }: { value: string }) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Text style={{ width: 7 }}>$</Text>
      <Text style={{ width: 70, textAlign: 'right', fontSize: 8 }}>
        {formatCurrency(value, 2, false)}
      </Text>
    </View>
  );
}

interface I_TotalsRow {
  subtotal: string;
  tax: string;
  payments: string;
  colors: I_Colors;
}

function TotalsRow({ subtotal, tax, payments, colors }: I_TotalsRow) {
  return (
    <View
      style={{
        ...s.col,
        borderTop: `4px solid ${colors.color1}`,
      }}
      wrap={false}
    >
      <View style={s.row}>
        <View>
          <Text style={{ fontWeight: 600 }}>Subtotal</Text>
        </View>
        <TotalItem value={subtotal} />
      </View>
      <View style={s.row}>
        <View>
          <Text style={{ fontWeight: 600 }}>Tax</Text>
        </View>
        <TotalItem value={tax} />
      </View>
      <View style={s.row}>
        <View>
          <Text style={{ fontWeight: 600 }}>Payments</Text>
        </View>
        <TotalItem value={payments} />
      </View>
    </View>
  );
}

function TableFooter({ data, colors }: { data: I_Data; colors: I_Colors }) {
  return (
    <View wrap={false}>
      <TotalsRow
        subtotal={data.amount.subtotal}
        tax={data.amount.tax}
        payments={data.amount.paid}
        colors={colors}
      />
      <View
        style={{
          ...s.footer,
          color: colors.color1text,
          backgroundColor: colors.color1,
          borderRight: `6px solid ${colors.color2}`,
          paddingRight: '6px',
        }}
      >
        <View
          style={{
            width: '30%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>Balance:</Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={{ width: 7 }}>$</Text>
            <Text style={{ width: 70, textAlign: 'right' }}>
              {formatCurrency(data.amount.balance, 2, false)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export default TableFooter;
