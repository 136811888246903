/* eslint-disable react/prop-types */

import { Form } from 'react-bootstrap';
import { useImageCropContext } from './ImageCropProvider';

export function ZoomSlider() {
  const { zoom, setZoom, maxZoom, minZoom, zoomStep } = useImageCropContext();

  return (
    <Form.Group>
      <Form.Label>Zoom</Form.Label>
      <Form.Range
        id="zoom"
        name="zoom"
        min={minZoom}
        max={maxZoom}
        step={zoomStep}
        value={zoom}
        onChange={(e) => {
          setZoom(Number(e.target.value));
        }}
      />
    </Form.Group>
  );
}

// This slider is not being used right now.
// WARNING: It was never tested how the implementation would work
// when rotation comes in the mix, be VERY CAREFUL if you add this slider.
export function RotationSlider() {
  const { rotation, setRotation, maxRotation, minRotation, rotationStep } =
    useImageCropContext();

  return (
    <input
      type="range"
      name="rotation"
      min={minRotation}
      max={maxRotation}
      step={rotationStep}
      value={rotation}
      onChange={(e) => {
        setRotation(Number(e.target.value));
      }}
    />
  );
}
