import ChecksNew from '../../components/Checks/ChecksForm/ChecksNew';
import CustomerModal from '../../components/Checks/ChecksForm/CustomerModal';
import MerchantModal from '../../components/Checks/ChecksForm/MerchantModal';
import VendorModal from '../../components/Checks/ChecksForm/VendorModal';
import ChecksPage from '../../components/Checks/ChecksPage';
import GenericPage from '../../pages/GenericPage';
import {
  clearTray,
  createCheckAction,
  deleteCheckAction,
  getCheck,
  getChecksLoader,
  getRecipientsLoader,
  printingTraychecks,
  toggleCheckStatus,
  updateCheckAction,
  voidCheck,
} from './checksLA';
import Tray from '../../components/Checks/Tray/Tray';
import Check from '../../components/Checks/Check';

const checksRoutes = [
  {
    path: '/checks',
    element: <GenericPage />,
    action: toggleCheckStatus,
    loader: getChecksLoader,
    id: 'checks',
    handle: {
      crumb: () => 'Checks',
      sublinks: () => [
        {
          label: 'Checks',
          to: '',
          end: true,
        },
        {
          label: 'Printing Tray',
          to: 'tray',
        },
      ],
    },
    children: [
      {
        path: '',
        element: <ChecksPage />,
        action: toggleCheckStatus,
        loader: getChecksLoader,
        handle: {
          linkTo: {
            label: 'New',
            to: 'new',
          },
        },
        children: [
          {
            path: ':id',
            element: <Check />,
            loader: getCheck,
            action: toggleCheckStatus,
          },
        ],
      },
      {
        path: 'new',
        element: <ChecksNew />,
        action: createCheckAction,
        children: [
          { path: 'customers-complete', element: <CustomerModal /> },
          { path: 'merchants-complete', element: <MerchantModal /> },
          { path: 'vendors-complete', element: <VendorModal /> },
        ],
      },
      {
        path: ':id/edit',
        element: <ChecksNew />,
        loader: getCheck,
        action: updateCheckAction,
      },
      {
        path: ':id/delete',
        action: deleteCheckAction,
      },
      {
        path: 'tray',
        element: <Tray />,
        action: clearTray,
        loader: printingTraychecks,
        children: [
          {
            path: ':id',
            element: <Check />,
            loader: getCheck,
            action: toggleCheckStatus,
          },
        ],
      },
      {
        path: 'recipients',
        loader: getRecipientsLoader,
      },
      {
        path: 'toggle',
        action: toggleCheckStatus,
      },
      {
        path: 'void',
        action: voidCheck,
      },

      // {
      //   path: 'numeration',
      //   element: <CheckInitialNumber />,
      // },
    ],
  },
];
export default checksRoutes;
