import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

function CreateFormLabel({
  children,
  className,
  errors,
  inputName,
  label,
  labelAction,
  required,
  type,
}) {
  return (
    <Form.Group className={`mb-3 ${className || ''}`} controlId={inputName}>
      {type !== 'checkbox' && type !== 'switch' && (
        <Form.Label className="d-flex justify-content-between w-100 small">
          <span>
            {label}
            {required && <span className="text-danger">*</span>}
          </span>
          {labelAction && labelAction}
        </Form.Label>
      )}

      {children}
      <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
    </Form.Group>
  );
}

CreateFormLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  errors: PropTypes.string,
  inputName: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  labelAction: PropTypes.node,
};

export default CreateFormLabel;
