import React from 'react';
import propTypes from 'prop-types';
import TextField from '../../UI/text/TextField';
import FieldData from '../../UI/text/FieldData';
import { transformDateHook } from '../../Helpers/toDates';

const getDaysDiff = (start, end) => {
  const MSPERDAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const a = new Date(start);
  const b = new Date(end);
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MSPERDAY);
};

const isPastDate = (date) => {
  const dateToEval = new Date(date);
  const now = new Date();
  return dateToEval > now;
};

const getDaysLeftLabel = (start, end) => {
  const isExpired = isPastDate(end);
  const difference = getDaysDiff(start, end);
  return difference > 0 && isExpired ? `Days left: ${difference}` : 'Expired';
};

export default function DatesSection({ start, end }) {
  const created = transformDateHook(start);
  const today = new Date().toLocaleDateString('en-US');
  const expires = transformDateHook(end);
  const expireLabel = getDaysLeftLabel(today, end);
  return (
    <section className="d-flex align-items-end">
      <TextField label="Created">{created}</TextField>
      <TextField label="Expires">{expires}</TextField>
      <FieldData variant="lb-modal__price ms-auto">{expireLabel}</FieldData>
    </section>
  );
}

DatesSection.propTypes = {
  start: propTypes.string.isRequired,
  end: propTypes.string.isRequired,
};
