/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
// import './button-wrapper.styles.scss';

function ButtonWrapper({ children, ...delegated }) {
  const customClasses = {
    secondary: 'leadsbox-button--secondary',
  };

  return (
    <Button
      {...delegated}
      className={`leadsbox-button btn btn-primary ${
        customClasses[delegated.variant]
      } ${delegated.className}`}
    >
      {children}
    </Button>
  );
}

ButtonWrapper.propTypes = {
  children: PropTypes.node,
};

export default ButtonWrapper;
