/* eslint-disable react/prop-types */

import React from 'react';
import './TableWidget.scss';

const CUSTOM_CLASSES = {
  completed: 'completed',
  pending: 'pending',
  overdue: 'overdue',
};

function TableWidget({ children }) {
  return (
    <div className="table__overflow-container">
      <div className="table__container">{children}</div>
    </div>
  );
}

function Item({ children, status, onClick, onKeyDown, ...delegated }) {
  return (
    <div
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="row"
      tabIndex={-1}
      className={`item ${status && CUSTOM_CLASSES[status]}`}
      style={{ ...delegated.style }}
      type="button"
    >
      {children}
    </div>
  );
}

TableWidget.Item = Item;

export default TableWidget;
