// Note: Invoices Routes

// Loaders and actions
import { invoicesLoader } from './invoicesLA';
import GenericPage from '../../pages/GenericPage';
import createInvoice from './invoiceCreate';

const invoicesRoutes = [
  {
    path: 'invoices',
    element: <GenericPage />,
    loader: invoicesLoader,
    handle: {
      crumb: () => 'Invoices',
    },
    children: [...createInvoice('invoiceData')],
  },
];
export default invoicesRoutes;
