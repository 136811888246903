/* eslint-disable react/prop-types */
import React from 'react';
import { getCroppedAndResizedImage } from './utils';

export const ImageCropContext = React.createContext({});

const defaultImage = null;
const defaultCrop = { x: 0, y: 0 };
const defaultRotation = 0;
const defaultZoom = 1;
const defaultCroppedAreaPixels = null;

function ImageCropProvider({
  children,
  maxZoom = 3,
  minZoom = 0.2,
  zoomStep = 0.1,
  maxRotation = 360,
  minRotation = 0,
  rotationStep = 5,
}) {
  const [image, setImage] = React.useState(defaultImage);
  const [crop, setCrop] = React.useState(defaultCrop);
  const [rotation, setRotation] = React.useState(defaultRotation);
  const [zoom, setZoom] = React.useState(defaultZoom);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(
    defaultCroppedAreaPixels
  );

  const onCropComplete = React.useCallback(
    (_croppedArea, _croppedAreaPixels) => {
      setCroppedAreaPixels(_croppedAreaPixels);
    },
    []
  );

  const handleZoomIn = () => {
    if (zoom < maxZoom) {
      setZoom(zoom + zoomStep * 2);
    }
  };

  const handleZoomOut = () => {
    if (zoom > minZoom) {
      setZoom(zoom - zoomStep * 2);
    }
  };

  const handleRotateCw = () => {
    setRotation(rotation + rotationStep);
  };

  const handleRotateAntiCw = () => {
    setRotation(rotation - rotationStep);
  };

  // const getProcessedImage = async () => {
  //   if (image && croppedAreaPixels) {
  //     const croppedImage = await getCroppedAndResizedImage(
  //       image,
  //       croppedAreaPixels,
  //       rotation
  //     );
  //     const imageFile = new File([croppedImage.file], `img-${Date.now()}.png`, {
  //       type: 'image/png',
  //     });
  //     return imageFile;
  //   }
  //   return null;
  // };

  const getProcessedImage = async () => {
    try {
      if (image && croppedAreaPixels) {
        const croppedAndResizedImage = await getCroppedAndResizedImage(
          image,
          croppedAreaPixels,
          rotation
        );
        return croppedAndResizedImage; // Directly return the Data URL
      }
      return null;
    } catch (error) {
      throw new Error('Failed to get processed image');
    }
  };

  const resetStates = () => {
    setImage(defaultImage);
    setCrop(defaultCrop);
    setRotation(defaultRotation);
    setZoom(defaultZoom);
    setCroppedAreaPixels(defaultCroppedAreaPixels);
  };

  const resetTransformations = () => {
    setCrop(defaultCrop);
    setRotation(defaultRotation);
    setZoom(defaultZoom);
    setCroppedAreaPixels(defaultCroppedAreaPixels);
  };

  const valueObject = React.useMemo(() => {
    return {
      image,
      setImage,
      zoom,
      setZoom,
      rotation,
      setRotation,
      crop,
      setCrop,
      croppedAreaPixels,
      setCroppedAreaPixels,
      onCropComplete,
      getProcessedImage,
      handleZoomIn,
      handleZoomOut,
      handleRotateAntiCw,
      handleRotateCw,
      maxZoom,
      minZoom,
      zoomStep,
      maxRotation,
      minRotation,
      rotationStep,
      resetStates,
      resetTransformations,
    };
  }, [
    image,
    setImage,
    zoom,
    setZoom,
    rotation,
    setRotation,
    crop,
    setCrop,
    croppedAreaPixels,
    setCroppedAreaPixels,
    onCropComplete,
    getProcessedImage,
    handleZoomIn,
    handleZoomOut,
    handleRotateAntiCw,
    handleRotateCw,
    maxZoom,
    minZoom,
    zoomStep,
    maxRotation,
    minRotation,
    rotationStep,
    resetStates,
    resetTransformations,
  ]);

  return (
    <ImageCropContext.Provider value={valueObject}>
      {children}
    </ImageCropContext.Provider>
  );
}

export const useImageCropContext = () => React.useContext(ImageCropContext);

export default ImageCropProvider;
