import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import '../Widgets.scss';

export function EmptyWidget() {
  return (
    <div
      className="widgets__empty-widget rounded-2"
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      No entries for the selected range
    </div>
  );
}
function WidgetContainer({
  children,
  className = 'widgets__item-container p-4 shadow-sm d-flex flex-column',
}) {
  return <article className={className}>{children}</article>;
}

WidgetContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export function Select({ value, onChangeHandler, children }) {
  return (
    <Form.Select
      value={value}
      onChange={onChangeHandler}
      className="select ms-auto"
      size="sm"
    >
      {children}
    </Form.Select>
  );
}

Select.propTypes = {
  value: PropTypes.string,
  onChangeHandler: PropTypes.func,
  children: PropTypes.node,
};

export function Header({ title, cta = 'Call to Action', url, children }) {
  return (
    <header className="d-flex align-items-center mb-4">
      <div className="d-flex flex-column">
        <h2 className="title">{title}</h2>
        {url && (
          <Link className="call-to-action" to={url}>
            {cta} →
          </Link>
        )}
      </div>
      {children}
    </header>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  cta: PropTypes.string,
  children: PropTypes.node,
};

export function Body({
  fetcherState,
  entries,
  serverMessage,
  loadingComponent,
  children,
}) {
  if (fetcherState !== 'idle') {
    return loadingComponent || <p>Loading</p>;
  }

  if (serverMessage !== 'success') {
    return (
      <div
        className="widgets__error-widget rounded-2"
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        Error loading widget data, try again later
      </div>
    );
  }

  if (!entries) {
    return <EmptyWidget />;
  }

  return children;
}
Body.propTypes = {
  fetcherState: PropTypes.string,
  entries: PropTypes.number,
  serverMessage: PropTypes.string,
  loadingComponent: PropTypes.node,
  children: PropTypes.node,
};

WidgetContainer.Header = Header;
WidgetContainer.Select = Select;
WidgetContainer.Body = Body;

export default WidgetContainer;
