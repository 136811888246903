import React from 'react';
import PropTypes from 'prop-types';
import LBToggleButton from '../../UI/LBToogleButton/LBToggleButton';

function CreateToggleGroup({ element, value, setFieldValue }) {
  const { options, name } = element;

  const handleChange = (e) => {
    if (element?.change) element.change(e);
    setFieldValue(name, e.target.value);
  };

  return (
    <fieldset className="d-flex gap-2 flex-wrap">
      {options.map((option) => (
        <LBToggleButton
          name={name}
          key={option.value}
          color={option.color}
          value={option.value}
          onChange={handleChange}
          checked={value === option.value}
          style={{ whiteSpace: 'nowrap' }}
        >
          {option.label}
        </LBToggleButton>
      ))}
    </fieldset>
  );
}

CreateToggleGroup.propTypes = {
  setFieldValue: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  element: PropTypes.object,
  value: PropTypes.string,
};

export default CreateToggleGroup;
