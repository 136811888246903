import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams, useRouteLoaderData } from 'react-router-dom';
import { transformData } from './PaymentsModal/PaymentDocument';
import useFetcherOnce from '../Invoices/useFetcherOnce';
import PDFTesting from '../PDF/react-pdf/PDFTesting.tsx';

export default function SavingPaymentAndPdfUpload() {
  const { customerId } = useParams();
  const routeLoaders = customerId ? 'customerPaymentData' : 'paymentData';
  const loaderData = useRouteLoaderData(routeLoaders);
  const {
    user: { company_logo_base64: logoBase64 },
  } = useRouteLoaderData('root');
  const { payment, invoice: invoiceP } = loaderData?.data || {};
  const invoiceFetcher = useFetcherOnce(`/invoices/${invoiceP.id}`);
  const invoiceData = invoiceFetcher?.data?.data || {};
  if (!invoiceFetcher?.data?.data) return <div>Loading...</div>;
  const data = transformData({
    payment,
    invoice: invoiceData,
    logoBase64,
  });
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <Spinner animation="border" role="status">
          <span className="sr-only" />
        </Spinner>
        <h3 className="ms-2">Uploading Payment</h3>
      </div>
      <p>
        Your recipt will be available in a moment. Please don&rsquo;t close this
        page.
      </p>
      <div className="d-none">
        <PDFTesting data={data} save />
      </div>
    </>
  );
}
