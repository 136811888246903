import React from 'react';

function PiePlaceholder() {
  return (
    <div
      style={{
        flex: '1 1 20%',
        marginTop: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: '1 / 1',
        height: 'auto',
        borderRadius: '1000px',
        backgroundColor: 'var(--bs-gray-300)',

        maxHeight: 240,
        maxWidth: 240,
      }}
    >
      <div
        style={{
          //   width: '100%',
          //   height: 'auto',
          //   aspectRatio: '1 / 1',
          minWidth: '70%',
          minHeight: '70%',
          //   alignSelf: 'center',
          borderRadius: '1000px',
          backgroundColor: 'var(--bs-white)',
        }}
      />
    </div>
  );
}

function OneThirdPlaceholder() {
  return (
    <div className="animations__breathe" style={{ paddingTop: 28 }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
          gap: 32,
          paddingRight: 16,
          paddingLeft: 16,
        }}
      >
        <PiePlaceholder />
        <PiePlaceholder />
        <PiePlaceholder />
      </div>
      <div
        style={{
          marginTop: '30px',
          display: 'flex',

          paddingLeft: 24,
          paddingRight: 24,
          gap: '8%',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px',
        }}
      >
        {[0, 1, 2].map((item) => (
          <div
            style={{
              display: 'flex',
              gap: '8px',
              flexBasis: '30%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            key={item}
          >
            <span
              style={{
                display: 'inline-block',
                height: '40px',
                minWidth: '50px',
                width: '30%',
                borderRadius: '4px',
                backgroundColor: 'var(--bs-gray-300)',
              }}
            />
            <span
              style={{
                display: 'inline-block',
                minWidth: '80px',
                width: '60%',
                height: '20px',
                borderRadius: '4px',
                backgroundColor: 'var(--bs-gray-300)',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default OneThirdPlaceholder;
