import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { I_Data } from '../propTypes';

const s = StyleSheet.create({
  container: {
    width: '100%',
    padding: '130px 50px 20px 50px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 9,
    fontWeight: 300,
  },
  label: {
    fontSize: 8,
    fontWeight: 600,
  },
  name: {
    fontSize: 18,
    fontWeight: 600,
  },
  checkbox: {
    height: 8,
    width: 8,
    border: '1px solid gray',
    borderRadius: 3,
  },
  checkLine: {
    width: 80,
    borderBottom: '1px solid gray',
    alignSelf: 'flex-end',
  },
  paymentInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
});

function BillingTo({ data }: { data: I_Data }) {
  return (
    <View style={s.container}>
      <View style={s.content}>
        <View style={{ ...s.col, paddingTop: 8 }}>
          <Text style={s.label}>Billing to:</Text>
        </View>
        <View
          style={{
            ...s.col,
            width: data.type === 'receipt' ? '60%' : '100%',
            marginLeft: 8,
          }}
        >
          <Text style={s.name}>{data.customer.name}</Text>
          <Text>{data.customer.address.street_address_1}</Text>
          {data.customer.address?.street_address_2 && (
            <Text>{data.customer.address.street_address_2}</Text>
          )}
          <Text>
            {data.customer.address.city}, {data.customer.address.state}
          </Text>
          <Text>
            {data.customer.address.country}, {data.customer.address.zipcode}
          </Text>
        </View>
        {data.type === 'receipt' && (
          <View
            style={{
              ...s.col,
              width: '40%',
              justifyContent: 'center',
              gap: 4,
              marginLeft: 16,
              paddingLeft: 16,
              borderLeft: '1px solid gray',
            }}
          >
            <Text style={{ marginBottom: 4, fontSize: 10, fontWeight: 'bold' }}>
              Payment Information
            </Text>
            <View style={s.paymentInfoRow}>
              <View style={s.checkbox} />
              <Text>Credit Card</Text>
            </View>
            <View style={s.paymentInfoRow}>
              <View style={s.checkbox} />
              <Text>Cash</Text>
            </View>
            <View style={s.paymentInfoRow}>
              <View style={s.checkbox} />
              <Text>Check No.</Text>
              <View style={s.checkLine} />
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

export default BillingTo;
