export default function formatCurrency(value, digits = 0, style = true) {
  const format = style ? 'currency' : 'decimal';
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: format,
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: 2,
  }).format(value);

  return formattedValue;
}
