import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import formatCurrency from '../../Dashboard/Widgets/utils/formatCurrency';

import CHECK_DATA_SHAPE from './types';
import FONT_STYLES from '../../Reports/PDF/FontStyles';

const styles = StyleSheet.create({
  ...FONT_STYLES,
  container: {
    height: '249px',
  },
  dateAndName: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '32px',
    marginLeft: '58px',
    gap: '40px',
  },
  itemTable: {
    fontSize: 9.5,
    marginTop: '6px',
    marginLeft: '247px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '340px',
    gap: '8px',
  },
});

function Check({ data }) {
  const { recipient } = data;
  // console.log(data);

  return (
    <View style={styles.container}>
      <View style={styles.dateAndName}>
        <Text style={styles.bold}>
          {new Date(data.payment_date).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </Text>
        <Text style={styles.bold}>
          {recipient.name} {recipient?.lastname}
        </Text>
      </View>
      <View style={styles.itemTable}>
        {data.items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <View key={index} style={styles.itemRow}>
            <View style={{ width: '78%' }}>
              <Text>{item.description}</Text>
            </View>
            <View style={{ width: '22%', fontFamily: 'Courier-Bold' }}>
              <Text>$ {formatCurrency(item.amount, 2, false)}</Text>
            </View>
          </View>
        ))}
      </View>
      <View style={{ ...styles.itemTable, marginTop: 'auto', marginBottom: 0 }}>
        <View style={styles.itemRow}>
          <View style={{ width: '78%' }}>
            <Text>{data.memo}</Text>
          </View>
          <View style={{ width: '22%', fontFamily: 'Courier-Bold' }}>
            <Text>$ {formatCurrency(data.total, 2, false)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

Check.propTypes = {
  data: CHECK_DATA_SHAPE,
};

export default Check;
