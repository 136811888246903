import {
  isSameDay,
  areIntervalsOverlapping,
  getMinutes,
  getHours,
  getDay,
  set,
} from 'date-fns';

export function findAppointmentsByDate(date, appointmentsArr) {
  if (!appointmentsArr || !appointmentsArr.length) return [];
  const dateAppointments = appointmentsArr.filter((appointment) => {
    if (isSameDay(date, new Date(appointment.start_date))) {
      return appointment;
    }
    return false;
  });

  return dateAppointments;
}

export function findAppointmentsByInterval(start, end, appointmentsArr) {
  const weekInterval = {
    start,
    end,
  };

  const dateAppointments = appointmentsArr.filter((appointment) => {
    if (
      areIntervalsOverlapping(weekInterval, {
        start: new Date(appointment.start_date),
        end: new Date(appointment.end_date),
      })
    ) {
      return appointment;
    }
    return false;
  });

  return dateAppointments;
}

/*
  This function calculates the row start and end positions
  of the appointment item in the grid overlay.

  Appointment start and end must be Date ISO strings (not objects)
*/
export function calculateGridPosition(
  appointmentStart,
  appointmentEnd,
  intervalInMinutes
) {
  const startTime = new Date(appointmentStart);
  const endTime = new Date(appointmentEnd);

  // Calculate the start and end times in minutes from the beginning of the day (7:00 AM)
  const startMinutes = getHours(startTime) * 60 + getMinutes(startTime);

  const endMinutes = getHours(endTime) * 60 + getMinutes(endTime);

  // Calculate the grid row start and end values based on the specified interval
  const interval = intervalInMinutes || 30; // default to 30 minutes
  const gridRowStart = Math.floor(startMinutes / interval) + 1; // Add 1 because grid lines start at 1
  const gridRowEnd = Math.ceil(endMinutes / interval) + 1; // Add 1 because grid lines start at 1

  // DEGUG TABLE
  // console.table({
  //   startTime,
  //   endTime,
  //   gridRowStart,
  //   gridRowEnd,
  // });

  return { gridRowStart, gridRowEnd };
}

/* 
  This function generates an array that determines how many steps the
  scheduler will have. It expects an interval in minutes.
*/
export function calculateSlots(intervalInMinutes) {
  const startDate = new Date();
  const startTime = set(startDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const endDate = new Date();
  const endTime = set(endDate, {
    hours: 24,
    minutes: 0,
    seconds: 0,
  });

  const slotArray = [];

  let currentTime = startTime.getTime();
  while (currentTime < endTime.getTime()) {
    const nextTime = new Date(currentTime).setTime(
      currentTime + intervalInMinutes
    );

    slotArray.push({
      startTime: new Date(currentTime),
      endTime: new Date(nextTime),
    });
    currentTime = nextTime;
  }

  return slotArray;
}

// Day enum
export const WEEK_DAYS = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

// Helper function to group appointments by day
export const groupAppointmentsByDay = (appointments) => {
  if (!appointments?.length) return [];
  return appointments.reduce((grouped, appointment) => {
    const day = getDay(new Date(appointment.start_date)); // Replace with your actual date property
    // eslint-disable-next-line no-param-reassign
    grouped[day] = [...(grouped[day] || []), appointment];
    return grouped;
  }, {});
};

// Accepts a number and pads it with a "0" if it's less than 10
function padWithZero(num) {
  if (typeof num !== 'number') return new Error('padWithZero expects a number');

  if (num < 10) {
    // Add a "0" to the left of number and return as a string
    return `0${num.toString()}`;
  }
  // Return the original number as a string if it's not a single-digit number
  return num.toString();
}

// Helper function to format a Date object to the format
// the native date input expects (YYYY-MM-DD)
export const toLegacyDateString = (date) => {
  const day = padWithZero(date.getDate());
  const month = padWithZero(date.getMonth() + 1);
  const year = date.getFullYear().toString();

  return `${year}-${month}-${day}`;
};

/* Function that selectively renders the toggle group for status */
export const statusOptions = (isOverdue) => {
  const options = [
    {
      color: 'yellow',
      label: 'Pending',
      value: 'pending',
    },
    {
      color: 'green',
      label: 'Completed',
      value: 'completed',
    },
  ];

  if (isOverdue) {
    return [
      ...options,
      {
        color: 'red',
        label: 'Overdue',
        value: 'overdue',
      },
    ];
  }

  return options;
};
