import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useFetcher, useLocation } from 'react-router-dom';
import ProductCategoriesTable from './ProductCategoriesTable';
import ModalForActions from '../../UI/ModalForActions';

function ProductsCategories() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/products/categories') setShow(false);
    if (
      pathname.includes('/products/categories/update') ||
      pathname.includes('/products/categories/new')
    )
      setShow(true);
  }, [pathname]);

  const handleClose = () => {
    navigate('/products/categories');
  };
  const handleDelete = (rowID) => {
    fetcher.submit(
      {},
      { method: 'POST', action: `/products/categories/delete/${rowID}` }
    );
  };
  const handleEdit = (rowID, name) => {
    navigate(`/products/categories/update/${rowID}/${name}`);
  };

  return (
    <>
      <ProductCategoriesTable
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
      <ModalForActions
        show={show}
        onHide={handleClose}
        title={
          pathname === '/products/categories/new'
            ? 'Create new category'
            : 'Update category'
        }
      >
        <Outlet />
      </ModalForActions>
    </>
  );
}

export default ProductsCategories;
