import React from 'react';
import { parseISO, toDate } from 'date-fns';
import PrintStateManager from './PrintStateManager';

export default function useGetCheckTableData({ dbChecks, statuses }) {
  const nextChecks = dbChecks.map((check) => ({
    filterChips: `filter__${statuses[check.print_state].label}`,
    id: check.id,
    number: check.id,
    to: `${check.recipient.name} ${check.recipient.lastname ? check.recipient.lastname : ''}`,
    payment_date: toDate(parseISO(check.payment_date)),
    total: check.total,
    print_state: {
      label: statuses[check.print_state].label,
      color: statuses[check.print_state].color,
    },
    print_actions: (
      <PrintStateManager id={check.id} state={check.print_state} />
    ),
  }));
  return nextChecks;
}

// import ChecksActions from './ChecksActions';
// more: <ChecksActions id={check.id} state={check.printState} />,
