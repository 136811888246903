import React, { useEffect, useState } from 'react';
import { useActionData, useLoaderData, useSubmit } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import ReportsSearcher from '../../ReportsSearcher/ReportsSearcher';
import {
  formatShortDate,
  getCurrentFormattedDate,
  getCurrentTimeZone,
} from '../utils/helpers';
import BusinessOverviewPDF from '../../../PDF/ReportsPDFGenerator/BusinessOverview/BusinessOverviewPDF';
import { getCompanyInfo } from '../../../../routes/reports/reportsLA';

function ProfitLossCustomer() {
  const { customers } = useLoaderData();
  const [fetchedData, setFetchedData] = useState(false);
  const [data, setData] = useState({});
  const [selectOption, setSelectOption] = useState('none');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [enablePrintDownload, setEnablePrintDownload] = useState(false);
  const [userCompany, setUserCompany] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (toDate && fromDate !== '') {
      setEnablePrintDownload(true);
    }
    setFetchedData(false);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (selectOption !== 'none') {
      setFetchedData(false);
    }
  }, [selectOption, fromDate, toDate]);

  const customersArray = customers.map((item) => ({
    id: item.id,
    title: item.name,
  }));

  const submit = useSubmit();
  const actionData = useActionData();

  useEffect(() => {
    if (actionData !== undefined) {
      const entries = Object.entries(actionData.sales).map((user, index) => ({
        id: index + 1,
        customer: user[0],
        sales: actionData.sales[user[0]],
        expenses: actionData.expenses[user[0]],
      }));
      const result = {
        data: entries,
        total_sales: actionData.total_sales,
        total_expenses: actionData.total_expenses,
      };
      setData(result);
      setFetchedData(true);
    }
  }, [actionData]);

  const handleRunReport = async () => {
    setLoading(true);
    // const values = new FormData();
    // values.append('customer', selectOption);
    // values.append('from', fromDate);
    // values.append('to', toDate);
    const values = {
      customer: selectOption,
      from: fromDate,
      to: toDate,
    };
    submit(values, { method: 'post', encType: 'application/json' });
    setLoading(false);
  };

  const getUserCompany = async () => {
    const { user } = await getCompanyInfo();
    setUserCompany(user.user_company);
  };

  useEffect(() => {
    getUserCompany();
  }, []);

  const pdfFile = (
    <BusinessOverviewPDF
      fetchedData={data}
      profitLossCustomer
      title="Profit and Loss by Customer"
      from={formatShortDate(fromDate)}
      to={formatShortDate(toDate)}
      currentDate={getCurrentFormattedDate()}
      timezone={getCurrentTimeZone()}
      userCompany={userCompany}
    />
  );

  return (
    <div>
      <ReportsSearcher
        pdfFileName="profit-and-loss-by-customer"
        options={customersArray}
        setSelectOption={setSelectOption}
        selectOption={selectOption}
        setFromDate={setFromDate}
        fromDate={fromDate}
        toDate={toDate}
        setToDate={setToDate}
        pdfChildren={pdfFile}
        enablePrintDownload={enablePrintDownload}
        handleRunReport={enablePrintDownload ? handleRunReport : () => {}}
        loading={loading}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 40,
        }}
      >
        {fetchedData && (
          <PDFViewer height={705} width={500} showToolbar={false}>
            {pdfFile}
          </PDFViewer>
        )}
      </div>
    </div>
  );
}

export default ProfitLossCustomer;
