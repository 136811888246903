import React from 'react';
import { Form } from 'react-bootstrap';
/* eslint-disable react/prop-types */

export default function TextArea({
  element,
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
}) {
  const currentLength = values[element.name]?.length || 0;
  const maxChar = element?.maxLength || 300;
  const isInvalid = touched[element.name] && !!errors[element.name];
  return (
    <div
      style={{ position: 'relative' }}
      className={`${isInvalid ? 'is-invalid' : ''}`}
    >
      <Form.Control
        as="textarea"
        autoComplete={element?.autocomplete || 'on'}
        disabled={element?.disabled}
        isInvalid={touched[element.name] && !!errors[element.name]}
        isValid={touched[element.name] && !errors[element.name]}
        maxLength={maxChar}
        name={element.name}
        onBlur={handleBlur}
        onChange={(e) => {
          handleChange(e);
          if (element?.change) element.change(e);
        }}
        plaintext={element?.plaintext}
        placeholder={element.placeholder}
        required={element?.required}
        style={{
          fontSize: '0.875rem',
          paddingBottom: '1.5rem',
        }}
        value={values[element.name]}
      />
      <Form.Text
        className="text-muted"
        style={{
          bottom: '0',
          fontSize: '0.75rem',
          paddingRight: '0.75rem',
          paddingBottom: '0.25rem',
          position: 'absolute',
          right: '0',
        }}
      >
        Max length: {currentLength}/{maxChar}
      </Form.Text>
    </div>
  );
}
