/* eslint-disable react/prop-types */
import React from 'react';

import ImageButton from '../../../../UI/buttons/ImageButton';
import pattern1 from './images/pattern1.png';
import pattern2 from './images/pattern2.png';
import pattern3 from './images/pattern3.png';

function LayoutSelector({ handleLayoutChange }) {
  const layouts = [
    {
      image: pattern1,
      name: 1,
    },
    {
      image: pattern2,
      name: 2,
    },
    {
      image: pattern3,
      name: 3,
    },
  ];

  const style = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1rem',
  };

  return (
    <div style={style}>
      {layouts.map(({ name, image }) => (
        <ImageButton onClick={() => handleLayoutChange(name)} key={name}>
          <img src={image} alt={`Pattern number ${name}`} />
        </ImageButton>
      ))}
    </div>
  );
}

export default LayoutSelector;
