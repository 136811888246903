import {
  appointmentsLoader,
  newAppointmentAction,
  editAppointmentAction,
  deleteAppointmentAction,
  toggleAppointmentAction,
  deleteAppointmentUtil,
  appointmentLoader,
} from './appointmentsLA';
import EditAppointment from '../../components/Appointments/EditAppointment';
import NewAppointment from '../../components/Appointments/NewAppointment';
import ShowAppointment from '../../components/Appointments/ShowAppointment';
import GenericPage from '../../pages/GenericPage';
import AppointmentsLayout from '../../components/Appointments/AppointmentsLayout';
import DayView from '../../components/Appointments/DayView';
import WeekView from '../../components/Appointments/WeekView/WeekView';
import NewAppointmentModal from '../../components/Appointments/NewAppointmentModal';

const viewRoutes = [
  {
    path: 'new',
    element: <NewAppointmentModal />,
  },
  {
    path: ':appointmentId',
    loader: appointmentLoader,
    element: <ShowAppointment />,
  },
  {
    path: ':appointmentId/delete',
    action: deleteAppointmentAction,
  },
  {
    path: ':appointmentId/delete-util',
    action: deleteAppointmentUtil,
  },
  {
    path: ':appointmentId/toggle-status',
    action: toggleAppointmentAction,
  },
];

const appointmentsRoutes = [
  {
    path: '/appointments',
    id: 'appointments',
    loader: appointmentsLoader,
    element: <GenericPage />,
    handle: {
      crumb: () => 'Appointments',
    },
    children: [
      {
        element: <AppointmentsLayout />,

        children: [
          {
            path: '',
            element: <DayView />,
            handle: {
              linkTo: {
                label: 'New',
                to: '/../new',
              },
            },
            children: [...viewRoutes],
          },

          {
            path: 'week',
            // index: true,
            element: <WeekView />,
            handle: {
              linkTo: {
                label: 'New',
                to: '/../new',
              },
            },
            children: [...viewRoutes],
          },
          {
            path: '/appointments/new-extended',
            action: newAppointmentAction,
            element: <NewAppointment />,
            handle: {
              crumb: () => 'New',
            },
          },
          {
            path: ':appointmentId/edit',
            loader: appointmentLoader,
            action: editAppointmentAction,
            element: <EditAppointment />,
            handle: {
              crumb: () => 'Edit',
            },
          },
        ],
      },
    ],
  },
];

export default appointmentsRoutes;
