import React from 'react';
import PropTypes from 'prop-types';
import './card-wrapper.styles.scss';

function CardWrapper({ children }) {
  return <div className="card-style">{children}</div>;
}

CardWrapper.propTypes = {
  children: PropTypes.node,
};

export default CardWrapper;
