import React from 'react';

import { Button } from 'react-bootstrap';
import { Link, useMatch } from 'react-router-dom';
import useAppointmentContext from './Context/useAppointmentContext';

/*

  This component will:

  - Show the number of appointments for that particular week
    - Of those, how many are completed
    - Of those, how many are pending

*/

function AppointmentsActions() {
  const { thisWeekAppointments } = useAppointmentContext();
  const dayMatch = useMatch('/appointments');
  const weekMatch = useMatch('/appointments/week');

  return (
    <div
      style={{
        flex: '1 0 auto',
      }}
    >
      {dayMatch && (
        <Button
          className="w-100 mb-4"
          as={Link}
          to="week"
          variant="secondary"
          size="sm"
        >
          Go to week view →
        </Button>
      )}
      {weekMatch && (
        <Button
          className="w-100 mb-4"
          as={Link}
          to=".."
          variant="secondary"
          size="sm"
        >
          ← Back to scheduler
        </Button>
      )}
      {!dayMatch && !weekMatch && (
        <Button
          className="w-100 mb-4"
          as={Link}
          to=".."
          variant="secondary"
          size="sm"
          disabled
        >
          ...
        </Button>
      )}

      <header className="d-flex align-items-center justify-content-between mb-3">
        <h3>Appointments Activity per Week</h3>
      </header>

      <div>
        {!thisWeekAppointments?.length ? (
          <div className="appointments-week-view__actions-no-apps">
            No appointments for the current week
          </div>
        ) : (
          <div className="appointments-week-view__actions-container">
            {thisWeekAppointments.filter((app) => app.status === 'overdue')
              ?.length ? (
              <p className="appointments-week-view__actions overdue">
                <strong>
                  {
                    thisWeekAppointments.filter(
                      (app) => app.status === 'overdue'
                    )?.length
                  }
                </strong>{' '}
                | Overdue appointments
              </p>
            ) : undefined}

            {thisWeekAppointments.filter((app) => app.status === 'pending')
              ?.length ? (
              <p className="appointments-week-view__actions pending">
                <strong>
                  {
                    thisWeekAppointments.filter(
                      (app) => app.status === 'pending'
                    )?.length
                  }
                </strong>{' '}
                | Pending appointments
              </p>
            ) : undefined}

            {thisWeekAppointments.filter((app) => app.status === 'completed')
              ?.length ? (
              <p className="appointments-week-view__actions completed">
                <strong>
                  {
                    thisWeekAppointments.filter(
                      (app) => app.status === 'completed'
                    )?.length
                  }
                </strong>{' '}
                | Completed appointments
              </p>
            ) : undefined}
          </div>
        )}
      </div>
    </div>
  );
}

export default AppointmentsActions;
