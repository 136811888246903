import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import './PhoneInput.scss';

function CreatePhoneInput({
  errors,
  name,
  setFieldValue,
  setTouched,
  touched,
  value,
}) {
  const handleChange = (newValue) => {
    setFieldValue(name, newValue);
  };

  const handleFocus = () => {
    const newTouchedObj = {
      ...touched,
      [name]: true,
    };
    setTouched(newTouchedObj);
  };
  return (
    <PhoneInput
      className={`form-control ${errors && touched[name] ? 'is-invalid' : ''} ${
        !errors && touched[name] ? 'is-valid' : ''
      }`}
      international
      name={name}
      defaultCountry="US"
      value={formatPhoneNumberIntl(value)}
      onChange={handleChange}
      onBlur={() => handleFocus()}
      id={name}
    />
  );
}

CreatePhoneInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  setFieldValue: PropTypes.func,
  errors: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  touched: PropTypes.object,
  setTouched: PropTypes.func,
};

export default CreatePhoneInput;
