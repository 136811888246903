/* eslint react/prop-types: 0 */

import React from 'react';
import './StatusBadge.scss';
import { Badge } from 'react-bootstrap';
import statusCodes from './statusCodes';

function StatusBadge({ statusCode, type, customClasses }) {
  return (
    <Badge
      bg="custom"
      className={`${
        customClasses !== undefined
          ? customClasses
          : 'd-flex align-items-center ms-2'
      }`}
      style={{
        '--badge-color': `var(--bs-${statusCodes[type][statusCode].color})`,
        '--badge-bg': `var(--bs-box-shadow-${statusCodes[type][statusCode].color})`,
      }}
    >
      {statusCodes[type][statusCode].label}
    </Badge>
  );
}

export default StatusBadge;
