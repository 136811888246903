import BusinessScreenshot from '../../components/UI/Reports/BusinessOverview/BusinessScreenshot';
import ProfitLossCustomer from '../../components/UI/Reports/BusinessOverview/ProfitLossCustomer';
import ProfitLossReport from '../../components/UI/Reports/BusinessOverview/ProfitLossReport';
import Employees from '../../components/UI/Reports/Expenses/Employees';
import ExpensesCategory from '../../components/UI/Reports/Expenses/ExpensesCategory';
import ExpensesInvoice from '../../components/UI/Reports/Expenses/ExpensesInvoice';
import ExpensesMerchant from '../../components/UI/Reports/Expenses/ExpensesMerchant';
import ExpensesVendor from '../../components/UI/Reports/Expenses/ExpensesVendor';
import CSVFiles from '../../components/UI/Reports/Files/CSVFiles';
import CustomersCSVFiles from '../../components/UI/Reports/Files/CustomersCSVFiles';
import ProductsServicesList from '../../components/UI/Reports/Sales/ProductsServicesList';
import Sales from '../../components/Reports/Sales/Sales';
import SalesCustomers from '../../components/UI/Reports/Sales/SalesCustomers';
import SalesProducts from '../../components/UI/Reports/Sales/SalesProducts';
import SalesServices from '../../components/UI/Reports/Sales/SalesServices';
import GenericPage from '../../pages/GenericPage';
import ReportsPage from '../../pages/ReportsPage';
import {
  expensesByCategoryAction,
  expensesByInvoiceAction,
  expensesByMerchantAction,
  expensesByVendorAction,
  getAllCustomers,
  getAllInvoices,
  getAllProducts,
  getAllVendorMerchants,
  getCategories,
  getExpensesCategories,
  postCustomerProfitLoss,
  postCustomerSales,
  postExpenseInvoice,
  postExpenseMerchant,
  postExpenseVendor,
  postExpensesCategory,
  postProductServiceList,
  postProfitLoss,
  postSales,
  postSalesProducts,
  postSalesServices,
  productsAndServicesAction,
  profitAndLossAction,
  profitAndLossByCustomerAction,
  salesAction,
  salesByCustomerAction,
  salesByProductAction,
  salesByServiceAction,
} from './reportsLA';

import BusinessOverview from '../../components/Reports/BusinessOverview/BusinessOverview';
import ProfitAndLoss from '../../components/Reports/BusinessOverview/ProfitAndLoss/ProfitAndLoss';
import ProfitAndLossByCustomer from '../../components/Reports/BusinessOverview/ProfitAndLossByCustomer/ProfitAndLossByCustomer';
import SalesOld from '../../components/UI/Reports/Sales/SalesOld';
import SalesReport from '../../components/Reports/Sales/SalesReport/SalesReport';
import SalesByCustomer from '../../components/Reports/Sales/SalesByCustomer/SalesByCustomer';
import SalesByProduct from '../../components/Reports/Sales/SalesByProduct/SalesByProduct';
import SalesByService from '../../components/Reports/Sales/SalesByService/SalesByService';
import ProductsAndServicesList from '../../components/Reports/Sales/ProductsAndServicesList/ProductsAndServicesList';
import Expenses from '../../components/Reports/Expenses/Expenses';
import ExpensesByCategory from '../../components/Reports/Expenses/ExpensesByCategory/ExpensesByCategory';
import ExpensesByInvoice from '../../components/Reports/Expenses/ExpensesByInvoice/ExpensesByInvoice';
import ExpensesByVendor from '../../components/Reports/Expenses/ExpensesByVendor/ExpensesByVendor';
import ExpensesByMerchant from '../../components/Reports/Expenses/ExpensesByMerchant/ExpensesByMerchant';

const reportsRoutes = [
  {
    path: '',
    element: <GenericPage />,
    handle: {
      crumb: () => 'Reports',
      sublinks: () => [
        {
          label: 'Business Overview',
          to: '/reports/business-overview',
        },
        {
          label: 'Sales',
          to: '/reports/sales',
        },
        {
          label: 'Expenses',
          to: '/reports/expenses',
        },
      ],
    },
    children: [
      {
        path: '/reports/business-overview',
        element: <BusinessOverview />,

        children: [
          {
            path: 'profit-and-loss-report',
            element: <ProfitAndLoss />,
            action: profitAndLossAction,
          },
          {
            path: 'profit-and-loss-by-customer',
            element: <ProfitAndLossByCustomer />,
            action: profitAndLossByCustomerAction,
          },
        ],
      },
      {
        path: '/reports/sales',
        element: <Sales />,
        children: [
          {
            path: 'sales-report',
            element: <SalesReport />,
            action: salesAction,
          },
          {
            path: 'sales-by-customer',
            element: <SalesByCustomer />,
            action: salesByCustomerAction,
          },
          {
            path: 'sales-by-product',
            element: <SalesByProduct />,
            action: salesByProductAction,
          },
          {
            path: 'sales-by-service',
            element: <SalesByService />,
            action: salesByServiceAction,
          },
          {
            path: 'products-and-services-list',
            element: <ProductsAndServicesList />,
            action: productsAndServicesAction,
          },
        ],
      },
      {
        path: '/reports/expenses',
        element: <Expenses />,
        children: [
          {
            path: 'expenses-by-category',
            element: <ExpensesByCategory />,
            action: expensesByCategoryAction,
          },
          {
            path: 'expenses-by-invoice',
            element: <ExpensesByInvoice />,
            action: expensesByInvoiceAction,
          },
          {
            path: 'expenses-by-merchant',
            element: <ExpensesByMerchant />,
            action: expensesByMerchantAction,
          },
          {
            path: 'expenses-by-vendor',
            element: <ExpensesByVendor />,
            action: expensesByVendorAction,
          },
        ],
      },
    ],
  },
  {
    path: 'old_reports',
    element: <GenericPage />,
    handle: {
      crumb: () => 'Reports',
    },
    children: [
      {
        path: '',
        index: true,
        element: <ReportsPage />,
      },
      // BUSINESS OVERVIEW
      {
        path: 'business_screenshot',
        element: <BusinessScreenshot />,
        handle: {
          crumb: () => 'Business Screenshot',
        },
      },
      {
        path: 'profit_loss_customer',
        element: <ProfitLossCustomer />,
        loader: getAllCustomers,
        action: postCustomerProfitLoss,
        handle: {
          crumb: () => 'Profit and Loss by Customer',
        },
      },
      {
        path: 'profit_loss_report',
        element: <ProfitLossReport />,
        action: postProfitLoss,
        handle: {
          crumb: () => 'Profit and Loss Report',
        },
      },
      // SALES
      {
        path: 'sales',
        element: <SalesOld />,
        action: postSales,
        handle: {
          crumb: () => 'Sales',
        },
      },
      {
        path: 'sales_customers',
        element: <SalesCustomers />,
        loader: getAllCustomers,
        action: postCustomerSales,
        handle: {
          crumb: () => 'Sales by Customers',
        },
      },
      {
        path: 'sales_products',
        element: <SalesProducts />,
        loader: getAllProducts,
        action: postSalesProducts,
        handle: {
          crumb: () => 'Sales by Products',
        },
      },
      {
        path: 'sales_services',
        element: <SalesServices />,
        loader: getAllProducts,
        action: postSalesServices,
        handle: {
          crumb: () => 'Sales by Services',
        },
      },
      {
        path: 'products_services_list',
        element: <ProductsServicesList />,
        loader: getCategories,
        action: postProductServiceList,
        handle: {
          crumb: () => 'Products and Services List',
        },
      },
      // EXPENSES
      {
        path: 'employees',
        element: <Employees />,
        loader: getAllCustomers,
        handle: {
          crumb: () => 'Employees',
        },
      },
      {
        path: 'by_category',
        element: <ExpensesCategory />,
        loader: getExpensesCategories,
        action: postExpensesCategory,
        handle: {
          crumb: () => 'Expenses by Category',
        },
      },
      {
        path: 'by_invoice',
        element: <ExpensesInvoice />,
        loader: getAllInvoices,
        action: postExpenseInvoice,
        handle: {
          crumb: () => 'Expenses by Invoice',
        },
      },
      {
        path: 'by_merchant',
        element: <ExpensesMerchant />,
        loader: getAllVendorMerchants,
        action: postExpenseMerchant,
        handle: {
          crumb: () => 'Expenses by Merchant',
        },
      },
      {
        path: 'by_vendor',
        element: <ExpensesVendor />,
        loader: getAllVendorMerchants,
        action: postExpenseVendor,
        handle: {
          crumb: () => 'Expenses by Vendor',
        },
      },
      // FILES
      {
        path: 'csv_files',
        element: <CSVFiles />,
        loader: getAllCustomers,
        handle: {
          crumb: () => 'CSV Files (Quickbooks) Import, Export',
        },
      },
      {
        path: 'customers_csv_files',
        element: <CustomersCSVFiles />,
        loader: getAllCustomers,
        action: postCustomerSales,
        handle: {
          crumb: () => 'Customers CSV Files',
        },
      },
    ],
  },
];

// const reportsRoutes = [
//   {
//     path: 'old_reports',
//     element: <GenericPage />,
//     handle: {
//       crumb: () => 'Reports',
//     },
//     children: [
//       {
//         path: '',
//         index: true,
//         element: <ReportsPage />,
//       },
//       // BUSINESS OVERVIEW
//       {
//         path: 'business_screenshot',
//         element: <BusinessScreenshot />,
//         handle: {
//           crumb: () => 'Business Screenshot',
//         },
//       },
//       {
//         path: 'profit_loss_customer',
//         element: <ProfitLossCustomer />,
//         loader: getAllCustomers,
//         action: postCustomerProfitLoss,
//         handle: {
//           crumb: () => 'Profit and Loss by Customer',
//         },
//       },
//       {
//         path: 'profit_loss_report',
//         element: <ProfitLossReport />,
//         action: postProfitLoss,
//         handle: {
//           crumb: () => 'Profit and Loss Report',
//         },
//       },
//       // SALES
//       {
//         path: 'sales',
//         element: <Sales />,
//         action: postSales,
//         handle: {
//           crumb: () => 'Sales',
//         },
//       },
//       {
//         path: 'sales_customers',
//         element: <SalesCustomers />,
//         loader: getAllCustomers,
//         action: postCustomerSales,
//         handle: {
//           crumb: () => 'Sales by Customers',
//         },
//       },
//       {
//         path: 'sales_products',
//         element: <SalesProducts />,
//         loader: getAllProducts,
//         action: postSalesProducts,
//         handle: {
//           crumb: () => 'Sales by Products',
//         },
//       },
//       {
//         path: 'sales_services',
//         element: <SalesServices />,
//         loader: getAllProducts,
//         action: postSalesServices,
//         handle: {
//           crumb: () => 'Sales by Services',
//         },
//       },
//       {
//         path: 'products_services_list',
//         element: <ProductsServicesList />,
//         loader: getCategories,
//         action: postProductServiceList,
//         handle: {
//           crumb: () => 'Products and Services List',
//         },
//       },
//       // EXPENSES
//       {
//         path: 'employees',
//         element: <Employees />,
//         loader: getAllCustomers,
//         handle: {
//           crumb: () => 'Employees',
//         },
//       },
//       {
//         path: 'by_category',
//         element: <ExpensesCategory />,
//         loader: getExpensesCategories,
//         action: postExpensesCategory,
//         handle: {
//           crumb: () => 'Expenses by Category',
//         },
//       },
//       {
//         path: 'by_invoice',
//         element: <ExpensesInvoice />,
//         loader: getAllInvoices,
//         action: postExpenseInvoice,
//         handle: {
//           crumb: () => 'Expenses by Invoice',
//         },
//       },
//       {
//         path: 'by_merchant',
//         element: <ExpensesMerchant />,
//         loader: getAllVendorMerchants,
//         action: postExpenseMerchant,
//         handle: {
//           crumb: () => 'Expenses by Merchant',
//         },
//       },
//       {
//         path: 'by_vendor',
//         element: <ExpensesVendor />,
//         loader: getAllVendorMerchants,
//         action: postExpenseVendor,
//         handle: {
//           crumb: () => 'Expenses by Vendor',
//         },
//       },
//       // FILES
//       {
//         path: 'csv_files',
//         element: <CSVFiles />,
//         loader: getAllCustomers,
//         handle: {
//           crumb: () => 'CSV Files (Quickbooks) Import, Export',
//         },
//       },
//       {
//         path: 'customers_csv_files',
//         element: <CustomersCSVFiles />,
//         loader: getAllCustomers,
//         action: postCustomerSales,
//         handle: {
//           crumb: () => 'Customers CSV Files',
//         },
//       },
//     ],
//   },
// ];

export default reportsRoutes;
