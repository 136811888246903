import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import Check from './Check';
import CHECK_DATA_SHAPE from './types';

const styles = StyleSheet.create({
  container: {},
});

function Body({ data }) {
  return (
    <View style={styles.container}>
      <Check data={data} />
      <Check data={data} />
    </View>
  );
}

Body.propTypes = {
  data: CHECK_DATA_SHAPE,
};

export default Body;
