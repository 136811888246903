import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from './styles';

function BusinessOverviewPDF({
  title = 'Insert a Title',
  from = '',
  to = '',
  currentDate = '',
  timezone = '',
  fetchedData = {},
  profitLossReport = false,
  profitLossCustomer = false,
  userCompany,
}) {
  // PROFIT & LOSS REPORT
  if (profitLossReport)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>

          <View style={styles.body}>
            <View style={styles.strongDivider} />
            <View style={styles.categoriesBar}>
              <Text style={styles.textCategories}>Income</Text>
            </View>
            <View style={styles.softDivider} />
            <View style={styles.incomeContent}>
              <View style={styles.productsContainer}>
                <Text style={styles.categoryText}>Products</Text>
                <Text style={styles.categoryText}>
                  ${fetchedData.incomes?.product}
                </Text>
              </View>
              <View style={styles.servicesContainer}>
                <Text style={styles.categoryText}>Services</Text>
                <Text style={styles.categoryText}>
                  ${fetchedData.incomes?.service}
                </Text>
              </View>
            </View>
            <View style={styles.softDivider} />
            <View style={styles.totalGrossContainer}>
              <Text style={styles.totalSalesTxt}>
                Total Sales (Gross Profit)
              </Text>
              <Text style={styles.totalSalesGrossPriceTxt}>
                ${fetchedData.total_invoice}
              </Text>
            </View>
            <View style={styles.strongDivider} />
            <View style={{ ...styles.categoriesBar, marginHorizontal: 10 }}>
              <Text style={styles.textCategories}>Expenses</Text>
            </View>
            <View style={styles.softDivider} />
          </View>
          <View>
            {fetchedData.expenses?.map((item) => {
              return (
                <View key={item.id} style={styles.expensesContent}>
                  <Text style={{ ...styles.categoryText, marginTop: 5 }}>
                    {item.name}
                  </Text>
                  <Text style={{ ...styles.categoryText, marginTop: 5 }}>
                    ${item.amount}
                  </Text>
                </View>
              );
            })}
          </View>
          <View
            style={{ ...styles.softDivider, marginTop: 50, marginBottom: 10 }}
          />
          <View style={styles.totalGrossContainer}>
            <Text style={styles.totalSalesTxt}>Total Expenses</Text>
            <Text style={styles.totalSalesGrossPriceTxt}>
              ${fetchedData.total_expense}
            </Text>
          </View>
          <View style={{ ...styles.strongDivider, marginTop: 10 }} />
          <View style={styles.netIncomeContainer}>
            <Text style={styles.totalSalesTxt}>Net Income</Text>
            <Text style={styles.totalSalesGrossPriceTxt}>
              ${fetchedData.total_invoice - fetchedData.total_expense}
            </Text>
          </View>

          <View style={styles.altFooter}>
            <Text
              style={{
                fontSize: 12,
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
              }}
            >
              Report Generated on
            </Text>
            <Text
              style={{
                marginLeft: 5,
                fontSize: 12,
                fontFamily: 'Montserrat',
              }}
            >
              {currentDate}
            </Text>
            <Text
              style={{
                marginLeft: 5,
                fontSize: 12,
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
              }}
            >
              {timezone}
            </Text>
          </View>
        </Page>
      </Document>
    );

  // PROFIT & LOSS BY CUSTOMER
  if (profitLossCustomer)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>

          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View style={styles.categoriesBar}>
              <View style={styles.customer}>
                <Text style={{ ...styles.categoryBarText, marginLeft: 10 }}>
                  Customer
                </Text>
              </View>
              <View style={styles.otherCategoriesContainer}>
                <View style={styles.categoryBarContainer}>
                  <Text style={styles.categoryBarText}>Sales</Text>
                </View>
                <View style={styles.categoryBarContainer}>
                  <Text style={styles.categoryBarText}>Expenses</Text>
                </View>
                <View style={{ borderRight: 1, marginVertical: -5 }} />
                <View style={styles.categoryBarContainer}>
                  <Text style={{ ...styles.categoryBarText, marginLeft: 10 }}>
                    Net Income
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={{ ...styles.content, top: 180 }}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.id}
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    paddingLeft: 20,
                  }}
                >
                  <Text style={{ ...styles.contentText, paddingLeft: 0 }}>
                    {item.customer}
                  </Text>
                  <View style={styles.verticalDivider} />
                  <Text
                    style={{
                      ...styles.contentText,
                      width: 100,
                      paddingLeft: 10,
                    }}
                  >
                    ${item.sales}
                  </Text>
                  <View style={styles.verticalDivider} />
                  <Text
                    style={{
                      ...styles.contentText,
                      width: 100,
                      paddingLeft: 10,
                    }}
                  >
                    ${item.expenses}
                  </Text>
                  <View style={styles.verticalDivider} />
                  <Text
                    style={{
                      ...styles.contentText,
                      width: 100,
                      paddingLeft: 10,
                    }}
                  >
                    ${item.sales - item.expenses}
                  </Text>
                </View>
              );
            })}
            <View style={styles.bottomStrongDivider} />
            <View style={styles.categoriesBar}>
              <View style={styles.customer}>
                <Text style={{ ...styles.categoryBarText, marginLeft: 10 }}>
                  Total:
                </Text>
              </View>
              <View style={styles.otherCategoriesContainer}>
                <View style={styles.categoryBarContainer}>
                  <Text style={styles.categoryBarText}>
                    ${fetchedData.total_sales}
                  </Text>
                </View>
                <View style={styles.categoryBarContainer}>
                  <Text style={styles.categoryBarText}>
                    ${fetchedData.total_expenses}
                  </Text>
                </View>
                <View style={{ borderRight: 1, marginVertical: -5 }} />
                <View style={styles.categoryBarContainer}>
                  <Text style={{ ...styles.categoryBarText, marginLeft: 10 }}>
                    ${fetchedData.total_sales - fetchedData.total_expenses}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
}

BusinessOverviewPDF.propTypes = {
  title: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  fetchedData: PropTypes.shape({}),
  currentDate: PropTypes.string,
  timezone: PropTypes.string,
  profitLossReport: PropTypes.bool,
  profitLossCustomer: PropTypes.bool,
  userCompany: PropTypes.string,
};

export default BusinessOverviewPDF;
