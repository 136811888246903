/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
/* eslint-disable no-use-before-define */

import React from 'react';
import * as Yup from 'yup';
import { useParams, useFetcher, useSubmit } from 'react-router-dom';
import CreateForm from '../Forms/CreateForm/CreateForm';
import InvoiceRecurrenceForm from '../Invoices/InvoiceRecurrenceForm';
import File from '../Forms/FileDragAndDrop/File';
import createFileForm from '../Forms/helpers/createFileForm';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import FormWrapper from '../Forms/builders/FormWrapper';
import useIsAuthorized from '../../routes/protectedPages/useIsAuthorized';

function CreateExpense({ data = {} }) {
  const {
    amount,
    as_at,
    category_id,
    vendor_id,
    merchant_id,
    recurrent,
    recur_frequency,
    recur_day,
    recur_date,
    recur_till,
    notes,
    invoice_id,
    files,
    expense_id,
  } = data;

  const TODAY = new Date().toISOString().substring(0, 10);
  const expenseInformation = [
    {
      formGroup: 'Expense information',
      groupChildren: [
        {
          name: 'amount',
          label: 'Amount',
          initialValue: amount || 0,
          type: Yup.number()
            .positive('Amount must be more than zero')
            .required('Please complete the field'),
          formType: 'number',
          required: true,
        },
        {
          name: 'as_at',
          label: 'Date',
          initialValue: as_at || TODAY,
          type: Yup.date().required('Please complete the field'),
          formType: 'date',
          required: true,
        },
        {
          initialValue: category_id || '',
          name: 'category_id',
          label: 'Category',
          type: Yup.string().required('Please complete the field'),
          formType: 'expenseCategory',
          required: true,
        },
        {
          initialValue:
            (vendor_id && `v${vendor_id}`) ||
            (merchant_id && `m${merchant_id}`) ||
            '',
          label: 'Select merchant or vendor',
          name: 'merchant_vendor_selector',
          type: Yup.string(),
          formType: 'merchantsAndVendors',
        },
        {
          initialValue: vendor_id || '',
          label: 'vendor_id',
          name: 'vendor_id',
          type: Yup.string(),
          className: 'd-none',
        },
        {
          initialValue: merchant_id || '',
          label: 'merchant_id',
          name: 'merchant_id',
          type: Yup.string(),
          className: 'd-none',
        },
        {
          initialValue: invoice_id || '',
          name: 'invoice_id',
          label: 'Select invoice',
          type: Yup.string(),
          formType: 'invoiceSelect',
          change: () => {
            formik.setFieldValue('recurrent', 0);
            formik.setFieldValue('recur_frequency', '');
            formik.setFieldValue('recur_day', '');
            formik.setFieldValue('recur_date', '');
            formik.setFieldValue('recur_till', '');
          },
        },
      ],
    },
  ];
  const recurrence = [
    {
      formGroup: 'Recurrence',
      groupChildren: [
        {
          name: 'recurrent',
          label: 'Make this expense recurrent',
          initialValue: recurrent || 0,
          type: Yup.string(),
        },
        {
          name: 'recur_frequency',
          label: 'Recur frequency',
          initialValue: recur_frequency || '',
          type: Yup.string().when(['recurrent'], {
            is: '1',
            then: (schema) => schema.required('Please select a frequency'),
          }),
          formType: 'select',
          options: [
            { label: 'Never', value: '0' },
            { label: 'Daily', value: 'daily' },
            { label: 'Weekly', value: 'weekly' },
            { label: '2 Weekly', value: '2_weekly' },
            { label: 'Monthly', value: 'monthly' },
          ],
          change: (e) => {
            const nextRecurrent = e === '0' || e === '' ? 0 : 1;
            formik.setFieldValue('recurrent', nextRecurrent); // eslint-disable-line no-use-before-define
          },
          required: true,
        },
        {
          name: 'recur_day',
          label: 'Recurence day',
          initialValue: recur_day || '',
          type: Yup.string().when(['recur_frequency'], {
            is: (recurFrequency) =>
              recurFrequency === 'weekly' || recurFrequency === '2_weekly',
            then: (schema) => schema.required('Please select a day'),
          }),
          formType: 'select',
          options: [
            { label: 'Monday', value: 'monday' },
            { label: 'Tuesday', value: 'tuesday' },
            { label: 'Wednesday', value: 'wednesday' },
            { label: 'Thursday', value: 'thursday' },
            { label: 'Friday', value: 'friday' },
            { label: 'Saturday', value: 'saturday' },
            { label: 'Sunday', value: 'sunday' },
          ],
          rule: ['recur_frequency', 'weekly'],
          required: true,
        },
        {
          name: 'recur_date',
          label: 'Recurence date',
          initialValue: recur_date || '',
          type: Yup.date()
            .when(['as_at'], {
              is: (as) => as !== '',
              then: (schema) =>
                schema.min(
                  Yup.ref('as_at'),
                  "End recurrence date can't be before date created"
                ),
            })
            .when(['recur_frequency'], {
              is: 'monthly',
              then: (schema) => schema.required('Please select a date'),
            }),
          formType: 'date',
          required: true,
        },
        {
          name: 'recur_till',
          label: 'Recurence ends',
          initialValue: recur_till || '',
          type: Yup.date()
            .min(TODAY, 'End date can not be in the past')
            .when(['as_at'], {
              is: (as) => as !== '',
              then: (schema) =>
                schema.min(
                  Yup.ref('as_at'),
                  "End recurrence date can't be before date"
                ),
            })
            .when(['recur_frequency'], {
              is: (frequency) => frequency === 'monthly',
              then: (schema) =>
                schema.min(
                  Yup.ref('recur_date'),
                  "End recurrence date can't be before first recurrence date"
                ),
            })
            .when(['recur_frequency'], {
              is: (frequency) =>
                frequency === 'weekly' ||
                frequency === '2_weekly' ||
                frequency === 'monthly',

              then: (schema) => schema.required('Please select a date'),
            }),
          formType: 'date',
          required: true,
        },
      ],
    },
  ];
  const additonalInformation = [
    // NOTES
    {
      formGroup: 'Notes',
      groupChildren: [
        {
          name: 'notes',
          label: 'Notes',
          initialValue: notes || '',
          type: Yup.string(),
          formType: 'textarea',
          className: 'input-wide',
        },
      ],
    },
  ];
  const filesSchema = [
    // FILES
    {
      name: 'files',
      label: 'Upload files',
      type: Yup.string(),
      initialValue: files || '',
    },
  ];

  const formValues = [
    ...expenseInformation,
    ...additonalInformation,
    ...recurrence,
    ...filesSchema,
  ];
  const { expenseId } = useParams();
  const reactRouterSubmit = useSubmit();
  const submitAction = async (values) => {
    if (expenseId) {
      const nextValues = createFileForm(values, formData);
      nextValues.append('id', expenseId);
      reactRouterSubmit(nextValues, {
        action: './update',
        method: 'post',
        encType: 'multipart/form-data',
      });
    } else {
      const nextValues = createFileForm(values, formData);
      reactRouterSubmit(nextValues, {
        method: 'post',
        encType: 'multipart/form-data',
      });
    }
  };

  const [formik] = useFormikTemplate({
    initial: formValues,
    yupValues: formValues,
    submitAction,
  });
  // FILE COMPONENT STATE AND FUNCTIONS START
  // Tracks save the file sin this component cause formik is tracking them but no saving them
  const [formData, setFormData] = React.useState({});

  // Remove file from db when the user its updating
  const fetcher = useFetcher();

  const deleteFile = (name) => {
    if (data[name] === formik.values[name] && data[name] !== null) {
      if (fetcher.state === 'submiting') return;
      fetcher.submit(
        {
          module: 'expense',
          id: expense_id,
          file: 'expense_file',
        },
        { method: 'post', action: '/expenses/delete-expense-files' }
      );
    }
  };

  const recurrentAllowed = useIsAuthorized({ tiersAllowed: ['tier_3'] });

  return (
    <FormWrapper
      id="CreateExpense"
      handleSubmit={formik.handleSubmit}
      isSubmitting={formik.isSubmitting}
      dirty={formik.dirty}
      isValid={formik.isValid}
    >
      <div className="row">
        <div className="col-md-6">
          <CreateForm structure={expenseInformation} {...formik} width="" />
          {formik.values.invoice_id === '' && recurrentAllowed && (
            <div className="mb-1">
              <h5 className="mb-4 mt-4">Special features</h5>{' '}
              <InvoiceRecurrenceForm {...formik} />
            </div>
          )}
        </div>

        <div className="col-md-6">
          <CreateForm structure={additonalInformation} {...formik} />
          <File
            value={formik.values.files}
            setFieldValue={formik.setFieldValue}
            setFormData={setFormData}
            deleteFile={deleteFile}
            name="files"
            label="Attachments"
            resize={{ type: 'cropAndResize', x: 1020, y: 1320 }}
          />
        </div>
      </div>
    </FormWrapper>
  );
}

export default CreateExpense;
