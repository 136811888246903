/* eslint-disable no-use-before-define */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useLoaderData, useParams, useSubmit } from 'react-router-dom';
import { date, string } from 'yup';
import {
  areIntervalsOverlapping,
  differenceInMinutes,
  isAfter,
  isEqual,
  isValid,
  parseISO,
  set,
} from 'date-fns';

import useAppointmentContext from './Context/useAppointmentContext';
import {
  findAppointmentsByDate,
  statusOptions,
  toLegacyDateString,
} from './utils/helpers';
import useCustomerFormData from './utils/useCustomerFormData';
import LBMapContainer from './LBMapContainer';
import { setCurrentDay, setFormikValues } from './utils/setCurrentDay';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import FormWrapper from '../Forms/builders/FormWrapper';
import CreateForm from '../Forms/CreateForm/CreateForm';
import checkIfPastDay from './utils/customValidations';
import {
  isPhoneNumber,
  textInput,
  textarea,
} from '../Forms/yup/customValidations';

function EditAppointment() {
  const {
    currentDate,
    updateCurrentDay,
    appointments,
    customerNames,
    customers,
  } = useAppointmentContext();
  const { appointmentId } = useParams();

  const { data: appointment } = useLoaderData();

  // We determine what's the max date for that day
  const maxDate = React.useMemo(() => {
    return set(currentDate, {
      hours: 23,
      minutes: 59,
    });
  }, [currentDate]);

  // Get the current date's appointments (this could be moved to
  // the context, I've been using it in several places...)
  const isOverlapping = React.useCallback(
    (appStart, appEnd) => {
      if (!isValid(appStart) || !isValid(appEnd)) return true;
      if (appStart > appEnd) return true;
      const todaysAppointments = findAppointmentsByDate(
        currentDate,
        appointments
      );
      return !todaysAppointments.some((app) => {
        if (app.id.toString() === appointmentId) return false;
        const intervalOverlap = areIntervalsOverlapping(
          {
            start: new Date(app.start_date),
            end: new Date(app.end_date),
          },
          { start: appStart, end: appEnd }
        );
        return intervalOverlap;
      });
    },
    [currentDate]
  );

  const fields = [
    {
      formGroup: 'Appointment details',
      groupChildren: [
        {
          name: 'name',
          formType: 'text',
          label: 'Appointment Name',
          initialValue: appointment?.name || '',
          type: textInput.required('Please complete the field'),
          required: true,
        },
        {
          name: 'current_day',
          formType: 'date',
          label: 'Appointment Day',
          // This weird string split is needed to use the vanilla date input
          // we should -probably- use a better date picker component
          // in the future using the react-calendar library as a base
          initialValue: toLegacyDateString(new Date(appointment?.start_date)),
          change: (e) => {
            const nextDate = setCurrentDay({
              initialStartDate: formik.values.start_date,
              initialEndDate: formik.values.end_date,
              dateValue: e.target.value,
            });

            setFormikValues({
              updateCurrentDay,
              formik,
              nextDate,
            });
          },
          type: string()
            .required()
            .test({
              name: 'checkIfPastDay',
              exclusive: false,
              params: {},
              message: "Can't create appointments in the past",
              test(value) {
                return checkIfPastDay(value, new Date(appointment?.start_date));
              },
            }),
          required: true,
        },
        {
          name: 'start_date',
          formType: 'time',
          label: 'Start time',
          initialValue: new Date(appointment.start_date),
          className: 'flex__span-3',
          type: date()
            .test({
              name: 'minOrNotEqual',
              exclusive: false,
              params: {},
              message: "Date can't be in the past",
              test(value) {
                if (isEqual(value, new Date(appointment.start_date))) {
                  return true;
                }
                if (isAfter(value, new Date())) {
                  return true;
                }
                return false;
              },
            })
            .test({
              name: 'isWithinInterval',
              exclusive: false,
              params: {},
              message: 'Appointment overlapping',
              test(value) {
                if (isEqual(value, new Date(appointment.start_date)))
                  return true;
                return isOverlapping(new Date(value), this.parent.end_date);
              },
            })
            .required('Please complete the field'),
          required: true,
        },
        {
          name: 'end_date',
          formType: 'time',
          label: 'End time',
          initialValue: new Date(appointment.end_date),
          className: 'flex__span-3',
          type: date()
            .test({
              name: 'greaterThanStart',
              exclusive: false,
              params: {},
              message: 'End date must be greater than start date',
              test(value) {
                return value > this.parent.start_date;
              },
            })
            .test({
              name: 'isWithinInterval',
              exclusive: false,
              params: {},
              message: 'Appointment overlapping',
              test(value) {
                if (isEqual(value, new Date(appointment.end_date))) return true;
                const overlap = isOverlapping(
                  this.parent.start_date,
                  new Date(value)
                );
                return overlap;
              },
            })
            .test({
              name: 'minimumDuration',
              exclusive: false,
              params: {},
              message: 'Minimum duration is 30 minutes',
              test() {
                const delta = differenceInMinutes(
                  new Date(this.parent.end_date),
                  new Date(this.parent.start_date)
                );
                return delta >= 30;
              },
            })
            .test({
              name: 'minOrNotEqual',
              exclusive: false,
              params: {},
              message: "Date can't be in the past",
              test(value) {
                if (isEqual(value, new Date(appointment.end_date))) {
                  return true;
                }
                if (isAfter(value, new Date())) {
                  return true;
                }
                return false;
              },
            })
            .max(maxDate, ({ max }) => {
              return `End date can't be higher than ${max.toLocaleString(
                'en-US'
              )}`;
            })
            .required('Please complete the field'),
          required: true,
        },
        {
          name: 'notes',
          formType: 'textarea',
          label: 'Notes',
          className: 'flex__span-6 flex__row-span-2',
          initialValue: appointment.notes || '',
          type: textarea,
        },
        {
          name: 'performer_name',
          formType: 'text',
          label: 'Performer Name',
          initialValue: appointment.performer?.name || '',
          type: textInput,
        },
        {
          name: 'customer_id',
          formType: 'select',
          label: 'Select a Customer',
          initialValue: appointment.customer_id || '',
          options: customerNames,
          type: string(),
        },
        {
          name: 'contact_name',
          formType: 'text',
          label: 'Contact name',
          initialValue: appointment.contact_name || '',
          options: customerNames,
          type: textInput,
        },
        {
          name: 'contact_phone',
          formType: 'phone',
          label: 'Phone',
          initialValue: appointment.contact_phone || '',
          type: isPhoneNumber,
        },
        {
          name: 'status',
          formType: 'toggleGroup',
          label: 'Status',
          initialValue: appointment.status,
          type: string().required(),
          options: statusOptions(appointment.status === 'overdue'),
          required: true,
        },
      ],
    },

    {
      formGroup: 'Appointment Address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          className: 'flex__span-12',
          initialValue: appointment.address.full_address || '',
          type: string(),
          formType: 'autocompleteAddress',
          fieldNames: {
            street: 'appointment_address_street_line_1',
            city: 'appointment_city',
            state: 'appointment_state',
            zipcode: 'appointment_zipcode',
            country: 'appointment_country',
          },
        },
        {
          name: 'appointment_address_street_line_1',
          formType: 'text',
          label: 'Address Line 1',
          initialValue: appointment.address.street_address_1 || '',
          type: string(),
          autocomplete: 'address-line1',
        },
        {
          name: 'appointment_address_street_line_2',
          formType: 'text',
          label: 'Address Line 2',
          initialValue: appointment.address.street_address_2 || '',
          type: string(),
        },
        {
          name: 'appointment_zipcode',
          formType: 'text',
          label: 'ZIP Code',
          initialValue: appointment.address.zipcode || '',
          type: string(),
        },
        {
          name: 'appointment_city',
          formType: 'text',
          label: 'City',
          initialValue: appointment.address.city || '',
          type: string(),
        },
        {
          name: 'appointment_state',
          formType: 'text',
          label: 'State',
          initialValue: appointment.address.state || '',
          type: string(),
        },
        {
          name: 'appointment_country',
          formType: 'text',
          label: 'Country',
          initialValue: appointment.address.country || '',
          type: string(),
        },
      ],
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post', encType: 'application/json' });
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  // Set customer related fields
  useCustomerFormData({ customers, formik });

  // We need to set the currentDate to the appointment date, or else the UI
  // will use the current day
  React.useEffect(() => {
    if (!appointment?.length) return;
    updateCurrentDay(new Date(appointment.start_date));
  }, []);

  return (
    <>
      <h4 className="mb-5">
        {parseISO(formik.values.current_day).toLocaleDateString('en-US', {
          dateStyle: 'full',
        })}
      </h4>
      <FormWrapper
        id="new-appointment"
        className="flex__form-wrapper"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        label="Save changes"
        portalId="edit-appointment-submit-container"
        dirty={formik.dirty}
        isValid={formik.isValid}
      >
        <CreateForm structure={fields} {...formik} />
      </FormWrapper>

      <LBMapContainer
        address={`${formik.values.appointment_address_street_line_1} ${formik.values.appointment_state} ${formik.values.appointment_city} ${formik.values.appointment_country}`}
      />

      <div style={{ position: 'sticky', bottom: 0, right: 0 }}>
        <div
          className="sticky__submit-container"
          id="edit-appointment-submit-container"
        />
      </div>
    </>
  );
}

export default EditAppointment;
