/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { ToWords } from 'to-words';

function toWordsCheck(amount) {
  const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
      currency: true,
      ignoreDecimal: true,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
      currencyOptions: {
        name: '',
        plural: '',
        symbol: '$',
        fractionalUnit: {
          name: 'Cent',
          plural: 'Cents',
          symbol: '',
        },
      },
    },
  });
  const intPart = toWords.convert(amount);
  const decimalIndex = amount.toString().indexOf('.');
  const decimals =
    decimalIndex > 0 ? amount.toString().substring(decimalIndex + 1) : -1;

  return `${intPart} ${Number(decimals) > 0 ? `and ${decimals}/100` : 'and 0/100'}`.replaceAll(
    ' ',
    '\u00A0'
  );
}

export default toWordsCheck;
