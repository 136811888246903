import CreatePayment from '../../components/Payments/CreatePayment';
import GetPayment from '../../components/Payments/GetPayment';
import {
  createPaymentAction,
  deletePaymentAction,
  nextPaymentNumberLoader,
  paymentLoader,
  updatePaymentAction,
} from './paymentsLA';

const paymentCreate = [
  {
    path: 'new',
    element: <CreatePayment />,
    action: createPaymentAction,
    handle: {
      crumb: () => 'New',
      linkTo: {
        noShow: true,
      },
    },
  },
  { path: 'nextPaymentNumber', loader: nextPaymentNumberLoader },
  {
    path: ':paymentId',
    element: <GetPayment />,
    loader: paymentLoader,
    handle: {
      crumb: (match) => `${match?.data?.data?.payment?.number || 'Payment'}`,
    },
    children: [
      {
        path: 'update',
        element: <CreatePayment />,
        action: updatePaymentAction,
        loader: paymentLoader,
        handle: {
          crumb: (match) =>
            `Update ${match?.data?.data?.payment?.number || 'Payment'}`,
        },
        linkTo: {
          noShow: true,
        },
      },
      {
        path: 'delete',
        action: deletePaymentAction,
      },
    ],
  },
];

export default paymentCreate;
