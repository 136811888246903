export default function extractValues(valueKey, elements) {
  const values = elements.flatMap((element) =>
    element?.name
      ? { [element.name]: element[valueKey] }
      : extractValues(valueKey, element.groupChildren)
  );
  const result = values.reduce((accumulator, currentValue) => {
    const keys = Object.keys(currentValue);
    keys.map((key) => {
      accumulator[key] = currentValue[key];
      return key;
    });
    return accumulator;
  }, {});
  return result;
}
