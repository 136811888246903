/* eslint react/prop-types: 0 */
/* eslint react/jsx-props-no-spreading: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import Body from './Body';
import PaginationV8 from './PaginationV8';
import PageSizeSelector from './PageSizeSelectorV8';
import Header from './Header';
import TableGlobalFilterV8 from './TableGlobalFilterV8';
import ChipList from '../ChipList';
import useStickyState from '../../Helpers/useStickyState';
import './ReactTable.scss';
import './ReactTableV8.scss';

function checkRows(original, filterValue) {
  let matches;
  if (original?.subRows?.length > 0) {
    matches =
      original.subRows.filter((subRow) => checkRows(subRow, filterValue))
        .length > 0;
  } else {
    matches = Object?.keys(original)?.some((key) => {
      if (original[key] === null) return false;
      if (original[key] === undefined) return false;
      if (typeof original[key] === 'string' || original[key] instanceof String)
        return original[key].toLowerCase().includes(filterValue?.toLowerCase());
      if (typeof original[key] === 'object' && original[key] !== null) {
        return original[key]?.label
          ?.toLowerCase()
          .includes(filterValue?.toLowerCase());
      }
      return false;
    });
  }
  return matches;
}

function ReactTablev8({
  dataTable,
  tableLayout,
  tableAction = null,
  columnfilter,
  initialSortBy = [],
  mobileClass,
  tableOptions,
  setData = () => {},
  initialFilter = [],
  filterSetter = () => null,
}) {
  const defaultOptions = {
    search: true,
    pagination: {
      show: true,
      layout: 'full', // "full" | "compact"
    },
    sorting: true,
  };

  const options = {
    ...defaultOptions,
    ...tableOptions,
    pagination: tableOptions?.pagination
      ? { ...tableOptions?.pagination }
      : { ...defaultOptions.pagination },
  };

  const data = dataTable;
  const columns = React.useMemo(() => tableLayout, [tableLayout]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState(initialFilter);
  const [sorting, setSorting] = useStickyState(initialSortBy, mobileClass);

  const table = useReactTable({
    state: {
      globalFilter,
      columnFilters,
      sorting,
      sortBy: initialSortBy,
    },
    columns,
    data,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),

    meta: {
      updateData: (rowIndex, columnId, value) => {
        // skipAutoResetPageIndex()
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
    sortingFns: {
      sortByLabel: (rowA, rowB, columnId) => {
        const a = rowA?.getValue(columnId)?.label;
        const b = rowB?.getValue(columnId)?.label;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    onSortingChange: setSorting,
    globalFilterFn: (row, columnId, filterValue) => {
      if (
        filterValue === '' ||
        filterValue === null ||
        filterValue === undefined
      ) {
        return true;
      }
      if (typeof filterValue !== 'string') {
        return null;
      }
      const { original } = row;
      return checkRows(original, filterValue);
    },
  });

  useEffect(
    () =>
      options.pagination.show
        ? table.setPageSize(10)
        : table.setPageSize(data.length),
    []
  );

  const handleChipChange = (value) => {
    setColumnFilters([{ id: 'filterChips', value }]);
  };

  return (
    <>
      {/* //TABLE TOP FILTERS */}

      <div className="d-flex justify-content-between flex-column flex-lg-row filters">
        {options.pagination.show && (
          <PageSizeSelector
            pageSize={table.getState().pagination?.pageSize}
            setPageSize={table.setPageSize}
          />
        )}
        {columnfilter && (
          <ChipList
            name="chipFilter"
            chipFilter={handleChipChange}
            filterValues={columnfilter}
            initialFilter={initialFilter}
            setter={filterSetter}
          />
        )}
        {options.search && (
          <TableGlobalFilterV8
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
      </div>
      <div className="table-responsive">
        <table
          role="table"
          className={`react_table ${mobileClass || ''}`}
          style={{ width: '100%' }}
        >
          <Header table={table} flexRender={flexRender} />

          <Body table={table} onClickRow={tableAction} />
        </table>
      </div>
      {options.pagination.show && <PaginationV8 table={table} />}
    </>
  );
}

export default ReactTablev8;
