/* eslint react/prop-types: 0 */
/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import TableData from './TableData';
import RowEmpty from './RowEmpty';
import MileagesTable from '../../Settings/Mileages/MileagesTable';

function Body({ table, onClickRow }) {
  const { rows } = table.getRowModel();

  return (
    <tbody>
      {rows.length !== 0 ? (
        rows?.map((row) => {
          const isExpanded = row.getIsExpanded();
          const isVehicles = !!row?.original?.mileages?.length;
          return (
            <React.Fragment key={row.id}>
              <tr
                {...(onClickRow && {
                  onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickRow(row.original.id);
                  },
                })}
                className="lb_table-row"
              >
                {row.getVisibleCells()?.map((cell) => {
                  return <TableData key={cell.id} cell={cell} row={row} />;
                })}
              </tr>

              {isExpanded &&
                !isVehicles &&
                row.subRows?.map((subRow) => {
                  return (
                    <tr
                      key={subRow.id}
                      className="bg-light"
                      {...(onClickRow && {
                        onClick: (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onClickRow(subRow.original.id);
                        },
                      })}
                    >
                      {subRow.getVisibleCells()?.map((cell) => (
                        <TableData key={cell.id} cell={cell} row={subRow} />
                      ))}
                    </tr>
                  );
                })}

              {isExpanded && isVehicles && (
                <MileagesTable mileages={row?.original?.mileages} />
              )}
            </React.Fragment>
          );
        })
      ) : (
        <RowEmpty length={table.getAllColumns().length} />
      )}
    </tbody>
  );
}

export default Body;
