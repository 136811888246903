import messagesAPI from '../../API/routesAPI/messagesAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

export const sendSMSAction = async ({ request }) => {
  const body = await request.formData();
  const response = await messagesAPI.sendMessage(body);
  const redirectTo = '../success';
  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
};

export const sendEmailAction = async ({ request }) => {
  const body = await request.formData();
  const response = await messagesAPI.sendMessage(body);
  const redirectTo = '../success';
  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
};

export const messagesLoader = async ({ params }) => {
  const { documentId, type, module } = params;
  const response = await messagesAPI.history({
    documentId,
    type,
    module,
  });
  return handleResposeAbstraction({
    response,
  });
};

export const uploadFileAction = async ({ request }) => {
  const body = await request.formData();
  const response = await messagesAPI.uploadFile(body);

  let redirectTo;
  let result;

  if (response.status === 200) {
    result = await response.json();
    redirectTo = `../send-email?pdf=${result.link}`;
  }

  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
    showSuccess: true,
  });
};
