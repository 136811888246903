import React from 'react';
import { Link } from 'react-router-dom';

import { Mail } from '../Icons/Icons';
import IconButton from '../IconButton/IconButton';

function SendToButtons() {
  return (
    <div className="d-flex flex-column pt-3 justify-content-center">
      <IconButton
        className="icon-button__modal"
        size={22}
        strokeWidth={3}
        text="Send email"
        alignText="left"
        color="primary"
        as={Link}
        to="save"
        outline={false}
      >
        <Mail />
      </IconButton>
    </div>
  );
}

export default SendToButtons;
// import smsIcon from '../../../img/sms.svg';
// DELETED CODE

/* <Link to="send-sms" className="decoration-none">
        <Button
          size="small"
          className="p-3 mt-3 w-100 d-flex align-items-center justify-content-between"
        >
          Send Sms
          <img
            style={{ width: '20px', height: '20px' }}
            src={smsIcon}
            alt=""
            className="me-2"
          />
        </Button>
      </Link> */
