/* eslint-disable camelcase */
import { add } from './AddProductAux';

export function clearFields(fields, setFieldValue) {
  fields.forEach((field) => setFieldValue(field, ''));
}

export async function addFields(fields, setFieldValue, values) {
  fields.forEach(async (field) => {
    if (Array.isArray(field)) {
      const pathArray = field[1].split('.');
      if (pathArray.length > 1) {
        await setFieldValue(field[0], values[pathArray[0]][pathArray[1]]);
        return;
      }
      await setFieldValue(field[0], values[field[1]]);
      return;
    }
    await setFieldValue(field, values[field] || '');
  });
}

export function calculateAmountAndTax({ quantity, price_before_tax }) {
  // console.log(
  //   'values when calculating amount before tax',
  //   quantity,
  //   price_before_tax
  // );
  const item_price_before_tax = `${(+quantity * +price_before_tax).toFixed(2)}`;
  // const taxTotal = `${+quantity * +rate * (+tax / 100)}`;
  return { item_price_before_tax };
}

export function setAmountAndTax(values, setFieldValue) {
  const fields = {
    ...values,
    ...calculateAmountAndTax(values),
  };
  addFields(add.amounts, setFieldValue, fields);
}
