import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

export interface I_QS_Customer {
  id?: string;
  fullName?: string;
  fullAddress?: string;
  email?: string;
}

export interface I_QuickStartContext {
  step: 1 | 2 | 3;
  stepChangeHanlder: (val: number) => void;
  customer: I_QS_Customer;
  customerChangeHanlder: (val: I_QS_Customer) => void;
  prodOrServ: string | null;
  prodChangeHandler: (val: string) => void;
}

const initCustomer = {
  id: '',
  fullName: undefined,
  fullAddress: undefined,
  email: undefined,
};

function QuickStart() {
  const [step, setStep] = React.useState<1 | 2 | 3>(1);
  const [customer, setCustomer] = React.useState<I_QS_Customer>(initCustomer);
  const [prodOrServ, setProdOrServ] = React.useState<null | string>('');
  const handleStepChange = (val: 1 | 2 | 3) => setStep(val);
  const hanldeCustomerChange = (val: I_QS_Customer) =>
    setCustomer((prev) => ({ ...prev, ...val }));
  const handleProdChange = (val: string) => setProdOrServ(val);

  return (
    <Container className="p-4 pb-0 d-flex flex-column" fluid>
      <Row className="mb-3">
        <h1 style={{ color: 'var(--bs-primary)' }}>Quick Start</h1>
      </Row>

      <Outlet
        context={{
          step,
          stepChangeHanlder: handleStepChange,
          customer,
          customerChangeHanlder: hanldeCustomerChange,
          prodOrServ,
          prodChangeHandler: handleProdChange,
        }}
      />
    </Container>
  );
}

export default QuickStart;
