/* eslint-disable react/prop-types */

import React from 'react';
import { DragOverlay } from '@dnd-kit/core';
import LinkContainer from './LinkContainer';

function LinkOverlay({ activeLink }) {
  return (
    <DragOverlay style={{ pointerEvents: 'none' }}>
      {activeLink && (
        <LinkContainer key={activeLink.id} link={activeLink}>
          {activeLink.cta ? (
            <LinkContainer.Cta>{activeLink.cta}</LinkContainer.Cta>
          ) : (
            <LinkContainer.Stat
              label={activeLink.stat.label}
              value={activeLink.stat.value}
            />
          )}
        </LinkContainer>
      )}
    </DragOverlay>
  );
}

export default LinkOverlay;
