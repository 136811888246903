/* eslint-disable react/prop-types */
import React from 'react';
import { Form } from 'react-bootstrap';

function LBFormControl({
  element,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}) {
  return (
    <Form.Control
      as={element.formType === 'textarea' ? 'textarea' : 'input'}
      autoComplete={element?.autocomplete || 'on'}
      disabled={element?.disabled}
      inputMode={
        element?.inputMode === 'numeric' ? 'decimal' : element?.inputMode
      }
      isInvalid={touched[element.name] && !!errors[element.name]}
      isValid={touched[element.name] && !errors[element.name]}
      maxLength={element?.maxLength}
      name={element.name}
      onBlur={handleBlur}
      onChange={(e) => {
        if (e.nativeEvent.data === ',')
          e.target.value = e.target.value.replace(/,/g, '.');
        // if (element?.inputMode === 'numeric') e.target.value = +e.target.value;
        handleChange(e);
        if (element?.change) element.change(e);
      }}
      placeholder={element.placeholder}
      plaintext={element?.plaintext}
      required={element?.required}
      rows={4}
      type={element.formType}
      value={values[element.name]}
      onFocus={(e) => {
        if (e.target.value === '0') e.target.select();
      }}
    />
  );
}

export default LBFormControl;
