export const minuteIncrement = 5;
export const selectMidday = [
  {
    index: 0,
    value: 'AM',
  },
  {
    index: 1,
    value: 'PM',
  },
];

export const selectHours = Array.from({ length: 12 }, (_, index) => ({
  index,
  value: index + 1,
}));

export const selectMinutes = Array.from(
  { length: 60 / minuteIncrement },
  (_, index) => ({
    index,
    value: minuteIncrement * index + minuteIncrement,
  })
);
