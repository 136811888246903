const formikHelpers = (formik) => ({
  push: (key, value) => {
    const next = [...formik.values[key], value];
    formik.setFieldValue(key, next, true);
  },
  remove: (key, index) => {
    const next = formik.values[key].filter((_, i) => i !== index);
    formik.setFieldValue(key, next, true);
  },
  update: (key, index, value) => {
    const next = formik.values[key].map((item, i) =>
      i === index ? { ...item, ...value } : item
    );
    formik.setFieldValue(key, next, true);
  },
});

export default formikHelpers;
