import React from 'react';
import LBOffCanvas from '../UI/LBOffCanvas';
import CustomersNew from './CustomersNew';

function CustomersNewModal() {
  return (
    <LBOffCanvas.Wrapper>
      <LBOffCanvas.Left>
        <LBOffCanvas.Header>Create Customer</LBOffCanvas.Header>
        <CustomersNew modal />
      </LBOffCanvas.Left>
    </LBOffCanvas.Wrapper>
  );
}

export default CustomersNewModal;
