import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PageHeader from '../components/Layout/PageHeader';
import useIsAuthorized from '../routes/protectedPages/useIsAuthorized';
import InvalidSubscriptionPage from '../components/Settings/ManageSubscription/InvalidSubscriptionPage';

function GenericPage() {
  const location = useLocation();
  location.pathname = location.pathname.toLowerCase();
  const currentSection = `/${location.pathname.split('/')[1]}`;
  const pathAllowed = {
    '/appointments': ['tier_2', 'tier_3'],
    '/reports': ['tier_2', 'tier_3'],
    '/vendors': ['tier_2', 'tier_3'],
    '/checks': ['tier_3'],
  };

  const pathNotAllowed = [
    'new',
    'edit',
    'send',
    'copy',
    'convert',
    'update',
    'templates',
    'messages',
  ];
  const pathNotAllowedHook = useIsAuthorized({
    tiersAllowed: ['tier_1', 'tier_2', 'tier_3'],
  });
  const isNotAllowed = pathNotAllowed.some(
    (path) => location.pathname.includes(path) && !pathNotAllowedHook
  );
  const isRestricted = pathAllowed[currentSection]
    ? pathAllowed[currentSection]
    : null;
  let isAuthorized = true;
  const pathRestricted = useIsAuthorized({
    tiersAllowed: pathAllowed[currentSection],
  });

  if (isRestricted) {
    isAuthorized = pathRestricted;
  }
  return (
    <PageHeader>
      {!isAuthorized || isNotAllowed ? (
        <InvalidSubscriptionPage
          tiersAllowed={pathAllowed[currentSection]}
          isAuthorized={isAuthorized}
        />
      ) : (
        <Outlet />
      )}
    </PageHeader>
  );
}

export default GenericPage;
