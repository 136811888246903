/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Alert } from 'react-bootstrap';
import { useFetcher, useLocation } from 'react-router-dom';
import { string } from 'yup';
import FormWrapper from '../../Forms/builders/FormWrapper';
import CreateForm from '../../Forms/CreateForm';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import LBOffCanvas from '../../UI/LBOffCanvas';

function CustomerModal() {
  const { state: historyState, pathname } = useLocation();
  const customerId = historyState?.id;

  const getCustomer = useFetcher();
  const updateCustomer = useFetcher();

  React.useEffect(() => {
    if (!customerId) return;

    if (getCustomer.state === 'idle' && !getCustomer.data) {
      getCustomer.load(`/customers/${customerId}`);
    }

    if (getCustomer.data) {
      const { first_name: firstName, last_name: lastName } =
        getCustomer.data.data.name;
      const {
        street_address_1: streetAddress1,
        street_address_2: streetAddress2,
        city,
        state,
        zipcode,
        country,
      } = getCustomer.data.data.address;
      setValue('first_name', firstName || '');
      setValue('last_name', lastName || '');
      setValue('street_address', streetAddress1 || '');
      setValue('street_address_2', streetAddress2 || '');
      setValue('city', city || '');
      setValue('state', state || '');
      setValue('zipcode', zipcode || '');
      setValue('country', country || '');
    }
  }, [getCustomer]);

  const fields = [
    {
      formGroup: 'Name asd',
      groupChildren: [
        {
          name: 'first_name',
          label: 'First Name',
          initialValue: '',
          type: string().required('Please complete the field'),
          required: true,
        },
        {
          name: 'last_name',
          label: 'Last Name',
          initialValue: '',
          type: string().required(),
          required: true,
        },
      ],
    },
    {
      formGroup: 'Billing Address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          className: 'flex__span-12',
          initialValue: '',
          type: string(),
          formType: 'autocompleteAddress',
        },
        {
          name: 'street_address',
          label: 'Street Address',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
          autocomplete: 'address-line1',
        },
        {
          name: 'street_address_2',
          label: 'Street Address 2',
          initialValue: '',
          type: string(),
          autocomplete: 'address-line2',
        },
        {
          name: 'city',
          label: 'City',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
        },
        {
          name: 'state',
          label: 'State',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
        },
        {
          name: 'zipcode',
          label: 'Zip code',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
        },
        {
          name: 'country',
          label: 'Country',
          type: string().required('This field is mandatory'),
          required: true,
          initialValue: '',
        },
      ],
    },
  ];

  const submitAction = (values) => {
    const nextValues = {
      ...getCustomer.data.data.contact,
      ref_info: getCustomer.data.data.notes,
      company: getCustomer.data.data.company,
      ...values,
      id: customerId,
      modal_redirect: pathname.substring(0, pathname.lastIndexOf('/')),
    };

    updateCustomer.submit(nextValues, {
      method: 'post',
      action: `/customers/${customerId}/edit`,
    });
  };

  //   Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: true,
  });

  async function setValue(field, value) {
    if (!field === undefined || !value === undefined)
      throw new Error('Value and field are required arguments');

    formik.setFieldValue(field, value);
  }

  if (!customerId)
    return (
      <LBOffCanvas.Wrapper>
        <LBOffCanvas.Left>
          <LBOffCanvas.Header>Oops!</LBOffCanvas.Header>
          <Alert variant="danger">
            Error, couldn&apos;t find a target, go back and select a customer,
            vendor or merchant, if it&apos;s missing any fields you will be able
            to complete them in this modal.
          </Alert>
        </LBOffCanvas.Left>
      </LBOffCanvas.Wrapper>
    );

  return (
    <LBOffCanvas.Wrapper>
      <LBOffCanvas.Left>
        <LBOffCanvas.Header>Update Customer</LBOffCanvas.Header>
        <Alert variant="info" style={{ fontSize: '0.8rem' }}>
          Keep in mind that the following changes will be applied to the
          customer itself, and not just on this particular check.
        </Alert>
        {getCustomer.state === 'loading' ||
        updateCustomer.state === 'loading' ? (
          <p>Loading...</p>
        ) : (
          <FormWrapper
            id="update-customer-checks"
            className="flex__form-wrapper"
            handleSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
            label="Update Customer"
            dirty={formik.dirty}
            isValid={formik.isValid}
            portalId="offcanvas-action-group-left"
          >
            <CreateForm structure={fields} {...formik} />
          </FormWrapper>
        )}
      </LBOffCanvas.Left>
    </LBOffCanvas.Wrapper>
  );
}

export default CustomerModal;
