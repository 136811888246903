import React from 'react';
import { Button } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
function MeterItem({ used, limitReached }) {
  let styles;

  if (limitReached) {
    styles = {
      backgroundColor: 'var(--bs-danger)',
    };
  }

  if (!limitReached && used) {
    styles = {
      backgroundColor: 'var(--bs-primary)',
    };
  }

  return (
    <span className="lb-subscription__usage__meter__invoice" style={styles} />
  );
}

// eslint-disable-next-line react/prop-types
function UsageMeter({ maxInvoices, currentInvoices }) {
  const limitReached = currentInvoices >= maxInvoices;

  return (
    <div className="mt-4">
      {limitReached && (
        <p
          className="mb-2"
          style={{
            fontSize: '0.9rem',
            fontWeight: '600',
            color: 'var(--bs-danger)',
          }}
        >
          Limit Reached!
        </p>
      )}
      <div className="lb-subscription__usage__meter">
        {Array.from(Array(maxInvoices).keys()).map((n) => (
          <MeterItem
            key={n}
            used={n < currentInvoices}
            limitReached={limitReached}
          />
        ))}
      </div>
      <div className="d-flex align-items-center gap-3 mt-3">
        <h2
          style={{
            color: limitReached ? 'var(--bs-danger)' : 'var(--bs-primary)',
            fontWeight: 700,
            fontSize: '1.6rem',
            whiteSpace: 'nowrap',
          }}
        >
          {currentInvoices} / {maxInvoices}
        </h2>
        <p>Monthly invoices used</p>
      </div>
      {limitReached && (
        <Button
          href="https://www.leadsboxinvoice.com/subscription"
          className="mt-4"
        >
          Get Unlimited Invoices
        </Button>
      )}
    </div>
  );
}

export default UsageMeter;
