/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconButton from '../../UI/IconButton/IconButton.tsx';
import { Edit } from '../../UI/Icons/Icons';

function CustomerInfo({ customer, abridged = false }) {
  if (!customer?.id) return null;

  if (!customer?.fullName && !customer?.fullAddress && !customer?.email)
    return <Alert variant="danger">Error retrieving customer data</Alert>;

  if (abridged)
    return (
      <div className="customer-info__container p-3 rounded container-fluid">
        <Row className="gy-3 mb-3">
          <Col
            md={6}
            className="d-flex w-100 flex-row align-items-center justify-content-between"
          >
            <h6 className="mb-0">Selected Customer</h6>
            <IconButton
              text="Edit"
              size={16}
              style={{ fontSize: '0.9rem', lineHeight: 0 }}
              as={Link}
              to="/onboarding/customer"
            >
              <Edit />
            </IconButton>
          </Col>
        </Row>
        <Row className="gy-3">
          <Col md={6} className="d-flex flex-column">
            <span className="customer-info__label">Name:</span>
            {customer.fullName}
          </Col>
          <Col md={6} className="d-flex flex-column customer-label__container">
            <span className="customer-info__label">Email:</span>
            {customer.email}
          </Col>
        </Row>
      </div>
    );

  return (
    <div className="customer-info__container p-3 rounded container-fluid">
      <Row className="gy-3 mb-3">
        <Col md={6} className="d-flex flex-column">
          <span className="customer-info__label">Name:</span>
          {customer.fullName}
        </Col>
      </Row>
      <Row className="gy-3 mb-3">
        <Col md={6} className="d-flex flex-column">
          <span className="customer-info__label">Address:</span>
          {customer.fullAddress}
        </Col>
      </Row>
      <Row className="gy-3">
        <Col md={6} className="d-flex flex-column">
          <span className="customer-info__label">Email:</span>
          {customer.email}
        </Col>
      </Row>
    </div>
  );
}

export default CustomerInfo;
