import React from 'react';
import { Outlet } from 'react-router-dom';
import AppointmentProvider from './Context/AppointmentContext';

import './Appointments.scss';

function AppointmentsLayout() {
  return (
    <AppointmentProvider>
      <Outlet />
    </AppointmentProvider>
  );
}

export default AppointmentsLayout;
