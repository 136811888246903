/* eslint-disable react/prop-types */

import React from 'react';
import { useNavigation } from 'react-router-dom';

function SectionContent({ className, children }) {
  const navigation = useNavigation();

  const classes = `sectionContent ${className} ${
    navigation.state === 'loading' ? 'loading' : ''
  }`;

  return <div className={classes}>{children}</div>;
}
export default SectionContent;
