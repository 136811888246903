import fetchAPI from '../fetchUtils';

const tradesAPI = {
  getAllTrades: async () => fetchAPI.getWithAuth('/get-trade-list'),
  createTrade: async (body) => fetchAPI.postWithAuth('/create-trade', body),
  deleteTrade: async (tradeId) =>
    fetchAPI.getWithAuth(`/delete-trade/${tradeId}`),
};

export default tradesAPI;
