/* eslint-disable react/prop-types */

import Appointments from './Appointments';
import Invoices from './Invoices';
import ProfitAndLoss from './ProfitAndLoss';
import MonthlyGrowth from './MonthlyGrowth';

import './Widgets.scss';
import useIsAuthorized from '../../../routes/protectedPages/useIsAuthorized';

function Widgets() {
  // const isAuthorized = useIsAuthorized({ rolesAllowed: [70] });
  const isAuthorized = useIsAuthorized({ tiersAllowed: ['tier_2', 'tier_3'] });
  // console.log('isAuthorized', isAuthorized);
  return (
    <section className="widgets__grid-container">
      {isAuthorized && <Appointments />}
      <Invoices />
      {isAuthorized && <ProfitAndLoss />}
      <MonthlyGrowth />
      {/* <ActivityTimeline /> */}
      {/* <SalesByCategory /> */}
      {/* <ActiveWorkOrders /> */}
    </section>
  );
}

export default Widgets;
