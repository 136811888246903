/* eslint react/prop-types: 0 */
import React from 'react';

function RowEmpty({ length }) {
  return (
    <tr>
      <td colSpan={length} className="text-center">
        No data available
      </td>
    </tr>
  );
}

export default RowEmpty;
