import React from 'react';
import propTypes from 'prop-types';
import { useRouteError } from 'react-router-dom';

function ErrorMesage({ message, className }) {
  const error = useRouteError();
  console.log(error);
  return (
    <div className={className}>
      <div style={{ maxWidth: '400px', textAlign: 'center' }}>
        <h1>{message}</h1>

        <p className="mt-2">Sorry, an unexpected error has occurred.</p>
        <p className="mt-3">
          <i className="small">{error.statusText || error.message}</i>
        </p>
      </div>
    </div>
  );
}

ErrorMesage.propTypes = {
  message: propTypes.string,
  className: propTypes.string,
};

ErrorMesage.defaultProps = {
  message: 'Oops!',
  className:
    'd-flex justify-content-center align-items-center flex-column mt-5',
};

export default ErrorMesage;
