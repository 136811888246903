/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { createPortal } from 'react-dom';
import { Form, Link, Outlet, useLoaderData, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import TextField from '../UI/text/TextField';
import LBOffCanvas from '../UI/LBOffCanvas';
import StatusBadge from '../UI/StatusBadge';
import { Carat } from '../UI/Icons/Icons';
import formatAddress from './utils/formatAddress';
import LBMapContainer from './LBMapContainer';

function ShowAppointment() {
  const { appointmentId } = useParams();
  // Get the current appointment

  const { data: appointment } = useLoaderData();

  // In order for the portal to work using "document.getElementById"
  // we need to ensure the component is mounted. The "React way" would
  // be using ref.current, but we have no way to access the target component,
  // so this is a better solution (as ugly as it looks :P)
  // (This could be a custom hook, soon™, anytime now™)
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    // This code runs after the component has mounted
    setIsMounted(true);

    // Cleanup function to run when the component unmounts
    return () => {
      setIsMounted(false);
    };
  }, []); // Runs only once (probably twice in dev mode)

  return (
    <>
      <LBOffCanvas.Wrapper>
        <LBOffCanvas.Left>
          <LBOffCanvas.Header>
            <h2 className="new-appointment__header">View Appointment</h2>
            <div className="new-appointment__status-container">
              <StatusBadge
                statusCode={appointment?.status}
                type="appointment"
                customClasses="new-appointment__status-badge"
              />
              <h3 className="new-appointment__sub-header">
                {new Date(appointment.start_date).toLocaleDateString('en-US', {
                  dateStyle: 'long',
                })}
              </h3>
            </div>
          </LBOffCanvas.Header>
          <section className="d-flex flex-column gap-3">
            <TextField label="Name">{appointment.name}</TextField>
            <div className="new-appointment__duration-container">
              <TextField label="Starts at">
                {new Date(appointment.start_date).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: undefined,
                })}
              </TextField>
              <span className="arrow-right">
                <svg
                  fill="transparent"
                  stroke="currentColor"
                  strokeLinecap="round"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                >
                  <Carat />
                </svg>
              </span>
              <TextField label="Ends at">
                {new Date(appointment.end_date).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: undefined,
                })}
              </TextField>
            </div>
            <TextField label="Creation date">
              {new Date(appointment.creation_date).toLocaleString('en-US', {
                dateStyle: 'medium',
                timeStyle: 'medium',
              })}
            </TextField>
            {appointment.notes && (
              <TextField style={{ whiteSpace: 'pre-line' }} label="Notes">
                {appointment.notes}
              </TextField>
            )}
            {appointment.contact_name && (
              <TextField label="Customer">{appointment.contact_name}</TextField>
            )}
            {appointment.contact_phone && appointment.contact_phone && (
              <TextField label="Customer Phone">
                <a href={`tel:${appointment.contact_phone}`}>
                  {formatPhoneNumberIntl(appointment.contact_phone)}
                </a>
              </TextField>
            )}
            {appointment?.performer?.name && (
              <TextField label="Performer">
                {`${appointment.performer.name}`}
              </TextField>
            )}
            {appointment?.address?.street_address_1 && (
              <TextField label="Appointment Address">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                    formatAddress(appointment.address)
                  )}`}
                >
                  {formatAddress(appointment.address)}
                </a>
              </TextField>
            )}
            {appointment?.address?.street_address_1 && (
              <LBMapContainer address={formatAddress(appointment.address)} />
            )}
          </section>
        </LBOffCanvas.Left>
        <LBOffCanvas.Right>
          <Outlet />
        </LBOffCanvas.Right>
      </LBOffCanvas.Wrapper>
      {isMounted &&
        createPortal(
          <>
            <Button as={Link} to={`/appointments/${appointmentId}/edit`}>
              Edit
            </Button>
            <Form method="post" action="delete">
              <Button type="submit" variant="danger">
                Delete
              </Button>
            </Form>
          </>,
          document.getElementById('offcanvas-action-group-left')
        )}
    </>
  );
}

export default ShowAppointment;
