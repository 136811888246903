const FONT_STYLES = {
  bold: {
    fontWeight: 600,
  },
  italic: {
    fontStyle: 'italic',
  },
  regular: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
};

export default FONT_STYLES;
