/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Alert, Row, Col, Form } from 'react-bootstrap';
import CreateForm from '../Forms/CreateForm';

function ServiceInfoForm({ formik, structure }) {
  const handleSwitchChange = (e) => {
    e.stopPropagation();
    return formik.setFieldValue(
      'show_service_info',
      !formik.values.show_service_info
    );
  };

  return (
    <div className="service-info__container">
      <button
        onClick={(e) => handleSwitchChange(e)}
        aria-expanded={formik.values.show_service_info ? 'true' : 'false'}
        type="button"
        className="d-flex align-items-center justify-content-between w-100 pt-4 pb-4 service-info__toggle"
      >
        <h5 className="mb-0">Toggle Service Information</h5>
        <Form.Switch
          className="align-self-center"
          name="show_service_info"
          id="show_service_info"
          checked={formik.values.show_service_info}
          onChange={(e) => handleSwitchChange(e)}
        />
      </button>
      {formik.values.show_service_info && (
        <div className="pb-4">
          <Alert
            variant="warning"
            style={{ fontSize: '0.8rem', padding: '8px 10px' }}
          >
            This will replace the &quot;Customer Notes&quot; in the PDF
          </Alert>
          <CreateForm {...formik} structure={structure} />
          {formik.values.service_name && formik.values.service_address && (
            <div className="customer-info__container p-3 rounded container-fluid">
              <Row className="gy-3">
                <Col md={6} className="d-flex flex-column">
                  <span className="customer-info__label">Name:</span>
                  {formik.values.service_name}
                </Col>
                <Col md={6} className="d-flex flex-column">
                  <span className="customer-info__label">Address:</span>
                  {formik.values.service_address}
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ServiceInfoForm;
