import React from 'react';
import { Link, useRouteError } from 'react-router-dom';

function StepError() {
  const error = useRouteError() as any;
  console.log(error);
  return (
    <div className="d-flex flex-column gap-4 mt-5">
      <Link to="..">Go back</Link>
      {error?.message || 'Unknown error.'}
    </div>
  );
}

export default StepError;
