/* eslint-disable react/prop-types */
import React from 'react';
import AddItem from './AddItem';

export default function AddItemWrapper({ columns, rows, onAdd }) {
  // Commenting this console.log, he spams :x
  // console.log('TFoot:', columns, rows, onAdd);
  return (
    <div className="add-check react_table">
      {rows.length < 5 ? (
        <AddItem columns={columns} onAddItem={(item) => onAdd(item)} />
      ) : (
        <div className="add-check__row">
          <div className="add-check__col">Maximum number of items reached</div>
        </div>
      )}
    </div>
  );
}
