import React, { useState } from 'react';
import PropTypes from 'prop-types';

function SplitBox({ splits, handler, id, index }) {
  const [value, setValue] = useState(splits);

  const change = (e) => {
    const nextValue = +e.target.value;
    setValue(nextValue);
  };
  const focusOut = () => {
    handler(value, index);
  };
  return (
    <div className="split-box" id={id}>
      <div className="d-flex flex-column">
        <p>{id}</p>
        <p>{`Split ${index + 1}`}</p>
      </div>
      <div className="d-flex align-items-center">
        <p className="pl-2">$</p>
        <input
          className="input-split"
          value={value}
          onChange={change}
          onBlur={focusOut}
          type="text"
        />
      </div>
    </div>
  );
}
SplitBox.propTypes = {
  splits: PropTypes.number.isRequired,
  handler: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
export default SplitBox;
