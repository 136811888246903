import React from 'react';
import { useRouteLoaderData, Link } from 'react-router-dom';
import ModalTitle from '../UI/text/ModalTitle';

function isPdfAllowed(template) {
  const { user } = useRouteLoaderData('root');
  const isDataReady =
    user?.user_company &&
    user?.user_country &&
    user?.user_state &&
    user?.user_zip &&
    user?.company_logo_base64 &&
    user?.user_phone &&
    true;

  return [
    isDataReady,
    <div className="d-flex flex-column align-items-center h-100 justify-content-center">
      <div className="d-flex flex-column align-items-center">
        <ModalTitle title={`${template} complete:`} />

        <ul className="w-100">
          <Link to="/settings">
            {!user?.user_company ? (
              <li className="list-group-item my-2">Company name</li>
            ) : (
              ''
            )}
            {user?.user_country &&
            user?.user_state &&
            user?.user_zip &&
            true ? (
              ''
            ) : (
              <li className="list-group-item my-2 ">State, Country, Zip</li>
            )}
            {!user?.company_logo_base64 ? (
              <li className="list-group-item my-2">Logo</li>
            ) : (
              ''
            )}
            {!user?.user_phone ? (
              <li className="list-group-item my-2">Mobile</li>
            ) : (
              ''
            )}
            {!user?.user_email ? (
              <li className="list-group-item my-2">e-mail</li>
            ) : (
              ''
            )}
          </Link>
        </ul>
      </div>
    </div>,
  ];
}

export default isPdfAllowed;
