/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import '../Navigation.scss';
import { Button } from 'react-bootstrap';
import Bars from '../../../img/bars-icon.svg';
import isologo from '../../../img/leadsbox.png';
import NavItem from '../NavItem';
import routes from './storeIcons';
import NavigationLayout from './NavigationLayout';
import { login } from '../../../routes/paths';
import { Logout } from '../../UI/Icons/Icons';

function Navigation() {
  const { user } = useRouteLoaderData('root');

  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const logIcon = <Logout />;
  const toggleShow = () => {
    setShow(!show);
  };

  const closeMenu = () => {
    setShow(false);
    window.scrollTo(0, 0);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const logOut = () => {
    localStorage.setItem('token', '');
    navigate(`${login}`);
  };

  const pathAllowed = {
    '/appointments': ['tier_2', 'tier_3'],
    '/reports': ['tier_2', 'tier_3'],
    '/vendors': ['tier_2', 'tier_3'],
    '/checks': ['tier_3'],
  };

  const isLinkAllowed = (path) => {
    const allowed = pathAllowed[path];
    if (!allowed) return false;
    return !allowed.includes(user?.subscription?.tier);
  };

  return (
    <>
      <div className="nav-mobile w-100 d-md-none">
        <div className="logo-box">
          <img src={isologo} alt="leadsbox" className="isologo" />
        </div>

        <Button className="menu-btn " onClick={toggleShow}>
          <img src={Bars} className="w-100" alt="" />
        </Button>
      </div>
      <div
        className={`closeLayer d-lg-none ${!show ? 'd-none' : ''}`}
        onClick={closeMenu}
      />

      <NavigationLayout show={show}>
        <ul>
          {routes.map(({ label, path, icon }) => (
            <li key={path}>
              <NavItem
                icon={icon}
                url={path}
                title={label}
                onClick={closeMenu}
                isDisabled={isLinkAllowed(path)}
              />
              {/* {subItems && (
                <NavSubItem subItems={subItems} onClick={closeMenu} />
              )} */}
            </li>
          ))}
          <li>
            <NavItem
              icon={logIcon}
              url={login}
              title="Log Out"
              onClick={logOut}
            />
          </li>
        </ul>
      </NavigationLayout>
    </>
  );
}
export default Navigation;
