import React from 'react';
import propTypes from 'prop-types';
import { useNavigation } from 'react-router-dom';

function ReportDisplay({ children, reportValue }) {
  const navigation = useNavigation();

  if (navigation.state !== 'idle')
    return <p className="card p-4 mt-4 border-0">Loading...</p>;

  if (!reportValue)
    return (
      <p className="card p-4 mt-4 border-0">Generate report to see the data</p>
    );

  return <section className="card p-4 mt-4 border-0">{children}</section>;
}

ReportDisplay.propTypes = {
  children: propTypes.node,
  reportValue: propTypes.string,
};

export default ReportDisplay;
