import React, { useEffect, useState } from 'react';
import { useActionData, useLoaderData, useSubmit } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import {
  formatShortDate,
  getCurrentFormattedDate,
  getCurrentTimeZone,
} from '../utils/helpers';
import ReportsSearcher from '../../ReportsSearcher/ReportsSearcher';
import ExpensesPDF from '../../../PDF/ReportsPDFGenerator/ExpensesPDF/ExpensesPDF';
import { getCompanyInfo } from '../../../../routes/reports/reportsLA';

function ExpensesVendor() {
  const { vendors } = useLoaderData();
  const [fetchedData, setFetchedData] = useState(false);
  const [data, setData] = useState({});
  const [selectOption, setSelectOption] = useState('none');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [enablePrintDownload, setEnablePrintDownload] = useState(false);
  const [userCompany, setUserCompany] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (toDate && fromDate !== '') {
      setEnablePrintDownload(true);
    }
    setFetchedData(false);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (selectOption !== 'none') {
      setFetchedData(false);
    }
  }, [selectOption, fromDate, toDate]);

  const vendorsOptions = vendors.map((item) => ({
    id: item.id,
    title: item.label,
  }));

  const submit = useSubmit();
  const actionData = useActionData();

  useEffect(() => {
    if (actionData !== undefined) {
      setData(actionData);
      setFetchedData(true);
    }
  }, [actionData]);

  const handleRunReport = async () => {
    setLoading(true);
    // const values = new FormData();
    // values.append('vendor', selectOption);
    // values.append('from', fromDate);
    // values.append('to', toDate);
    const values = {
      vendor: selectOption,
      from: fromDate,
      to: toDate,
    };
    submit(values, { method: 'post', encType: 'application/json' });
    setLoading(false);
  };

  const getUserCompany = async () => {
    const { user } = await getCompanyInfo();
    setUserCompany(user.user_company);
  };

  useEffect(() => {
    getUserCompany();
  }, []);

  const pdfFile = (
    <ExpensesPDF
      fetchedData={data}
      expensesVendor
      title="Expenses by Vendor Report"
      from={formatShortDate(fromDate)}
      to={formatShortDate(toDate)}
      currentDate={getCurrentFormattedDate()}
      timezone={getCurrentTimeZone()}
      userCompany={userCompany}
    />
  );
  return (
    <div>
      <ReportsSearcher
        pdfFileName="expenses-by-vendor-report"
        options={vendorsOptions}
        setSelectOption={setSelectOption}
        selectOption={selectOption}
        setFromDate={setFromDate}
        fromDate={fromDate}
        toDate={toDate}
        setToDate={setToDate}
        pdfChildren={pdfFile}
        enablePrintDownload={enablePrintDownload}
        handleRunReport={enablePrintDownload ? handleRunReport : () => {}}
        loading={loading}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 40,
        }}
      >
        {fetchedData && (
          <PDFViewer height={705} width={500} showToolbar={false}>
            {pdfFile}
          </PDFViewer>
        )}
      </div>
    </div>
  );
}

export default ExpensesVendor;
