/* eslint-disable prefer-template */
/* eslint react/prop-types: 0 */
import React from 'react';
import DataPhone from './td/DataPhone';
import DataCurrency from './td/DataCurrency';
import DataExpand from './td/DataExpand';
import StatusIndicator from '../StatusIndicator';
import StatusBadge from '../StatusBadge';
import DataLink from './td/DataLink';
import DataTextArea from './td/DataTextArea';
import DataInput from './td/DataInput';
import DataSelect from './td/DataSelect';
import { sanitize } from './utils';

function TableData({ cell, row, onChange = () => {} }) {
  const type = cell.column.columnDef?.type;
  const value = cell.getValue();
  const sanitizedHeader = sanitize(cell.column.columnDef?.header);

  // This fixes "false" being considered as a non-empty cell
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  if ((!value && type !== 'money') || (!value && value !== 0))
    return (
      <td
        className={`${cell.column.columnDef?.className || ''} ${
          'mobile__' + sanitizedHeader
        }`}
        key={cell.id}
        aria-label="empty cell"
      />
    );

  return (
    <td
      key={cell.id}
      className={`${cell.column.columnDef?.className || ''} ${
        'mobile__' + sanitizedHeader
      }`}
      style={{
        gridArea: sanitizedHeader,
      }}
    >
      {((!!value && cell.column.columnDef?.mobileLabel) ||
        (type === 'money' &&
          value === 0 &&
          cell.column.columnDef?.mobileLabel)) && (
        <span className="accesor">{cell.column.columnDef?.header}:</span>
      )}
      {type === 'input' && (
        <DataInput
          iitialVlaue={cell.getValue()}
          row={row}
          onChange={onChange}
        />
      )}
      {type === 'select' && (
        <DataSelect
          initialValue={cell.getValue()}
          options={cell.column.columnDef?.options}
          onChange={(e) => onChange(cell.column.id, e)}
        />
      )}
      {type === 'expand' && <DataExpand cell={cell} row={row} />}
      {type === 'money' && <DataCurrency value={value} />}
      {type === 'phoneNumber' && <DataPhone value={value} />}
      {type === 'textArea' && <DataTextArea value={value} />}
      {type === 'date' && value
        ? new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : null}
      {type === 'statusIndicator' && (
        <StatusIndicator color={value.color}>{value.label}</StatusIndicator>
      )}
      {type === 'statusBadge' && (
        <StatusBadge statusCode={value.code} type={value.type} />
      )}
      {type === 'link' && <DataLink value={value} />}

      {!type && value}
    </td>
  );
}

export default TableData;
