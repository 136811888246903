/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '../UI/text/TextField';

function Item({ item, status }) {
  return (
    <div className="gap-2 bg-light p-3 mt-3">
      <TextField label="">{item.description}</TextField>
      <div className="d-flex justify-content-between gap-2 pt-2">
        {status === 2 && (
          <TextField label="Expense">
            <Link to={`/expenses/${item?.expense_id}`}>View expense</Link>
          </TextField>
        )}
        <TextField label="Invoice">
          {item?.invoice_id ? (
            <Link to={`/invoices/${item?.invoice_id}`}>
              {item?.invoice_name}
            </Link>
          ) : (
            '-'
          )}
        </TextField>
        <TextField label="Amount">{`$	${item.amount}`}</TextField>
      </div>
    </div>
  );
}

export default Item;
