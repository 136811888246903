import TradesPopover from '../../components/TradesPopover/TradesPopover';
import {
  createTradeAction,
  deleteTradeAction,
  getAllTradesLoader,
} from './tradesLA';

const tradesRoutes = {
  path: 'trades',
  loader: getAllTradesLoader,
  element: <TradesPopover />,
  action: createTradeAction,

  children: [
    {
      path: 'delete-trade',
      action: deleteTradeAction,
    },
  ],
};
export default tradesRoutes;
