import { redirect } from 'react-router-dom';
import appointmentsAPI from '../../API/routesAPI/appointmentsAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';
import { transformAppointment, completionCheck } from './helpers';
import { toastSuccess } from '../../API/toastHandler';

export const appointmentsLoader = async ({ request }) => {
  // Get month from QS or use current month
  const url = new URL(request.url);
  const month = url.searchParams.get('month');
  const year = url.searchParams.get('year');
  const response = await appointmentsAPI.getAppointments(
    month || new Date().getMonth(),
    year || new Date().getFullYear()
  );
  const { appointments } = await handleResposeAbstraction({ response });
  return { appointments: appointments || [] };
};

export const newAppointmentAction = async ({ request }) => {
  const json = await request.json();
  const newAppointment = {
    ...json,
    status: 'pending',
    creation_date: new Date().toISOString(),
  };

  const response = await appointmentsAPI.createAppointment(
    JSON.stringify(newAppointment)
  );
  return handleResposeAbstraction({
    response,
    redirectTo: '/appointments',
  });
};

export const appointmentLoader = async ({ params }) => {
  const response = await appointmentsAPI.getAppointment(params.appointmentId);

  return handleResposeAbstraction({ response });
};

export const editAppointmentAction = async ({ request, params }) => {
  const json = await request.json();
  const updatedAppointment = {
    ...json,
    id: params.appointmentId,
    status: json.status === 'overdue' ? 'pending' : json.status,
    complete_date: completionCheck(json.status),
  };
  const response = await appointmentsAPI.updateAppointment(
    JSON.stringify(updatedAppointment)
  );
  return handleResposeAbstraction({
    response,
    redirectTo: `/appointments/${params.appointmentId}`,
  });
};

export const toggleAppointmentAction = async ({ params }) => {
  const getResponse = await appointmentsAPI.getAppointment(
    params.appointmentId
  );
  const { data } = await handleResposeAbstraction({ response: getResponse });
  // toggleAppointment(params.appointmentId);
  const appointmentData = transformAppointment(data);
  const updatedAppointment = {
    ...appointmentData,
    status:
      appointmentData.status === 'pending' ||
      appointmentData.status === 'overdue'
        ? 'completed'
        : 'pending',
    complete_date: completionCheck(appointmentData.status),
  };

  const response = await appointmentsAPI.updateAppointment(
    JSON.stringify(updatedAppointment)
  );

  if (response.status === 200) {
    toastSuccess({ alert: 'Appointment updated' });
    return null;
  }

  return handleResposeAbstraction({
    response,
  });
};

export const deleteAppointmentUtil = async ({ params }) => {
  const response = await appointmentsAPI.deleteAppointment(
    params.appointmentId
  );

  if (response.status === 200) {
    toastSuccess({ alert: 'Appointment deleted' });
    return null;
  }

  return handleResposeAbstraction({
    response,
  });
};

export const deleteAppointmentAction = async ({ params }) => {
  const response = await appointmentsAPI.deleteAppointment(
    params.appointmentId
  );

  if (response.status === 200) {
    toastSuccess({ alert: 'Appointment deleted' });
    return redirect('..');
  }

  return handleResposeAbstraction({
    response,
  });
};
