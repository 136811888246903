/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button } from 'react-bootstrap';
import { string } from 'yup';
import {
  Link,
  useFetcher,
  useLoaderData,
  useRouteLoaderData,
  useSubmit,
} from 'react-router-dom';
import LBOffCanvas from '../UI/LBOffCanvas';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import CreateForm from '../Forms/CreateForm/CreateForm';
import FormWrapper from '../Forms/builders/FormWrapper';
import formatCustomerData from '../Appointments/utils/formatCustomerData';
import MessagePreview from '../ContactForm/ContactFormModal/MessagePreview';
import useIsAuthorized from '../../routes/protectedPages/useIsAuthorized';
import HelpTooltip from '../HelpTooltip/HelpTooltip.tsx';

function ReviewFormModal() {
  // Loader
  const { data } = useLoaderData();

  // Get the customer list
  const fetcher = useFetcher();

  // Get the user company from root loader
  const { user } = useRouteLoaderData('root');
  const userCompany = user.user_company || '';

  // For authorization conditional rendering
  const isAuthorized = useIsAuthorized({
    tiersAllowed: ['tier_1', 'tier_2', 'tier_3'],
  });

  React.useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load('/customers');
    }
  }, [fetcher]);

  const [customer, setCustomer] = React.useState({});

  const { customers } = fetcher.data || [];

  const customerNames = React.useMemo(
    () => formatCustomerData(fetcher?.data, 'names'),
    [fetcher]
  );

  const fields = [
    {
      formGroup: 'Ask for Review',
      showTitle: false,
      groupChildren: [
        {
          name: 'customer',
          formType: 'select',
          label: 'Select a Customer',
          initialValue: '',
          options: customerNames,
          type: string().required(),
          className: 'input-wide',
          required: true,
        },
        {
          name: 'phone',
          formType: 'phone',
          label: 'Phone',
          initialValue: '',
          className: 'input-wide',
          type: string().required(),
          required: true,
        },
        {
          name: 'review_message',
          formType: 'textarea',
          label: 'Message',
          initialValue: data.review_message || '',
          className: 'input-wide',
          type: string(),
          maxLength: 800,
        },
        {
          name: 'review_url',
          label: 'Review URL',
          initialValue: data.google_review_page_url || '',
          className: 'input-wide',
          disabled: true,
          type: string().required(
            <p>
              Please add a review URL in the{' '}
              <Link to="/settings/messages/reviews">settings menu</Link>
            </p>
          ),
        },
      ],
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    const nextValues = {
      ...values,
      // This weird spacing is needed to prevent the addition of
      // unneeded spaces in the string literal
      review_message: userCompany
        ? `Message from ${userCompany}:
${values.review_message}`
        : values.review_message,
    };
    submit(nextValues, { method: 'post', encType: 'application/json' });
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  // Set fields that depend on customer
  React.useEffect(() => {
    if (!customers) return;
    if (customer.id !== formik.values.customer) {
      const customerObject = customers.find(
        (item) => item.id === Number(formik.values.customer)
      );

      if (customerObject) {
        setCustomer(customerObject);
        formik.setFieldValue('phone', customerObject.mobile);
        return;
      }
      setCustomer({});
      formik.setFieldValue('phone', '');
    }
  }, [formik.values.customer]);

  return (
    <LBOffCanvas.Wrapper>
      <LBOffCanvas.Left>
        {isAuthorized ? (
          <>
            <LBOffCanvas.Header>
              <div className="d-flex gap-3">
                <h2 className="new-appointment__header">Ask for Review</h2>
                <HelpTooltip>
                  Send a link to your preferred review site to a customer, to
                  increase the possibility of a positive review!
                </HelpTooltip>
              </div>
            </LBOffCanvas.Header>
            <FormWrapper
              id="review-form"
              portalId="offcanvas-action-group-left"
              handleSubmit={formik.handleSubmit}
              isSubmitting={formik.isSubmitting}
              dirty={formik.dirty}
              isValid={formik.isValid}
              label="Send SMS"
            >
              <CreateForm structure={fields} {...formik} />
              <MessagePreview company={userCompany}>
                {formik.values.review_message}
              </MessagePreview>
            </FormWrapper>
          </>
        ) : (
          <div>
            <LBOffCanvas.Header>
              <h2 className="new-appointment__header">Forbidden</h2>
            </LBOffCanvas.Header>
            <p className="mb-4">
              You need to have an active subscription to access this feature.
            </p>
            <Button as={Link} to="/settings/subscription">
              Manage
            </Button>
          </div>
        )}
      </LBOffCanvas.Left>
    </LBOffCanvas.Wrapper>
  );
}

export default ReviewFormModal;
