import formatAddress from '../../components/Appointments/utils/formatAddress';

export default function customerResponse(response) {
  return {
    message: response.message,
    data: {
      id: response.customer.client_id,
      name: {
        fullname: `${response.customer.client_firstname} ${
          response.customer.client_lastname
            ? `${response.customer.client_lastname}`
            : ''
        }`,
        first_name: response.customer.client_firstname,
        last_name: response.customer.client_lastname,
      },
      address: {
        zipcode: response.customer.client_zip,
        street_address_1: response.customer.client_address_1,
        street_address_2: response.customer.client_address_2,
        city: response.customer.client_city,
        state: response.customer.client_state,
        country: response.customer.client_country,
        full_address: formatAddress({
          street_address_1: response.customer.client_address_1,
          street_address_2: response.customer.client_address_2,
          city: response.customer.client_city,
          state: response.customer.client_state,
          zipcode: response.customer.client_zip,
          country: response.customer.client_country,
        }),
      },
      contact: {
        phone: response.customer.client_phone || '',
        fax: response.customer.client_fax || '',
        mobile: response.customer.client_mobile || '',
        email: response.customer.client_email,
      },
      notes: response.customer.ref_info,
      company: response.customer.client_company,
    },
  };
}
