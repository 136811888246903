import React from 'react';
import proptypes from 'prop-types';

export default function StickyButton({ id }) {
  return (
    <div style={{ position: 'sticky', bottom: 0, right: 0 }}>
      <div className="sticky__submit-container" id={id} />
    </div>
  );
}

StickyButton.propTypes = {
  id: proptypes.string.isRequired,
};
