/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

import React, { useState } from 'react';
import {
  Link,
  Outlet,
  useRouteLoaderData,
  useSearchParams,
  useSubmit,
  useResolvedPath,
} from 'react-router-dom';
import * as Yup from 'yup';
import CreateForm from '../Forms/CreateForm/CreateForm';
import SelectedProducts from '../ProductsAndServices/SelectedProducts/SelectedProducts';
import InvoiceRecurrenceForm from './InvoiceRecurrenceForm';
import InvoiceDatesForm from './InvoiceDatesForm';
import FormWrapper from '../Forms/builders/FormWrapper';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import BlockerModal from '../Appointments/BlockerModal';
import useIsAuthorized from '../../routes/protectedPages/useIsAuthorized';
import InvalidSubscriptionPage from '../Settings/ManageSubscription/InvalidSubscriptionPage';
import ServiceInfoForm from './ServiceInfoForm';
import CustomerInfoForm from './CustomerInfoForm';
import useModal from '../UI/LBOffCanvas/useModal';
import LBOffCanvas from '../UI/LBOffCanvas';

function InvoiceNew({ customer, invoice, recurrence, copy }) {
  // Needed to check if user can create new invoices
  const {
    user: {
      subscription: {
        max_invoices: maxInvoices,
        current_invoices: currentInvoices,
      },
    },
  } = useRouteLoaderData('root');

  const { pathname: currentPath } = useResolvedPath('.');

  let modalRootPath = 'new';

  if (invoice?.id) modalRootPath = 'edit';
  if (copy) modalRootPath = 'copy';

  // Modal config
  const { show, size, handleCloseModal } = useModal(modalRootPath);

  // Query string manipulation
  const [searchParams, setSearchParams] = useSearchParams();
  const serviceClientId = JSON.parse(searchParams.get('serviceClientId'));
  const customerId = JSON.parse(searchParams.get('customerId'));

  // This will be a hook
  if (!invoice?.items) invoice.items = [];
  // SET ADITIONAL DATA MANAGED BY OTHER COMPONENTS
  const [aditionalData, setAditionalData] = useState({
    items: {
      product_name: [],
      product_cat: [],
      product_cat_name: [],
      product_id: [],
      product_tax: [],
      product_desc: [],
      product_qty: [],
      product_rate: [],
      product_amount: [],
    },
  });

  // SELECTED PRODUCTS TABLE
  const handleAddItems = (newItems) => {
    const nextItem = {
      product_name: [],
      product_amount: [],
      product_cat: [],
      product_cat_name: [],
      product_id: [],
      product_rate: [],
      product_qty: [],
      product_desc: [],
      product_tax: [],
    };

    newItems?.map((item) => {
      nextItem.product_amount.push(item.item_price_before_tax);
      nextItem.product_cat.push(item.category_id);
      nextItem.product_cat_name.push(item.category_name);
      nextItem.product_desc.push(item.description);
      nextItem.product_id.push(item.product_id);
      nextItem.product_name.push(item.product_name);
      nextItem.product_rate.push(item.price_before_tax);
      nextItem.product_qty.push(item.quantity);
      nextItem.product_tax.push(item.tax_rate);
      return null;
    });
    const nextItems = { items: nextItem };
    setAditionalData((prevItems) => ({ ...prevItems, ...nextItems }));
  };

  const TODAY = new Date().toISOString().substring(0, 10);
  const optionalField = [];
  if (!invoice?.id || copy) {
    optionalField.push({
      name: 'invoice_deposit',
      label: 'Deposit',
      placeholder: '0',
      initialValue: 0,
      className: 'd-none',
      type: Yup.lazy((value) =>
        value !== undefined
          ? Yup.number()
              .typeError('Must be a number')
              .min(0, 'Must be 0 or more')
              .required('Please add the deposit')
          : Yup.string()
      ),
    });
  }

  // FORMIK
  const customerInformation = [
    {
      formGroup: 'Customer information',
      groupChildren: [
        {
          name: 'client_id',
          label: 'Select customer',
          formType: 'customer',
          initialValue: customerId || customer.id,
          type: Yup.string().required('Please select a customer'),
          required: true,
          className: 'flex__span-12',
          labelAction: (
            <Link
              to={`new-customer?customerType=billing${serviceClientId ? `&serviceClientId=${serviceClientId}` : ''}${customerId ? `&customerId=${customerId}` : ''}`}
              // state={{ customerType: 'billing', serviceClientId, customerId }}
            >
              New Customer
            </Link>
          ),
          change: (e) => {
            if (!e)
              return serviceClientId && setSearchParams({ serviceClientId });

            return serviceClientId
              ? setSearchParams({ serviceClientId, customerId: e })
              : setSearchParams({ customerId: e });
          },
        },
        {
          name: 'customer_email',
          label: 'Customer email',
          formType: 'email',
          initialValue: customer.email,
          type: Yup.string().email('Not a proper email'),
          plaintext: true,
          disabled: true,
          readOnly: true,
          className: 'd-none',
        },
        {
          name: 'billing_address',
          label: 'Billing address',
          initialValue: customer.fullAddress,
          formType: 'address',
          type: Yup.string().required(
            'Please select a customer to display the address'
          ),
          disabled: true,
          plaintext: true,
          readOnly: true,
          className: 'd-none',
        },
      ],
    },
  ];

  const serviceInformation = [
    {
      formGroup: 'Service Information',
      showTitle: false,
      groupChildren: [
        // Hidden fields
        {
          name: 'show_service_info',
          label: 'Switch Test',
          initialValue:
            Boolean(invoice?.notes?.serviceInfo?.show_service_info) ||
            Boolean(serviceClientId) ||
            false,
          type: Yup.bool(),
          className: 'd-none',
        },
        {
          name: 'service_name',
          label: 'Customer Name',
          type: Yup.string(),
          initialValue: invoice?.notes?.serviceInfo?.name || '',
          className: 'd-none',
        },
        {
          name: 'service_address',
          label: 'Service Address',
          type: Yup.string(),
          initialValue: invoice?.notes?.serviceInfo?.address || '',
          className: 'd-none',
        },
        // Shown fields
        {
          name: 'service_project_manager',
          label: 'Project Manager',
          type: Yup.string(),
          initialValue: invoice?.notes?.serviceInfo?.project_manager || '',
        },
        {
          name: 'service_estimate',
          label: 'Estimate',
          type: Yup.string(),
          initialValue: invoice?.notes?.serviceInfo?.estimate || '',
        },
        {
          name: 'service_client_id',
          label: 'Select customer',
          formType: 'customer',
          initialValue:
            serviceClientId ||
            invoice?.notes?.serviceInfo?.service_client_id ||
            '',
          type: Yup.string(),
          className: 'flex__span-12',
          labelAction: (
            <Link
              to={`new-customer?customerType=service${serviceClientId ? `&serviceClientId=${serviceClientId}` : ''}${customerId ? `&customerId=${customerId}` : ''}`}
              // state={{ customerType: 'service', serviceClientId, customerId }}
            >
              New Customer
            </Link>
          ),
          config: {
            name: 'service_name',
            address: 'service_address',
          },
          change: (e) => {
            if (!e) return customerId ? setSearchParams({ customerId }) : null;

            return customerId
              ? setSearchParams({ customerId, serviceClientId: e })
              : setSearchParams({ serviceClientId: e });
          },
        },
      ],
    },
  ];

  const invoiceInformation = [
    {
      formGroup: 'Invoice information',
      groupChildren: [
        {
          name: 'invoice_terms',
          label: 'Invoice terms days',
          initialValue: invoice?.dates?.terms || 'due',
          type: Yup.string().required('Please add'),
          required: true,
        },
        {
          name: 'invoice_date_created',
          label: 'Date created',
          initialValue:
            invoice?.dates?.dateCreated ||
            new Date().toISOString().substring(0, 10),
          type: Yup.date().required('Please add the starting date'),
          formType: 'date',
          required: true,
        },
        {
          name: 'invoice_date_due',
          label: 'Date end',
          initialValue: invoice?.dates?.dateDue || '',
          type: Yup.date()
            .min(
              Yup.ref('invoice_date_created'),
              "End date can't be before start date"
            )
            .required('Please add the end date'),
          formType: 'date',
          required: true,
        },
        {
          name: 'invoice_number',
          label: 'Invoice number',
          initialValue: invoice?.number || '',
          type: Yup.string().required('Please add the invoice number'),
          required: true,
          plainText: true,
          disabled: true,
          readOnly: true,
        },
        ...optionalField,
      ],
    },
  ];

  const recurrent = [
    // RECURRENCE
    {
      formGroup: 'Recurrence',
      groupChildren: [
        {
          name: 'recurrent',
          label: 'Make this invoice recurrent',
          initialValue: recurrence?.isRecurrent || 0,
          type: Yup.string(),
        },
        {
          name: 'recur_frequency',
          label: 'Recur frequency',
          initialValue: recurrence?.frequency || 0,
          type: Yup.string().when(['recurrent'], {
            is: '1',
            then: (schema) => schema.required('Please select a frequency'),
          }),
          formType: 'select',
          options: [
            { label: 'Never', value: '0' },
            { label: 'Daily', value: 'daily' },
            { label: 'Weekly', value: 'weekly' },
            { label: '2 Weekly', value: '2_weekly' },
            { label: 'Monthly', value: 'monthly' },
          ],
          change: (e) => {
            const nextRecurrent = e === '0' || e === '' ? 0 : 1;
            formik.setFieldValue('recurrent', nextRecurrent); // eslint-disable-line no-use-before-define
          },
          required: true,
        },
        {
          name: 'recur_day',
          label: 'Recurence day',
          initialValue: recurrence?.day || '',
          type: Yup.string().when(['recur_frequency'], {
            is: (recurFrequency) =>
              recurFrequency === 'weekly' || recurFrequency === '2_weekly',
            then: (schema) => schema.required('Please select a day'),
          }),
          formType: 'select',
          options: [
            { label: 'Monday', value: 'monday' },
            { label: 'Tuesday', value: 'tuesday' },
            { label: 'Wednesday', value: 'wednesday' },
            { label: 'Thursday', value: 'thursday' },
            { label: 'Friday', value: 'friday' },
            { label: 'Saturday', value: 'saturday' },
            { label: 'Sunday', value: 'sunday' },
          ],
          rule: ['recur_frequency', 'weekly'],
          required: true,
        },
        {
          name: 'recur_date',
          label: 'Recurence date',
          initialValue: recurrence?.date || '',
          type: Yup.date()
            .when(['invoice_date_created'], {
              is: (invoiceDateCreates) => invoiceDateCreates !== '',
              then: (schema) =>
                schema.min(
                  Yup.ref('invoice_date_created'),
                  "End recurrence date can't be before invoice date created"
                ),
            })
            .when(['recur_frequency'], {
              is: 'monthly',
              then: (schema) => schema.required('Please select a date'),
            }),
          formType: 'date',
          required: true,
        },
        {
          name: 'recur_till',
          label: 'Recurence ends',
          initialValue: recurrence?.till || '',
          type: Yup.date()
            .min(TODAY, 'End date can not be in the past')
            .when(['invoice_date_created'], {
              is: (invoiceDateCreates) => invoiceDateCreates !== '',
              then: (schema) =>
                schema.min(
                  Yup.ref('invoice_date_created'),
                  "End recurrence date can't be before invoice date created"
                ),
            })
            .when(['recur_frequency'], {
              is: (frequency) => frequency === 'monthly',
              then: (schema) =>
                schema.min(
                  Yup.ref('recur_date'),
                  "End recurrence date can't be before first recurrence date"
                ),
            })
            .when(['recur_frequency'], {
              is: (frequency) =>
                frequency === 'weekly' ||
                frequency === '2_weekly' ||
                frequency === 'monthly',

              then: (schema) => schema.required('Please select a date'),
            }),
          formType: 'date',
          required: true,
        },
      ],
    },
  ];

  const notes = [
    // NOTES
    {
      formGroup: 'Customer Notes',
      groupChildren: [
        {
          name: 'statement_notes',
          label: 'Statement notes',
          initialValue: invoice?.notes?.statementNotes || '',
          type: Yup.string(),
          formType: 'textarea',
          maxLength: 800,
        },
        {
          name: 'customer_notes',
          label: 'Customer notes',
          initialValue: invoice?.notes?.customerNotes || '',
          type: Yup.string(),
          formType: 'textarea',
          maxLength: 1000,
        },
      ],
    },
  ];
  const [aditionalDataFeedback, setAditionalDataFeedback] = useState(false);

  const recurrentAllowed = useIsAuthorized({ tiersAllowed: ['tier_3'] });

  const addRecurrent = recurrentAllowed ? recurrent : [];

  const formValues = [
    ...customerInformation,
    ...serviceInformation,
    ...invoiceInformation,
    ...notes,
    ...addRecurrent,
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    if (aditionalData.items.product_id.length === 0) {
      formik.setSubmitting(false);
      setAditionalDataFeedback(true);
      return;
    }
    setAditionalDataFeedback(false);
    const newDeposit =
      values.invoice_deposit === '' ? 0 : values.invoice_deposit;
    const preNextValues = {
      ...values,
      ...aditionalData,
      invoice_deposit: newDeposit,
    };

    if (invoice?.id) preNextValues.invoice_id = invoice?.id;
    const nextValues = { json: JSON.stringify(preNextValues) };
    submit(nextValues, { method: 'post' });
    formik.setSubmitting(false);
  };

  const [formik] = useFormikTemplate({
    initial: formValues,
    yupValues: formValues,
    submitAction,
    enableReinitialize: false,
  });

  if (maxInvoices !== 'unlimited' && currentInvoices >= maxInvoices) {
    return (
      <InvalidSubscriptionPage
        tiersAllowed={['tier_2', 'tier_3']}
        limitReached
      />
    );
  }

  // Set billing and/or service client if provided in query string
  React.useEffect(() => {
    if (customerId) formik.setFieldValue('client_id', Number(customerId));
    if (serviceClientId)
      formik.setFieldValue('service_client_id', Number(serviceClientId));
  }, [customerId, serviceClientId]);

  return (
    <>
      <LBOffCanvas show={show} size={size} handleCloseModal={handleCloseModal}>
        <Outlet />
      </LBOffCanvas>
      <ActionErrorInformation />
      <FormWrapper
        id="CreateInvoice"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        dirty={formik.dirty}
        isValid={formik.isValid}
        className="invoice-form-wrapper flex__form-wrapper"
      >
        <CustomerInfoForm formik={formik} structure={customerInformation} />

        <InvoiceDatesForm {...formik} invoiceId={invoice?.id} copy={copy} />

        <h5 className="mb-4 mt-4">Items </h5>
        <SelectedProducts
          onAddItems={handleAddItems}
          initialItems={invoice?.items}
          feedback={aditionalDataFeedback}
        />
        {recurrentAllowed && (
          <>
            <h5 className="mb-4 mt-4">Special features</h5>
            <InvoiceRecurrenceForm {...formik} />
          </>
        )}

        <ServiceInfoForm formik={formik} structure={serviceInformation} />

        <CreateForm structure={notes} {...formik} />
      </FormWrapper>
      <BlockerModal exceptions={[`${currentPath}/new-customer`, currentPath]} />
    </>
  );
}
export default InvoiceNew;
