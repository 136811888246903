const BUSINESS_OVERVIEW_TOGGLE = [
  {
    label: 'Profit and Loss Report',
    value: 'profit_and_loss_report',
    color: 'primary',
  },
  {
    label: 'Profit and Loss by Customer',
    value: 'profit_and_loss_by_customer',
    color: 'primary',
  },
];

const SALES_TOGGLE = [
  {
    label: 'Sales',
    value: 'sales_report',
    color: 'primary',
  },
  {
    label: 'Sales by Customer',
    value: 'sales_by_customer',
    color: 'primary',
  },
  {
    label: 'Sales by Product',
    value: 'sales_by_product',
    color: 'primary',
  },
  {
    label: 'Sales by Service',
    value: 'sales_by_service',
    color: 'primary',
  },
  {
    label: 'Products and Services List',
    value: 'products_and_services_list',
    color: 'primary',
  },
];

const EXPENSES_TOGGLE = [
  {
    label: 'Expenses by Category',
    value: 'expenses_by_category',
    color: 'primary',
  },
  {
    label: 'Expenses by Invoice',
    value: 'expenses_by_invoice',
    color: 'primary',
  },
  {
    label: 'Expenses by Merchant',
    value: 'expenses_by_merchant',
    color: 'primary',
  },
  {
    label: 'Expenses by Vendor',
    value: 'expenses_by_vendor',
    color: 'primary',
  },
];

export { SALES_TOGGLE, BUSINESS_OVERVIEW_TOGGLE, EXPENSES_TOGGLE };
