/* eslint-disable no-use-before-define */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useLocation, useSearchParams, useSubmit } from 'react-router-dom';
import {
  set,
  add,
  differenceInMinutes,
  areIntervalsOverlapping,
  isValid,
  parseISO,
} from 'date-fns';
import { date, string } from 'yup';
import CreateForm from '../Forms/CreateForm/CreateForm';
import FormWrapper from '../Forms/builders/FormWrapper';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import useAppointmentContext from './Context/useAppointmentContext';
import { findAppointmentsByDate, toLegacyDateString } from './utils/helpers';
import useCustomerFormData from './utils/useCustomerFormData';
import LBMapContainer from './LBMapContainer';
import BlockerModal from './BlockerModal';
import { setCurrentDay, setFormikValues } from './utils/setCurrentDay';
import {
  textarea,
  isPhoneNumber,
  textInput,
} from '../Forms/yup/customValidations';
import checkIfPastDay from './utils/customValidations';

function NewAppointment() {
  const {
    currentDate,
    appointments,
    customerNames,
    customers,
    updateCurrentDay,
  } = useAppointmentContext();
  const [searchParams] = useSearchParams();
  const initialHours = searchParams.get('startHours');
  const initialMinutes = searchParams.get('startMinutes');
  const location = useLocation();

  // We determine what's the max date for that day
  const maxDate = React.useMemo(() => {
    return set(currentDate, {
      hours: 23,
      minutes: 59,
      seconds: 0,
      milliseconds: 0,
    });
  }, [currentDate]);

  // Get the current date's appointments (this could be moved to
  // the context, I've been using it in several places...)
  const isOverlapping = React.useCallback(
    (appStart, appEnd) => {
      if (!isValid(appStart) || !isValid(appEnd)) return true;
      if (appStart > appEnd) return true;
      const todaysAppointments = findAppointmentsByDate(
        currentDate,
        appointments
      );
      return !todaysAppointments.some((app) => {
        return areIntervalsOverlapping(
          {
            start: new Date(app.start_date),
            end: new Date(app.end_date),
          },
          { start: appStart, end: appEnd }
        );
      });
    },
    [currentDate]
  );

  // We set the date to the current day with the current time
  // plus 5 minutes (to give some margin while the user creates the
  // appointment)
  const initialDate = React.useMemo(() => {
    if (initialHours && initialMinutes) {
      const res = set(currentDate, {
        hours: initialHours,
        minutes: initialMinutes,
      });
      return res;
    }

    if (location?.state?.values?.start_date) {
      return location.state.values.start_date;
    }

    return set(currentDate, {
      hours: new Date().getHours(),
      minutes: new Date().getMinutes() + 5,
    });
  }, [currentDate]);

  // We add the minimum duration to get the end date inital value
  const addThirty = React.useMemo(() => {
    if (location?.state?.values?.end_date) {
      return location.state.values.end_date;
    }
    const newDate = add(initialDate, { minutes: 30 });
    return newDate >= maxDate ? maxDate : newDate;
  }, [currentDate]);

  const fields = [
    {
      formGroup: 'Appointment details',
      groupChildren: [
        {
          name: 'name',
          formType: 'text',
          label: 'Appointment name',
          initialValue: location?.state?.values?.name || '',
          type: textInput.required('Please complete the field'),
          required: true,
        },
        {
          name: 'current_day',
          formType: 'date',
          label: 'Appointment Day',
          // This weird string split is needed to use the vanilla date input
          // we should -probably- use a better date picker component
          // in the future using the react-calendar library as a base
          initialValue: toLegacyDateString(currentDate),
          change: (e) => {
            const nextDate = setCurrentDay({
              initialStartDate: formik.values.start_date,
              initialEndDate: formik.values.end_date,
              dateValue: e.target.value,
            });

            setFormikValues({
              updateCurrentDay,
              formik,
              nextDate,
            });
          },
          type: string()
            .required()
            .test({
              name: 'checkIfPastDay',
              exclusive: false,
              params: {},
              message: "Can't create appointments in the past",
              test(value) {
                return checkIfPastDay(value);
              },
            }),
          required: true,
        },
        {
          name: 'start_date',
          formType: 'time',
          label: 'Start time',
          initialValue: initialDate,
          className: 'flex__span-3',
          type: date()
            .min(new Date(), "Can't create apppointments in the past")
            .test({
              name: 'isWithinInterval',
              exclusive: false,
              params: {},
              message: 'Appointment overlapping',
              test(value) {
                return isOverlapping(new Date(value), this.parent.end_date);
              },
            })
            .required('Please complete the field'),
          required: true,
        },
        {
          name: 'end_date',
          formType: 'time',
          label: 'End time',
          initialValue: addThirty,
          className: 'flex__span-3',
          type: date()
            .test({
              name: 'greaterThanStart',
              exclusive: false,
              params: {},
              message: 'End date must be greater than start date',
              test(value) {
                return value > this.parent.start_date;
              },
            })
            .test({
              name: 'isWithinInterval',
              exclusive: false,
              params: {},
              message: 'Appointment overlapping',
              test(value) {
                const overlap = isOverlapping(
                  this.parent.start_date,
                  new Date(value)
                );
                return overlap;
              },
            })
            .test({
              name: 'minimumDuration',
              exclusive: false,
              params: {},
              message: 'Minimum duration is 30 minutes',
              test() {
                const delta = differenceInMinutes(
                  new Date(this.parent.end_date),
                  new Date(this.parent.start_date)
                );
                return delta >= 30;
              },
            })
            .max(maxDate, ({ max }) => {
              return `End date can't be higher than ${max.toLocaleString(
                'en-US'
              )}`;
            })
            .required('Please complete the field'),
          required: true,
        },
        {
          name: 'notes',
          formType: 'textarea',
          label: 'Notes',
          initialValue: location?.state?.values?.notes || '',
          className: 'flex__row-span-2',
          type: textarea,
          maxLength: 500,
        },
        {
          name: 'performer_name',
          formType: 'text',
          label: 'Performer Name',
          initialValue: location?.state?.values?.performer_name || '',
          type: textInput,
        },
        {
          name: 'customer_id',
          formType: 'select',
          label: 'Customer',
          initialValue: location?.state?.values?.customer_id || '',
          options: customerNames,
          type: string(),
        },
        {
          name: 'contact_name',
          formType: 'text',
          label: 'Contact name',
          initialValue: '',
          options: customerNames,
          type: textInput,
          className: 'input-wide',
        },
        {
          name: 'contact_phone',
          formType: 'phone',
          label: 'Phone',
          type: isPhoneNumber,
          initialValue: '',
        },
      ],
    },
    {
      formGroup: 'Appointment address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          className: 'flex__span-12',
          initialValue: '',
          type: string(),
          formType: 'autocompleteAddress',
          fieldNames: {
            street: 'appointment_address_street_line_1',
            city: 'appointment_city',
            state: 'appointment_state',
            zipcode: 'appointment_zipcode',
            country: 'appointment_country',
          },
        },
        {
          name: 'appointment_address_street_line_1',
          formType: 'text',
          label: 'Address Line 1',
          initialValue: '',
          autocomplete: 'address-line1',
          type: string(),
        },
        {
          name: 'appointment_address_street_line_2',
          formType: 'text',
          label: 'Address Line 2',
          initialValue: '',
          type: string(),
        },
        {
          name: 'appointment_zipcode',
          formType: 'text',
          label: 'ZIP Code',
          initialValue: '',
          type: string(),
        },
        {
          name: 'appointment_city',
          formType: 'text',
          label: 'City',
          initialValue: '',
          type: string(),
        },
        {
          name: 'appointment_state',
          formType: 'text',
          label: 'State',
          initialValue: '',
          type: string(),
        },
        {
          name: 'appointment_country',
          formType: 'text',
          label: 'Country',
          initialValue: '',
          type: string(),
        },
      ],
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post', encType: 'application/json' });
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  // Set customer related fields
  useCustomerFormData({ customers, formik });

  return (
    <div>
      <h4 className="mb-5">
        {parseISO(formik.values.current_day).toLocaleDateString('en-US', {
          dateStyle: 'full',
        })}
      </h4>
      <FormWrapper
        id="new-appointment"
        className="flex__form-wrapper"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        label="Create appointment"
        portalId="new-appointment-submit-container"
        dirty={formik.dirty}
        isValid={formik.isValid}
      >
        <CreateForm structure={fields} {...formik} />
      </FormWrapper>
      <LBMapContainer
        address={`${formik.values.appointment_address_street_line_1} ${formik.values.appointment_state} ${formik.values.appointment_city} ${formik.values.appointment_country}`}
      />

      <div style={{ position: 'sticky', bottom: 0, right: 0 }}>
        <div
          className="sticky__submit-container"
          id="new-appointment-submit-container"
        />
      </div>

      <BlockerModal />
    </div>
  );
}

export default NewAppointment;
