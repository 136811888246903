import fetchAPI from '../fetchUtils';

const productsAPI = {
  create: async (body) =>
    fetchAPI.postWithAuth('/create-product', body, 'application/json'),
  update: async (body) =>
    fetchAPI.postWithAuth('/update-product', body, 'application/json'),
  delete: async (productId) =>
    fetchAPI.getWithAuth(`/delete-product/${productId}`),
  get: async (productId) => fetchAPI.getWithAuth(`/get-product/${productId}`),
  getAll: async () => fetchAPI.getWithAuth('/get-products'),
};

export default productsAPI;
