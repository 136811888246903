/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';

export default function DataSelect({
  options,
  value,
  onChange,
  prefix = 'edit-select',
}) {
  if (!options) return null;
  return (
    <Select
      value={value ? options.find((o) => o.value === value) : null}
      isClearable
      options={options}
      placeholder="Select"
      classNamePrefix={prefix}
      onChange={(e) => {
        if (!e) onChange(null);
        else if (e.value !== value) onChange(e.value);
      }}
      menuPlacement="auto"
    />
  );
}
