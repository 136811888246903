/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import React from 'react';
import {
  useSubmit,
  useRouteLoaderData,
  useParams,
  useNavigate,
  Outlet,
} from 'react-router-dom';
import * as Yup from 'yup';
import CreateForm from '../Forms/CreateForm/CreateForm';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import FormWrapper from '../Forms/builders/FormWrapper';

function CreateExpenseCategory() {
  const loaderData = useRouteLoaderData('expenseCategories');
  const navigate = useNavigate();
  const { expenseCategoryId } = useParams();
  const initialData =
    loaderData?.result?.filter(
      (item) => `${item?.expense_category_id}` === `${expenseCategoryId}`
    )[0] || '';
  const [checked, setChecked] = React.useState(initialData.profit_loss === 1);
  const formValues = [
    {
      name: 'expense_category_name',
      label: 'Expense category name',
      type: Yup.string().required(
        'Please enter a name for the expense category'
      ),

      required: true,
      className: 'input-wide',
      initialValue: initialData?.expense_category_name || '',
    },
    {
      name: 'expense_category_description',
      label: 'Expense category description',
      formType: 'textarea',
      initialValue: initialData?.description || '',
      type: Yup.string(),
    },
  ];
  // close modal on cancel
  const handleClose = () => {
    navigate('/expenses/categories');
  };
  const submit = useSubmit();
  const submitAction = async (values) => {
    const nextValues = { ...values };
    nextValues.profit_loss = checked ? 'Yes' : '';
    if (expenseCategoryId) {
      nextValues.id = expenseCategoryId;
    }
    submit(nextValues, { method: 'post' });
    formik.setSubmitting(false);
    handleClose();
  };
  const [formik] = useFormikTemplate({
    initial: formValues,
    yupValues: formValues,
    submitAction,
  });

  return (
    <>
      <Outlet />
      <ActionErrorInformation />
      <FormWrapper
        id="NewExpenseCategory"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        dirty={formik.dirty}
        isValid={formik.isValid}
      >
        <CreateForm structure={formValues} {...formik} />
        <label htmlFor="profit_loss">
          <input
            type="checkbox"
            id="profit_loss"
            name="profit_loss"
            checked={checked}
            onChange={() => setChecked((prev) => !prev)}
            onBlur={formik.handleBlur}
            className="form-check-input"
          />
          Add category to profit loss report?
        </label>
      </FormWrapper>
    </>
  );
}

export default CreateExpenseCategory;
