/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */

import React from 'react';
import PropTypes from 'prop-types';
import TimePickerPopover from './TimePickerPopover';

function CreateTimePicker({
  errors,
  name,
  setTouched,
  setFieldValue,
  touched,
  value,
}) {
  const initHours = (hours) => {
    if (hours === 12 && value.getMinutes() >= 0) {
      return { hours, midday: 'PM' };
    }

    if (hours > 12) {
      return { hours: hours - 12, midday: 'PM' };
    }
    return { hours, midday: 'AM' };
  };

  const [hours, setHours] = React.useState(
    () => initHours(value.getHours()).hours
  );
  const [minutes, setMinutes] = React.useState(value.getMinutes());
  const [midday, setMidday] = React.useState(
    () => initHours(value.getHours()).midday
  );

  const hourRef = React.useRef(null);
  const minutesRef = React.useRef(null);
  const middayRef = React.useRef(null);

  React.useEffect(() => {
    if (!value) return;
    const newValue = value;

    // Convert AM/PM hours to intl format
    if (midday === 'PM' && hours < 12) {
      newValue.setHours(hours + 12);
    } else if (midday === 'AM' && hours === 12) {
      // It's midnight
      newValue.setHours(0);
    } else {
      newValue.setHours(hours);
    }
    newValue.setMinutes(minutes);
    newValue.setSeconds(0);
    newValue.setMilliseconds(0);
    setFieldValue(name, newValue);
  }, [hours, minutes, midday]);

  const handleFocus = (e) => {
    setTouched({ ...touched, [name]: true });
    e.target.select();
  };

  const handleHours = (e) => {
    if (!minutesRef.current) return;

    const textValue = e.target.value;
    const numericValue = Number(textValue);

    if (Number.isNaN(numericValue)) return;

    if (textValue.length <= 1 && numericValue > 2) {
      setHours(numericValue);
      if (e.target.type !== 'select-one') minutesRef.current.focus();
      return null;
    }

    if (numericValue > 12 && numericValue <= 24) {
      setHours(numericValue - 12);
      setMidday('PM');
      return null;
    }

    if (numericValue > 24) {
      setHours(12);
      setMidday('PM');
      return null;
    }

    return setHours(numericValue);
  };

  const handleMinutes = (e) => {
    if (!middayRef.current) return;

    const textValue = e.target.value;
    const numericValue = Number(textValue);

    if (Number.isNaN(numericValue)) return;

    if (textValue.length <= 1 && numericValue > 6) {
      setMinutes(numericValue);
      if (e.target.type !== 'select-one') middayRef.current.focus();
      return null;
    }

    if (
      textValue.replace(/^0+/, '').length === 2 &&
      numericValue >= 10 &&
      numericValue <= 59
    ) {
      setMinutes(numericValue);
      if (e.target.type !== 'select-one') middayRef.current.focus();
      return null;
    }

    if (numericValue > 59) {
      setMinutes(0);
      return null;
    }

    setMinutes(numericValue);
    return null;
  };

  const handleMidday = (e) => {
    const textValue = e.target.value;
    if (['a', 'A', 'am', 'AM'].includes(textValue)) {
      setMidday('AM');
      return null;
    }
    if (['p', 'P', 'pm', 'PM'].includes(textValue)) {
      setMidday('PM');
      return null;
    }
    e.target.select();
  };

  const handleMiddayKey = (e) => {
    e.stopPropagation();
    const allowedKeys = ['p', 'P', 'a', 'A', 'Tab', 'Enter'];

    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }

    if (e.code === 'KeyA') {
      setMidday('AM');
      return null;
    }
    if (e.code === 'KeyP') {
      setMidday('PM');
      return null;
    }
    e.target.select();
  };

  return (
    <div
      className={`form-control time-picker__input-wrapper ${
        errors && touched[name] ? 'is-invalid' : ''
      } ${!errors && touched[name] ? 'is-valid' : ''}`}
      onClick={() => hourRef.current.focus()}
      role="group"
    >
      <input
        type="text"
        inputMode="numeric"
        value={String(hours).padStart(2, '0')}
        ref={hourRef}
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => handleFocus(e)}
        onChange={(e) => handleHours(e)}
        id={name}
      />
      :
      <input
        type="text"
        inputMode="numeric"
        value={String(minutes).padStart(2, '0')}
        ref={minutesRef}
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => handleFocus(e)}
        onChange={(e) => handleMinutes(e)}
        id={`${name}-minutes`}
      />
      <input
        type="text"
        value={midday}
        ref={middayRef}
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => handleFocus(e)}
        onKeyDown={(e) => handleMiddayKey(e)}
        onChange={(e) => handleMidday(e)}
        id={`${name}-midday`}
      />
      <TimePickerPopover
        name={name}
        handleHours={handleHours}
        handleMinutes={handleMinutes}
        handleMidday={handleMidday}
        hours={hours}
        minutes={minutes}
        midday={midday}
      />
    </div>
  );
}

CreateTimePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  setFieldValue: PropTypes.func,
  name: PropTypes.string,
  errors: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  touched: PropTypes.object,
  setTouched: PropTypes.func,
};

export default CreateTimePicker;
