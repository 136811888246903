import React from 'react';

function ScrollToError({ isSubmitting }: { isSubmitting: boolean }) {
  React.useEffect(() => {
    const el = document.querySelector('.is-invalid');
    (el?.parentElement ?? el)?.scrollIntoView();
  }, [isSubmitting]);
  return null;
}

export default ScrollToError;
