/* eslint-disable react/prop-types */

import React from 'react';
import { Sector } from 'recharts';

import './CenterLabel.scss';
import formatCurrency from './formatCurrency';

export function CustomLabel({ totalValue, color, viewBox, sub, main }) {
  const { cx, cy } = viewBox;
  const x = cx;
  const y = cy;

  return (
    <text offset={0} x={x} y={y} textAnchor="middle" className="custom-label">
      <tspan className="sub" x={x} dy="-0.7em">
        {sub || 'Total'}
      </tspan>
      <tspan
        className="main"
        x={x}
        dy="1.2em"
        style={{ fill: color || 'var(--bs-primary)' }}
      >
        {formatCurrency(main || totalValue)}
      </tspan>
    </text>
  );
}

export const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    payload,
    value,
    fill,
  } = props;

  return (
    <g>
      <CustomLabel
        viewBox={{ cx, cy }}
        main={value}
        sub={payload.name}
        color={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 5}
        outerRadius={outerRadius + 5}
        startAngle={startAngle}
        endAngle={endAngle}
        stroke="white"
        strokeWidth={2}
        fill={fill}
      />
    </g>
  );
};
