import React from 'react';
import propTypes from 'prop-types';
import { parseISO } from 'date-fns';
import { usePDF } from '@react-pdf/renderer';
import { useOutletContext } from 'react-router-dom';
import LBTooltip from '../UI/LBTooltip/LBTooltip';
import IconButton from '../UI/IconButton/IconButton';
import { Download, Print } from '../UI/Icons/Icons';
import PDFViewer from '../Checks/PDF/lbPDFViewer';

function ReportHeader({ reportSection, pdfDocument }) {
  const { reportValue, dateFrom, dateTo } = useOutletContext();
  const iFrameRef = React.useRef({});
  const [instance] = usePDF({
    document: pdfDocument,
  });

  if (!reportValue) return <p>Generate report first!</p>;

  const reportLabel = reportSection.find(
    (item) => item.value === reportValue
  ).label;

  const handlePrint = () => {
    if (!iFrameRef.current) return;
    if (instance.loading) return;
    iFrameRef.current.focus();
    iFrameRef.current.contentWindow.print();
  };

  return (
    <>
      <PDFViewer
        style={{ display: 'none' }}
        innerRef={iFrameRef}
        instance={instance}
      />

      <header className="d-flex flex-column flex-md-row align-items-md-center justify-content-between pb-4">
        <div className="d-flex flex-column gap-1 p-2">
          <h2 className="d-inline">{reportLabel}</h2>
          {dateFrom && dateTo && (
            <small style={{ color: 'var(--bs-gray-600)', fontStyle: 'italic' }}>
              {parseISO(dateFrom).toLocaleDateString('en-US', {
                dateStyle: 'long',
              })}{' '}
              to{' '}
              {parseISO(dateTo).toLocaleDateString('en-US', {
                dateStyle: 'long',
              })}
            </small>
          )}
        </div>
        <div className="d-flex gap-2 align-items-center">
          <LBTooltip
            tooltipText={
              instance.error ? 'Error loading PDF' : 'Download Report'
            }
          >
            <IconButton
              as="a"
              href={instance.url}
              download={`${reportValue}-${new Date().toLocaleDateString('en-US', { dateStyle: 'short' }).replaceAll('/', '-')}`}
              target="_blank"
              disabled={instance.error || instance.loading}
            >
              <Download />
            </IconButton>
          </LBTooltip>
          <LBTooltip
            tooltipText={instance.error ? 'Error loading PDF' : 'Print Report'}
          >
            <IconButton
              disabled={instance.error || instance.loading}
              onClick={handlePrint}
            >
              <Print />
            </IconButton>
          </LBTooltip>
        </div>
      </header>
    </>
  );
}

ReportHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  reportSection: propTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  pdfDocument: propTypes.object,
};

export default ReportHeader;
