const UNITS = [
  {
    id: 0,
    label: 'Unit (un)',
    value: 'UN',
  },
  {
    id: 1,
    label: 'Lineal Feet (lnft)',
    value: 'LNFT',
  },
  {
    id: 2,
    label: 'Square Feet (sqft)',
    value: 'SQFT',
  },
  {
    id: 3,
    label: 'Yard (yrd)',
    value: 'YRD',
  },
  {
    id: 4,
    label: 'Square Yard (sqyrd)',
    value: 'SQYRD',
  },
  {
    id: 5,
    label: 'Square Inch (sqin)',
    value: 'SQIN',
  },
  {
    id: 6,
    label: 'Gallon (gl)',
    value: 'GL',
  },
  {
    id: 7,
    label: 'Quartz (qt)',
    value: 'QT',
  },
];

export default UNITS;
