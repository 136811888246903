/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function ConfirmPrintModal({ show, setShow, handleSuccess }) {
  return (
    <Modal show={show} onHide={() => setShow(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Confirm print success</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The check has been successfully printed? Please confirm that the check
        has been printed.
      </Modal.Body>
      <Modal.Footer>
        <Button
          value="cancel"
          variant="secondary"
          onClick={() => setShow(false)}
        >
          Cancel
        </Button>
        <Button value="proceed" variant="primary" onClick={handleSuccess}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
