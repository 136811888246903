import React from 'react';
import { Link } from 'react-router-dom';
import OnboardGrid from '../../UI/OnboardGrid/OnboardGrid';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import BulletList from '../../UI/BulletList/BulletList';
import StepList from '../../UI/StepList/StepList';
import image from '../../../img/assets/images/c-userOnboard-payments.svg';

import './paymentsOnboard.styles.scss';

export const paymentsBullets = [
  {
    id: 0,
    pre: 'Payment Options',
    body: 'Get paid faster accepting credit card payments and money transfer.',
  },
  {
    id: 1,
    pre: 'Follow up',
    body: 'Follow up notifications when payment is overdue.',
  },
  {
    id: 2,
    pre: 'Notes',
    body: 'Create special notes for the payment.',
  },
  {
    id: 3,
    pre: 'Attachments',
    body: 'Add attachments to the invoice like, photos and documents.',
  },
  {
    id: 4,
    pre: 'Send Receipts',
    body: "Send profesional receipts to your customers for every payment. Feature is available in the The App's Box.",
  },
  {
    id: 5,
    pre: 'Notifications',
    body: 'Stay on the top of your overdue payment.',
  },
  {
    id: 6,
    pre: 'Automated Payment Reminder',
    body: 'Customers with overdue invoices will receive an automated email on the following day of due date. Then every 2 days until the invoice is paid or due date is extended.',
  },
  {
    id: 7,
    pre: 'And much more...',
    body: '',
  },
];

export const paymentsSteps = [
  {
    id: 0,
    body: <p>Create expense categories.</p>,
  },
  {
    id: 1,
    body: <p>Select Invoice.</p>,
  },
  {
    id: 2,
    body: <p>Enter Amount.</p>,
  },
  {
    id: 3,
    body: <p>Select Payment Method.</p>,
  },
];

function Payments() {
  return (
    <CardWrapper>
      <OnboardGrid>
        <OnboardGrid.Header>
          <h2>Get Paid Faster</h2>
        </OnboardGrid.Header>
        <OnboardGrid.Left>
          <BulletList list={paymentsBullets} title="Payment module features:" />
          <StepList stepArray={paymentsSteps} />
        </OnboardGrid.Left>
        <OnboardGrid.Right>
          <img
            className="page-image"
            src={image}
            alt="vector illustration of a person viewing data visualizations"
          />
          <div className="action-group">
            <Link
              className="support-link btn btn-primary p-2 mb-3"
              variant="secondary"
              to="/payments/new"
            >
              Add your first payment
            </Link>
          </div>
          <div className="support-wrapper">
            <p className="support">Have Questions?</p>
            <a
              className="support-link"
              href="mailto:support@leadsbox.com?subject=Support needed"
            >
              Go to support
            </a>
          </div>
        </OnboardGrid.Right>
      </OnboardGrid>
    </CardWrapper>
  );
}

export default Payments;
