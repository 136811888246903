/* eslint-disable react/prop-types */
import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import PDFCol from './PDFCol';

const rootStyles = getComputedStyle(document.body);

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '6px 0',
    borderBottom: `1px solid ${rootStyles.getPropertyValue('--bs-gray-200')}`,
  },
});

function PDFRow({ forwardKey, tableSetup, rowData }) {
  return (
    <View style={styles.row}>
      {Array.from(Object.keys(rowData))
        .filter((item) => item !== 'id')
        .map((row) => (
          <PDFCol
            key={`${forwardKey}-${row}`}
            colSetup={tableSetup.find((item) => item.accessorKey === row)}
          >
            {rowData[row]}
          </PDFCol>
        ))}
    </View>
  );
}

export default PDFRow;
