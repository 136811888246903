import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

function DataLink({ value }) {
  return value?.href ? (
    <Link
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="table__click"
      to={value?.href}
    >
      {value?.label}
    </Link>
  ) : (
    <span>{value?.label ? value?.label : ''}</span>
  );
}

DataLink.propTypes = {
  value: propTypes.shape({
    href: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
  }).isRequired,
};
export default DataLink;
