/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

import React, { useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import GOOGLE_MAPS_OPTIONS from '../../Customers/mapConstants';

import '../Forms.scss';

const DEFAULT_FIELD_NAMES = {
  street: 'street_address',
  city: 'city',
  state: 'state',
  zipcode: 'zipcode',
  country: 'country',
};

function AutoCompleteAddress({
  fieldNames = DEFAULT_FIELD_NAMES,
  name,
  setFieldValue,
  touched,
  setTouched,
  // validateField,
  // validateForm,
  // setFieldTouched,
}) {
  const [hasChanged, setHasChanged] = React.useState(false);
  const { isLoaded, loadError } = useJsApiLoader(GOOGLE_MAPS_OPTIONS);
  const setFieldValueAndValidate = async (field, value) => {
    await setFieldValue(field, value);
  };

  useEffect(() => {
    if (hasChanged) {
      const touchedFields = Object.values(fieldNames).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      setTouched({
        ...touched,
        ...touchedFields,
      });
      setHasChanged(false);
    }
  }, [hasChanged]);
  const autoCompleteRef = React.useRef();
  const inputRef = React.useRef();

  const options = React.useMemo(
    () => ({
      // This is to restrict searches to a country. Maybe we could implement this
      // to the account country? Then again maybe the account wants to have
      // international customers

      // componentRestrictions: { country: 'at' },
      fields: ['address_components', 'geometry', 'name'],

      // Regarding this field:
      // https://developers.google.com/maps/documentation/places/web-service/supported_types

      // Had better results leaving it empty, but some places don't provide enough information
      // such as street numbers

      // Consider using "address" as a type
      types: [],
    }),
    []
  );

  const getComponentValue = (type, addrComp) => {
    const [component] = addrComp.filter((item) => item.types.includes(type));
    if (!component) return '';
    return component.long_name;
  };

  const onChange = async (val) => {
    const components = val.address_components;
    if (!components) return;

    // DEBUG
    // const debugTable = components.map((component) => {
    //   return {
    //     'Long Name': component.long_name,
    //     Types: component.types.reduce((acc, cur) => `${acc}, ${cur}`),
    //   };
    // });

    // console.table(debugTable);

    const streetName = getComponentValue('route', components);
    const streetNumber = getComponentValue('street_number', components);
    const city = getComponentValue('locality', components);
    const state = getComponentValue('administrative_area_level_1', components);
    const zipCode = getComponentValue('postal_code', components);
    const country = getComponentValue('country', components);

    await setFieldValueAndValidate(
      fieldNames.street,
      `${streetNumber} ${streetName}`
    );
    await setFieldValueAndValidate(fieldNames.city, city);
    await setFieldValueAndValidate(fieldNames.state, state);
    await setFieldValueAndValidate(fieldNames.zipcode, zipCode);
    await setFieldValueAndValidate(fieldNames.country, country);
    setHasChanged(true);
    // This timeout is needed because formik. Tried everything, this just
    // works, don't ask why, smarter people than me tried to answer this here:
    // https://github.com/jaredpalmer/formik/issues/2059
    // setTimeout(() => {
    //   const touchedFields = Object.values(fieldNames).reduce((acc, key) => {
    //     acc[key] = true;
    //     return acc;
    //   }, {});

    //   setTouched({
    //     ...touched,
    //     ...touchedFields,
    //   });
    // }, 100);
  };

  React.useEffect(() => {
    // Conditions to ensure that no multiple instances of the
    // Google Places API class and event listener exist.
    if (
      autoCompleteRef.current ||
      !isLoaded ||
      !inputRef.current ||
      !window.google ||
      !window.google.maps ||
      !window.google.maps.places
    ) {
      return;
    }

    if (loadError) {
      // Report error
      return;
    }

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener('place_changed', () => {
      if (!autoCompleteRef.current) {
        return;
      }

      // Retrieve the selected location with the `getPlace` method.
      // console.log('autocomplete', autoCompleteRef.current.getPlace());
      onChange(autoCompleteRef.current.getPlace());
    });
  }, [isLoaded, options, onChange]);

  if (!isLoaded)
    return (
      <p
        style={{
          padding: '0.375rem 0.75rem',
          backgroundColor: 'var(--bs-white)',
          border: '1px solid var(--bs-gray-300)',
          borderRadius: '6px',
          color: 'var(--bs-gray-500)',
        }}
      >
        Loading...
      </p>
    );

  return (
    <input
      id={name}
      name={name}
      type="text"
      className="form-control"
      ref={inputRef}
      // onChange={() => onChange({})}
      onKeyUp={() => onChange({})}
      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
      autoComplete="off"
    />
  );
}

export default AutoCompleteAddress;
