import React from 'react';
import BusinessOverviewReport from '../components/UI/Reports/BusinessOverviewReport';
import SalesReport from '../components/UI/Reports/SalesReport';
import FilesReport from '../components/UI/Reports/FilesReport';
import ExpensesReport from '../components/UI/Reports/ExpensesReport';

function ReportsPage() {
  return (
    <section className="widgets__grid-twocol-container">
      <BusinessOverviewReport />
      <SalesReport />
      <FilesReport />
      <ExpensesReport />
    </section>
  );
}

export default ReportsPage;
