import React from 'react';
/* eslint-disable react/prop-types */
import ColorPicker from '../../../../UI/ColorPicker';
import './ColorsSelector.scss';
import GroupColorSelector from './GroupColorSelector';

const swatches = [
  {
    key: 'color1',
    id: '--color-1',
    label: 'Background Color 1',
  },
  {
    key: 'color1text',
    id: '--color-bkg-1',
    label: 'Text color 1',
  },
  {
    key: 'color2',
    id: '--color-2',
    label: 'Background Color 2',
  },
  {
    key: 'color2text',
    id: '--color-bkg-2',
    label: 'Text color 2',
  },
  {
    key: 'color3',
    id: '--color-3',
    label: 'Background Color 3',
  },
  {
    key: 'color3text',
    id: '--color-bkg-3',
    label: 'Text color 3',
  },
];

const defaultColors = {
  color1: '#00334f',
  color2: '#186fd7',
  color3: '#001825',
  color4: '#010101',
  color1text: '#FFF',
  color2text: '#FFF',
  color3text: '#FFF',
  color4text: '#FFF',
  bodyText: '#010101',
};

const getSwatchColor = (set) =>
  swatches.map((color) => ({
    ...color,
    hex: set[color.key],
  }));

function ColorsSelector({
  currentColors,
  updateColor,
  layoutNumber,
  initialColors,
}) {
  const savedSwatches = getSwatchColor(initialColors);
  const currentSwatches = getSwatchColor(currentColors);
  const defaultSwatches = getSwatchColor(defaultColors);
  const ids = ['--color-1', '--color-2', '--color-3'];

  return (
    <>
      <fieldset className="text-nowrap d-flex gap-2 overflow-auto pb-3">
        <GroupColorSelector
          group={defaultSwatches}
          updateColor={updateColor}
          name="defaultColors"
          label="Default values"
          ids={ids}
        />
        <GroupColorSelector
          group={savedSwatches}
          updateColor={updateColor}
          name="dbColors"
          label="Saved values"
          ids={ids}
        />
      </fieldset>
      <div className="Colors-Selector__wrapper mb-3">
        {currentSwatches.map(({ key, label, hex, index }) => (
          <ColorPicker
            key={key}
            color={hex}
            label={label}
            onChange={(newColor) => {
              updateColor({ key, color: newColor.hex });
            }}
            disabled={
              (layoutNumber === 2 && index > 3) ||
              (layoutNumber === 3 && index > 3)
            }
          />
        ))}
      </div>
    </>
  );
}

export default ColorsSelector;
