import {
  formatISO,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  addWeeks,
} from 'date-fns';

const getRange = (range) => {
  const today = new Date();
  let startDate;
  let endDate;

  switch (range) {
    case 'lastMonth':
      startDate = addMonths(today, -1);
      endDate = today;
      break;
    case 'lastWeek':
      startDate = addWeeks(today, -1);
      endDate = today;
      break;
    case 'thisWeek':
      startDate = startOfWeek(today);
      endDate = endOfWeek(today, { weekStartsOn: 1 });
      break;
    case 'thisMonth':
      startDate = startOfMonth(today);
      endDate = endOfMonth(today);
      break;
    default:
      break;
  }

  return {
    startDate: formatISO(startDate, { representation: 'date' }),
    endDate: formatISO(endDate, { representation: 'date' }),
  };
};

export default getRange;
