import React from 'react';
import WidgetContainer, {
  EmptyWidget,
} from './WidgetContainer/WidgetContainer';
import SimpleBarChart from './BarCharts/SimpleBarChart';
import useWidgetFilter from './utils/useWidgetFilter';
import SimpleBarChartPlaceholder from './BarCharts/SimpleBarChartPlaceholder';

// This is to provice nice labels from the object keys (below)
const LABELS = {
  totalSales: 'Total Sales',
  expenses: 'Expenses',
};

// This will be the data structure the bar chart will take,
// it will then ignore the "name" key and generate a bar group
// for each object with every key-value pair (double check that
// every key-value is populated, since we're not type checking)
// const TEST_DATA = [
//   {
//     name: 'Jan',
//     totalSales: 1290,
//     expenses: 5765,
//   },
//  ...
// ]

function MonthlyGrowth() {
  const {
    serverMessage,
    fetcherData,
    fetcherState,
    filter,
    handleSelectChange,
  } = useWidgetFilter('monthly-growth');

  const totalValue = React.useMemo(() => {
    if (!fetcherData) return null;
    return fetcherData.reduce(
      (acc, cur) => acc + cur.totalSales + cur.expenses,
      0
    );
  }, [fetcherData]);

  return (
    <WidgetContainer>
      <WidgetContainer.Header
        title="Monthly Growth"
        url="/expenses"
        cta="Go to expenses"
      >
        <WidgetContainer.Select
          value={filter}
          onChangeHandler={handleSelectChange}
        >
          <option value="thisYear">This Year</option>
          <option value="lastYear">Last Year</option>
        </WidgetContainer.Select>
      </WidgetContainer.Header>
      <WidgetContainer.Body
        fetcherState={fetcherState}
        entries={fetcherData?.length}
        serverMessage={serverMessage}
        loadingComponent={<SimpleBarChartPlaceholder />}
      >
        {totalValue <= 0 ? (
          <EmptyWidget />
        ) : (
          <SimpleBarChart
            chartData={fetcherData}
            labels={LABELS}
            fetcherState={fetcherState}
          />
        )}
      </WidgetContainer.Body>
    </WidgetContainer>
  );
}

export default MonthlyGrowth;
