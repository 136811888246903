import DashboardPage from '../../pages/DashboardPage';

import {
  widgetsLoader,
  reviewModalAction,
  reviewModalLoader,
  quickLinksLoader,
  quickLinksAction,
  contactModalLoader,
  contactModalAction,
  notificationsLoader,
  notificationsAction,
  notificationsDeleteAction,
} from './quicklinksLA';
import ContactFormModal from '../../components/ContactForm/ContactFormModal';
import ReviewFormModal from '../../components/ReviewFormModal';
import IconsTestPage from '../../components/UI/Icons/IconsTestPage';
import onboardingRoutes from '../onboarding/onboardingRoutes';

const dashboardRoutes = [
  {
    path: '/',
    element: <DashboardPage />,
    children: [
      {
        loader: contactModalLoader,
        action: contactModalAction,
        path: 'contact-form-modal',
        element: <ContactFormModal />,
      },
      {
        loader: reviewModalLoader,
        action: reviewModalAction,
        shouldRevalidate: ({ currentUrl }) => {
          return currentUrl.pathname === '/review-form-modal';
        },
        path: 'review-form-modal',
        element: <ReviewFormModal />,
      },
    ],
  },
  onboardingRoutes,
  {
    path: 'dashboard/notifications',
    loader: notificationsLoader,
    action: notificationsAction,
    children: [
      {
        path: 'delete',
        action: notificationsDeleteAction,
      },
    ],
  },
  {
    path: 'dashboard/quick-links',
    loader: quickLinksLoader,
    action: quickLinksAction,
    shouldRevalidate: ({ currentUrl }) => {
      return currentUrl.pathname === '/dashboard/quick-links';
    },
  },
  {
    path: 'dashboard/widgets',
    children: [
      {
        path: 'monthly-growth',
        loader: ({ request }) => widgetsLoader({ request }, 'monthly-growth'),
        shouldRevalidate: () => false,
      },
      {
        path: 'appointments',
        loader: ({ request }) => widgetsLoader({ request }, 'appointments'),
        shouldRevalidate: () => false,
      },
      {
        path: 'invoices',
        loader: ({ request }) => widgetsLoader({ request }, 'invoices'),
        shouldRevalidate: () => false,
      },
      {
        path: 'profit-and-loss',
        loader: ({ request }) => widgetsLoader({ request }, 'profit-and-loss'),
        shouldRevalidate: () => false,
      },
    ],
  },
  // This is a debug route for icon testing
  // maybe delete in production?
  {
    path: 'icons',
    element: <IconsTestPage />,
  },
];

export default dashboardRoutes;
