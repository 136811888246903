import React from 'react';
import proptypes from 'prop-types';
import SelectWithDefault from './SelectWithDefault';
import getSelectData from './getSelectData';

function SelectPredefined({
  formType,
  handleChangeSelect,
  inputName,
  isInvalid,
  value,
  hasAll = false,
}) {
  const urls = {
    invoiceSelect: '/invoices',
    productCategoryCombo: '/products/categories',
  };
  const combo = formType === 'productCategoryCombo';
  const options = getSelectData(urls[formType], combo, hasAll);

  return (
    <SelectWithDefault
      handleChange={(e) => handleChangeSelect(e)}
      isInvalid={isInvalid}
      name={inputName}
      options={options}
      value={value}
    />
  );
}

export default SelectPredefined;

SelectPredefined.propTypes = {
  value: proptypes.oneOfType([proptypes.string, proptypes.number]),
  handleChangeSelect: proptypes.func.isRequired,
  isInvalid: proptypes.bool,
  formType: proptypes.string.isRequired,
  inputName: proptypes.string.isRequired,
  hasAll: proptypes.bool.isRequired,
};

SelectPredefined.defaultProps = {
  value: '',
  isInvalid: false,
};
