import React from 'react';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import './reports-searcher.styles.scss';
import PropTypes from 'prop-types';
import { getLastDayOfCurrentYear } from '../Reports/utils/helpers';
import printIcon from '../../../img/012-print.svg';
import downloadIcon from '../../../img/013-download.svg';
import DropdownOptions from '../../Reports/DropdownOptions';

function ReportsSearcher({
  title = 'Report Period',
  options = [],
  secondaryOptions = [],
  pdfChildren,
  pdfFileName = 'leadsbox-report',
  setSelectOption = () => {},
  // selectOption = '',
  fromDate = '',
  setFromDate = () => {},
  toDate = '',
  setToDate = () => {},
  enablePrintDownload = false,
  twoOptions = false,
  secondOptions,
  setSecondOptions,
  handleRunReport,
  loading,
}) {
  return (
    <div className="searcher-container shadow">
      <div className="filters-container">
        <h3 className="title">{title}</h3>
        {twoOptions && (
          <div className="labels-container">
            <h6 className="left-label">Category</h6>
            <h6 className="right-label">Product/Service</h6>
          </div>
        )}
        <div className="dates-container">
          {options.length > 0 && (
            <DropdownOptions
              options={options}
              setSelectOption={setSelectOption}
            />
          )}
          {twoOptions ? (
            <DropdownOptions
              options={secondaryOptions}
              setSelectOption={setSecondOptions}
              secondOptions={secondOptions}
            />
          ) : (
            <>
              <p className="date-text">From</p>
              <input
                type="date"
                max={getLastDayOfCurrentYear()}
                className="date-input-from"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <p className="date-text">To</p>
              <input
                type="date"
                max={getLastDayOfCurrentYear()}
                className="date-input-to"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </>
          )}
        </div>
      </div>
      <div className="buttons-container">
        <div className="download-print-btn-container">
          {enablePrintDownload ? (
            <>
              <PDFDownloadLink
                document={pdfChildren}
                fileName={`${pdfFileName}.pdf`}
              >
                <button
                  type="button"
                  className="download-btn shadow-sm"
                  aria-label="prnt-btn"
                >
                  <img
                    style={{ width: '20px', height: '20px' }}
                    src={downloadIcon}
                    alt=""
                  />
                </button>
              </PDFDownloadLink>
              <button type="button" className="print-btn shadow-sm">
                <BlobProvider document={pdfChildren}>
                  {({ url }) => (
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="dwnl-btn"
                    >
                      <img
                        style={{ width: '20px', height: '20px' }}
                        src={printIcon}
                        alt=""
                        className="me-2"
                      />
                    </a>
                  )}
                </BlobProvider>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="download-btn
                shadow-sm"
                aria-label="prnt-btn2"
              >
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={downloadIcon}
                  alt=""
                  className="me-2"
                />
              </button>
              <button
                type="button"
                className="print-btn shadow-sm"
                aria-label="dwnl-btn2"
              >
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={printIcon}
                  alt=""
                  className="me-2"
                />
              </button>
            </>
          )}
        </div>

        <div className="run-report-container">
          <button
            type="submit"
            className="report-btn shadow-sm"
            onClick={handleRunReport}
            disabled={loading}
          >
            Run Report
          </button>
        </div>
      </div>
    </div>
  );
}

ReportsSearcher.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  secondaryOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  pdfChildren: PropTypes.element,
  pdfFileName: PropTypes.string,
  setSelectOption: PropTypes.func,
  // selectOption: PropTypes.string,
  fromDate: PropTypes.string,
  setFromDate: PropTypes.func,
  toDate: PropTypes.string,
  setToDate: PropTypes.func,
  enablePrintDownload: PropTypes.bool,
  twoOptions: PropTypes.bool,
  secondOptions: PropTypes.string,
  setSecondOptions: PropTypes.func,
  handleRunReport: PropTypes.func,
  loading: PropTypes.bool,
};

export default ReportsSearcher;
