import React from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import PaymentsTable from './PaymentsModal/PaymentsTable';
import LateralModal from '../UI/LateralModal';
import PaymentsOnboard from './PaymentsOnboard/PaymentsOnboard';

export default function Payments() {
  const { payments } = useLoaderData();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const data = React.useMemo(() => payments, [payments]);

  return data.length === 0 ? (
    <PaymentsOnboard />
  ) : (
    <>
      <LateralModal expanded={isExpanded} setIsExpanded={setIsExpanded}>
        <Outlet context={[setIsExpanded, isExpanded]} />
      </LateralModal>
      <PaymentsTable payments={data} />{' '}
    </>
  );
}
