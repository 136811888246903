/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';

function DefaultMessages() {
  const { pathname } = useLocation();
  const basePath = '/settings/messages';
  const paths = [
    { label: 'Invoices', path: `${basePath}/invoices` },
    { label: 'Estimates', path: `${basePath}/estimates` },
    { label: 'Receipts', path: `${basePath}/receipts` },
    { label: 'Reviews', path: `${basePath}/reviews` },
    { label: 'Contact form', path: `${basePath}/contact-form` },
  ];

  return (
    <div className="row">
      <div className="col-5 mt-5">
        <div className="overflow-hidden">
          <ListGroup as="ul">
            {paths.map(({ label, path }, index) => (
              <ListGroup.Item as="li" key={index} active={pathname === path}>
                <Link
                  className="text-decoration-none text-reset d-block w-100 h-100"
                  to={path}
                  // 🔴 This was throwing an error. Not sure why it was being set twice (see line 25)
                  // active={pathname === path}
                >
                  {label}
                </Link>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </div>
      <div className="col-7 ">
        <Outlet />
      </div>
    </div>
  );
}

export default DefaultMessages;
