import messagesRoutes from '../messages/messagesRoutes';
import PaymentDocument from '../../components/Payments/PaymentsModal/PaymentDocument';
import ErrorMesage from '../../pages/ErrorMesage';

const paymentsModal = [
  ...messagesRoutes,
  {
    path: 'pdf',
    element: <PaymentDocument />,
    errorElement: <ErrorMesage message="Payment preview unavailable" />,
  },
];

export default paymentsModal;
