import { resizeAndCropImage } from '../FileDragAndDrop/utils';

export function dataURLtoFile(dataUrl, filename) {
  // Convert data URL to binary data
  const base64Data = atob(dataUrl.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(base64Data.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < base64Data.length; i += 1) {
    uint8Array[i] = base64Data.charCodeAt(i);
  }
  // Create Blob from binary data
  const blob = new Blob([uint8Array], { type: 'image/png' });
  // Create File from Blob
  const file = new File([blob], filename, { type: 'image/png' });
  return file;
}

export const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement('canvas');

  const croppedCtx = croppedCanvas.getContext('2d');

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    croppedCanvas.toBlob((file) => {
      resolve({ file, url: URL.createObjectURL(file) });
    }, 'image/png');
  });
}

// Combine getCroppedImg and resizeAndCropImage to get a 500px x 500px image
export const getCroppedAndResizedImage = async (
  imageSrc,
  pixelCrop = { x: 0, y: 0 },
  rotation = 0,
  flip = { horizontal: false, vertical: false },
  targetSize = { x: 500, y: 500 }
) => {
  try {
    // Get the square cropped image using getCroppedImg
    const croppedImageData = await getCroppedImg(
      imageSrc,
      pixelCrop,
      rotation,
      flip
    );

    if (!croppedImageData) {
      return null; // Return null if getCroppedImg fails
    }

    // Create an image element from the croppedImageData url
    const croppedImage = await createImage(croppedImageData.url);

    // Use resizeAndCropImage to resize and crop the square image to the desired size
    const resizedAndCroppedDataURL = resizeAndCropImage(
      croppedImage,
      targetSize.x,
      targetSize.y,
      'image/png',
      1
    );

    return resizedAndCroppedDataURL;
  } catch (error) {
    // Error handling
    throw new Error('Failed to process image');
  }
};

export default getCroppedImg;
