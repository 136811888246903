/* eslint-disable react/prop-types */

import React, { createContext, useMemo, useState } from 'react';
import { useFetcher, useLocation } from 'react-router-dom';
import { startOfWeek, endOfWeek } from 'date-fns';
import { findAppointmentsByInterval } from '../utils/helpers';
import formatCustomerData from '../utils/formatCustomerData';

// Create a context
export const MyContext = createContext();

// Create a provider component
function AppointmentProvider({ children }) {
  // const { appointments } = useRouteLoaderData('appointments');
  const { state } = useLocation();

  const [currentDate, setCurrentDate] = useState(
    state?.values?.start_date || new Date()
  );

  // Get appointments
  const appointmentsFetcher = useFetcher();

  React.useEffect(() => {
    if (appointmentsFetcher.state === 'idle' && !appointmentsFetcher.data) {
      appointmentsFetcher.load(
        `/appointments?=month${currentDate.getMonth()}&=year=${currentDate.getFullYear()}`
      );
    }
  }, [appointmentsFetcher, currentDate]);

  const { appointments } = appointmentsFetcher.data || [];

  // Get the customer list
  const customersFetcher = useFetcher();

  React.useEffect(() => {
    if (customersFetcher.state === 'idle' && !customersFetcher.data) {
      customersFetcher.load('/customers');
    }
  }, [customersFetcher]);

  const { customers } = customersFetcher.data || [];

  const customerNames = React.useMemo(
    () => formatCustomerData(customersFetcher?.data, 'names'),
    [customersFetcher]
  );

  const updateCurrentDay = (date) => {
    if (!date) return;

    setCurrentDate(date);
    // We need to check the year as well
    if (
      date.getMonth() !== currentDate.getMonth() ||
      date.getFullYear() !== currentDate.getFullYear()
    ) {
      appointmentsFetcher.submit(
        { month: date.getMonth(), year: date.getFullYear() },
        { method: 'get', action: '/appointments' }
      );
    }
  };

  const thisWeekAppointments = useMemo(() => {
    if (!appointments?.length) return {};
    const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(currentDate, { weekStartsOn: 1 });
    const weekAppointments = findAppointmentsByInterval(
      weekStart,
      weekEnd,
      appointments
    );

    return {
      weekStart,
      weekEnd,
      weekAppointments,
    };
  }, [currentDate, appointments]);

  const contextValues = React.useMemo(() => {
    return {
      currentDate,
      updateCurrentDay,
      appointments,
      thisWeekAppointments: thisWeekAppointments?.weekAppointments,
      customerNames,
      customers,
    };
  }, [
    currentDate,
    updateCurrentDay,
    appointments,
    thisWeekAppointments?.weekAppointments,
    customerNames,
    customers,
  ]);

  return (
    <MyContext.Provider value={contextValues}>{children}</MyContext.Provider>
  );
}

export default AppointmentProvider;
