import productsCategoriesAPI from '../../API/routesAPI/productsCategoriesAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

export const createProductCategoryAction = async ({ request }) => {
  const body = await request.formData();
  const response = await productsCategoriesAPI.create(body);
  return handleResposeAbstraction({
    response,
    redirectTo: '/products/categories',
    showSuccess: true,
  });
};

export const createProductCategoryUtility = async ({ request }) => {
  const body = await request.formData();
  const response = await productsCategoriesAPI.create(body);
  return handleResposeAbstraction({ response, showSuccess: true });
};

export const updateProductCategoryAction = async ({ request, params }) => {
  const body = await request.formData();
  const { categoryId } = params;
  body.append('id', categoryId);
  const response = await productsCategoriesAPI.update(body);
  return handleResposeAbstraction({
    response,
    redirectTo: '/products/categories',
    showSuccess: true,
  });
};

export const deleteProductCategoryAction = async ({ params }) => {
  const { categoryId } = params;
  const response = await productsCategoriesAPI.delete(categoryId);
  return handleResposeAbstraction({
    response,
    redirectTo: '/products/categories',
    showSuccess: true,
  });
};

export const getProductsCategoriesLoader = async () => {
  const response = await productsCategoriesAPI.get();
  return handleResposeAbstraction({ response });
};
