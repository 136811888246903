import {
  Appointments,
  Coins,
  ContactForm,
  Customers,
  NewInvoice,
  NewPayment,
  OverdueInvoice,
  ReviewForm,
} from '../../../UI/Icons/Icons';

const QL_ICONS = {
  totalCustomers: <Customers />,
  activeEstimates: <Coins />,
  overdueInvoices: <OverdueInvoice />,
  contactForm: <ContactForm />,
  reviewForm: <ReviewForm />,
  appointments: <Appointments />,
  newInvoice: <NewInvoice />,
  newPayment: <NewPayment />,
};

export default QL_ICONS;
