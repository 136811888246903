const tableSetup = [
  {
    header: 'To',
    accessorKey: 'to',
  },
  {
    header: 'id',
    accessorKey: 'id',
  },
  {
    header: 'Payment',
    accessorKey: 'payment_date',
    type: 'date',
    mobileLabel: true,
  },
  { header: 'Total', accessorKey: 'total', type: 'money', mobileLabel: true },
  {
    header: 'Actions',
    accessorKey: 'print_actions',
    enableSorting: false,
  },
];

export default tableSetup;
