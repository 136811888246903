const QUICK_LINKS = {
  totalCustomers: {
    id: 'totalCustomers',
    containerId: 'container1',
    color: 'indigo',
    cta: '',
    url: '/customers',
    stat: {
      label: 'Customers',
      value: 0,
    },
  },
  activeEstimates: {
    id: 'activeEstimates',
    containerId: 'container1',
    color: 'orange',
    cta: '',
    url: '/estimates',
    stat: {
      label: 'Active Estimates',
      value: 0,
    },
  },
  overdueInvoices: {
    id: 'overdueInvoices',
    containerId: 'container1',
    color: 'red',
    cta: '',
    url: '/invoices',
    stat: {
      label: 'Overdue Invoices',
      value: 0,
    },
  },
  contactForm: {
    id: 'contactForm',
    containerId: 'container1',
    color: 'green',
    cta: 'Contact Form',
    url: '/contact-form-modal',
    stat: {
      label: '',
      value: 0,
    },
  },
  reviewForm: {
    id: 'reviewForm',
    containerId: 'container1',
    color: 'yellow',
    cta: 'Review Form',
    url: '/review-form-modal',
    stat: {
      label: '',
      value: 0,
    },
  },
  appointments: {
    id: 'appointments',
    containerId: 'container1',
    color: 'pink',
    cta: 'Appointments',
    url: '/appointments',
    stat: {
      label: '',
      value: 0,
    },
  },
  newInvoice: {
    id: 'newInvoice',
    containerId: 'container1',
    color: 'teal',
    cta: 'New Invoice',
    url: '/invoices/new',
    stat: {
      label: '',
      value: 0,
    },
  },
  newPayment: {
    id: 'newPayment',
    containerId: 'container1',
    color: 'blue',
    cta: 'New Payment',
    url: '/payments/new',
    stat: {
      label: '',
      value: 0,
    },
  },
};

export default QUICK_LINKS;
