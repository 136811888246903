const formatCustomerData = (fetcherData, key = 'address') => {
  if (!fetcherData) return;
  const { customers } = fetcherData;

  if (key === 'names')
    // eslint-disable-next-line consistent-return
    return customers.map((customer) => ({
      label: customer.name,
      value: customer.id,
    }));

  // Here I would search by ID and get the address of the customer, maybe?

  // if (key === 'address') {
  //   const [currentCustomer] = customers.filter(
  //     (customer) => customer.id === id
  //   );

  //   const {

  //   }
  //   return {

  //   }
  // }
};

export default formatCustomerData;
