import rootAPI from '../../API/routesAPI/rootAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';
import planData from '../../components/Settings/ManageSubscription/planData';

const DEV_EMAILS = [
  'ernesto@example.com',
  'papes.caro+leadsboxTesting@gmail.com',
  'papes.caro+leadsboxTesting1@gmail.com',
];

export const checkIfDev = (userEmail) => {
  const match = DEV_EMAILS.filter((devEmail) => devEmail === userEmail).some(
    (item) => item
  );
  return match;
};

// Format function while we test/implement the BE
// const ticket973 = async (input) => {
//   const customersRes = await customersAPI.getAll();
//   const productsRes = await productsAPI.getAll();
//   const { customers } = await customersRes.json();
//   const { products } = await productsRes.json();

//   const res = {
//     message: input.message,
//     user: {
//       ...input.user,
//       onboarding_helpers: {
//         customers_count: customers.length,
//         prod_and_serv_count: products.length,
//         show_tutorial: true,
//         no_settings: true,
//       },
//     },
//   };

//   return res;
// };

const userLoader = async () => {
  const response = await rootAPI.user();
  const result = await response.json();

  const isDev = response.status === 200 && checkIfDev(result.user.user_email);
  const isOverriden = JSON.parse(
    window.localStorage.getItem('override_subscription')
  );

  if (response.status === 200 && isDev && isOverriden === true) {
    result.user.subscription = {
      tier:
        JSON.parse(window.localStorage.getItem('tier_subscription')) ||
        'tier_3',
      current_invoices:
        JSON.parse(window.localStorage.getItem('current_invoices')) || 0,
      max_invoices:
        planData.find(
          (plan) =>
            plan.id ===
            JSON.parse(window.localStorage.getItem('tier_subscription'))
        )?.maxInvoices || 'unlimited',
    };
  } else if (response.status === 200) {
    result.user.subscription.tier = result.user.subscription.tier.replace(
      'tier',
      'tier_'
    );
  }

  // result.user.roles = [
  //   { name: 'starter', id: 4040 },
  //   { name: 'boost', id: 5050 },
  //   { name: 'elite', id: 9090 },
  // ];

  return handleResposeAbstraction({
    response,
    result,
  });
};

export default userLoader;
