import React from 'react';
import { Outlet } from 'react-router-dom';

function ContactFormLayout() {
  return (
    <div
      style={{ display: 'grid', placeContent: 'center', minHeight: '100vh' }}
    >
      <Outlet />
    </div>
  );
}

export default ContactFormLayout;
