import productsAPI from '../../API/routesAPI/productsAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

// const nextProducts = (api) => ({
//   message: api.message,
//   data: {
//     filter: api?.filter,
//     products: api.products.map((rawProduct) => ({
//       id: rawProduct.product_id, // Required (on edit)
//       type: rawProduct.product_type, // Required
//       name: rawProduct.product_name, // Required

//       sku: rawProduct.product_sku,
//       description: rawProduct.product_description,
//       provider_name: rawProduct.provider_name,

//       category: {
//         id: rawProduct.family_id, // Required (on edit)
//         name: rawProduct.family_name, // Required
//       },

//       unit: {
//         quantity: rawProduct.unit_qty, // Required
//         type: rawProduct.unit_type, // Required
//       },
//       amount: {
//         tax_rate: rawProduct?.tax_rate_id || 0, // Required (if product)
//         cost: rawProduct.purchase_price || rawProduct.labor_budget, // Required
//         profit_rate: rawProduct.bus_profit_percentage, // Required
//         profit_net: rawProduct.bus_profit_price, // Required
//         price_before_tax: rawProduct.product_price, // Required
//       },
//     })),
//   },
// });

export const createProductAction = async ({ request }) => {
  const body = await request.json();
  const response = await productsAPI.create(JSON.stringify(body));
  return handleResposeAbstraction({
    response,
    redirectTo: '/products',
  });
};

export const updateProductAction = async ({ request, params }) => {
  // const body = await request.formData();
  const json = await request.json();
  const { productId } = params;
  const body = {
    ...json,
    id: productId,
  };
  // body.append('id', productId);
  const response = await productsAPI.update(JSON.stringify(body));
  return handleResposeAbstraction({
    response,
    redirectTo: '/products',
  });
};

export const deleteProductAction = async ({ params }) => {
  const { productId } = params;
  const response = await productsAPI.delete(productId);
  return handleResposeAbstraction({
    response,
    redirectTo: '/products',
    showSuccess: true,
  });
};

export const getProductAction = async ({ params }) => {
  const { productId } = params;
  const response = await productsAPI.get(productId);
  return handleResposeAbstraction({ response });
};

export const getProductsAction = async () => {
  const response = await productsAPI.getAll();

  const result = await response.json();
  return handleResposeAbstraction({
    response,
    result,
  });
};
