const testEndDateGreaterThanStart = {
  name: 'greaterThanStart',
  exclusive: false,
  params: {},
  message: 'End date must be greater than start date',
  test(value) {
    return value > this.parent.date_from;
  },
};

export default testEndDateGreaterThanStart;
