import React from 'react';
import { useRouteError } from 'react-router-dom';
import Navigation from '../components/NavigationMenu/Navigation/Navigation';
import GeneralHeader from '../components/NavigationMenu/Header/GeneralHeader';

function ErrorPage() {
  const error = useRouteError();

  return (
    <div className="base-layout">
      <Navigation />
      <main className="main-content">
        <GeneralHeader />
        <div className="d-flex justify-content-center align-items-center flex-column mt-5">
          <div style={{ maxWidth: '400px', textAlign: 'center' }}>
            <h1>Oops!</h1>
            <p className="mt-2">Sorry, an unexpected error has occurred.</p>
            <p className="mt-3">
              <i className="small">{error.statusText || error.message}</i>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ErrorPage;
