import React from 'react';
import { useNavigate } from 'react-router-dom';
import WidgetContainer from '../../Dashboard/Widgets/WidgetContainer/WidgetContainer';
import LinkButton from '../LinkButton/LinkButton';

function SalesReport() {
  const navigate = useNavigate();

  const navigateSales = () => {
    navigate('/old_reports/sales');
  };
  const navigateSalesCustomer = () => {
    navigate('/old_reports/sales_customers');
  };
  const navigateSalesProducts = () => {
    navigate('/old_reports/sales_products');
  };
  const navigateSalesServices = () => {
    navigate('/old_reports/sales_services');
  };
  const navigateProductsServicesList = () => {
    navigate('/old_reports/products_services_list');
  };

  return (
    <WidgetContainer className="widgets__twocol-item-container p-4 shadow-sm">
      <h3>Sales</h3>
      <div style={{ marginTop: 20 }}>
        <LinkButton title="Sales" onClick={navigateSales} />
        <LinkButton
          title="Sales by Customers"
          onClick={navigateSalesCustomer}
        />
        <LinkButton title="Sales by Products" onClick={navigateSalesProducts} />
        <LinkButton title="Sales by Services" onClick={navigateSalesServices} />
        <LinkButton
          title="Products and Services List"
          onClick={navigateProductsServicesList}
        />
      </div>
    </WidgetContainer>
  );
}

export default SalesReport;
