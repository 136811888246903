import React, { useState, useEffect } from 'react';

function DateTime() {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <div style={{ fontVariant: 'tabular-nums' }}>
      <span> {date.toLocaleTimeString('en-US')}</span> |{' '}
      <span> {date.toLocaleDateString('en-US')}</span>
    </div>
  );
}

export default DateTime;
