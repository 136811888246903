import React from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { LocationPin, Smartphone } from '../../UI/Icons/Icons';
import IconWrapper from './IconWrapper';

function CustomerListItem({ name, address, phone }) {
  return (
    <li className="csv__customer-list-item">
      <span className="csv__customer-list-item__name">{name}</span>
      <span className="csv__customer-list-item__address">
        <IconWrapper>
          <LocationPin />
        </IconWrapper>
        {address || 'Error retrieving address'}
      </span>
      <span className="csv__customer-list-item__phone">
        <IconWrapper>
          <Smartphone />
        </IconWrapper>
        {formatPhoneNumberIntl(phone) || 'Error retrieving phone'}
      </span>
    </li>
  );
}

CustomerListItem.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
};

function CustomerList({ children }) {
  return <ul className="csv__customer-list">{children}</ul>;
}

CustomerList.propTypes = {
  children: PropTypes.node,
};

CustomerList.Item = CustomerListItem;

export default CustomerList;
