/* eslint-disable react/prop-types */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PDFBoilerplate from '../../PDF/PDFBoilerplate';
import PDFTable from '../../PDF/Table/PDFTable';
import PDFError from '../../PDF/PDFError';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';
import FONT_STYLES from '../../PDF/FontStyles';

const rootStyles = getComputedStyle(document.body);

const styles = StyleSheet.create({
  ...FONT_STYLES,
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '6px 8px',
    borderRadius: '3px',
    width: '100%',
    backgroundColor: rootStyles.getPropertyValue('--bs-gray-200'),
    fontWeight: 600,
    fontSize: 10,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    textAlign: 'right',
    padding: '0 4px',
  },
});

function ExpensesByVendorPDF({ pdfData, userData, dateFrom, dateTo }) {
  if (!pdfData || !userData) return <PDFError />;

  const { tableData, tableSetup, total } = pdfData;

  const sizeArray = ['70%', '30%'];

  return (
    <PDFBoilerplate
      title="Expenses by Vendor"
      logoBase64={userData.user.company_logo_base64}
      companyName={userData.user.user_company}
      dateFrom={dateFrom}
      dateTo={dateTo}
    >
      <PDFTable
        sizeArray={sizeArray}
        tableData={tableData}
        tableSetup={tableSetup}
      />
      <View wrap={false} style={styles.totalRow}>
        <View style={{ ...styles.col, width: '20%' }}>
          <Text>Total</Text>
        </View>
        <View style={{ ...styles.col, width: '20%' }}>
          <Text>$ {formatCurrency(total, 2, false)}</Text>
        </View>
      </View>
    </PDFBoilerplate>
  );
}

export default ExpensesByVendorPDF;
