import React, { useState, useEffect } from 'react';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import Selector from './Selector';
import isPdfAllowed from '../../PDF/IsPdfAllowed';
import PDFTesting from '../../PDF/react-pdf/PDFTesting.tsx';
import documentMockData from './DocumentMockData';

function getColors(data) {
  return {
    color1: data?.primary_color_bg || '#00334f',
    color2: data?.secondary_color_bg || '#186fd7',
    color3: data?.third_color_bg || '#001825',
    color4: '#010101',
    color1text: data?.primary_color_text || '#FFF',
    color2text: data?.secondary_color_text || '#FFF',
    color3text: data?.third_color_text || '#FFF',
    color4text: '#FFF',
    bodyText: '#010101',
  };
}

function generatePdfData(user, data, type, slogan, layout, colors) {
  const deferredData = documentMockData(user, data, type, slogan);

  return [deferredData, layout, colors];
}

function DocumentTemplateSelector() {
  const { data } = useLoaderData();
  const { user } = useRouteLoaderData('root');

  // State
  const [colors, setColors] = useState(getColors(data));
  const [initialColors, setInitialColors] = useState(colors);
  const [layout, setLayout] = useState(Number(data?.invoice_type) || 1);
  const [slogan, setSlogan] = useState(data?.company_slogan || '');
  const [type, setType] = useState('invoice');

  // Setters and handlers
  const handleColor = ({ key, color }) =>
    setColors((prev) => ({ ...prev, [key]: color }));
  const handleLayout = (number) => {
    setLayout(number);
  };
  useEffect(() => {
    setInitialColors(getColors(data));
  }, [data]);

  const preFillData = isPdfAllowed('To use template selector');
  const pdfData = generatePdfData(user, data, type, slogan, layout, colors);

  const [deferredData, deferredLayout, deferredColors] =
    React.useDeferredValue(pdfData);

  return (
    <div className="row">
      {preFillData[0] ? (
        <>
          <div className="col-lg-7">
            <PDFTesting
              data={deferredData}
              overrideLayout={deferredLayout}
              overrideColors={deferredColors}
            />
          </div>
          <div className="col-lg-5">
            <Selector
              colors={colors}
              handleLayoutChange={handleLayout}
              initialColors={initialColors}
              layoutNumber={layout}
              setType={setType}
              setSlogan={setSlogan}
              slogan={slogan}
              type={type}
              updateColor={handleColor}
            />
          </div>
        </>
      ) : (
        preFillData[1]
      )}
    </div>
  );
}

export default DocumentTemplateSelector;
