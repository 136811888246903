import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  useActionData,
  useOutletContext,
  useRouteLoaderData,
} from 'react-router-dom';
import ReactTablev8 from '../../../UI/Table/ReactTablev8';
import { BUSINESS_OVERVIEW_TOGGLE } from '../../constants/toggleOptions';
import ReportHeader from '../../ReportHeader';
import ProfitAndLossByCustomerPDF from './ProfitAndLossByCustomerPDF';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';
import ReportFooter from '../../ReportFooter';

function ProfitAndLossByCustomer() {
  const actionData = useActionData();
  const { dateTo, dateFrom } = useOutletContext();
  const rootLoader = useRouteLoaderData('root');
  if (actionData?.message !== 'success' || !actionData)
    return <p className="card p-4 mt-4 border-0">Error generating report</p>;

  const customerNames = Array.from(Object.keys(actionData.sales));

  const tableData = customerNames
    .map((customer, index) => {
      if (
        actionData.sales[customer] === 0 &&
        actionData.expenses[customer] === 0
      )
        return null;

      return {
        id: index,
        name: customer,
        sales: actionData.sales[customer].toString(),
        expenses: actionData.expenses[customer].toString(),
        netIncome: (
          actionData.sales[customer] - actionData.expenses[customer]
        ).toString(),
      };
    })
    .filter((value) => (value === null ? false : value));

  const tableSetup = [
    {
      header: 'Customer',
      accessorKey: 'name',
      enableSorting: false,
      mobileLabel: true,
    },
    {
      header: 'Sales',
      accessorKey: 'sales',
      type: 'money',
      enableSorting: false,
      mobileLabel: true,
    },
    {
      header: 'Expenses',
      accessorKey: 'expenses',
      type: 'money',
      enableSorting: false,
      mobileLabel: true,
    },
    {
      header: 'Net Income',
      accessorKey: 'netIncome',
      type: 'money',
      enableSorting: false,
      mobileLabel: true,
    },
  ];

  const totals = tableData.reduce(
    (acc, entry) => {
      return {
        totalNet: +acc.totalNet + +entry.netIncome,
        totalSales: +acc.totalSales + +entry.sales,
        totalExpenses: +acc.totalExpenses + +entry.expenses,
      };
    },
    {
      totalNet: 0,
      totalSales: 0,
      totalExpenses: 0,
    }
  );

  const tableOptions = {
    search: false,
    pagination: {
      show: false,
    },
    sorting: false,
  };

  return (
    <>
      <ReportHeader
        reportSection={BUSINESS_OVERVIEW_TOGGLE}
        pdfDocument={
          <ProfitAndLossByCustomerPDF
            dateTo={dateTo}
            dateFrom={dateFrom}
            pdfData={{ tableData, tableSetup, totals }}
            userData={rootLoader}
          />
        }
      />
      <div className="container-fluid">
        {tableData.length === 0 ? (
          <Row>
            <Col className="alert alert-secondary" role="alert">
              No sales/expenses registered for this date range
            </Col>
          </Row>
        ) : (
          <div className="reports__display-react-table">
            <ReactTablev8
              dataTable={tableData}
              tableLayout={tableSetup}
              tableOptions={tableOptions}
              mobileClass="reports_pl_customer_table"
            />
            <div className="reports__display-totals">
              <div>
                <h6>Totals</h6>
                <table>
                  <tbody>
                    <tr>
                      <td>Sales:</td>
                      <td style={{ paddingRight: 0 }}>$</td>
                      <td>{formatCurrency(totals.totalSales, 2, false)}</td>
                    </tr>
                    <tr>
                      <td>Expenses:</td>
                      <td style={{ paddingRight: 0 }}>$</td>
                      <td>{formatCurrency(totals.totalExpenses, 2, false)}</td>
                    </tr>
                    <tr>
                      <td>Net Income:</td>
                      <td style={{ paddingRight: 0 }}>$</td>
                      <td>
                        <strong>
                          {formatCurrency(totals.totalNet, 2, false)}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ReportFooter />
          </div>
        )}
      </div>
    </>
  );
}

export default ProfitAndLossByCustomer;
