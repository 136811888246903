/* eslint react/prop-types: 0 */
import React from 'react';
import { Pagination } from 'react-bootstrap';

function PaginationV8({ table }) {
  const { pageIndex } = table.getState().pagination;

  return (
    <div className="pagination d-flex justify-content-between align-items-center flex-column flex-lg-row pag mt-3">
      <p className="small mb-2 mb-lg-0">
        Showing page {pageIndex + 1} of {table.getPageCount()}
      </p>
      <Pagination>
        <Pagination.First
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        />
        <Pagination.Prev
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        />
        {table.getPageOptions().map((op) => {
          return (
            <Pagination.Item
              key={`page_item_key_${op}`}
              active={op === pageIndex}
              onClick={() => table.setPageIndex(op)}
            >
              {' '}
              {op + 1}{' '}
            </Pagination.Item>
          );
        })}
        <Pagination.Next
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        />
        <Pagination.Last
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        />
      </Pagination>
    </div>
  );
}

export default PaginationV8;
