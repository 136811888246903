import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { useFetcher } from 'react-router-dom';

function Delete({ route, label }) {
  const fetcher = useFetcher();
  const handleSubmit = (event) => {
    event.preventDefault();
    // eslint-disable-next-line
    if (!confirm('Please confirm you want to delete this record.')) {
      event.preventDefault();
    }
    fetcher.submit(null, {
      method: 'post',
      action: route,
    });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Button variant="danger" className="mt-3 w-100" type="submit">
        {label}
      </Button>
    </Form>
  );
}

Delete.propTypes = {
  route: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Delete;
