import estimatesAPI from '../../API/routesAPI/estimatesAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

const nextEstimate = (api) => ({
  message: api.message,
  data: {
    customer: api.customer,
    estimate: {
      id: api.estimate_details.quote_id,
      number: api.estimate_details.quote_number,
      status: api.estimate_details.quote_status_id,
      pdf: api.estimate_details.quote_pdf,
      amount: {
        balance: api.quote_amount[0].quote_balance,
        subtotal: api.quote_amount[0].quote_item_subtotal
          ? api.quote_amount[0].quote_item_subtotal
          : api.quote_items.reduce(
              (acc, item) => Number(item.item_price) + acc,
              0
            ),
        paid: api.quote_amount[0].quote_paid,
        tax: api.quote_amount[0].quote_tax_total,
        total: api.quote_amount[0].quote_total,
      },
      dates: {
        dateCreated: api.estimate_details.quote_date_created,
        dateDue: api.estimate_details.quote_due_date,
        terms: api.estimate_details.quote_terms,
      },
      items: api.quote_items.map((item) => ({
        category_id: item.item_cat_id,
        category_name: item.item_cat_name,
        description: item.item_description,
        item_id: item.item_id,
        item_price_before_tax: item.item_price,
        price_before_tax: +item.item_rate,
        product_id: item.item_product_id,
        product_name: item.item_product_name,
        quantity: item.item_quantity,
        tax_amount: item.item_tax_amount,
        tax_rate: item.item_tax || 0,
        unit_quantity: item.item_product_unit_quantity,
        unit_type: item.item_product_unit_id,
      })),
      notes: {
        description: api.estimate_details.quote_project_description || '',
        statementNotes: api.estimate_details.quote_alternative_message,
        customerNotes: api.estimate_details.customer_notes,
      },
    },
    send: {
      is_sent: api.estimate_details.is_sent,
      email_status: api.estimate_details.quote_email_status_id,
    },
    template: api?.template_settings
      ? {
          type: api.template_settings.invoice_type,
          primary_color_bg: api.template_settings.primary_color_bg,
          primary_color_text: api.template_settings.primary_color_text,
          secondary_color_bg: api.template_settings.secondary_color_bg,
          secondary_color_text: api.template_settings.secondary_color_text,
          third_color_bg: api.template_settings.third_color_bg,
          third_color_text: api.template_settings.third_color_text,
        }
      : null,
    user: {
      company: {
        name: api.user_details?.user_company,
        slogan: api?.template_settings
          ? api.template_settings.company_slogan
          : null,
        logo: api.user_details.company_logo,
      },
      contact: {
        address: {
          street_address_1: api.user_details.address.street_address_1,
          street_address_2: api.user_details.address.street_address_2,
          city: api.user_details.address.city,
          state: api.user_details.address.state,
          country: api.user_details.address.country,
          zipcode: api.user_details.address.zipcode,
          full_address: api.user_details.address.full_address,
        },
        mobile: api.user_details.user_mobile,
        email: api.user_details.user_email,
        phone: api.user_details.user_phone,
      },
    },
  },
});

export const estimatesLoader = async ({ params }) => {
  const customerId = params?.customerId;
  const response = await estimatesAPI.getAll(customerId);
  return handleResposeAbstraction({
    response,
  });
};

export const estimateLoader = async ({ params }) => {
  const { estimateId, copyId, convertId } = params;
  const response = await estimatesAPI.get(estimateId || copyId || convertId);
  let result = await response.json();
  if (result?.estimate_details) {
    result = nextEstimate(result);
  }
  return handleResposeAbstraction({
    response,
    result,
  });
};

export const deleteEstimateAction = async ({ params }) => {
  const { estimateId } = params;
  const response = await estimatesAPI.delete(estimateId);
  const redirectTo = '../..';
  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
};

export const estimateNumberLoader = async () => {
  const response = await estimatesAPI.nextNumber();
  return handleResposeAbstraction({
    response,
  });
};

export const createEstimateAction = async ({
  request,
  params,
  copy = false,
}) => {
  const body = await request.formData();
  const updates = Object.fromEntries(body);
  const { customerId } = params;
  const estimateClientId = JSON.parse(updates.json).client_id.toString();
  const { json } = updates;
  const response = await estimatesAPI.create(json);
  const result = await response.json();

  // Redirect galore (sorry UwU)
  let redirectTo;

  if (copy) {
    if (!customerId || customerId === estimateClientId) {
      redirectTo = `../../${result.id}`;
    } else {
      redirectTo = `../../../../${estimateClientId}/estimates/${result.id}`;
    }
  }

  if (!copy) {
    if (!customerId || customerId === estimateClientId) {
      redirectTo = `../${result.id}`;
    } else {
      redirectTo = `../../../${estimateClientId}/estimates/${result.id}`;
    }
  }

  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
  });
};

export const updateEstimateAction = async ({ request, params }) => {
  const body = await request.formData();
  const updates = Object.fromEntries(body);
  const { customerId } = params;
  const estimateClientId = JSON.parse(updates.json).client_id.toString();
  const { json } = updates;
  const response = await estimatesAPI.update(json);
  const result = await response.json();
  const redirectTo =
    !customerId || customerId === estimateClientId
      ? `..`
      : `../../../../${estimateClientId}/estimates/${result.id}`;
  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
  });
};

export const declineEstimateAction = async ({ params }) => {
  const { estimateId } = params;
  const response = await estimatesAPI.decline(estimateId);
  const redirectTo = '..';
  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
};

export const reactivateEstimateAction = async ({ params }) => {
  const { estimateId } = params;
  const response = await estimatesAPI.reactivate(estimateId);
  const redirectTo = '..';
  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
};
