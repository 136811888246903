import CustomersNewModal from '../../components/Customers/CustomersNewModal';
import EstimateModalContent from '../../components/Estimates/EstimateModalContent';
import Estimates from '../../components/Estimates/Estimates';
import EstimatesNewInitialiazer from '../../components/Estimates/EstimatesNewInitialiazer';
import InvoiceNewInitializer from '../../components/Invoices/InvoiceNewInitializer';
import ErrorMesage from '../../pages/ErrorMesage';
import { createCustomerAction } from '../customers/customersLA';
import { convertEstimateAction } from '../invoices/invoicesLA';
import {
  createEstimateAction,
  declineEstimateAction,
  deleteEstimateAction,
  estimateLoader,
  estimateNumberLoader,
  estimatesLoader,
  reactivateEstimateAction,
  updateEstimateAction,
} from './estimatesLA';
import estimatesListAndModal from './estimatesModal';

const createEstimate = (routeId) => {
  return [
    {
      path: '',
      element: <Estimates />,
      loader: estimatesLoader,
      handle: {
        linkTo: {
          label: 'New',
          to: 'new',
        },
      },
      children: [
        {
          path: ':estimateId',
          loader: estimateLoader,
          handle: {
            crumb: (match) =>
              `${
                match?.data?.data?.estimate?.number || 'Estimate number missing'
              }`,
          },
          id: routeId,
          errorElement: (
            <ErrorMesage
              className="w-50, mt-5"
              message="Sorry, get estimate failed"
            />
          ),

          children: [
            {
              path: '',
              element: <EstimateModalContent />,
              children: [...estimatesListAndModal],
            },
          ],
        },
      ],
    },
    {
      path: 'new',
      element: <EstimatesNewInitialiazer />,
      action: createEstimateAction,
      handle: {
        crumb: () => 'New',
        linkTo: {
          noShow: true,
        },
      },
      children: [
        {
          path: 'new-customer',
          element: <CustomersNewModal />,
          action: ({ request, params }) =>
            createCustomerAction({ request, params, hardRedirect: '..' }),
        },
      ],
    },
    { path: 'lastEstimateNumber', loader: estimateNumberLoader },
    {
      path: ':estimateId',
      loader: estimateLoader,
      handle: {
        crumb: (match) =>
          `${match?.data?.data?.estimate?.number || 'Estimate number missing'}`,
      },
      children: [
        {
          path: 'edit',
          element: <EstimatesNewInitialiazer />,
          action: updateEstimateAction,
          loader: estimateLoader,
          handle: {
            crumb: (match) =>
              `Edit ${match?.data?.data?.estimate?.number || 'estimate'}`,
            linkTo: {
              noShow: true,
            },
          },
        },
        {
          path: 'copy',
          element: <EstimatesNewInitialiazer copy />,
          action: ({ request, params }) =>
            createEstimateAction({ request, params, copy: true }),
          loader: estimateLoader,
          handle: {
            crumb: (match) =>
              `Copy ${match?.data?.data?.estimate?.number || 'Estimate'}`,
            linkTo: {
              noShow: true,
            },
          },
        },
        {
          path: 'decline',
          action: declineEstimateAction,
        },
        {
          path: 'delete',
          action: deleteEstimateAction,
        },
        {
          path: 'reactivate',
          action: reactivateEstimateAction,
        },
        {
          path: 'convert',
          element: <InvoiceNewInitializer />,
          action: convertEstimateAction,
          loader: estimateLoader,
          handle: {
            crumb: (match) =>
              `Convert ${match?.data?.data?.estimate?.number || 'estimate'}`,
            linkTo: {
              noShow: true,
            },
          },
        },
      ],
    },
  ];
};

export default createEstimate;
