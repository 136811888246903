import React from 'react';
import propTypes from 'prop-types';
import isologo from '../../../img/leadsbox.png';

function NavigationLayout({ show, children }) {
  return (
    <div className="navigation-wrapper">
      <div className={`nav-inner ${show ? 'expand' : ''}`}>
        <div className="nav-header">
          <img src={isologo} alt="leadsbox" className="isologo" />
        </div>
        <div className="nav-body">{children}</div>
      </div>
    </div>
  );
}

NavigationLayout.propTypes = {
  show: propTypes.bool.isRequired,
  children: propTypes.node.isRequired,
};
export default NavigationLayout;
