const supported = {
  image: {
    type: {
      types: ['image/jpeg', 'image/png'],
      error: 'You must input an image file (png/jpeg).',
    },
    size: {
      max: 10240,
      error: 'The image must be smaller than 10MB.',
    },
    input: 'image/png, image/jpeg',
    label:
      'Width/heigth 5000px max and 200px min | Max size 10MB | Format PNG or JPG',
    dimensions: {
      min: 200,
      max: 5000,
      error: 'The image must be between 200px and 5000px.',
    },
    aspectRatio: {
      min: 0.8,
      max: 1.25,
      error: 'The aspect ratio must be between 0.8 and 1.25.',
    },
  },
  pdf: {
    type: {
      types: ['application/pdf'],
      error: 'You must input a pdf file.',
    },
    size: {
      max: 1024,
      error: 'The pdf must be smaller than 1MB.',
    },
    label: 'Max size 1024KB | Format PDF',
    input: 'application/pdf',
  },
  pdfAndImage: {
    type: {
      types: ['application/pdf', 'image/jpeg', 'image/png'],
      error: 'You must input a pdf or image file.',
    },
    size: {
      max: 10240,
      error: 'The image must be smaller than 10MB.',
    },
    dimensions: {
      min: 200,
      max: 5000,
      error: 'The image must be between 200px and 5000px.',
    },
    aspectRatio: {
      min: 0.8,
      max: 1.25,
      error: 'The aspect ratio must be between 0.8 and 1.25.',
    },
    label:
      'Max size 10MB | Format PDF or PNG/JPG | If image width/heigth 5000px max and 200px min ',
    input: 'application/pdf, image/png, image/jpeg',
  },
};

export default supported;
