/* eslint react/prop-types: 0 */
import React from 'react';
import { Form } from 'react-bootstrap';
import SelectWithDefault from '../Forms/Select/SelectWithDefault';

import '../Forms/Forms.scss';

function InvoiceRecurrenceForm({
  errors,
  values,
  handleBlur,
  handleChange: handleChangeParent,
  touched,
  setFieldValue,
}) {
  const optionsRecurrence = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: '2 Weekly', value: '2_weekly' },
    { label: 'Monthly', value: 'monthly' },
  ];
  const optionsDays = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
  ];
  return (
    <>
      <Form.Check // prettier-ignore
        type="checkbox"
        id="recurrent"
        label="Recurrent"
        name="recurrent_1"
        checked={`${values?.recurrent}` === '1'}
        onChange={async (e) => {
          await setFieldValue('recurrent', e.target.checked ? '1' : '0');
          await setFieldValue('recur_frequency', '');
          await setFieldValue('recur_day', '');
          await setFieldValue('recur_date', '');
          await setFieldValue('recur_till', '');
        }}
        onBlur={handleBlur}
      />
      {`${values?.recurrent}` === '1' && (
        <div className="">
          <Form.Group className="mt-3 mb-3">
            <Form.Label className="small">
              Every
              <span className="text-danger">*</span>
            </Form.Label>
            <SelectWithDefault
              options={optionsRecurrence}
              value={values.recur_frequency}
              isInvalid={touched.recur_frequency && !!errors.recur_frequency}
              name="recur_frequency"
              handleChange={async (selectedOption, triggeredAction) => {
                if (triggeredAction.action === 'clear') {
                  await setFieldValue('recur_frequency', '');
                  await setFieldValue('recur_day', '');
                  await setFieldValue('recur_date', '');
                  await setFieldValue('recur_till', '');
                } else {
                  if (selectedOption.value === 'monthly') {
                    await setFieldValue('recur_day', '');
                  }
                  if (
                    selectedOption.value === 'weekly' ||
                    selectedOption.value === '2_weekly'
                  ) {
                    await setFieldValue('recur_date', '');
                  }
                  await setFieldValue('recur_frequency', selectedOption.value);
                }
              }}
            />

            <Form.Control.Feedback type="invalid">
              {errors.recur_frequency}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="form-two">
            {(values.recur_frequency === 'weekly' ||
              values.recur_frequency === '2_weekly') && (
              <Form.Group className="mt-3 mb-3">
                <Form.Label className="small">
                  Day
                  <span className="text-danger">*</span>
                </Form.Label>
                <SelectWithDefault
                  value={values.recur_day}
                  options={optionsDays}
                  name="recur_day"
                  className={
                    touched.recur_day &&
                    !!errors.recur_day &&
                    'invalid_form  is-invalid'
                  }
                  handleChange={async (selectedOption, triggeredAction) => {
                    if (triggeredAction.action === 'clear') {
                      await setFieldValue('recur_day', '');
                    }
                    await setFieldValue('recur_day', selectedOption.value);
                  }}
                  isClearable
                  required
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.recur_day}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            {values.recur_frequency === 'monthly' && (
              <Form.Group className="mt-3" controlId="day">
                <Form.Label className="small">Day of recurrence</Form.Label>
                <Form.Control
                  type="date"
                  name="recur_date"
                  required
                  className={
                    touched.recur_date &&
                    !!errors.recur_date &&
                    'invalid_form  is-invalid'
                  }
                  value={values.recur_date}
                  onChange={async (e) => {
                    await setFieldValue('recur_date', e.target.value);
                  }}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.recur_date}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            {values.recur_frequency !== '' && (
              <Form.Group className="mt-3" controlId="day">
                <Form.Label className="small">Recurrence end date</Form.Label>
                <Form.Control
                  type="date"
                  name="recur_till"
                  required
                  className={
                    touched.recur_till &&
                    !!errors.recur_till &&
                    'invalid_form  is-invalid'
                  }
                  value={values.recur_till}
                  onChange={handleChangeParent}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.recur_till}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default InvoiceRecurrenceForm;
