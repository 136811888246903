/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React from 'react';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import LinkContainer from '../LinkContainer/LinkContainer';
import './LinkWrapper.scss';

function LinkWrapper({ quickLinks, quickLinksId, id, disabled }) {
  const { setNodeRef } = useSortable({
    id,
    data: {
      type: 'Container',
    },
  });

  return (
    <div ref={setNodeRef} className="quick--links_drop-area">
      <SortableContext items={quickLinksId} id={id} disabled={disabled}>
        {quickLinks.length > 0 ? (
          quickLinks.map((link) => (
            <LinkContainer key={link.id} link={link} disabled={disabled}>
              {link.cta ? (
                <LinkContainer.Cta>{link.cta}</LinkContainer.Cta>
              ) : (
                <LinkContainer.Stat
                  label={link.stat.label}
                  value={link.stat.value}
                />
              )}
            </LinkContainer>
          ))
        ) : (
          <div
            className="quick--links_empty rounded-3"
            style={{
              '--h': disabled ? '40px' : '140px',
              zIndex: disabled ? 'revert' : '101',
            }}
          >
            Your Quick Links will appear here
          </div>
        )}
      </SortableContext>
    </div>
  );
}

export default LinkWrapper;
