import React from 'react';
import Proptypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';

function AccordionItem({ eventKey, header, children, className }) {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{header}</Accordion.Header>
      <Accordion.Body className={className}>{children}</Accordion.Body>
    </Accordion.Item>
  );
}

AccordionItem.propTypes = {
  eventKey: Proptypes.string.isRequired,
  header: Proptypes.string.isRequired,
  children: Proptypes.node.isRequired,
  className: Proptypes.string,
};

AccordionItem.defaultProps = {
  className: '',
};

export default AccordionItem;
