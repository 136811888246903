/* eslint-disable react/prop-types */
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import LBFormControl from '../CreateForm/LBFormControl';

function UnitInput({
  element,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) {
  const updateHiddenField = (e) => {
    setFieldValue(element?.config?.hiddenField, e.target.value);
  };

  return (
    <InputGroup style={{ isolation: 'isolate' }}>
      <LBFormControl
        element={element}
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <Form.Select
        style={{
          flexBasis: 'max(35%, 17ch)',
          fontSize: '0.87rem',
          flexGrow: 0,
          backgroundColor: 'var(--bs-tertiary-bg)',
        }}
        name={element?.config?.hiddenField}
        onChange={updateHiddenField}
        defaultValue={values[element?.config?.hiddenField]}
      >
        {element?.config?.options.map(({ value, label, id }) => (
          <option key={id} value={value}>
            {label}
          </option>
        ))}
      </Form.Select>
    </InputGroup>
  );
}

export default UnitInput;
