/* eslint-disable react/prop-types */
import React from 'react';
import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import PDFFooter from './PDFFooter';
import PDFHeader from './PDFHeader';

import './FontSetup';

const styles = StyleSheet.create({
  pdfMainContainer: {
    // display: 'flex',
    // flexDirection: 'column',
    fontFamily: 'Montserrat',
    padding: '72px 52px',
  },
});

function PDFBoilerplate({
  children,
  companyName,
  logoBase64,
  dateFrom,
  dateTo,
  title,
}) {
  return (
    <Document>
      <Page style={styles.pdfMainContainer} size="LETTER">
        <PDFHeader dateFrom={dateFrom} dateTo={dateTo} title={title} />
        {children}
        <PDFFooter companyName={companyName} logoBase64={logoBase64} />
      </Page>
    </Document>
  );
}

export default PDFBoilerplate;
