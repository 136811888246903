import ExpensesCategories from '../../components/ExpenseCategories/ExpensesCategories';
import CreateExpenseCategory from '../../components/ExpenseCategories/CreateExpenseCategory';
import {
  createExpenseCategoryUtilityAction,
  createExpensesCategoryAction,
  deleteExpensesCategoryAction,
  expensesCategoriesLoader,
  updateExpensesCategoryAction,
} from './expensesCategoriesLA';

const expensesCategoriesRoutes = [
  {
    path: 'categories',
    element: <ExpensesCategories />,
    loader: expensesCategoriesLoader,
    id: 'expenseCategories',
    handle: {
      crumb: () => 'Categories',
      linkTo: {
        label: 'New',
        to: '/new',
      },
    },
    children: [
      {
        path: 'new',
        action: createExpensesCategoryAction,
        element: <CreateExpenseCategory />,
        handle: {
          crumb: () => 'New',
        },
      },
      {
        path: 'update/:expenseCategoryId',
        action: updateExpensesCategoryAction,
        element: <CreateExpenseCategory />,
      },
      {
        path: 'delete/:expenseCategoryId',
        action: deleteExpensesCategoryAction,
      },
      {
        path: 'new-utility',
        action: createExpenseCategoryUtilityAction,
      },
    ],
  },
];

export default expensesCategoriesRoutes;
