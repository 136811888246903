/* eslint-disable react/prop-types */
import React from 'react';

export default function Info({ colspan, message, className }) {
  return (
    <tr className={className}>
      <td colSpan={colspan} style={{ gridColumn: `span ${colspan}` }}>
        {message}
      </td>
    </tr>
  );
}
