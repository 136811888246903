/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Link } from 'react-router-dom';
import ButtonWrapper from '../../../UI/ButtonWrapper';
import QuickLinkIcon from '../QuickLinkIcon';

import './LinkContainer.scss';

function Cta({ children }) {
  return (
    <div className="body">
      <ButtonWrapper type="button">{children}</ButtonWrapper>
    </div>
  );
}

function Stat({ label, value }) {
  return (
    <div className="body stat">
      {value}
      <span>{label}</span>
    </div>
  );
}

function LinkContainer({ link, children, disabled }) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: link.id,
    data: {
      link,
      type: 'Item',
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div className="link--container dragging" ref={setNodeRef} style={style}>
        <div className="link--container__icon dragging-icon" />
        <div className="dragging-placeholder dragging-title rounded-3" />
        <div className="dragging-placeholder dragging-sub-title rounded-3" />
      </div>
    );
  }

  return (
    <Link
      className="link--container shadow-sm"
      ref={setNodeRef}
      style={{
        ...style,
        '--icon-bg': `var(--bs-${link.color})`,
        cursor: disabled ? 'pointer' : null,
        border: !disabled && '2px dashed var(--bs-gray-700',
      }}
      to={disabled ? link.url : null}
      {...attributes}
      {...listeners}
    >
      <div className="link--container__icon shadow-md-sm rounded-4">
        {/* <QuickLinkIcon iconId={link.id} /> */}
        <svg
          strokeLinecap="round"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
        >
          <QuickLinkIcon iconId={link.id} />
        </svg>
      </div>

      {children}
    </Link>
  );
}

LinkContainer.Cta = Cta;
LinkContainer.Stat = Stat;

export default LinkContainer;
