import React from 'react';
import PropTypes from 'prop-types';

function ResetMessage({ handleClick, children }) {
  return (
    <button
      type="button"
      style={{
        padding: 0,
        background: 'transparent',
        border: 'none',
        textDecoration: 'underline',
      }}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

ResetMessage.propTypes = {
  handleClick: PropTypes.func,
  children: PropTypes.node,
};

export default ResetMessage;
