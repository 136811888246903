/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton } from 'react-bootstrap';
import '../../../App.scss';

const LBToggleButton = React.forwardRef(
  (
    {
      type = 'radio',
      children,
      color = 'blue',
      name,
      onChange,
      value,
      checked,
      ...delegated
    },
    forwardedRef
  ) => {
    return (
      <ToggleButton
        ref={forwardedRef}
        type={type}
        variant={`outline-${color}`}
        className={delegated.className}
        style={{ ...delegated.style }}
        name={name}
        value={value}
        onChange={onChange}
        id={`${type}-${value}-${name}`}
        size="sm"
        checked={checked}
        {...delegated}
      >
        {children}
      </ToggleButton>
    );
  }
);

LBToggleButton.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']),
  children: PropTypes.node,
  color: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  checked: PropTypes.bool,
};
export default LBToggleButton;
