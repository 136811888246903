import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import PropTypes, { oneOf } from 'prop-types';
import './LBTooltip.scss';

interface I_LBTooltip extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode | string;
  tooltipText?: string;
  tooltipContent?: React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  delayDuration?: number;
}

function LBTooltip({
  children,
  tooltipText,
  tooltipContent,
  side = 'top',
  sideOffset = 8,
  delayDuration = 700,
  ...delegated
}: I_LBTooltip) {
  if (!tooltipText && !tooltipContent)
    throw new Error(
      'Remember to provide either a tooltipText or tooltipContent prop'
    );
  return (
    <Tooltip.Root delayDuration={delayDuration}>
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content
          className="tooltip__content"
          sideOffset={sideOffset}
          side={side}
          style={delegated.style}
        >
          {tooltipText || tooltipContent}
          {/* <Tooltip.Arrow width={20} height={10} className="tooltip__arrow" /> */}
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
}

LBTooltip.propTypes = {
  children: PropTypes.node,
  tooltipText: PropTypes.string,
  tooltipContent: PropTypes.node,
  sideOffset: PropTypes.number,
  delayDuration: PropTypes.number,
  side: oneOf(['top', 'right', 'bottom', 'left']),
};

export default LBTooltip;
