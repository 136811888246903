/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useLoaderData, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import FormWrapper from '../../Forms/builders/FormWrapper';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import CreateForm from '../../Forms/CreateForm';
import ResetMessage from './ResetMessage';
import resetMessageUtil from './resetMessageUtil';

function MessageSettingsContactForm() {
  const loaderData = useLoaderData();

  const fields = [
    {
      formGroup: 'Contact form default messages',
      groupChildren: [
        {
          name: 'contact_form_text',
          label: 'Contact form text',
          required: true,
          initialValue: loaderData?.data?.contact_form_text || '',
          type: Yup.string().required(),
          formType: 'textarea',
          className: 'input-wide',
          maxLength: 800,
          labelAction: (
            <ResetMessage
              handleClick={() =>
                resetMessageUtil(
                  'contact_form_text',
                  loaderData?.data?.contact_form_default_text,
                  formik
                )
              }
            >
              Reset
            </ResetMessage>
          ),
        },
      ],
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post' });
  };
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
  });

  return (
    <FormWrapper
      id="contact-form-settings"
      handleSubmit={formik.handleSubmit}
      isSubmitting={formik.isSubmitting}
      dirty={formik.dirty}
      isValid={formik.isValid}
      label="Save"
    >
      <CreateForm structure={fields} {...formik} />
    </FormWrapper>
  );
}

export default MessageSettingsContactForm;
