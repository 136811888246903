/* eslint-disable react/prop-types */
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function RecipientsPopover({ show, setShow, value, clear }) {
  // const [isMounted, setIsMounted] = useState(false);
  // console.log(selectedValue);
  // useEffect(() => {
  //   // This code runs after the component has mounted
  //   setIsMounted(true);

  //   // Cleanup function to run when the component unmounts
  //   return () => {
  //     setIsMounted(false);
  //   };
  // }, []); // Runs only once (probably twice in dev mode)
  console.log(value);
  const type = value.split('_')[0];
  const id = value.split('_')[1];
  let url;
  if (type === 'merchants') url = `/expenses/merchants/${id}/update`;
  else if (type === 'vendors') url = `/vendors/${id}/update`;
  else url = `/${type}/${id}/edit`;

  const handleClose = () => {
    clear();
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Some data is missing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The recipient&apos;s data is incomplete, address and complete name are
        required.
      </Modal.Body>
      <Modal.Footer>
        <Button
          value="Select another"
          variant="secondary"
          onClick={(e) => handleClose(e)}
        >
          Change selection
        </Button>
        <Link to={url}>
          <Button value="proceed" variant="primary" onClick={() => null}>
            Update recipient
          </Button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
}
