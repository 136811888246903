import React from 'react';
import { Link, useFetcher, useOutletContext, Outlet } from 'react-router-dom';
import IconButton from '../UI/IconButton/IconButton.tsx';
import EstimateDocument from './EstimateDocument.tsx';
import {
  Add,
  ConvertToInvoice,
  Copy,
  Edit,
  Remove,
  TrashCan,
} from '../UI/Icons/Icons';

function ManageEstimate() {
  const fetcher = useFetcher();
  const handleDecline = () => {
    fetcher.submit({}, { method: 'post', action: '../decline' });
  };
  const handleReactivate = () => {
    fetcher.submit({}, { method: 'post', action: '../reactivate' });
  };

  const handleDelete = () => {
    fetcher.submit(
      {},
      {
        method: 'post',
        action: '../delete',
      }
    );
  };

  const { status: statusId } = useOutletContext();
  const active = statusId === 1;
  const declined = statusId === 2;
  const accepted = statusId === 3;
  const converted = statusId === 4;
  const expired = statusId === 5;

  return (
    <div className="d-flex flex-column h-100 justify-content-center gap-2">
      {(active || !declined || expired) && (
        <IconButton
          className="icon-button__modal"
          size={22}
          strokeWidth={3}
          text="Edit"
          alignText="left"
          color="primary"
          as={Link}
          to="../edit"
          outline={false}
        >
          <Edit />
        </IconButton>
      )}
      {(active || accepted || converted || declined || expired) && (
        <IconButton
          className="icon-button__modal"
          size={22}
          strokeWidth={3}
          text="Copy"
          alignText="left"
          color="primary"
          as={Link}
          to="../copy"
          outline={false}
        >
          <Copy />
        </IconButton>
      )}
      {active && (
        <IconButton
          className="icon-button__modal"
          size={22}
          strokeWidth={3}
          text="Decline"
          alignText="left"
          color="primary"
          onClick={handleDecline}
          outline={false}
        >
          <Remove />
        </IconButton>
      )}
      {declined && (
        <IconButton
          className="icon-button__modal"
          size={22}
          strokeWidth={3}
          text="Activate"
          alignText="left"
          color="primary"
          onClick={handleReactivate}
          outline={false}
        >
          <Add />
        </IconButton>
      )}
      {(accepted || active) && (
        <IconButton
          className="icon-button__modal"
          size={22}
          strokeWidth={3}
          text="Convert to invoice"
          alignText="left"
          color="primary"
          as={Link}
          to="../convert"
          outline={false}
        >
          <ConvertToInvoice />
        </IconButton>
      )}
      <EstimateDocument printDocument />

      <IconButton
        className="icon-button__modal"
        text="Delete"
        alignText="left"
        color="red"
        onClick={handleDelete}
        outline={false}
        data-testid="deleteButton"
      >
        <TrashCan />
      </IconButton>

      {converted && (
        <p className="mt-5">
          This estimate has been converted to invoice, <i>delete</i>,&nbsp;
          <i>decline</i>,&nbsp;
          <i>edit </i> and <i>convert to invoice</i> actions are not available.
        </p>
      )}
      {declined && (
        <p className="mt-5">
          This estimate has been declined, <i>delete</i>,&nbsp; <i>decline</i>
          ,&nbsp;
          <i>edit </i> and <i>convert to invoice</i> actions are not available.
        </p>
      )}

      <Outlet />
    </div>
  );
}

export default ManageEstimate;
