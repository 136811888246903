/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */

import React from 'react';
import {
  useLoaderData,
  useSubmit,
  useRouteLoaderData,
  Link,
} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormWrapper from '../../Forms/builders/FormWrapper';
import CreateForm from '../../Forms/CreateForm/CreateForm';
import extractValues from '../../Helpers/extractValues';
import LBOffCanvas from '../../UI/LBOffCanvas';
import { isPhoneNumber } from '../../Forms/yup/customValidations';
import MessagePreview from './MessagePreview';
import useIsAuthorized from '../../../routes/protectedPages/useIsAuthorized';
import HelpTooltip from '../../HelpTooltip/HelpTooltip.tsx';

function ContactForm() {
  // Way to destructure data, could replace some formatting functions
  // but it may get too wild when the field number increases
  const { data } = useLoaderData();
  const contactMessage = data?.contact_form_text || '';
  const { user } = useRouteLoaderData('root');
  const userCompany = user.user_company || null;
  const isAuthorized = useIsAuthorized({
    tiersAllowed: ['tier_1', 'tier_2', 'tier_3'],
  });

  // Form schema
  const contactForm = [
    {
      name: 'mobile_number',
      label: 'Phone number',
      formType: 'phone',
      initialValues: '',
      type: isPhoneNumber.required('Please enter a phone number'),
      className: 'input-wide',
    },
    {
      name: 'message_data',
      label: 'Message',
      labelAction: (
        <HelpTooltip size="sm">
          The message will always include the intro &quot;Message from...&quot;
          and the link at the end of the message body.
        </HelpTooltip>
      ),
      initialValues: contactMessage || '',
      type: Yup.string(),
      formType: 'textarea',
      className: 'input-wide',
      maxLength: 800,
    },
  ];

  const initialValues = extractValues('initialValues', [...contactForm]);

  const SchemaObject = extractValues('type', [...contactForm]);

  const formSchema = Yup.object().shape(SchemaObject);

  const reactRouterSubmit = useSubmit();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      const nextValues = {
        ...values,
        // This weird spacing is needed to prevent the addition of
        // unneeded spaces in the string literal
        message_data: userCompany
          ? `Message from ${userCompany}:
${values.message_data}`
          : values.message_data,
      };

      reactRouterSubmit(nextValues, {
        method: 'post',
        action: '/contact-form-modal',
        encType: 'application/json',
      });
    },
  });

  return (
    <LBOffCanvas.Wrapper>
      <LBOffCanvas.Left>
        <LBOffCanvas.Header>
          <div className="d-flex gap-3">
            <h2 className="new-appointment__header">
              {isAuthorized ? 'Contact Form' : 'Forbidden'}
            </h2>
            <HelpTooltip>
              Send a potential customer a link to a &quot;New Customer&quot;
              form, so they can complete all their personal information for you!
            </HelpTooltip>
          </div>
        </LBOffCanvas.Header>
        {isAuthorized ? (
          <FormWrapper
            id="ContactForm"
            handleSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
            dirty={formik.dirty}
            isValid={formik.isValid}
            label="Send SMS"
            portalId="offcanvas-action-group-left"
          >
            <CreateForm structure={contactForm} {...formik} />
            <MessagePreview company={userCompany}>
              {formik.values.message_data}
            </MessagePreview>
          </FormWrapper>
        ) : (
          <div>
            <p className="mb-4">
              You need to have an active subscription to access this feature.
            </p>
            <Button as={Link} to="/settings/subscription">
              Manage
            </Button>
          </div>
        )}
      </LBOffCanvas.Left>
    </LBOffCanvas.Wrapper>
  );
}

export default ContactForm;
