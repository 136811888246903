/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { transformDateHook } from '../../Helpers/toDates';
import TableLink from './TableLink';
import ReactTablev8 from '../../UI/Table/ReactTablev8';

export default function PaymentsTable({ payments }) {
  // Transform data to table
  const nextPayments = payments.map(({ payment, customer, invoice }) => ({
    date: transformDateHook(payment.date),
    invoiceBalance: invoice.balance,
    invoice: (
      <TableLink url={`/invoices/${invoice.id}`} label={invoice.number} />
    ),
    invoiceFilter: invoice.number,
    customer: (
      <TableLink url={`/customers/${customer.id}`} label={customer.name} />
    ),
    customerNameFilter: customer.name,
    paymentMethod: payment.method,
    amount: payment.amount,
    id: payment.id,
  }));

  // Table scheme
  const tableSetup = [
    { header: 'Filter', accessorKey: 'invoiceFilter', className: 'd-none' },
    {
      header: 'Filter',
      accessorKey: 'customerNameFilter',
      className: 'd-none',
    },
    {
      header: 'Payment date',
      accessorKey: 'date',
      type: 'date',
      sortingFn: 'datetime',
    },
    {
      header: 'Invoice',
      accessorKey: 'invoice',
    },
    {
      header: 'Customer name',
      accessorKey: 'customer',
      mobileLabel: true,
    },
    {
      header: 'Payment method',
      accessorKey: 'paymentMethod',
      mobileLabel: true,
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      type: 'money',
    },
    {
      header: 'Invoice Balance',
      accessorKey: 'invoiceBalance',
      type: 'money',
      mobileLabel: true,
    },
  ];

  // Navigate to item
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`${id}`);
  };
  const data = React.useMemo(() => nextPayments, [payments]);
  return (
    <ReactTablev8
      dataTable={data}
      tableLayout={tableSetup}
      tableAction={handleRowClick}
      mobileClass="payments_table"
      // columnfilter={columnFilters}
    />
  );
}
