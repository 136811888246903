/* eslint-disable react/prop-types */
import React from 'react';
import { useFetcher } from 'react-router-dom';
import ActionButton from '../UI/buttons/ActionButton';

export default function PrintStateManager({ id, state }) {
  const fetcher = useFetcher();

  const handleChangeState = () =>
    fetcher.submit(
      {
        id,
      },
      {
        method: 'POST',
        encType: 'application/json',
        action: '/checks/toggle',
      }
    );
  const handleVoid = () =>
    fetcher.submit(
      {
        id,
      },
      {
        method: 'POST',
        encType: 'application/json',
        action: '/checks/void',
      }
    );

  switch (state) {
    case 0:
      return (
        <div className="d-flex">
          <ActionButton
            action="addToTray"
            handler={() => handleChangeState()}
          />
        </div>
      );
    case 1:
      return (
        <div className="d-flex">
          <ActionButton
            action="removeFromTray"
            handler={() => handleChangeState()}
          />
        </div>
      );
    case 2:
      return (
        <div className="d-flex align-items-center">
          <ActionButton action="printed" />
          <ActionButton action="voided" handler={() => handleVoid()} />
        </div>
      );
    default:
      return null;
  }
}
