import React from 'react';
import { useNavigate } from 'react-router-dom';
import WidgetContainer from '../../Dashboard/Widgets/WidgetContainer/WidgetContainer';
import LinkButton from '../LinkButton/LinkButton';

function FilesReport() {
  const navigate = useNavigate();

  // const navigateCSVFiles = () => {
  //   navigate('/reports/csv_files');
  // };
  const navigateCustomersCSVFiles = () => {
    navigate('/old_reports/customers_csv_files');
  };

  return (
    <WidgetContainer className="widgets__twocol-item-container p-4 shadow-sm">
      <h3>Files</h3>
      <div style={{ marginTop: 20 }}>
        {/* <LinkButton
          title="CSV Files (Quickbooks) Import, Export"
          onClick={navigateCSVFiles}
        /> */}
        <LinkButton
          title="Customers CSV Files"
          onClick={navigateCustomersCSVFiles}
        />
      </div>
    </WidgetContainer>
  );
}

export default FilesReport;
