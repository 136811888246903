/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */

import React from 'react';
import { createPortal } from 'react-dom';
import { useFetcher } from 'react-router-dom';

import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import QUICK_LINKS from './defs';
import LinkWrapper from './LinkWrapper/LinkWrapper';
import LinkOverlay from './LinkContainer/LinkOverlay';
import ToggleGroup from './ToggleGroup/ToggleGroup';

import './QuickLinks.scss';
import './LinkContainer/LinkContainer.scss';
import { toastError } from '../../../API/toastHandler';

function initializeQuickLinks(dataArray) {
  return dataArray.map((item) => {
    // Extract stat for lates destructuring (no mutation)
    // and spread the rest of the keys into "staticQL"
    const { stat, ...staticQL } = QUICK_LINKS[item.id];
    const res = {
      ...staticQL,
      stat: {
        ...stat,
        value: item.count,
      },
    };
    return res;
  });
}

function QuickLinks() {
  // Here we should be fetching the links data
  const fetcher = useFetcher();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [quickLinks, setQuickLinks] = React.useState([]);

  const quickLinksId = React.useMemo(
    () =>
      quickLinks.map((link) => link.containerId === 'container1' && link.id),
    [quickLinks]
  );

  // The fetcher would have to re-fetch whenever the quick links
  // of the non-modal container are updated
  React.useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load('/dashboard/quick-links');
    }

    if (fetcher.state === 'idle' && fetcher.data) {
      if (!fetcher.data?.length) return;
      const formattedLinks = initializeQuickLinks(fetcher.data);
      setQuickLinks(formattedLinks);
    }
  }, [fetcher]);

  const submitFetcher = (updatedLinks) => {
    fetcher.submit(
      {
        links: updatedLinks
          .filter((link) => link.containerId === 'container1')
          .map((link) => link.id),
      },
      {
        method: 'post',
        action: '/dashboard/quick-links',
        encType: 'application/json',
      }
    );
  };

  const [activeLink, setActiveLink] = React.useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10, // Start drag action after we move 10px (to allow click on buttons)
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 4,
      },
    })
  );

  function handleDragStart(event) {
    document.body.style.setProperty('cursor', 'grabbing');

    if (event.active.data.current?.type === 'Item') {
      setActiveLink(event.active.data.current.link);
    }
  }

  function handleDragEnd(event) {
    document.body.style.setProperty('cursor', 'auto');

    setActiveLink(null);
    const { active, over } = event;
    if (!over) return;

    const activeLinkId = active.id;
    const overLinkId = over.id;

    if (activeLinkId === overLinkId) return;

    // Find the active link and the link we are hovering
    const activeLinkIndex = quickLinks.findIndex(
      (link) => link.id === activeLinkId
    );
    const overLinkIndex = quickLinks.findIndex(
      (link) => link.id === overLinkId
    );

    // Use the dnd-kit helper function to sort the array
    const updatedArray = arrayMove(quickLinks, activeLinkIndex, overLinkIndex);

    // Submit the new link position to the server
    submitFetcher(updatedArray);

    // Update the UI state with the new link positions
    setQuickLinks(updatedArray);
  }

  function handleToggle(event) {
    const { value } = event.target;

    // Here we either already have that link in our state array and
    // we need to remove it, or we dont and have to add it
    const foundLink = quickLinks.findIndex((link) => link.id === value);

    if (foundLink === -1) {
      // We dont have the link, so we add it
      const newLink = QUICK_LINKS[value];

      const updatedLinks = [...quickLinks, newLink];
      submitFetcher(updatedLinks);
      setQuickLinks((prev) => [...prev, newLink]);
      return;
    }

    // We have the link, so we need to remove it from the array
    // if we have at least 4 links
    if (quickLinks.length <= 4) {
      toastError({ alert: 'Minimum of 4 quick links reached' });
      return;
    }
    const updatedLinks = [...quickLinks].filter((link) => link.id !== value);
    submitFetcher(updatedLinks);
    setQuickLinks((prevLinks) => [
      ...prevLinks.filter((link) => link.id !== value),
    ]);
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      // onDragOver={handleDragOver}
    >
      {isModalOpen && <div className="quick-links__overlay" />}

      <div
        className={`quick-links__container ${
          isModalOpen ? 'quick-links__highlight' : null
        }`}
      >
        {!fetcher?.data || fetcher?.status === 'loading' ? (
          <>
            <div
              className="quick-links__action-group animations__breathe"
              style={{ height: '32px' }}
            >
              <div
                className="dragging-placeholder rounded-3"
                style={{
                  '--w': '140px',
                  '--h': '24px',
                  flexBasis: 'var(--w)',
                  backgroundColor: 'var(--bs-gray-300)',
                }}
              />
            </div>
            <div className="quick--links_drop-area animations__breathe">
              {[1, 2, 3, 4].map((item) => (
                <div key={item} className="link--container dragging">
                  <div className="link--container__icon dragging-icon rounded-4" />
                  <div
                    className="dragging-placeholder rounded-3"
                    style={{ '--w': '20%', '--h': '24px' }}
                  />
                  <div
                    className="dragging-placeholder rounded-3"
                    style={{ '--w': '70%', '--h': '24px' }}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <ToggleGroup
              modalState={isModalOpen}
              setModalState={setIsModalOpen}
              quickLinks={quickLinks}
              onToggle={handleToggle}
            />

            <LinkWrapper
              disabled={!isModalOpen}
              quickLinksId={quickLinksId}
              // This calculation is obsolete right now since we are not really
              // using more than one container
              quickLinks={quickLinks.filter(
                (link) => link.containerId === 'container1'
              )}
              id="container1"
            />
          </>
        )}
      </div>

      {createPortal(<LinkOverlay activeLink={activeLink} />, document.body)}
    </DndContext>
  );
}

export default QuickLinks;
