/* eslint-disable react/prop-types */
import React from 'react';
import MiniSwatch from '../../../../UI/MiniSwatch/MiniSwatch';
import LBToggleButton from '../../../../UI/LBToogleButton/LBToggleButton';

export default function GroupColorSelector({
  group,
  updateColor,
  name,
  ids,
  label,
}) {
  const updateColors = () => {
    group.map((item) => updateColor({ key: item.key, color: item.hex }));
  };

  return (
    <LBToggleButton
      color="blue"
      name={name}
      value={name}
      label={label}
      onChange={updateColors}
    >
      <span className="d-flex gap-2">
        {group
          .filter((item) => ids.includes(item.id))
          .map(({ hex, id }) => (
            <MiniSwatch hex={hex} key={id} />
          ))}
        {label}
      </span>
    </LBToggleButton>
  );
}
