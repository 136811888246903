import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default function BackButton() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const nextPath = pathname.substring(0, pathname.lastIndexOf('/')); // modal
  const goBack = () => {
    navigate(nextPath);
  };
  return (
    <Button type="button" variant="light" onClick={goBack}>
      Back
    </Button>
  );
}
