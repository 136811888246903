/* eslint react/prop-types: 0 */
import React from 'react';
import Form from 'react-bootstrap/Form';

function PageSizeSelector({ pageSize, setPageSize }) {
  return (
    <Form.Group className="mb-1 mb-lg-3 " controlId="page_size">
      <Form.Label className="small" />
      <Form.Select
        aria-label="Select page size"
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pagesSize) => {
          return (
            <option key={`page_size_${pagesSize}`} value={pagesSize}>
              Show {pagesSize}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
}

export default PageSizeSelector;
