import { Path, Svg } from '@react-pdf/renderer';

export function LocationPin({ color }: { color: string }) {
  return (
    <Svg viewBox="0 0 19.22 19.22" fill={color} height={12} width={12}>
      <Path d="M14.26,3.67c-2.61-2.47-6.68-2.47-9.29,0-2.79,2.64-2.65,7.15.07,9.87l3.03,3.03c1.03,1.03,2.05,1.03,2.68.4l3.09-3.09c3.06-3.06,3.2-7.57.41-10.22ZM11.59,10.54c-1.1,1.09-2.85,1.09-3.95,0-1.09-1.09-1.09-2.85,0-3.94,1.1-1.09,2.85-1.09,3.95,0,1.09,1.09,1.09,2.85,0,3.94Z" />
    </Svg>
  );
}

export function Phone({ color }: { color: string }) {
  return (
    <Svg viewBox="0 0 19.22 19.22" fill={color} height={12} width={12}>
      <Path d="M15.77,12.78l-1.15-1.11c-.74-.71-1.9-.71-2.64,0l-.93.91c-1.34-.42-2.44-1.44-2.71-1.7-.26-.26-1.28-1.37-1.7-2.71l.91-.93c.72-.73.72-1.9,0-2.64l-1.11-1.15c-.75-.78-2.01-.77-2.75.03l-.19.2c-1.46,1.59-1.81,3.95-.76,5.83.65,1.18,1.63,2.53,3.08,3.87,1.34,1.45,2.69,2.43,3.87,3.08,1.89,1.05,4.25.7,5.83-.76l.2-.19c.8-.74.81-1.99.03-2.75Z" />
      <Path d="M13.97,9.1c-.41,0-.76-.32-.79-.74-.15-2.26-2.09-2.42-2.17-2.43-.44-.03-.76-.41-.73-.85.03-.44.44-.76.85-.73,1.17.09,3.44,1.01,3.64,3.9.03.44-.3.81-.74.84-.02,0-.04,0-.05,0Z" />
      <Path d="M16.28,8.99c-.41,0-.76-.32-.79-.74-.28-4.17-3.98-4.52-4.14-4.53-.43-.03-.76-.41-.73-.85.03-.43.38-.75.84-.73,1.81.13,5.31,1.56,5.61,6.01.03.44-.3.81-.74.84-.02,0-.04,0-.05,0Z" />
    </Svg>
  );
}

export function Mail({ color }: { color: string }) {
  return (
    <Svg viewBox="0 0 19.22 19.22" fill={color} height={12} width={12}>
      <Path d="M8.8,10.76L2.13,4.1c.29-.37.74-.62,1.25-.62h12.45c.51,0,.96.25,1.25.62l-6.65,6.66c-.46.44-1.19.44-1.64,0Z" />
      <Path d="M17.39,5.77v8.43c0,.86-.69,1.56-1.56,1.56H3.38c-.86,0-1.56-.69-1.56-1.56V5.77l5.98,5.97c.5.5,1.15.75,1.8.75s1.31-.25,1.79-.75l5.98-5.97Z" />
    </Svg>
  );
}
