import fetchAPI from '../fetchUtils';

const settingsAPI = {
  // ACCOUNT
  getAccount: async () => fetchAPI.getWithAuth('/get-account-settings'),
  updateAccount: async (body) =>
    fetchAPI.postWithAuth('/update-account-settings', body),
  toggleTutorial: async () => fetchAPI.getWithAuth('/toggle-tutorial'),
  // STRIPE SET UP
  getStripeUrl: async () => fetchAPI.getWithAuth('/generate-account'),
  stripeLinkSuccess: async (stripeToken) =>
    fetchAPI.getWithAuth(`/stripe-account-link-success/${stripeToken}`),
  // TAX
  updateTax: async (body) => fetchAPI.postWithAuth('/save-sales-tax', body),
  getTax: async () => fetchAPI.getWithAuth('/get-sales-tax'),
  // INVOICE
  updateInvoice: async (body) =>
    fetchAPI.postWithAuth('/save-invoice-settings', body),
  getInvoice: async () => fetchAPI.getWithAuth('/get-invoice-settings'),
  // REVIEWS
  getReviews: async () => fetchAPI.getWithAuth('/get-review-settings'),
  updateReviews: async (body) =>
    fetchAPI.postWithAuth('/save-review-settings', body),
  // CONTACT
  getContact: async () => fetchAPI.getWithAuth('/get-c-form-settings'),
  updateContact: async (body) =>
    fetchAPI.postWithAuth('/save-c-form-settings', body),
  // RECEIPT
  getReceipt: async () => fetchAPI.getWithAuth('/get-receipt-settings'),
  updateReceipt: async (body) =>
    fetchAPI.postWithAuth('/save-receipt-settings', body),
  // ESTIMATES
  getEstimates: async () => fetchAPI.getWithAuth('/get-estimate-settings'),
  updateEstimates: async (body) =>
    fetchAPI.postWithAuth('/save-estimate-settings', body),
  // DOCUMENT TEMPLATES
  getDocumentTemplates: async () =>
    fetchAPI.getWithAuth('/get-invoice-template-settings'),
  updateDocumentTemplates: async (body) =>
    fetchAPI.postWithAuth('/save-invoice-template-settings', body),
  deleteSettingsFiles: async (body) =>
    fetchAPI.postWithAuth('/delete-settings-file', body),
  // ASSETS (Vehicles)
  getVehicles: async () => fetchAPI.getWithAuth('/settings/assets/vehicles'),
  getVehicle: async (vehicleId) =>
    fetchAPI.getWithAuth(`/settings/assets/vehicles/${vehicleId}`),
  createVehicle: async (body) =>
    fetchAPI.postWithAuth(
      '/settings/assets/vehicles/new',
      body,
      'application/json'
    ),
  updateVehicle: async (body, vehicleId) =>
    fetchAPI.postWithAuth(
      `/settings/assets/vehicles/${vehicleId}/update-vehicle`,
      body,
      'application/json'
    ),
  updateMileage: async (body, vehicleId) =>
    fetchAPI.postWithAuth(
      `/settings/assets/vehicles/${vehicleId}/update-mileage`,
      body,
      'application/json'
    ),
  deleteVehicle: async (vehicleId) =>
    fetchAPI.getWithAuth(`/settings/assets/vehicles/${vehicleId}/delete`),
};

export default settingsAPI;
