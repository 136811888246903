// import components
import GenericPage from '../../pages/GenericPage';
// import API
import expensesCategoriesRoutes from '../expensesCategories/expensesCategoriesRoutes';
import merchantsRoutes from '../merchants/merchantsRoutes';
import { merchantsLoader } from '../merchants/merchantsLA';
import { deleteExpenseFileAction, expensesLoader } from './expensesLA';
import createExpense from './expenseCreate';
import Expenses from '../../components/Expenses/ExpensesOld';

const expensesRoutes = [
  {
    path: 'expenses',
    element: <GenericPage />,
    handle: {
      crumb: () => 'Expenses',
      sublinks: () => [
        {
          label: 'Expenses',
          to: '/expenses',
          end: true,
        },
        {
          label: 'Categories',
          to: '/expenses/categories',
        },
        {
          label: 'Merchants',
          to: '/expenses/merchants',
        },
      ],
    },
    children: [
      {
        path: '',
        element: <Expenses />,
        loader: expensesLoader,
        handle: {
          linkTo: {
            label: 'New',
            to: 'new',
          },
        },
      },
      ...createExpense,
      ...expensesCategoriesRoutes,
      ...merchantsRoutes,
      {
        path: 'delete-expense-files',
        action: deleteExpenseFileAction,
      },
      {
        path: 'merchants&vendors',
        loader: merchantsLoader,
      },
    ],
  },
];

export default expensesRoutes;
