import React from 'react';
import Proptypes from 'prop-types';

function FieldData({ children, variant }) {
  return <p className={`lb-modal__data ${variant}`}>{children}</p>;
}
FieldData.propTypes = {
  children: Proptypes.oneOfType([Proptypes.string, Proptypes.node]).isRequired,
  variant: Proptypes.string,
};
FieldData.defaultProps = {
  variant: '',
};
export default FieldData;
