/* eslint-disable react/prop-types */
import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import PDFCol from './PDFCol';

const rootStyles = getComputedStyle(document.body);

const styles = StyleSheet.create({
  tHeadRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${rootStyles.getPropertyValue('--bs-gray-300')}`,
  },
});

function PDFTableHeader({ tableSetup }) {
  return (
    <View style={styles.tHeadRow}>
      {tableSetup.map((entry) => (
        <PDFCol key={entry.accessorKey} colSetup={entry} header>
          {entry.header}
        </PDFCol>

        // <View key={entry.accessorKey} style={{ width: entry.size }}>
        //   <Text>{entry.header}</Text>
        // </View>
      ))}
    </View>
  );
}

export default PDFTableHeader;
