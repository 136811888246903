import React from 'react';
import propTypes from 'prop-types';
import { useLoaderData } from 'react-router-dom';
import ReactTablev8 from '../../UI/Table/ReactTablev8';
import ActionMenu from '../../UI/buttons/ActionMenu';

function ProductCategoriesTable({ handleEdit, handleDelete }) {
  const { categories } = useLoaderData();
  const tableSetup = [
    {
      header: 'Name',
      accessorKey: 'categoryName',
    },
    {
      header: 'Products & Services',
      accessorKey: 'itemsContained',
      mobileLabel: true,
    },
    {
      header: 'More',
      accessorKey: 'more',
    },
  ];

  const nextCategories = categories.map((category) => {
    return {
      categoryName: category.family_name,
      id: category.family_id,
      itemsContained: category.total_products,
      more: (
        <ActionMenu
          handleDelete={handleDelete}
          handleEdit={() =>
            handleEdit(category.family_id, category.family_name)
          }
          id={category.family_id}
        />
      ),
    };
  });

  return (
    <ReactTablev8
      className=""
      dataTable={nextCategories}
      tableLayout={tableSetup}
      mobileClass="product_categories_table"
    />
  );
}

ProductCategoriesTable.propTypes = {
  handleEdit: propTypes.func.isRequired,
  handleDelete: propTypes.func.isRequired,
};

export default ProductCategoriesTable;
