import React from 'react';
import { Link, useNavigation, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import TextTitle from '../UI/text/TextTitle';
import { resetPasswordRequest } from '../../routes/paths';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import GenericForm from '../Forms/builders/GenericForm';

function Login() {
  const navigation = useNavigation();

  const fields = [
    {
      name: 'email',
      label: 'Email',
      initialValue: '',
      placeholder: 'name@email.com',
      type: Yup.string().email().required('Please add your email'),
      formType: 'email',
      required: true,
    },
    {
      name: 'password',
      label: 'Password',
      initialValue: '',
      placeholder: 'password',
      type: Yup.string().strict(true).required('Password is required'),
      formType: 'password',
      autocomplete: 'current-password',
      required: true,
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post' });
  };

  return (
    <>
      <TextTitle>
        <h1>Log In</h1>
        <p className="lb-text__subtitle mt-2">
          Please enter your account details
        </p>
      </TextTitle>

      <ActionErrorInformation />

      <GenericForm
        fields={fields}
        submitAction={submitAction}
        formId="loginForm"
        navigation={navigation.state !== 'idle'}
        submitLabel="Send"
      />

      <div className=" mt-5">
        <Link to={resetPasswordRequest} className="small">
          Forgot Password?
        </Link>
        <div className="d-flex mt-2">
          <p className="small">Don&#39;t have an account?&nbsp;</p>
          <a href="https://leadsboxinvoice.com/subscription/" className="small">
            Subscribe now!
          </a>
        </div>
      </div>
    </>
  );
}

export default Login;
