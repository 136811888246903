/* eslint-disable  */
import React from 'react';
import { ChromePicker } from 'react-color';
import './ColorPicker.scss';

function ColorPicker({ color, label, onChange, disabled }) {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);

  const handleClick = () => {
    setDisplayColorPicker((prev) => !prev);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <div
      className="ColorPicker"
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? '0.25' : '1',
      }}
    >
      <button
        className="ColorPicker__swatch"
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        onClick={handleClick}
      >
        <div className="ColorPicker__color" style={{ background: color }} />
        <span className="ColorPicker__label">{label}</span>
      </button>
      {displayColorPicker ? (
        <div className="ColorPicker__popoverWrapper">
          <div className="ColorPicker__popover">
            <ChromePicker
              color={color}
              onChange={(newColor) => onChange(newColor)}
            />
          </div>
          <div className="ColorPicker__cover" onClick={handleClose} />
        </div>
      ) : null}
    </div>
  );
}

export default ColorPicker;
