/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  useOutletContext,
  Outlet,
  useRouteLoaderData,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import ModalTitle from '../UI/text/ModalTitle';
import '../Invoices/InvoiceModalContent.scss';
import TextField from '../UI/text/TextField';
import FieldData from '../UI/text/FieldData';
import StatusBadge from '../UI/StatusBadge';
import { transformDateHook } from '../Helpers/toDates';
import ModalNavigation from '../UI/ModalNavigation';
import formatAddress from '../Appointments/utils/formatAddress';
import formatCurrency from '../Dashboard/Widgets/utils/formatCurrency';

function EstimateModalContent() {
  const navigate = useNavigate();

  // go back button
  const { pathname } = useLocation();
  const nextPath = pathname.substring(0, pathname.lastIndexOf('/'));
  const goBack = () => {
    navigate(nextPath);
  };

  const { customerId } = useParams();
  const routeLoaders = customerId ? 'customerEstimateData' : 'estimateData';
  const { data } = useRouteLoaderData(routeLoaders);

  const {
    estimate,
    estimate: {
      amount: { total = 0, tax = 0 },
      dates,
    },
    customer,
  } = data;

  const daysDiff = () => {
    const MSPERDAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const a =
      new Date(dates.dateCreated) > new Date()
        ? new Date()
        : new Date(dates.dateCreated);
    const b = new Date(dates.dateDue);
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / MSPERDAY);
  };
  const isPastDate = () => {
    const b = new Date(dates.dateDue);
    const now = new Date();
    return b > now;
  };
  const expired = isPastDate();
  const created = transformDateHook(dates.dateCreated);
  const expires = transformDateHook(dates.dateDue);
  const title = 'Estimate';
  const outletContext = useOutletContext();

  const [setIsExpanded] = outletContext || [() => 0];
  const difference = daysDiff();
  const daysToExpire =
    difference > 0 && expired ? `Days left: ${difference}` : 'Expired';

  const phone = customer?.mobile || '';
  const email = customer?.email || '';

  useEffect(() => {
    if (
      pathname.includes('pdf-preview') ||
      pathname.includes('send') ||
      pathname.includes('manage')
    ) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [pathname]);
  const navigation = [
    {
      to: 'pdf-preview',
      label: 'View preview',
    },
    {
      to: 'manage',
      label: 'Manage document',
    },
    {
      to: 'send',
      label: 'Send Estimate',
    },
  ];
  return (
    <div className="modal-layout">
      <div className="main-top">
        <section>
          <ModalTitle title={title} subtitle={estimate.number} />
          <div className="d-flex">
            <FieldData variant="lb-modal__price">
              {formatCurrency(+total + +tax, 2) || 'missing'}
            </FieldData>
            <StatusBadge statusCode={estimate.status} type="estimate" />
          </div>
        </section>
        <section className="d-flex align-items-end">
          <TextField label="Created">{created || 'missing'}</TextField>
          <TextField label="Expires">{expires || 'missing'}</TextField>
          <FieldData variant="lb-modal__price ms-auto">
            {daysToExpire}
          </FieldData>
        </section>
        <section className="container-fluid pb-4 pt-1 px-0 mx-0">
          <div className="row">
            <div className="col-12 col-sm-6 gy-4">
              <TextField
                label="Customer"
                dataVariant="lb-modal__data--bold text-primary"
              >
                <Link
                  className="modal__overflow-ellipsis"
                  to={`/customers/${customer?.id}`}
                >
                  {customer?.name || 'missing'}
                </Link>
              </TextField>
            </div>
            <div className="col-12 col-sm-6 gy-4">
              <TextField label="Mobile">
                <Link className="modal__overflow-ellipsis" to={`tel:${phone}`}>
                  {formatPhoneNumberIntl(phone) || 'missing'}
                </Link>
              </TextField>
            </div>
            <div className="col-12 col-sm-6 gy-4">
              <TextField label="Email">
                <Link
                  className="modal__overflow-ellipsis"
                  to={`mailto:${email}`}
                >
                  {email || 'missing'}
                </Link>
              </TextField>
            </div>
            <div className="col-12 col-sm-6 gy-4">
              <TextField label="Address">
                <a
                  className="modal__overflow-ellipsis"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                    formatAddress(customer?.address)
                  )}`}
                >
                  {customer?.address?.street_address_1 || 'missing'}
                </a>
              </TextField>
            </div>
          </div>
        </section>

        {navigation.map((item) => (
          <ModalNavigation setIsExpanded {...item} key={item.label} />
        ))}
      </div>
      <div className="main-footer">
        <Button className="ms-auto d-none">View estimate full page</Button>
      </div>
      <div className="side-top">
        <Outlet
          context={{
            status: estimate.status,
            phone,
            email,
            subject: `Your estimate ${estimate.number} is ready.`,
          }}
        />
      </div>
      <div
        className="side-footer d-flex justify-content-between"
        id="modalBottom"
      >
        <Button type="button" variant="light" onClick={goBack}>
          Back
        </Button>
      </div>
    </div>
  );
}

export default EstimateModalContent;
