const schema = (options) => [
  {
    header: 'Description',
    accessorKey: 'description',
    type: 'input',
    enableSort: false,
    mobileLabel: 'true',
  },
  {
    header: 'Invoice',
    accessorKey: 'invoice_id',
    type: 'select',
    options,
    enableSort: false,
    mobileLabel: 'true',
  },
  {
    header: 'Amount',
    accessorKey: 'amount',
    type: 'input',
    enableSort: false,
    mobileLabel: 'true',
  },
];

export default schema;
