import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  useActionData,
  useOutletContext,
  useRouteLoaderData,
} from 'react-router-dom';
import { SALES_TOGGLE } from '../../constants/toggleOptions';

import ReactTablev8 from '../../../UI/Table/ReactTablev8';
import ReportHeader from '../../ReportHeader';
import SalesByCustomerPDF from './SalesByProductPDF';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';
import ReportFooter from '../../ReportFooter';

function SalesByProduct() {
  const actionData = useActionData();
  const { dateTo, dateFrom } = useOutletContext();
  const rootLoader = useRouteLoaderData('root');
  if (actionData?.message !== 'success' || !actionData)
    return <p className="card p-4 mt-4 border-0">Error generating report</p>;

  const tableData = actionData?.data.map((item) => ({
    product_name: item.name,
    total_qty: item.total_qty,
    total_tax: item.total_tax,
    total_price: item.total_price,
    id: item.product_id,
  }));

  const tableSetup = [
    {
      header: 'Product Name',
      accessorKey: 'product_name',
      enableSorting: false,
    },
    {
      header: 'Total Quantity',
      accessorKey: 'total_qty',
      enableSorting: false,
      mobileLabel: true,
    },
    {
      header: 'Total Tax',
      type: 'money',
      accessorKey: 'total_tax',
      enableSorting: false,
      mobileLabel: true,
    },
    {
      header: 'Total Price',
      type: 'money',
      accessorKey: 'total_price',
      enableSorting: false,
      mobileLabel: true,
    },
  ];

  const totals = tableData.reduce(
    (acc, entry) => {
      return {
        subtotal: acc.subtotal + Number(entry.total_price),
        taxTotal: acc.taxTotal + Number(entry.total_tax),
        total: acc.total + Number(entry.total_price) + Number(entry.total_tax),
      };
    },
    {
      subtotal: 0,
      taxTotal: 0,
      total: 0,
    }
  );

  const tableOptions = {
    search: false,
    pagination: {
      show: false,
    },
    sorting: false,
  };

  return (
    <>
      <ReportHeader
        reportSection={SALES_TOGGLE}
        pdfDocument={
          <SalesByCustomerPDF
            dateTo={dateTo}
            dateFrom={dateFrom}
            pdfData={{ tableData, tableSetup, totals }}
            userData={rootLoader}
          />
        }
      />
      <div className="container-fluid">
        {tableData.length === 0 ? (
          <Row>
            <Col className="alert alert-secondary" role="alert">
              No sales registered for this date range/product
            </Col>
          </Row>
        ) : (
          <div className="reports__display-react-table">
            <ReactTablev8
              dataTable={tableData}
              tableLayout={tableSetup}
              tableOptions={tableOptions}
              mobileClass="reports_sales_product_table"
            />
            <div className="reports__display-totals">
              <div>
                <h6>Totals</h6>
                <table>
                  <tbody>
                    <tr>
                      <td>Subtotal:</td>
                      <td style={{ paddingRight: 0 }}>$</td>
                      <td>{formatCurrency(totals.subtotal, 2, false)}</td>
                    </tr>
                    <tr>
                      <td>Total Tax:</td>
                      <td style={{ paddingRight: 0 }}>$</td>
                      <td>{formatCurrency(totals.taxTotal, 2, false)}</td>
                    </tr>
                    <tr>
                      <td>Total:</td>
                      <td style={{ paddingRight: 0 }}>$</td>
                      <td>
                        <strong>
                          {formatCurrency(totals.total, 2, false)}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ReportFooter />
          </div>
        )}
      </div>
    </>
  );
}

export default SalesByProduct;
