import React, { useEffect, useState } from 'react';
import WidgetContainer from '../../../Dashboard/Widgets/WidgetContainer/WidgetContainer';
import { fetchData } from '../../../../API/fetchUtilsOld';
import { BASE_URL } from '../../../../API/constants';
import { sumAmounts } from '../utils/helpers';
import ReportsPieChart from '../../ReportsCharts/ReportsPieChart';
import ReportsBarChart from '../../ReportsCharts/ReportsBarChart';

function BusinessScreenshot() {
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesTotal, setCategoriesTotal] = useState(0);
  const [categoryTime, setCategoryTime] = useState('this_year');
  const [categoryLoading, setCategoryLoading] = useState(false);

  const getSalesCategories = async () => {
    setCategoryLoading(true);
    const { data } = await fetchData(
      `${BASE_URL}/report/get-sale-categories?time=${categoryTime}`
    );
    setCategoriesTotal(sumAmounts(data));
    const newArray = data.map((item) => {
      return {
        name: item.category,
        value: item.amount,
      };
    });
    setCategoriesData(newArray);
    setCategoryLoading(false);
  };

  useEffect(() => {
    getSalesCategories();
  }, [categoryTime]);

  const [expensesData, setExpensesData] = useState([]);
  const [expensesTotal, setExpensesTotal] = useState(0);
  const [expensesTime, setExpensesTime] = useState('this_year');
  const [expensesLoading, setExpensesLoading] = useState(false);

  const getExpenses = async () => {
    setExpensesLoading(true);
    const { data } = await fetchData(
      `${BASE_URL}/report/get-expenses?time=${expensesTime}`
    );
    setExpensesTotal(sumAmounts(data));
    const newArray = data.map((item) => {
      return {
        name: item.category,
        value: item.amount,
      };
    });
    setExpensesData(newArray);
    setExpensesLoading(false);
  };

  useEffect(() => {
    getExpenses();
  }, [expensesTime]);

  const [incomeComparisonData, setIncomeComparisonData] = useState([]);
  const [incomeComparisonTime, setIncomeComparisonTime] = useState('monthly');
  const [incomeComparisonLoading, setIncomeComparisonLoading] = useState(false);
  const LABELS = {
    label1: 'Previous Year',
    label2: 'This Year',
  };
  const getIncomeComparison = async () => {
    setIncomeComparisonLoading(true);
    const { data } = await fetchData(
      `${BASE_URL}/report/get-income-comparison?period=${incomeComparisonTime}`
    );
    const newArray = data.map((item) => {
      return {
        name: item.label2.split(' 2023')[0],
        label1: item.amount1,
        label2: item.amount2,
      };
    });
    setIncomeComparisonData(newArray);
    setIncomeComparisonLoading(false);
  };

  useEffect(() => {
    getIncomeComparison();
  }, [incomeComparisonTime]);

  const [expensesComparisonData, setExpensesComparisonData] = useState([]);
  const [expensesComparisonTime, setExpensesComparisonTime] =
    useState('monthly');
  const [expensesComparisonLoading, setExpensesComparisonLoading] =
    useState(false);

  const getExpensesComparison = async () => {
    setExpensesComparisonLoading(true);
    const { data } = await fetchData(
      `${BASE_URL}/report/get-expense-comparison?period=${expensesComparisonTime}`
    );
    const newArray = data.map((item) => {
      return {
        name: item.label2.split(' 2023')[0],
        label1: item.amount1,
        label2: item.amount2,
      };
    });
    setExpensesComparisonData(newArray);
    setExpensesComparisonLoading(false);
  };

  useEffect(() => {
    getExpensesComparison();
  }, [expensesComparisonTime]);

  return (
    <section className="widgets__grid-container">
      {/* SALES BY CATEGORY PIE */}
      <WidgetContainer>
        <WidgetContainer.Header title="Sales by Category">
          <WidgetContainer.Select
            value={categoryTime}
            onChangeHandler={(event) => setCategoryTime(event.target.value)}
          >
            <option value="last_quarter">Last Quarter</option>
            <option value="last_month">Last Month</option>
            <option value="this_year">This Year to date</option>
          </WidgetContainer.Select>
        </WidgetContainer.Header>
        <ReportsPieChart
          data={categoriesData}
          totalValue={categoriesTotal}
          loading={categoryLoading}
        />
      </WidgetContainer>

      {/* EXPENSES PIE */}
      <WidgetContainer>
        <WidgetContainer.Header title="Expenses">
          <WidgetContainer.Select
            value={expensesTime}
            onChangeHandler={(event) => setExpensesTime(event.target.value)}
          >
            <option value="last_quarter">Last Quarter</option>
            <option value="last_month">Last Month</option>
            <option value="this_year">This Year to date</option>
          </WidgetContainer.Select>
        </WidgetContainer.Header>
        <ReportsPieChart
          data={expensesData}
          totalValue={expensesTotal}
          loading={expensesLoading}
        />
      </WidgetContainer>

      {/* INCOME COMPARISON BAR CHART */}
      <WidgetContainer>
        <WidgetContainer.Header title="Previous Year Income Comparison">
          <WidgetContainer.Select
            value={incomeComparisonTime}
            onChangeHandler={(event) =>
              setIncomeComparisonTime(event.target.value)
            }
          >
            <option value="quarterly">Quarterly</option>
            <option value="monthly">Monthly</option>
          </WidgetContainer.Select>
        </WidgetContainer.Header>
        <ReportsBarChart
          chartData={incomeComparisonData}
          labels={LABELS}
          loading={incomeComparisonLoading}
        />
      </WidgetContainer>

      <WidgetContainer>
        <WidgetContainer.Header title="Previous Year Expenses Comparison">
          <WidgetContainer.Select
            value={expensesComparisonTime}
            onChangeHandler={(event) =>
              setExpensesComparisonTime(event.target.value)
            }
          >
            <option value="quarterly">Quarterly</option>
            <option value="monthly">Monthly</option>
          </WidgetContainer.Select>
        </WidgetContainer.Header>
        <ReportsBarChart
          chartData={expensesComparisonData}
          labels={LABELS}
          loading={expensesComparisonLoading}
        />
      </WidgetContainer>
    </section>
  );
}

export default BusinessScreenshot;
