/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */

import { useState, useEffect } from 'react';
import {
  useLoaderData,
  useNavigation,
  useSubmit,
  useOutletContext,
  useFetcher,
} from 'react-router-dom';
import { string } from 'yup';
import { createPortal } from 'react-dom';
import { Form } from 'react-bootstrap';
import LBOffCanvas from '../UI/LBOffCanvas';
import LBTooltip from '../UI/LBTooltip/LBTooltip';
import IconButton from '../UI/IconButton/IconButton';
import { TrashCan } from '../UI/Icons/Icons';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import CreateForm from '../Forms/CreateForm';
import FormSubmit from '../WorkOrders/FormSubmit';

function TradesPopover() {
  const [isMounted, setIsMounted] = useState(false);
  const deleteFetcher = useFetcher();

  // Reset select if we delete the selected trade
  const { tradeId, setFieldValue, fieldKey } = useOutletContext();

  useEffect(() => {
    // This code runs after the component has mounted
    setIsMounted(true);

    // Cleanup function to run when the component unmounts
    return () => {
      setIsMounted(false);
    };
  }, []); // Runs only once (probably twice in dev mode)

  const loaderData = useLoaderData();

  const otherTrades = loaderData?.data?.others;

  const fields = [
    {
      name: 'name',
      label: 'Trade Name',
      initialValue: '',
      type: string().required(),
    },
  ];

  const submit = useSubmit();
  const navigation = useNavigation();
  const submitAction = (values) => {
    submit(values, {
      method: 'post',
    });

    formik.resetForm({ values });
    formik.setErrors({});
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
  });

  return (
    <LBOffCanvas.Wrapper>
      <LBOffCanvas.Left>
        <LBOffCanvas.Header>Manage Custom Trades</LBOffCanvas.Header>
        <>
          {isMounted &&
            document.getElementById('offcanvas-action-group-left') &&
            createPortal(
              <div className="d-flex flex-column w-100">
                <h6 className="mb-3">Add New Trade</h6>
                <Form noValidate id="add-trade" onSubmit={formik.handleSubmit}>
                  <CreateForm structure={fields} {...formik} />
                  <FormSubmit
                    id="add-trade"
                    isSubmitting={
                      formik.isSubmitting || navigation.state !== 'idle'
                    }
                    dirty={formik.dirty}
                    isValid={formik.isValid}
                    label="Add Trade"
                    variant="primary"
                  />
                </Form>
              </div>,
              document.getElementById('offcanvas-action-group-left')
            )}
          {otherTrades ? (
            <ul>
              {otherTrades.map(({ id, name }) => (
                <li
                  className="w-100 justify-content-between align-items-center d-flex border-bottom py-1"
                  key={id}
                >
                  {name}
                  <deleteFetcher.Form
                    method="POST"
                    action="./delete-trade"
                    replace={false}
                  >
                    <input
                      type="text"
                      name="tradeId"
                      className="d-none"
                      defaultValue={id}
                      readOnly
                    />
                    <LBTooltip tooltipText="Delete Trade">
                      <IconButton
                        color="red"
                        type="submit"
                        size={16}
                        // Reset trade if we delete the selected trade
                        onClick={() => {
                          if (String(id) === tradeId) {
                            setFieldValue(fieldKey, '');
                          }
                        }}
                      >
                        <TrashCan />
                      </IconButton>
                    </LBTooltip>
                  </deleteFetcher.Form>
                </li>
              ))}
            </ul>
          ) : (
            <p>Loading...</p>
          )}
        </>
      </LBOffCanvas.Left>
    </LBOffCanvas.Wrapper>
  );
}

export default TradesPopover;
