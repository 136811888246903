import Merchants from '../../components/Merchants/Merchants';
import CreateMerchant from '../../components/Merchants/CreateMerchant';
import GetMerchant from '../../components/Merchants/GetMerchant';
import {
  createMerchantAction,
  deleteMerchantAction,
  merchantLoader,
  merchantsLoader,
  updateMerchantAction,
} from './merchantsLA';
import Merchant from '../../components/Merchants/Merchant';

const merchantsRoutes = [
  {
    path: 'merchants',
    handle: {
      crumb: () => 'Merchants',
    },
    children: [
      {
        path: '',
        index: true,
        element: <Merchants />,
        loader: merchantsLoader,
        handle: {
          linkTo: {
            label: 'New',
            to: 'new',
          },
        },
      },
      {
        path: 'new',
        action: createMerchantAction,
        element: <CreateMerchant />,
        handle: {
          crumb: () => 'New',
        },
      },
      {
        path: ':merchantId',
        element: <GetMerchant />,
        loader: merchantLoader,
        handle: {
          linkTo: {
            label: 'Edit',
            to: 'update',
          },
        },
        children: [
          {
            path: '',
            element: <Merchant />,
            handle: {
              crumb: () => 'Details',
            },
          },
          {
            path: 'update',
            element: <CreateMerchant />,
            action: updateMerchantAction,
            handle: {
              crumb: () => 'Update',
            },
          },
          {
            path: 'delete',
            action: deleteMerchantAction,
          },
        ],
      },
    ],
  },
];
export default merchantsRoutes;
