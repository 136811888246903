import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useLoaderData, useSubmit } from 'react-router-dom';
import './ActivityReview.scss';
import Card from '../UI/Card';

function ActivityReview() {
  const submit = useSubmit();
  const [time, setTime] = useState('this_year');
  const { data: result } = useLoaderData();
  const {
    quote_count: quoteCount,
    invoice_count: invoiceCount,
    total_sales: totalSales,
    total_payment: totalPayments,
    total_expense: totalExpense,
    unpaid_balance: unpaidBalance,
    profit,
  } = result;
  const data = {
    quoteCount,
    invoiceCount,
    totalSales,
    totalPayments,
    totalExpense,
    unpaidBalance,
    profit,
  };
  useEffect(() => {
    const formData = new FormData();
    formData.append('time', time);
    submit(formData, {
      replace: true,
    });
  }, []);
  const handleTime = (e) => {
    const formData = new FormData();
    formData.append('time', e.target.value);
    setTime(e.target.value);

    submit(formData, {
      replace: true,
    });
  };
  return (
    <Card>
      <h2 className="lb-modal__title">Activity review</h2>

      <Form.Select
        aria-label="Default select example"
        value={time}
        onChange={handleTime}
        name="time"
      >
        <option value="today">Today</option>
        <option value="last_week">Last week</option>
        <option value="last_year">Last year</option>
        <option value="this_year">This year</option>
      </Form.Select>

      <hr />
      <table className="activity-table">
        <tbody>
          <tr>
            <td>Total estimates</td>
            <td>{data.quoteCount}</td>
          </tr>
          <tr className="border-bottom">
            <td>Total invoices</td>
            <td>{data.invoiceCount}</td>
          </tr>
          <tr>
            <td>Total Sales</td>
            <td className="color-sky-blue">{data.totalSales}</td>
          </tr>
          <tr>
            <td>Total Payments</td>
            <td className="color-c-green">{data.totalPayments}</td>
          </tr>
          <tr className="border-bottom">
            <td>Total Expenses</td>
            <td className="color-pink">{data.totalExpense}</td>
          </tr>
          <tr className="border-bottom">
            <td>Unpaid Balance</td>
            <td>{data.unpaidBalance}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Total Profit</td>
            <td className="color-c-green">{data.profit}</td>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
}

export default ActivityReview;
