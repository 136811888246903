import React from 'react';
import { Link } from 'react-router-dom';
import OnboardGrid from '../../UI/OnboardGrid/OnboardGrid';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import BulletList from '../../UI/BulletList/BulletList';
import StepList from '../../UI/StepList/StepList';
import image from '../../../img/assets/images/c-userOnboard-customers.svg';

import './customersOnboard.styles.scss';

export const customerBullets = [
  {
    id: 0,
    pre: 'Send Contact Form',
    body: 'Eliminate the margin of error customer’s data and speed up the process by letting your customer enter the data themselves.',
  },
  {
    id: 1,
    pre: 'Customer Overview',
    body: 'Keep track of Sales, Expenses, Payment, Unpaid balance and Total Profit.  All in one place.',
  },
  {
    id: 2,
    pre: 'Map',
    body: 'To easily locate in which area of the city the client lives.',
  },
  {
    id: 3,
    pre: 'Send email',
    body: 'Easy email access.',
  },
  {
    id: 4,
    pre: 'Click and Call',
    body: 'Phone call link for easy workflow.',
  },
];

export const customerSteps = [
  {
    id: 0,
    body: (
      <p>
        Send your customer the Contact Form (Recommend) or Enter the data
        yourself.
      </p>
    ),
  },
  {
    id: 1,
    body: <p>View customer details page by clicking View link.</p>,
  },
  {
    id: 2,
    body: <p>Select the activity you want to do form the top menu.</p>,
  },
];

function CustomersOnboard() {
  return (
    <CardWrapper>
      <OnboardGrid>
        <OnboardGrid.Header>
          <h2>All your customer data in one place</h2>
        </OnboardGrid.Header>
        <OnboardGrid.Left>
          <BulletList
            list={customerBullets}
            title="Customer module features:"
          />
          <StepList stepArray={customerSteps} />
        </OnboardGrid.Left>
        <OnboardGrid.Right>
          <img
            className="page-image"
            src={image}
            alt="vector illustration of a person viewing data visualizations"
          />
          <div className="action-group">
            <Link
              className="support-link btn btn-primary p-2 mb-3 me-3"
              variant="secondary"
              to="new"
            >
              Add your first customer
            </Link>
            <Link
              variant="secondary"
              to="/contact-form-modal"
              className="support-link btn btn-secondary p-2 mb-3"
            >
              Send Contract Form
            </Link>
          </div>
          <div className="support-wrapper">
            <p className="support">Have Questions?</p>
            <a
              className="support-link"
              href="mailto:support@leadsbox.com?subject=Support needed"
            >
              Go to support
            </a>
          </div>
        </OnboardGrid.Right>
      </OnboardGrid>
    </CardWrapper>
  );
}

export default CustomersOnboard;
