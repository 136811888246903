async function setFinalPrice(
  values,
  setValue,
  profitType,
  newValue,
  isCost = false
) {
  let finalPrice;
  const numberNewValue = newValue === '' ? 0 : Number(newValue);

  // Prevent working with NaN cuz he's mean
  if (
    Number.isNaN(+values.amount_cost) ||
    Number.isNaN(+values.amount_profit_rate) ||
    Number.isNaN(+values.amount_profit_net) ||
    Number.isNaN(numberNewValue)
  ) {
    await setValue('amount_price_before_tax', 0);
    return;
  }

  // Handle Rate (%) changes
  if (!isCost && profitType === 'profit_rate') {
    finalPrice =
      Number(values.amount_cost) +
      Number(values.amount_cost) * (numberNewValue / 100);
    await setValue(
      'amount_profit_net',
      Number(values.amount_cost) * (numberNewValue / 100)
    );
    await setValue('amount_price_before_tax', finalPrice);
  }

  // Handle Net changes
  if (!isCost && profitType === 'net_profit') {
    finalPrice = Number(values.amount_cost) + numberNewValue;
    // Prevent divide by 0
    if (Number(values.amount_cost) !== 0) {
      await setValue(
        'amount_profit_rate',
        (100 * numberNewValue) / Number(values.amount_cost)
      );
    }

    await setValue('amount_price_before_tax', finalPrice);
  }

  // Handle materials/cost changes
  if (isCost) {
    // Prevent operating with NaN cost
    if (Number.isNaN(numberNewValue)) return;

    // Update Net when we are basing our profit %
    if (profitType === 'profit_rate') {
      finalPrice =
        Number(values.amount_profit_rate) === 0
          ? numberNewValue
          : numberNewValue * (Number(values.amount_profit_rate) / 100) +
            numberNewValue;

      await setValue(
        'amount_profit_net',
        (Number(values.amount_profit_rate) / 100) * numberNewValue
      );
    }

    // Update Profit % when we have a fixed (net) profit
    if (profitType === 'net_profit') {
      finalPrice = numberNewValue + Number(values.amount_profit_net);

      // Prevent divide by 0
      if (numberNewValue !== 0) {
        await setValue(
          'amount_profit_rate',
          (100 * Number(values.amount_profit_net)) / numberNewValue
        );
      } else {
        await setValue('amount_profit_rate', 0);
      }
    }

    // We always set the final price/rate if we reach this point
    await setValue('amount_price_before_tax', finalPrice);
  }
}

export default setFinalPrice;
