import React, { useEffect, useState } from 'react';
import { useParams, useFetcher } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Card from '../UI/Card';
import { transformDateHook } from '../Helpers/toDates';
import formatCurrency from '../Dashboard/Widgets/utils/formatCurrency';

function VendorPayments() {
  const fetcher = useFetcher();
  const { vendorId } = useParams();
  const [time, setTime] = useState('this_year');

  const [data, setData] = useState(null);
  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      const body = new FormData();
      body.append('timeline', time);
      fetcher.submit(body, {
        method: 'post',
        action: `/vendors/${vendorId}/payments`,
      });
    }
    if (fetcher.data) {
      const payments = fetcher.data?.data;
      const total = fetcher.data?.total_amount;
      setData({ payments, total: total || 0 });
    }
  }, [fetcher, vendorId]);

  const handleTime = (e) => {
    const formData = new FormData();
    formData.append('timeline', e.target.value);
    setTime(e.target.value);

    fetcher.submit(formData, {
      method: 'post',
      action: `/vendors/${vendorId}/payments`,
    });
  };
  return data ? (
    <Card>
      <h2 className="lb-modal__title">Payments</h2>
      <Form.Select
        aria-label="Default select example"
        value={time}
        onChange={handleTime}
        name="timeline"
      >
        <option value="last_year">Last year</option>
        <option value="this_year">This year</option>
      </Form.Select>
      <hr />
      {data?.payments?.length === 0 ? (
        <div style={{ textAlign: 'center' }}>
          <h4 className="mt-3">No payments yet</h4>
          <p className="mt-2">
            This vendor has no payments for this period of time.
          </p>
        </div>
      ) : (
        <table className="activity-table" style={{ fontSize: '13px' }}>
          <thead>
            <tr>
              <th>Invoice #</th>
              <th>Payment date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.payments.map((item) => (
              <tr key={item?.expense_id}>
                <td>{item?.invoice_id || 'No invoice'}</td>
                <td>{transformDateHook(item?.as_at)}</td>
                <td className="table__money numbers">{item?.amount}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2">Total</td>
              <td className="table__money numbers">
                {formatCurrency(data?.total || '0', 2, false)}
              </td>
            </tr>
          </tfoot>
        </table>
      )}
    </Card>
  ) : (
    <div>
      <p>No vailable data</p>
    </div>
  );
}

export default VendorPayments;
