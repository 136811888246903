/* eslint-disable react/prop-types */
import React from 'react';
import PDFBoilerplate from '../../PDF/PDFBoilerplate';
import PDFTable from '../../PDF/Table/PDFTable';
import PDFError from '../../PDF/PDFError';

function ProductsAndServicesListPDF({ pdfData, userData }) {
  if (!pdfData || !userData) return <PDFError />;

  const { tableData, tableSetup } = pdfData;

  const sizeArray = ['25%', '15%', '32%', '8%', '20%'];

  return (
    <PDFBoilerplate
      title="Products and Services List"
      logoBase64={userData.user.company_logo_base64}
      companyName={userData.user.user_company}
    >
      <PDFTable
        sizeArray={sizeArray}
        tableData={tableData}
        tableSetup={tableSetup}
      />
    </PDFBoilerplate>
  );
}

export default ProductsAndServicesListPDF;
