import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import SplitBox from './SplitBox';

function InvoicesSplits() {
  const total = 100;
  const [splits, setSplits] = useState([0, total]);
  const handleChangeSplits = (value, index) => {
    const nextSplit = [...splits];
    nextSplit[index] = +value;
    let subtotal = 0;
    for (let i = 0; i < nextSplit.length - 1; i += 1) {
      subtotal += nextSplit[i];
    }
    nextSplit[nextSplit.length - 1] = total - subtotal;
    setSplits(nextSplit);
  };
  const addBox = () => {
    const nextSplit = [...splits];
    nextSplit.splice(-1, 0, 0);
    setSplits(nextSplit);
  };

  return (
    <>
      <h2 className="mt-5 mb-3">Split invoice</h2>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <h6>I-0000000000</h6>
          <p>Total amount</p>
        </div>
        <div className="">
          <p>{`$${total}`}</p>
        </div>
      </div>
      {splits.map((splitValue, index) => {
        return (
          <SplitBox
            splits={splitValue}
            handler={handleChangeSplits}
            key={`split-${Math.random()}`}
            id={`I-0000000000-${index + 1}`}
            index={index}
          />
        );
      })}

      <Button className="mt-3" onClick={addBox}>
        Add another split +
      </Button>

      <Button
        size="small"
        className="d-none p-3 mt-3 w-100 d-flex align-items-center justify-content-between"
        type="submit"
      >
        Save
        {/* <img src={iconBin} alt="" className="icon-btn" /> */}
      </Button>
    </>
  );
}

export default InvoicesSplits;
