/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Outlet,
  useLoaderData,
  useLocation,
  useSubmit,
} from 'react-router-dom';
import CreateForm from '../../Forms/CreateForm';
import ChecksTable from '../Table/ChecksTable';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import FormWrapper from '../../Forms/builders/FormWrapper';
import schema from './schema';
import StickyButton from './StickyButton';
import formikHelpers from './formikHelpers';
import LBOffCanvas from '../../UI/LBOffCanvas';
import useModal from '../../UI/LBOffCanvas/useModal';

const getRecipient = (values) => ({
  type: values.recipient_code.split('_')[0],
  id: values.recipient_code.split('_')[1],
});

function ChecksNew() {
  const loaderData = useLoaderData();

  const [fields] = useState(() => schema(loaderData?.data || {}));
  const { size, show, handleCloseModal } = useModal(
    loaderData ? 'edit' : 'new'
  );

  const submit = useSubmit();

  const submitAction = (values) => {
    // eslint-disable-next-line no-unused-vars
    const nextValues = (({ recipient_code: _, ...rest }) => rest)(values);
    nextValues.category_id = +values.category_id;
    const recipient = getRecipient(values);
    nextValues.recipient_id = +recipient.id;
    nextValues.recipient_type = recipient.type;
    if (loaderData?.data?.id) nextValues.id = loaderData.data.id;
    if (loaderData?.data?.print_state !== undefined)
      nextValues.print_state = loaderData.data.print_state;
    submit(nextValues, { method: 'post', encType: 'application/json' });
  };

  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  const { state: locationState } = useLocation();
  // We detect when the modal is closed before actually submitting
  // the form, and clear the recipient value
  React.useEffect(() => {
    if (locationState?.abort === true && formik.values.recipient_code !== '') {
      formik.setFieldValue('recipient_code', '');
    }
  }, [locationState]);

  const helpers = formikHelpers(formik);
  return (
    <>
      <LBOffCanvas
        size={size}
        show={show}
        handleCloseModal={() => handleCloseModal({ abort: true })}
      >
        <Outlet />
      </LBOffCanvas>
      <FormWrapper
        id="new-check"
        className="flex__form-wrapper"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        label="Save changes"
        portalId="new-check-submit-container"
        dirty
        isValid={formik.dirty}
      >
        <CreateForm structure={fields} {...formik} />
        <h5 className="mb-4 mt-2">Check Items</h5>
        <div id="add-item-portal" />
        <ChecksTable
          items={formik.values?.items}
          setItems={helpers}
          errors={formik.errors?.items}
        />
      </FormWrapper>
      <StickyButton id="new-check-submit-container" />
    </>
  );
}

export default ChecksNew;

// {
/* <Button
        onClick={() =>
          formikHelpers.remove('items', formik.values.items.length - 1)
        }
      >
        Remove last index
      </Button>
      <Button
        onClick={() =>
          formikHelpers.push('items', {
            description: 'hello',
            invoice: '',
            amount: 'a',
          })
        }
      >
        Add new index
      </Button> */
// }

// // eslint-disable-next-line no-param-reassign
// values.items = initialData;
// const checks = window.localStorage.getItem('checks');
// const nextChecks = !checks ? values : [...JSON.parse(checks), values];
// window.localStorage.setItem('checks', JSON.stringify(nextChecks));
// navigate('/checks');

// {
//   "category_id": 116,
//   "payment_date": "2024-05-04",
//   "memo": "a",
//   "items": [
//     {
//       "description": "a",
//       "invoice_id": 1279,
//       "amount": 1
//     }
//   ],
//   "total": 1,
//   "recipient_id": 265,
//   "recipient_type": "customers",
//   "print_state": 0
// }

// {
//         "category_id": "228",
//         "recipient_id": "99",
//         "recipient_type": "vendors",
//         "memo": null,
//         "payment_date": "2024-04-13",
//         "print_state": 0,
//         "items": [
//             {
//                 "description": "Insurance",
//                 "invoice_id" : 1187,
//                 "amount": 20
//             },
//             {
//                 "description": "Electricity",
//                 "invoice_id": 1187,
//                 "amount": 50
//             }
//         ]
//     }
