import React from 'react';
import PropTypes from 'prop-types';
import { Link, Outlet, useFetcher, useNavigate } from 'react-router-dom';
import useAppointmentContext from '../Context/useAppointmentContext';
import AppointmentsHeader from '../AppointmentsHeader';
import { WEEK_DAYS, groupAppointmentsByDay } from '../utils/helpers';
import IconButton from '../../UI/IconButton/IconButton';
import { Approve, Edit, Reschedule, TrashCan } from '../../UI/Icons/Icons';
import LBTooltip from '../../UI/LBTooltip/LBTooltip';
import AppointmentCalendar from '../Calendar/AppointmentCalendar';
import AppointmentsActions from '../AppointmentsActions';
import LBOffCanvas from '../../UI/LBOffCanvas';
import useModal from '../../UI/LBOffCanvas/useModal';

import './WeekView.scss';

const STATUS_COLORS = {
  pending: 'green',
  completed: 'yellow',
  overdue: 'green',
};

// eslint-disable-next-line react/prop-types
export function AppointmentToggle({ fetcher, status, id }) {
  return (
    <LBTooltip
      tooltipText={
        status === 'pending' || status === 'overdue'
          ? 'Mark as completed'
          : 'Mark as pending'
      }
    >
      <fetcher.Form method="post" action={`/appointments/${id}/toggle-status`}>
        <IconButton
          type="submit"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          color={STATUS_COLORS[status]}
        >
          {status === 'pending' || status === 'overdue' ? (
            <Approve />
          ) : (
            <Reschedule />
          )}
        </IconButton>
      </fetcher.Form>
    </LBTooltip>
  );
}

function Row({ appointment }) {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  return (
    <div
      tabIndex={0}
      role="row"
      onKeyDown={(e) => e.code === 'Enter' && navigate(appointment.id)}
      onClick={() => navigate(appointment.id.toString())}
      type="button"
      to={appointment.id}
      className="appointments-week-view__row"
    >
      <div className="appointments-week-view__row__name">
        {appointment.name}
      </div>

      <div className={`appointments-week-view__badge ${appointment.status}`}>
        {appointment.status}
      </div>

      <div className="appointments-week-view__row__time">
        {new Date(appointment.start_date).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          second: undefined,
        })}{' '}
        -{' '}
        {new Date(appointment.end_date).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          second: undefined,
        })}
      </div>

      <div className="appointments-week-view__row__action-group">
        <AppointmentToggle
          fetcher={fetcher}
          id={appointment.id}
          status={appointment.status}
        />
        <LBTooltip tooltipText="Edit appointment">
          <IconButton
            as={Link}
            to={`/appointments/${appointment.id}/edit`}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            color="blue"
          >
            <Edit />
          </IconButton>
        </LBTooltip>
        <LBTooltip tooltipText="Delete">
          <fetcher.Form
            method="post"
            action={`/appointments/${appointment.id}/delete-util`}
          >
            <IconButton
              type="submit"
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              color="red"
            >
              <TrashCan />
            </IconButton>
          </fetcher.Form>
        </LBTooltip>
      </div>
    </div>
  );
}

function WeekView() {
  const { thisWeekAppointments } = useAppointmentContext();
  const groupedAppointments = React.useMemo(
    () => groupAppointmentsByDay(thisWeekAppointments),
    [thisWeekAppointments]
  );

  // We are in /appointments (root)
  const { show, size, handleCloseModal } = useModal('week');
  return (
    <>
      <div className="container-fluid">
        <div className="row gx-0 gx-lg-4 gy-4">
          <div className="col-12 col-xl-5 order-xl-2 mb-4 w-lg-auto">
            <div className="appointments__sidebar">
              <AppointmentCalendar />
              <AppointmentsActions />
            </div>
          </div>
          <div className="col">
            <div className="appointments-week-view">
              <AppointmentsHeader />
              <h4>This Week&apos;s Appointments</h4>
              {!thisWeekAppointments?.length ? (
                <div className="appointments-week-view__actions-no-apps">
                  No appointments for the current week
                </div>
              ) : (
                Object.keys(groupedAppointments).map((dayIndex) => {
                  return (
                    <div key={dayIndex}>
                      <h5 className="appointments-week-view__row-header">
                        {WEEK_DAYS[dayIndex]}
                      </h5>
                      {groupedAppointments[dayIndex]
                        .sort(
                          (a, b) =>
                            new Date(a.start_date) - new Date(b.start_date)
                        )
                        .map((app) => (
                          <Row key={app.id} appointment={app} />
                        ))}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      <LBOffCanvas show={show} size={size} handleCloseModal={handleCloseModal}>
        <Outlet />
      </LBOffCanvas>
    </>
  );
}

Row.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    status: PropTypes.oneOf(['pending', 'completed', 'overdue']),
  }),
};

// WeekView.propTypes = {
//   toggler: PropTypes.func,
// };

export default WeekView;
