/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
// import { useAsyncDebounce } from 'react-table';
import LBToggleButton from '../LBToogleButton/LBToggleButton';
import './ChipList.scss';

// ! CHANGES - use async debounce must be removed from this component and moved to the parent component

function ChipList({ name, chipFilter, filterValues, initialFilter, setter }) {
  const [selectedValue, setSelectedValue] = React.useState(
    initialFilter.length > 0 ? initialFilter[0].value : ''
  );

  /*
    Expected filter value array:
    [
      {
        "label": "Partial P",
        "color"?: "orange",
        "count": 0
      }
    ]

    We're using base bootstrap variables (only input the name)

    blue -> --bs-blue, etc...
    indigo
    purple
    pink
    red
    orange
    yellow
    green
    teal
    cyan
  */

  const formattedFilter = filterValues.map((filter) => {
    return {
      label: filter.label,
      color: filter.color || filter.class,
      count: filter.count,
    };
  });

  // const updateFilter = useAsyncDebounce((data) => {
  //   chipFilter(data || '');
  // }, 200);
  const updateFilter = (data) => {
    chipFilter(data || '');
  };

  const handleChange = (e) => {
    e.persist();
    const newValue = e.currentTarget.value;
    updateFilter(newValue);
    setSelectedValue(newValue);
    setter(newValue);
  };

  return (
    <div className="overflow-auto pb-4 chiplist">
      <p className="small ps-3 mb-2">Filters:</p>
      <fieldset className="text-nowrap d-flex gap-2">
        <LBToggleButton
          color="blue"
          name={name}
          value=""
          onChange={handleChange}
          checked={selectedValue === ''}
        >
          All (
          {formattedFilter
            .map((filterObj) => filterObj.count)
            .reduce((acc, curr) => acc + curr)}
          )
        </LBToggleButton>
        {formattedFilter.map(({ color, label, count }) => (
          <LBToggleButton
            key={label}
            color={color}
            name={name}
            value={`filter__${label}`}
            onChange={handleChange}
            checked={selectedValue === `filter__${label}`}
          >
            {label} ({count})
          </LBToggleButton>
        ))}
      </fieldset>
    </div>
  );
}

ChipList.propTypes = {
  name: PropTypes.string,
  chipFilter: PropTypes.func,
  filterValues: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      count: PropTypes.number,
    })
  ),
};
export default ChipList;
