/* eslint-disable react/jsx-props-no-spreading */

import React, { ReactNode } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import './IconButton.scss';

// Define a type that includes both intrinsic elements and custom components
type AsProp = keyof JSX.IntrinsicElements | React.ComponentType<any>;

interface I_IconButton<T extends AsProp = 'button'>
  extends Omit<ButtonProps, 'size' | 'as'> {
  children: ReactNode;
  text?: string;
  color?: string;
  size?: number;
  strokeWidth?: number;
  type?: 'button' | 'reset' | 'submit';
  alignText?: 'left' | 'right';
  outline?: boolean;
  transform?: string;
  as?: T;
}

function IconButton<T extends AsProp = 'button'>(
  props: I_IconButton<T> & Omit<React.ComponentProps<T>, keyof I_IconButton<T>>,
  forwardedRef: React.Ref<HTMLButtonElement>
) {
  const {
    children,
    text,
    color = 'gray',
    size = 20,
    strokeWidth = 3.5,
    as = 'button',
    type = 'button',
    alignText = 'right',
    transform = undefined,
    outline = true,
    ...delegated
  } = props;

  return (
    <Button
      {...delegated}
      style={{
        minWidth: 'fit-content',
        flex: '0 0 fit-content',
        ...delegated.style,
      }}
      ref={forwardedRef}
      className={`icon-button ${delegated.className || ''}`}
      variant={`icon${outline ? '-outline' : ''}-${color}`}
      type={type}
      as={as as any} // Clown time
      id={delegated.id || undefined}
    >
      {alignText === 'left' && text}
      <svg
        height={size}
        width={size}
        transform={transform}
        fill="transparent"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
      >
        {children}
      </svg>
      {alignText === 'right' && text}
    </Button>
  );
}

export default React.forwardRef(IconButton);
