import React from 'react';
import * as Icons from './Icons';
import IconWrapper from '../../Customers/CustomersCsv/IconWrapper';

export default function IconsTestPage() {
  return (
    <div className="w-100 d-flex flex-wrap gap-5 align-items-start">
      {Object.keys(Icons).map((icon) => {
        return (
          <div>
            <span style={{ color: 'var(--bs-gray-500)', fontSize: '0.7rem' }}>
              {icon}
            </span>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: 64, height: 64 }}
            >
              <IconWrapper size={48}>{Icons[icon]()}</IconWrapper>
            </div>
          </div>
        );
      })}
    </div>
  );
}
