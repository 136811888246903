import React from 'react';
import propTypes from 'prop-types';
import { createPortal } from 'react-dom';

export default function FormSubmitPortal({ children, portalId }) {
  // In order for the portal to work using "document.getElementById"
  // we need to ensure the component is mounted. The "React way" would
  // be using ref.current, but we have no way to access the target component,
  // so this is a better solution (as ugly as it looks :P)
  // (This could be a custom hook, soon™, anytime now™)
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    // This code runs after the component has mounted
    setIsMounted(true);

    // Cleanup function to run when the component unmounts
    return () => {
      setIsMounted(false);
    };
  }, []); // Runs only once (probably twice in dev mode)

  return portalId
    ? isMounted &&
        document.getElementById(portalId) &&
        createPortal(children, document.getElementById(portalId))
    : children;
}

FormSubmitPortal.propTypes = {
  children: propTypes.node.isRequired,
  portalId: propTypes.string,
};

FormSubmitPortal.defaultProps = {
  portalId: '',
};
