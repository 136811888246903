import React from 'react';
import { useParams, useRouteLoaderData } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import PDFTesting from '../PDF/react-pdf/PDFTesting.tsx';
import { transformData } from './InvoiceDocument';

function SavingInvoiceAndPdfUpload() {
  const { customerId } = useParams();
  const routeLoaders = customerId ? 'customerInvoiceData' : 'invoiceData';
  const {
    user: { company_logo_base64: logoBase64 },
  } = useRouteLoaderData('root');
  const loaderData = useRouteLoaderData(routeLoaders);
  const data = transformData(loaderData, logoBase64);
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <Spinner animation="border" role="status">
          <span className="sr-only" />
        </Spinner>
        <h3 className="ms-2">Uploading invoice</h3>
      </div>
      <p>
        Your invoice will be available in a moment. Please don&rsquo;t close
        this page.
      </p>

      <div className="d-none">
        <PDFTesting data={data} save />
      </div>
    </>
  );
}

export default SavingInvoiceAndPdfUpload;
