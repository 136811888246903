import React from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import ReactTablev8 from '../UI/Table/ReactTablev8';
import schema from './tableSchema';
import useGetCheckTableData from './useGetCheckTableData';
import ModalController from './ModalController';

function ChecksPage() {
  const { result: dbChecks, check_statuses: statuses } = useLoaderData(); // This is a mock data will be response including recipients
  const [selected, setSelected] = React.useState(0);
  const [filter, setFilter] = React.useState(`filter__${statuses[0].label}`);
  const checks = useGetCheckTableData({ dbChecks, statuses });
  const data = React.useMemo(() => checks, [dbChecks]);
  const table = schema(filter);
  const navigate = useNavigate();

  return (
    <>
      <ModalController selected={selected} />
      <ReactTablev8
        dataTable={data}
        tableLayout={table}
        tableAction={(e) => {
          setSelected(e);
          navigate(`${e}`);
        }}
        columnfilter={statuses}
        mobileClass="checks__all"
        initialFilter={[
          {
            id: 'filterChips',
            value: 'filter__Not Printed',
          },
        ]}
        filterSetter={setFilter}
      />
    </>
  );
}

export default ChecksPage;
