import fetchAPI from '../fetchUtils';

const checksAPI = {
  getRecipients: async () => fetchAPI.getWithAuth('/get-recipients'),
  getChecks: async () => fetchAPI.getWithAuth('/get-checks'),
  getCheck: async (id) => fetchAPI.getWithAuth(`/get-check-details/${id}`),
  createCheck: async (data) =>
    fetchAPI.postWithAuth('/create-check', data, 'application/json'),
  toggleCheck: async (id) => fetchAPI.getWithAuth(`/check-toggle-tray/${id}`),
  clearTray: async (data) =>
    fetchAPI.postWithAuth('/check-print', data, 'application/json'),
  void: async (id) => fetchAPI.getWithAuth(`/void-check/${id}`),
  updateCheck: async (data) =>
    fetchAPI.postWithAuth('/update-check', data, 'application/json'),
  deleteCheck: async (id) => fetchAPI.getWithAuth(`/delete-check/${id}`),
  getChecksInTray: async () => fetchAPI.getWithAuth('/printing-tray-checks'),
};

export default checksAPI;
