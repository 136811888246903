/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import FormSubmit from '../../WorkOrders/FormSubmit';
import FormSubmitPortal from './FormSubmitPortal';
import ScrollToError from '../helpers/ScrollToError.tsx';

import '../Forms.scss';

function FormWrapper({
  children,
  className,
  dirty,
  handleSubmit,
  id,
  isSubmitting,
  isValid,
  label = 'Save',
  portalId,
  variant,
}) {
  const navigation = useNavigation();
  const submitting = navigation.state !== 'idle' || isSubmitting;
  return (
    <Form
      className={`mt-2 ${className}`}
      noValidate
      onSubmit={handleSubmit}
      id={id}
    >
      {children}
      <FormSubmitPortal portalId={portalId}>
        <FormSubmit
          id={id}
          isSubmitting={submitting}
          isValid={isValid}
          dirty={dirty}
          label={label}
          variant={variant}
        />
      </FormSubmitPortal>

      <ScrollToError isSubmitting={isSubmitting} />
    </Form>
  );
}

export default FormWrapper;
