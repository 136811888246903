import React from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';

export default function Vendor() {
  const loaderData = useLoaderData();
  const { data } = loaderData;
  const completeAddressData = [
    data.address_1,
    data.address_2,
    data.city,
    data.country,
  ].filter((value) => value);

  let completeAddress;
  if (completeAddressData.length !== 0)
    completeAddress = completeAddressData.join(', ');

  const vendor = {
    id: data.vendor_id,
    name: data.vendor_name || undefined,
    address: completeAddress || undefined,
    email: data.email || undefined,
    phone: data.phone || undefined,
    mobile: data.mobile || undefined,
    notes: data.notes || undefined,
    trade: data.vendor_trade || undefined,
    completeAddress,
    address1: data.address_1 || undefined,
    address2: data.address_2 || undefined,
    city: data.city || undefined,
    country: data.country || undefined,
    zipCode: data.zipcode || undefined,
    state: data.state || undefined,
    vendorLast: data.vendor_last || undefined,
    vendorFirst: data.vendor_name || undefined,
    einSsn: data.ein_ssn || undefined,
    photo: data.vendor_photo || undefined,
    insurance: data.insurance || undefined,
    insuranceExpireDate: data.insurance_expire_date || undefined,
    w9: data.w_9 || undefined,
  };

  return <Outlet context={vendor} />;
}
