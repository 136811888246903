const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

function getLastDayOfCurrentYear() {
  const currentYear = new Date().getFullYear();
  const lastDayOfYear = new Date(currentYear + 1, 0, 0);
  return formatDate(lastDayOfYear);
}

function formatShortDate(inputDate) {
  if (inputDate === '') {
    return '';
  }

  const options = { month: 'long', day: 'numeric', year: 'numeric' };

  const dateArray = inputDate.split('-');
  const year = dateArray[0];
  const month = parseInt(dateArray[1], 10) - 1;
  const date = dateArray[2];
  const parsedDate = new Date(year, month, date);

  // const parsedDate = new Date(inputDate);

  return new Intl.DateTimeFormat('en-US', options).format(parsedDate);
}

function getCurrentFormattedDate() {
  const currentDate = new Date();

  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
    currentDate
  );

  return formattedDate;
}

function getCurrentTimeZone() {
  const currentDate = new Date();

  // Get the raw offset in minutes
  const rawOffsetMinutes = currentDate.getTimezoneOffset();

  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(rawOffsetMinutes) / 60);
  const offsetMinutes = Math.abs(rawOffsetMinutes) % 60;

  // Determine the sign of the offset
  const offsetSign = rawOffsetMinutes < 0 ? '+' : '-';

  // Format the timezone with the offset
  const formattedTimeZone = `GMT ${offsetSign}${offsetHours}:${offsetMinutes
    .toString()
    .padStart(2, '0')}`;

  return formattedTimeZone;
}

function getTotalOfType(array, attribute, value) {
  let counter = 0;

  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attribute] === value) {
      counter += 1;
    }
  }

  return counter;
}

function sumAmounts(array) {
  // Use the reduce function to sum up all the "amount" values
  const totalAmount = array.reduce((accumulator, item) => {
    return accumulator + item.amount;
  }, 0);

  return totalAmount;
}

export {
  formatDate,
  getLastDayOfCurrentYear,
  formatShortDate,
  getCurrentFormattedDate,
  getCurrentTimeZone,
  getTotalOfType,
  sumAmounts,
};
