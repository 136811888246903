/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useFetcher, useLocation } from 'react-router-dom';
import ExpenseCategoriesTable from './ExpenseCategoriesTable';
import ModalForActions from '../UI/ModalForActions';

function ExpensesCategories() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { pathname } = useLocation();
  useEffect(() => {
    if (
      pathname === '/expenses/categories/new' ||
      pathname?.includes('expenses/categories/update/')
    )
      setShow(true);

    if (pathname === '/expenses/categories') setShow(false);
  }, [pathname]);

  const handleClose = () => {
    navigate('/expenses/categories');
  };

  const handleDelete = (id) => {
    if (fetcher.state === 'submiting') return;
    fetcher.submit(
      {},
      { method: 'post', action: `/expenses/categories/delete/${id}` }
    );
  };

  const handleEdit = (id) => {
    navigate(`/expenses/categories/update/${id}`);
  };

  return (
    <>
      <ModalForActions
        show={show}
        onHide={handleClose}
        title={
          pathname === '/expenses/categories/new'
            ? 'Create new category'
            : 'Update category'
        }
      >
        <Outlet context={[handleClose]} />
      </ModalForActions>
      <ExpenseCategoriesTable
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
    </>
  );
}

export default ExpensesCategories;
