import { Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import React from 'react';
import propTypes from 'prop-types';

import CHECK_DATA_SHAPE from './types';
import FONT_STYLES from '../../Reports/PDF/FontStyles';
import '../../Reports/PDF/FontSetup';

import Main from './Main';
import Body from './Body';

const pageStyles = StyleSheet.create({
  page: {
    ...FONT_STYLES.medium,
    fontFamily: 'Montserrat',
    fontSize: 10,
  },
});

function CheckPDF({ data }) {
  if (!data.length || !data[0].recipient)
    return (
      <Document>
        <Page>
          <View>
            <Text>Error</Text>
          </View>
        </Page>
      </Document>
    );

  return (
    <Document>
      {data.map((check, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Page key={index} style={pageStyles.page} size="LETTER">
          <Main data={check} />
          <Body data={check} />
        </Page>
      ))}
    </Document>
  );
}

CheckPDF.propTypes = {
  data: propTypes.arrayOf(CHECK_DATA_SHAPE),
};

export default CheckPDF;
