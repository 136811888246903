import fetchAPI from '../fetchUtils';

const productsCategoriesAPI = {
  create: async (body) => fetchAPI.postWithAuth('/create-category', body),
  update: async (body) => fetchAPI.postWithAuth('/update-category', body),
  delete: async (categoryId) =>
    fetchAPI.getWithAuth(`/delete-category/${categoryId}`),
  get: async () => fetchAPI.getWithAuth('/get-categories'),
};

export default productsCategoriesAPI;
