import React, { useEffect, useState } from 'react';
import proptypes from 'prop-types';
import SelectWithDefault from './SelectWithDefault';
import useFetcherOnce from '../../Invoices/useFetcherOnce';

function SelectByCategory({ handleChangeSelect, inputName, isInvalid, value }) {
  const [options, setOptions] = useState([]);
  const fetcher = useFetcherOnce('/products/categories');
  const categories = fetcher?.data?.categories;

  useEffect(() => {
    if (!categories) return;

    const nextOptions =
      categories &&
      categories?.map((item) => ({
        value: item.family_id,
        label: `${item.family_name} (${
          item.total_products ? item.total_products : 'No available'
        } items)`,
        isDisabled: item.total_products === 0,
      }));
    setOptions(nextOptions);
  }, [categories]);

  const returnSelectedCategory = (selectedValue) => {
    const selected =
      categories?.find(
        (category) => category.family_id === selectedValue?.value
      ) || {};
    return {
      event: selectedValue?.value,
      find: selected,
    };
  };
  if (fetcher?.state === 'loading') return <p>Loading...</p>;
  if (fetcher?.data?.message !== 'success')
    return <p>Data not available, try again later.</p>;
  return (
    <SelectWithDefault
      handleChange={(e) => handleChangeSelect(returnSelectedCategory(e))}
      isInvalid={isInvalid}
      name={inputName}
      options={options}
      value={value}
    />
  );
}

SelectByCategory.propTypes = {
  value: proptypes.oneOfType([proptypes.string, proptypes.number]),
  handleChangeSelect: proptypes.func.isRequired,
  isInvalid: proptypes.bool,
  inputName: proptypes.string.isRequired,
};

export default SelectByCategory;
