/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFetcher, useParams } from 'react-router-dom';
import GenericForm from '../Forms/builders/GenericForm';
import ActionErrorInformation from '../../API/ActionErrorInformation';

function VendorNotes({ initialValue = '' }) {
  const fields = [
    {
      name: 'notes',
      label: 'Vendor notes',
      initialValue,
      type: Yup.string(),
      formType: 'textarea',
      required: false,
    },
  ];

  const fetcher = useFetcher();
  const { vendorId } = useParams();

  const submitAction = (values) => {
    fetcher.submit(values, {
      method: 'post',
      action: `/vendors/${vendorId}/updateNotes`,
    });
  };

  return (
    <>
      <ActionErrorInformation />
      <GenericForm
        fields={fields}
        submitAction={submitAction}
        formId="createVendorNotesForm"
        navigation={fetcher.state !== 'idle'}
        submitLabel="Send"
      />
    </>
  );
}

VendorNotes.propTypes = {
  initialValue: PropTypes.string,
};

VendorNotes.defaultProps = {
  initialValue: '',
};

export default VendorNotes;
