/* eslint-disable react/prop-types */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PDFBoilerplate from '../../PDF/PDFBoilerplate';
import PDFTable from '../../PDF/Table/PDFTable';
import FONT_STYLES from '../../PDF/FontStyles';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';
import PDFError from '../../PDF/PDFError';

const styles = StyleSheet.create({
  ...FONT_STYLES,
  totalsHeaderText: {
    fontSize: 11,
  },
  totalBodyText: {
    fontSize: 8,
  },
  totalsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 12,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 8,
    gap: 4,
  },
});

function SalesByProductPDF({ pdfData, userData, dateFrom, dateTo }) {
  if (!pdfData || !userData) return <PDFError />;

  const { tableData, tableSetup, totals } = pdfData;

  const sizeArray = ['40%', '20%', '20%', '20%'];

  return (
    <PDFBoilerplate
      title="Sales by Product"
      dateFrom={dateFrom}
      dateTo={dateTo}
      logoBase64={userData.user.company_logo_base64}
      companyName={userData.user.user_company}
    >
      <PDFTable
        sizeArray={sizeArray}
        tableData={tableData}
        tableSetup={tableSetup}
      />
      <View style={styles.totalsContainer} wrap={false}>
        <View style={styles.col}>
          <Text style={styles.totalsHeaderText}>Totals</Text>
          <Text style={styles.totalBodyText}>Subtotal:</Text>
          <Text style={styles.totalBodyText}>Tax Total:</Text>
          <Text style={styles.totalBodyText}>Total:</Text>
        </View>
        <View style={{ ...styles.col, marginLeft: 12 }}>
          <Text style={styles.totalsHeaderText} />
          <Text style={styles.totalBodyText}>$</Text>
          <Text style={styles.totalBodyText}>$</Text>
          <Text style={styles.totalBodyText}>$</Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.totalBodyText}>
            {formatCurrency(totals.subtotal, 2, false)}
          </Text>
          <Text style={styles.totalBodyText}>
            {formatCurrency(totals.taxTotal, 2, false)}
          </Text>
          <Text style={styles.totalBodyText}>
            <Text style={styles.bold}>
              {formatCurrency(totals.total, 2, false)}
            </Text>
          </Text>
        </View>
      </View>
    </PDFBoilerplate>
  );
}

export default SalesByProductPDF;
