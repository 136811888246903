import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import ReportsSearcher from '../../ReportsSearcher/ReportsSearcher';
import {
  formatShortDate,
  getCurrentFormattedDate,
  getCurrentTimeZone,
} from '../utils/helpers';
import SalesPDF from '../../../PDF/ReportsPDFGenerator/SalesPDF/SalesPDF';
import fetchAPI from '../../../../API/fetchUtils';
// import { fetchData, postWithToken } from '../../../API/fetchUtils';
// import { BASE_URL } from '../../../API/constants';

function CSVFiles() {
  // CURRENTLY DEPRECATED / MAYBE DELETED

  const { customers } = useLoaderData();
  const [fetchedData, setFetchedData] = useState(false);
  const [data, setData] = useState({});
  const [selectOption, setSelectOption] = useState('none');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [enablePrintDownload, setEnablePrintDownload] = useState(false);
  const [userCompany, setUserCompany] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (toDate && fromDate !== '') {
      setEnablePrintDownload(true);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (selectOption !== 'none') {
      setFetchedData(false);
    }
  }, [selectOption, fromDate, toDate]);

  const customersArray = customers.map((item) => ({
    id: item.id,
    title: item.name,
  }));

  const handleRunReport = async () => {
    setLoading(true);
    const body = {
      customer: selectOption,
      from: fromDate,
      to: toDate,
    };
    const response = await fetchAPI.postWithAuth(
      `/report/customer-sales`,
      JSON.stringify(body),
      'application/json'
    );
    setData(response);
    setFetchedData(true);
    setLoading(false);
  };

  const getUserCompany = async () => {
    const { user } = await fetchAPI.getWithAuth(`/get-user`);
    setUserCompany(user.user_company);
  };

  useEffect(() => {
    getUserCompany();
  }, []);

  const pdfFile = (
    <SalesPDF
      fetchedData={data}
      salesCustomer
      title="Sales by Customer Report"
      from={formatShortDate(fromDate)}
      to={formatShortDate(toDate)}
      currentDate={getCurrentFormattedDate()}
      timezone={getCurrentTimeZone()}
      userCompany={userCompany}
    />
  );

  return (
    <div>
      <ReportsSearcher
        pdfFileName="sales-by-customer-report"
        options={customersArray}
        setSelectOption={setSelectOption}
        selectOption={selectOption}
        setFromDate={setFromDate}
        fromDate={fromDate}
        toDate={toDate}
        setToDate={setToDate}
        pdfChildren={pdfFile}
        enablePrintDownload={enablePrintDownload}
        handleRunReport={enablePrintDownload ? handleRunReport : () => {}}
        loading={loading}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 40,
        }}
      >
        {fetchedData && (
          <PDFViewer height={705} width={500} showToolbar={false}>
            {pdfFile}
          </PDFViewer>
        )}
      </div>
    </div>
  );
}

export default CSVFiles;
