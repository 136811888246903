const statusCodes = {
  invoice: {
    1: {
      label: 'Unpaid',
      color: 'red',
    },
    2: {
      label: 'Partial P',
      color: 'orange',
    },
    3: {
      label: 'Paid',
      color: 'green',
    },
  },
  estimate: {
    1: {
      label: 'Active',
      color: 'green',
    },
    2: {
      label: 'Declined',
      color: 'orange',
    },
    3: {
      label: 'Accepted',
      color: 'indigo',
    },
    4: {
      label: 'Converted',
      color: 'blue',
    },
    5: {
      label: 'Expired',
      color: 'red',
    },
  },
  appointment: {
    pending: {
      label: 'Pending',
      color: 'yellow',
    },
    completed: {
      label: 'Completed',
      color: 'green',
    },
    overdue: {
      label: 'Overdue',
      color: 'red',
    },
  },
  message: {
    message1: {
      color: 'grey',
      label: 'Not Sent',
    },
    message2: {
      color: 'orange',
      label: 'Sent',
    },
    message3: {
      color: 'green',
      label: 'Viewed',
    },
  },
  WIP: {
    1: {
      label: 'WIP - data base',
      color: 'yellow',
    },
  },
  check: {
    0: {
      label: 'Not Printed',
      color: 'red',
    },
    1: {
      label: 'Tray',
      color: 'blue',
    },
    2: {
      label: 'Printed',
      color: 'green',
    },
    3: {
      label: 'Void',
      color: 'indigo',
    },
  },
};

export default statusCodes;
