import React from 'react';

function ContactFormSuccess() {
  return (
    <div className="text-center">
      <h1 className="mb-3">Success!</h1>
      <p>Customer created successfully, thanks for your time!</p>
    </div>
  );
}

export default ContactFormSuccess;
