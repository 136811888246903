/* eslint-disable react/prop-types */
import React from 'react';
import './ImageButton.scss';

function ImageButton({ children, onClick }) {
  return (
    <button type="button" className="image-button" onClick={onClick}>
      {children}
    </button>
  );
}

export default ImageButton;
