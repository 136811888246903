import { redirect } from 'react-router-dom';
import { handleResposeAbstraction } from '../../API/fetchUtils';
import customersAPI from '../../API/routesAPI/customersAPI';
import productsAPI from '../../API/routesAPI/productsAPI';
import settingsAPI from '../../API/routesAPI/settings';

export const toggleTutorialAction = async () => {
  await settingsAPI.toggleTutorial();
  return redirect('/');
};

export const createCustomerAction = async ({ request }) => {
  const body = await request.formData();
  const response = await customersAPI.create(body);
  const result = await response.json();

  return handleResposeAbstraction({
    response,
    result,
    redirectTo: `../prod-and-serv?customerId=${result?.customer?.client_id}`,
  });
};

export const getProductsLoader = async () => {
  const response = await productsAPI.getAll();

  const result = await response.json();
  return handleResposeAbstraction({
    response,
    result,
  });
};

export const createProductAction = async ({ request }) => {
  const body = await request.json();
  const response = await productsAPI.create(JSON.stringify(body));
  const result = await response.json();

  return handleResposeAbstraction({
    response,
    result,
    redirectTo: `../finish?productId=${result?.id}`,
  });
};
