/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useLoaderData, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import ResetMessage from './ResetMessage';
import FormWrapper from '../../Forms/builders/FormWrapper';
import CreateForm from '../../Forms/CreateForm';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import resetMessageUtil from './resetMessageUtil';

function MessageSettingsReviews() {
  const loaderData = useLoaderData();

  const fields = [
    {
      formGroup: 'Reviews default messages',
      groupChildren: [
        {
          name: 'google_review_page_url',
          label: 'Review Page URL (Google, Angi, etc.)',
          initialValue: loaderData?.data?.google_review_page_url || '',
          type: Yup.string().matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url format: https://www.example.com'
          ),
          className: 'input-wide',
        },
        {
          name: 'review_message',
          label: 'Review request message',
          initialValue: loaderData?.data?.review_message || '',
          type: Yup.string().required(),
          formType: 'textarea',
          className: 'input-wide',
          maxLength: 800,
          labelAction: (
            <ResetMessage
              handleClick={() =>
                resetMessageUtil(
                  'review_message',
                  loaderData?.data?.review_message_default_text,
                  formik
                )
              }
            >
              Reset
            </ResetMessage>
          ),
        },
      ],
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post' });
  };
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
  });

  return (
    <FormWrapper
      id="review-form-settings"
      handleSubmit={formik.handleSubmit}
      isSubmitting={formik.isSubmitting}
      dirty={formik.dirty}
      isValid={formik.isValid}
      label="Save"
    >
      <CreateForm structure={fields} {...formik} />
    </FormWrapper>
  );
}

export default MessageSettingsReviews;
