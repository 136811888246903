import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import FieldData from './text/FieldData';
import chevronRight from '../../img/chevron-right-solid.svg';

function ModalNavigation({ label, to }) {
  return (
    <section>
      <NavLink
        className="d-flex justify-content-between align-items-center section--link"
        to={to}
      >
        <FieldData variant="lb-modal__data--mid">{label}</FieldData>
        <img style={{ height: '23px' }} src={chevronRight} alt="" />
      </NavLink>
    </section>
  );
}

ModalNavigation.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default ModalNavigation;
