import fetchAPI from '../fetchUtils';

const messagesAPI = {
  sendMessage: async (body) =>
    fetchAPI.postWithAuth('/send-message-to-client', body),
  history: async ({ documentId, type, module }) =>
    fetchAPI.getWithAuth(`/message-list/${type}/${module}/${documentId}`),
  uploadFile: async (body) => fetchAPI.postWithAuth('/upload-file', body),
};

export default messagesAPI;
