import React from 'react';
import { Button, ModalTitle } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function SendSuccess() {
  return (
    <div>
      <ModalTitle className="mb-4">Success sent!</ModalTitle>

      <Link to=".." className=" mt-3 ">
        <Button variant="primary" className="btn btn-primary">
          Send new
        </Button>
      </Link>
    </div>
  );
}

export default SendSuccess;
