import fetchAPI from '../fetchUtils';

const customersAPI = {
  getAll: async () => fetchAPI.getWithAuth('/get-customers/active'),
  delete: async (id) => fetchAPI.getWithAuth(`/delete-customer/${id}`),
  create: async (body) => fetchAPI.postWithAuth('/create-customer', body),
  update: async (body) => fetchAPI.postWithAuth('/update-customer', body),
  get: async (body) => fetchAPI.postWithAuth('/view-customer', body),
  updateNotes: async (body) =>
    fetchAPI.postWithAuth('/update-customer-note', body),
  getCustomerActivity: async (body) =>
    fetchAPI.postWithAuth('/customer-balance-activity', body),
  importCsv: async (body) =>
    fetchAPI.postWithAuth('/import-customer-csv', body),
  exportCsv: async () => fetchAPI.getWithAuth('/export-customers'),
};

export default customersAPI;
