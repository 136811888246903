/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link, useLoaderData, useSubmit } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Button } from 'react-bootstrap';
import { number, string } from 'yup';
import LBOffCanvas from '../../UI/LBOffCanvas';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import CreateForm from '../../Forms/CreateForm';
import FormWrapper from '../../Forms/builders/FormWrapper';
import { testEndGreaterThanStart } from './MileagesPage';

function CreateVehicleModal() {
  const { data: vehicle } = useLoaderData() || {};
  const { id, brand, model, productionYear, licensePlate } = vehicle || {};

  const vehicleFields = {
    formGroup: 'Vehicle Information',
    groupChildren: [
      {
        name: 'brand',
        label: 'Brand',
        initialValue: brand || '',
        type: string().required(),
        required: true,
        className: 'flex__span-12',
      },
      {
        name: 'model',
        label: 'Model',
        initialValue: model || '',
        type: string().required(),
        required: true,
        className: 'flex__span-12',
      },
      {
        name: 'productionYear',
        label: 'Production Year',
        inputType: 'numeric',
        initialValue: productionYear || '',
        type: number().typeError('Enter a number please'),
        className: 'flex__span-12',
      },
      {
        name: 'licensePlate',
        label: 'License Plate',
        initialValue: licensePlate || '',
        type: string(),
        className: 'flex__span-12',
      },
    ],
  };

  const mileageFields = {
    formGroup: 'Current Year Mileage',
    groupChildren: [
      {
        name: 'current_start',
        label: 'Start Mileage',
        initialValue: '',
        inputType: 'numeric',
        type: !vehicle
          ? number().typeError('Enter a number please').required()
          : number().typeError('Enter a number please'),
        required: !vehicle,
        formType: 'inputGroup',
        className: 'flex__span-12',
        config: {
          rightLabel: 'mi',
        },
      },
      {
        name: 'current_end',
        label: 'End Mileage',
        initialValue: '',
        inputType: 'numeric',
        type: number()
          .typeError('Enter a number please')
          .test(testEndGreaterThanStart('current_start')),
        formType: 'inputGroup',
        className: 'flex__span-12',
        config: {
          rightLabel: 'mi',
        },
      },
    ],
  };

  const submit = useSubmit();
  const submitAction = (values) => {
    let nextValues;

    // If we are in UPDATE route
    if (vehicle) {
      nextValues = {
        // We insert the id
        id,
        ...values,
      };
    } else {
      nextValues = { ...values };
    }

    submit(nextValues, {
      method: 'post',
      encType: 'application/json',
    });
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: [vehicleFields, mileageFields],
    yupValues: [vehicleFields, mileageFields],
    submitAction,
  });

  return (
    <>
      <LBOffCanvas.Header>
        {vehicle ? 'Edit' : 'Create'} Vehicle
      </LBOffCanvas.Header>
      <FormWrapper
        id="vehicleForm"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        label="Save Vehicle"
        dirty={formik.dirty}
        isValid={formik.isValid}
        portalId="offcanvas-action-group-right"
        className="flex__form-wrapper"
      >
        <CreateForm structure={[vehicleFields]} {...formik} />
        {!vehicle && <CreateForm structure={[mileageFields]} {...formik} />}
      </FormWrapper>

      {document.getElementById('offcanvas-action-group-right') &&
        createPortal(
          <Button as={Link} to=".." variant="secondary">
            Cancel
          </Button>,
          document.getElementById('offcanvas-action-group-right')
        )}
    </>
  );
}

export default CreateVehicleModal;
