/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetcherOnce from '../../Invoices/useFetcherOnce';
import SelectWithDefault from './SelectWithDefault';
import RecipientsPopover from './RecipientsPopover';

const validateData = (data, type) => {
  if (!data?.name || (!data?.lastname && type !== 'merchants')) return false;
  if (
    !data?.address?.street_address_1 ||
    !data?.address?.city ||
    !data?.address?.state ||
    !data?.address?.country
  )
    return false;
  return true;
};

export default function SelectRecipient({
  isInvalid,
  value,
  name,
  handleChange,
}) {
  const recipients = useFetcherOnce('/checks/recipients');
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!recipients?.data?.data) return;

    const nextOptions = [];
    Object.keys(recipients.data.data).forEach((key) => {
      const option = {
        label: key,
        options: recipients.data.data[key].map((item) => ({
          value: `${key}_${item.id}`,
          label: `${item.name} ${item.lastname ? item.lastname : ''}`,
          invalid: !validateData(item, key),
        })),
      };
      nextOptions.push(option);
    });
    setOptions(nextOptions);
  }, [recipients]);

  const [show, setShow] = useState(false);

  const clear = () => handleChange({ event: '', find: null });
  const onChange = (e) => {
    if (!e?.value) return clear();
    const nextValue = e.value;
    const [key, id] = nextValue.split('_');
    const nextSelected = recipients.data.data[key].find(
      (item) => item.id === +id
    );
    if (nextSelected) {
      handleChange({ event: nextValue, find: nextSelected });
      if (!validateData(nextSelected, key) && nextValue)
        if (nextValue)
          navigate(`./${key}-complete`, { state: { type: key, id } });
    }
    return null;
  };

  if (recipients.state === 'loading') return <p>Loading...</p>;
  if (recipients?.data?.message !== 'success')
    return <p>Data not available, try again later.</p>;

  return (
    <>
      {show && (
        <RecipientsPopover
          show={show}
          setShow={setShow}
          value={value}
          clear={clear}
        />
      )}
      <SelectWithDefault
        name={name}
        options={options}
        value={value}
        isInvalid={isInvalid}
        handleChange={(e) => {
          onChange(e);
        }}
      />
    </>
  );
}
