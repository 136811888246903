/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import ActionButton from '../../buttons/ActionButton';
import Td from './Td';

export default function Row({
  row,
  index,
  onChange,
  onDeleteItem,
  onClick,
  errors,
}) {
  const handleClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(id);
  };
  return (
    <tr
      onClick={(e) => handleClick(e, row.original.id)}
      className="lb_table-row"
    >
      {row.getVisibleCells()?.map((cell) => {
        return (
          <Td
            key={cell.column.id}
            column={cell.column.columnDef}
            newItem={cell.getValue()}
            setNewItem={(value) =>
              onChange({ [cell.column.columnDef.accessorKey]: value })
            }
          />
        );
      })}
      <td
        aria-label="edit"
        className="mobile__actions"
        style={{ gridArea: 'actions' }}
      >
        <ActionButton action="delete" handler={() => onDeleteItem(index)} />
      </td>
      {errors && (
        <td colSpan={4} className="pt-0 text-danger">
          {Object.keys(errors).map((key) => (
            <div key={key}>{errors[key]}</div>
          ))}
        </td>
      )}
    </tr>
  );
}
