import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 9,
    fontWeight: 300,
    gap: 16,
    width: '100%',
    paddingVertical: 12,
    alignItems: 'baseline',
  },
  paymentInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  checkbox: {
    height: 8,
    width: 8,
    border: '1px solid gray',
    borderRadius: 3,
  },
  checkLine: {
    width: 80,
    borderBottom: '1px solid gray',
    alignSelf: 'flex-end',
  },
});

function PaymentInfo() {
  return (
    <View style={s.container}>
      <Text style={{ fontWeight: 600, transform: 'translateY(1px)' }}>
        Payment Information
      </Text>
      <View style={s.paymentInfoRow}>
        <View style={s.checkbox} />
        <Text>Credit Card</Text>
      </View>
      <View style={s.paymentInfoRow}>
        <View style={s.checkbox} />
        <Text>Cash</Text>
      </View>
      <View style={s.paymentInfoRow}>
        <View style={s.checkbox} />
        <Text>Check No.</Text>
        <View style={s.checkLine} />
      </View>
    </View>
  );
}

export default PaymentInfo;
