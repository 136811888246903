import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextTitle from '../components/UI/text/TextTitle';
import { login } from '../routes/paths';

function AuthErrorPage() {
  //   const error = useRouteError();
  return (
    <>
      <TextTitle>
        <h1>Oops!</h1>
        <p className="mt-2">Sorry, an unexpected error has occurred.</p>
      </TextTitle>
      <Link to={login}>
        <Button type="primary" className="w-100">
          Go to login
        </Button>
      </Link>
    </>
  );
}

export default AuthErrorPage;
