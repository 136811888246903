import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  useActionData,
  useOutletContext,
  useRouteLoaderData,
} from 'react-router-dom';
import { EXPENSES_TOGGLE } from '../../constants/toggleOptions';

import ReactTablev8 from '../../../UI/Table/ReactTablev8';
import ReportHeader from '../../ReportHeader';
import SalesByCustomerPDF from './ExpensesByMerchantPDF';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';
import ReportFooter from '../../ReportFooter';

function ExpensesByMerchant() {
  const actionData = useActionData();
  const rootLoader = useRouteLoaderData('root');
  const { dateTo, dateFrom } = useOutletContext();

  if (actionData?.message !== 'success' || !actionData)
    return <p className="card p-4 mt-4 border-0">Error generating report</p>;

  const tableData = actionData?.data.map((item) => ({
    name: item.name,
    amount: item.amount,
    id: item.merhcant_id,
  }));

  const tableSetup = [
    {
      header: 'Merchant Name',
      accessorKey: 'name',
      enableSorting: false,
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      type: 'money',
      enableSorting: false,
      mobileLabel: true,
    },
  ];

  const tableOptions = {
    search: false,
    pagination: {
      show: false,
    },
    sorting: false,
  };

  const total = tableData.reduce((acc, entry) => {
    return acc + entry.amount;
  }, 0);

  return (
    <>
      <ReportHeader
        reportSection={EXPENSES_TOGGLE}
        pdfDocument={
          <SalesByCustomerPDF
            pdfData={{ tableData, tableSetup, total }}
            userData={rootLoader}
            dateTo={dateTo}
            dateFrom={dateFrom}
          />
        }
      />
      <div className="container-fluid">
        {tableData.length === 0 ? (
          <Row>
            <Col className="alert alert-secondary" role="alert">
              No sales registered for this date range/category
            </Col>
          </Row>
        ) : (
          <div className="reports__display-react-table">
            <ReactTablev8
              dataTable={tableData}
              tableLayout={tableSetup}
              tableOptions={tableOptions}
              mobileClass="reports_expenses_merchant"
            />
            <div className="reports__display-totals">
              <div
                className="p-3"
                style={{
                  width: '100%',
                  fontSize: '0.9rem',
                  fontWeight: 600,
                  textAlign: 'right',
                }}
              >
                Total: $ {formatCurrency(total, 2, false)}
              </div>
            </div>
            <ReportFooter />
          </div>
        )}
      </div>
    </>
  );
}

export default ExpensesByMerchant;
