import React from 'react';
import PropTypes from 'prop-types';
import SelectWithDefault from './SelectWithDefault';
// import getSelectData from './getSelectData';

const formatOptionLabel = ({ brand, model, licensePlate }) => (
  <span
    style={{
      fontSize: '0.8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflow: 'hidden',
      gap: 5,
    }}
  >
    <span>
      <strong>{brand}</strong> | {model}
      {/* {productionYear && (
        <span
          style={{
            fontSize: '0.72rem',
            fontStyle: 'italic',
            color: 'var(--bs-gray-500)',
            marginLeft: 3,
          }}
        >
          ({productionYear})
        </span>
      )} */}
    </span>

    {licensePlate && (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          fontSize: '0.65rem',
          fontWeight: 500,
          padding: '3px 6px',
          borderRadius: '4px',
          color: 'currentcolor',
          border: '1px solid currentcolor',
        }}
      >
        {licensePlate}
      </span>
    )}
  </span>
);

const getOption = ({ brand, model, productionYear, licensePlate }) =>
  `${brand} ${model} ${productionYear} ${licensePlate}`;

function SelectVehicle({
  handleChange,
  isInvalid,
  name,
  setFieldValue,
  value,
  options,
}) {
  // const options = getSelectData('/settings/assets');
  const formattedOptions = options.map((option) => ({
    ...option,
    value: option.id,
  }));

  return (
    <SelectWithDefault
      name={name}
      options={formattedOptions}
      value={value}
      isInvalid={isInvalid}
      handleChange={(e) => {
        if (e) e.value = e.id;
        return handleChange(e);
      }}
      setFieldValue={setFieldValue}
      getOptionLabel={(option) => getOption(option)}
      formatOptionLabel={formatOptionLabel}
    />
  );
}

SelectVehicle.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInvalid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
};

export default SelectVehicle;
