import expensesCategoriesAPI from '../../API/routesAPI/expensesCategoriesRoutes';
import { handleResposeAbstraction } from '../../API/fetchUtils';

export async function expensesCategoriesLoader() {
  const response = await expensesCategoriesAPI.getAll();
  return handleResposeAbstraction({
    response,
  });
}

export async function createExpensesCategoryAction({ request }) {
  const body = await request.formData();
  const response = await expensesCategoriesAPI.create(body);
  const redirectTo = '/expenses/categories';
  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
}

export async function updateExpensesCategoryAction({ request }) {
  const body = await request.formData();
  const response = await expensesCategoriesAPI.update(body);
  const redirectTo = '/expenses/categories';
  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
}

export async function deleteExpensesCategoryAction({ params }) {
  const { expenseCategoryId } = params;
  const response = await expensesCategoriesAPI.delete(expenseCategoryId);
  return handleResposeAbstraction({
    response,
    redirectTo: '/expenses/categories',
    showSuccess: true,
  });
}

export async function createExpenseCategoryUtilityAction({ request }) {
  const body = await request.formData();
  const response = await expensesCategoriesAPI.create(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
  });
}
