/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import LBInputGroup from '../Forms/LBInputGroup/LBInputGroup';
import CreateFormLabel from '../Forms/CreateForm/CreateFormLabel';
import CreateToggleGroup from '../Forms/ToggleGroup/ToggleGroup';
import UnitInput from '../Forms/UnitInput/UnitInput';
import LBFormControl from '../Forms/CreateForm/LBFormControl';
import formatCurrency from '../Dashboard/Widgets/utils/formatCurrency';

function findField(structure, fieldName) {
  return structure.groupChildren.find((field) => field.name === fieldName);
}

function VanillaField({ field, formik }) {
  if (!formik) return <p>Loading</p>;
  const { touched, values, errors, handleBlur, handleChange } = formik;
  return (
    <CreateFormLabel
      errors={errors[field.name]}
      inputName={field.name}
      key={field?.name}
      label={field.label}
      labelAction={field?.labelAction}
      required={field?.required}
    >
      {field?.formType === 'inputGroup' && (
        <LBInputGroup
          element={field}
          values={values}
          touched={touched}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      )}
      {field?.formType === 'unit' && (
        <UnitInput
          element={field}
          values={values}
          touched={touched}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={formik.setFieldValue}
        />
      )}
      {!field?.formType && (
        <LBFormControl
          element={field}
          values={values}
          touched={touched}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      )}
    </CreateFormLabel>
  );
}

function ExtraFields({ structure, profitType, narrow, ...formik }) {
  return (
    <div
      className={`${narrow ? 'container-fluid gx-0 mx-0 mb-3 mt-3 rounded' : 'py-3 px-4'}`}
      style={{ backgroundColor: 'var(--bs-white)' }}
    >
      <div className="container-fluid gx-0 mb-3 py-2">
        <div className="row gy-2">
          {!narrow && (
            <div className="col-md-6">
              <h5>Budget Information</h5>
            </div>
          )}

          <div
            className={`d-flex align-items-start align-items-md-center justify-content-start gap-2 ${narrow ? 'col-12 flex-column flex-sm-row' : 'flex-column col-md-6 flex-md-row justify-content-md-end '}`}
          >
            <small style={{ whiteSpace: 'nowrap' }}>Calculate profit by:</small>
            <CreateToggleGroup
              element={findField(structure, 'product_budget_type')}
              setFieldValue={formik.setFieldValue}
              value={
                formik.values[findField(structure, 'product_budget_type').name]
              }
            />
          </div>
        </div>
      </div>

      <div className="row gx-4">
        <div className={`${narrow ? 'col-sm-6' : 'col-md-4 mt-md-4'}`}>
          <VanillaField
            formik={formik}
            field={findField(structure, 'amount_cost')}
          />
        </div>
        {profitType === 'net_profit' && (
          <div className={`${narrow ? 'col-sm-6' : 'col-md-4 mt-md-4'}`}>
            <VanillaField
              formik={formik}
              field={findField(structure, 'amount_profit_net')}
            />
          </div>
        )}
        {profitType === 'profit_rate' && (
          <div className={`${narrow ? 'col-sm-6' : 'col-md-4 mt-md-4'}`}>
            <VanillaField
              formik={formik}
              field={findField(structure, 'amount_profit_rate')}
            />
          </div>
        )}
        <div className={`${narrow ? 'col-12' : 'col-md-4'}`}>
          <div className="w-100 h-100 p-4 rounded ps__price-insert">
            {formik.values.amount_price_before_tax !== '0' &&
            formik.values.amount_price_before_tax !== 0 ? (
              <div className="d-flex w-100 h-100 justify-content-start align-items-center">
                <div style={{ alignSelf: 'auto' }}>
                  <small style={{ whiteSpace: 'nowrap' }}>
                    Final{' '}
                    {formik.values.item_type === 'Service'
                      ? 'rate'
                      : 'Price (No Tax)'}
                  </small>
                  <h4>
                    <span>$</span>
                    {formatCurrency(
                      Number(formik.values.amount_price_before_tax),
                      2,
                      false
                    )}
                  </h4>

                  {profitType === 'net_profit' && (
                    <small className="ps__price-insert__secondary">
                      Profit Rate:{' '}
                      {Number(formik.values.amount_profit_rate).toFixed(2)}
                      <span>%</span>
                    </small>
                  )}
                  {profitType === 'profit_rate' && (
                    <small className="ps__price-insert__secondary">
                      Net Profit: <span>$</span>
                      {Number(formik.values.amount_profit_net).toFixed(2)}
                    </small>
                  )}
                </div>
              </div>
            ) : (
              <p
                className="d-flex w-100 h-100 align-items-center justify-content-center text-center"
                style={{ fontSize: '0.9rem', color: 'var(--bs-gray-700)' }}
              >
                To calculate the price, add the desired net or % profit and the
                materials/cost.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtraFields;
