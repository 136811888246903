/* eslint-disable no-use-before-define */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { createPortal } from 'react-dom';
import { Link, useSearchParams, useSubmit } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
  set,
  add,
  differenceInMinutes,
  areIntervalsOverlapping,
  isValid,
} from 'date-fns';
import { date, string } from 'yup';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import CreateForm from '../Forms/CreateForm/CreateForm';
import FormWrapper from '../Forms/builders/FormWrapper';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import useAppointmentContext from './Context/useAppointmentContext';
import { findAppointmentsByDate, toLegacyDateString } from './utils/helpers';
import LBOffCanvas from '../UI/LBOffCanvas';
import useCustomerFormData from './utils/useCustomerFormData';
import { setCurrentDay, setFormikValues } from './utils/setCurrentDay';
import checkIfPastDay from './utils/customValidations';
import { textInput, textarea } from '../Forms/yup/customValidations';
// import useCurrentDay from './utils/useCurrentDay';

function NewAppointmentModal() {
  const {
    currentDate,
    updateCurrentDay,
    appointments,
    customerNames,
    customers,
  } = useAppointmentContext();
  const [searchParams] = useSearchParams();
  const initialHours = searchParams.get('startHours');
  const initialMinutes = searchParams.get('startMinutes');

  // Get the current date's appointments (this could be moved to
  // the context, I've been using it in several places...)
  const isOverlapping = React.useCallback(
    (appStart, appEnd) => {
      if (!isValid(appStart) || !isValid(appEnd)) return true;
      if (appStart > appEnd) return true;
      const todaysAppointments = findAppointmentsByDate(
        currentDate,
        appointments
      );
      return !todaysAppointments.some((app) => {
        return areIntervalsOverlapping(
          {
            start: new Date(app.start_date),
            end: new Date(app.end_date),
          },
          { start: appStart, end: appEnd }
        );
      });
    },
    [currentDate]
  );

  // We set the date to the current day with the current time
  // plus 5 minutes (to give some margin while the user creates the
  // appointment)
  const initialDate = React.useMemo(() => {
    if (initialHours && initialMinutes) {
      const res = set(currentDate, {
        hours: initialHours,
        minutes: initialMinutes,
        seconds: 0,
        milliseconds: 0,
      });
      return res;
    }

    return set(currentDate, {
      hours: new Date().getHours(),
      minutes: new Date().getMinutes() + 5,
      seconds: 0,
      milliseconds: 0,
    });
  }, [currentDate]);

  // We determine what's the max date for that day
  const maxDate = React.useMemo(() => {
    return set(currentDate, {
      hours: 23,
      minutes: 59,
    });
  }, [currentDate]);

  // We add the minimum duration to get the end date inital value
  const addThirty = React.useMemo(() => {
    const newDate = add(initialDate, { minutes: 30 });
    return newDate >= maxDate ? maxDate : newDate;
  }, [currentDate]);

  const fields = [
    {
      name: 'name',
      formType: 'text',
      label: 'Appointment name',
      initialValue: '',
      className: 'input-wide',
      type: textInput.required('Please complete the field'),
      required: true,
    },
    {
      name: 'current_day',
      formType: 'date',
      label: 'Appointment Day',
      // This weird string split is needed to use the vanilla date input
      // we should -probably- use a better date picker component
      // in the future using the react-calendar library as a base
      change: (e) => {
        const nextDate = setCurrentDay({
          initialStartDate: formik.values.start_date,
          initialEndDate: formik.values.end_date,
          dateValue: e.target.value,
        });

        setFormikValues({
          updateCurrentDay,
          formik,
          nextDate,
        });
      },
      initialValue: toLegacyDateString(currentDate),
      type: string()
        .required()
        .test({
          name: 'checkIfPastDay',
          exclusive: false,
          params: {},
          message: "Can't create appointments in the past",
          test(value) {
            return checkIfPastDay(value);
          },
        }),
      required: true,
    },
    {
      name: 'start_date',
      formType: 'time',
      label: 'Start time',
      initialValue: initialDate,
      className: 'input-wide',
      type: date()
        .min(new Date(), "Can't create apppointments in the past")
        .test({
          name: 'isWithinInterval',
          exclusive: false,
          params: {},
          message: 'Appointment overlapping',
          test(value) {
            return isOverlapping(new Date(value), this.parent.end_date);
          },
        })
        .required('Please complete the field'),
      required: true,
    },
    {
      name: 'end_date',
      formType: 'time',
      label: 'End time',
      initialValue: addThirty,
      className: 'input-wide',
      type: date()
        .test({
          name: 'greaterThanStart',
          exclusive: false,
          params: {},
          message: 'End date must be greater than start date',
          test(value) {
            return value > this.parent.start_date;
          },
        })
        .test({
          name: 'isWithinInterval',
          exclusive: false,
          params: {},
          message: 'Appointment overlapping',
          test(value) {
            const overlap = isOverlapping(
              this.parent.start_date,
              new Date(value)
            );
            return overlap;
          },
        })
        .test({
          name: 'minimumDuration',
          exclusive: false,
          params: {},
          message: 'Minimum duration is 30 minutes',
          test() {
            const delta = differenceInMinutes(
              new Date(this.parent.end_date),
              new Date(this.parent.start_date)
            );
            return delta >= 30;
          },
        })
        .max(maxDate, ({ max }) => {
          return `End date can't be higher than ${max.toLocaleString('en-US')}`;
        })
        .required('Please complete the field'),
      required: true,
    },
    {
      name: 'notes',
      formType: 'textarea',
      label: 'Notes',
      initialValue: '',
      className: 'input-wide',
      type: textarea,
      maxLength: 500,
    },
    {
      name: 'performer_name',
      formType: 'text',
      label: 'Performer',
      initialValue: '',
      type: textInput,
      className: 'input-wide',
    },
    {
      name: 'customer_id',
      formType: 'select',
      label: 'Customer',
      initialValue: '',
      options: customerNames,
      type: string(),
      className: 'input-wide',
    },
    {
      name: 'contact_phone',
      formType: 'text',
      label: 'Contact Phone',
      initialValue: '',
      type: string(),
      className: 'new-appointment__disable',
    },
    {
      name: 'contact_name',
      formType: 'text',
      label: 'Contact Name',
      initialValue: '',
      type: string(),
      className: 'new-appointment__disable',
    },
    {
      name: 'appointment_address_street_line_1',
      formType: 'text',
      label: 'Address Line 1',
      initialValue: '',
      type: string(),
      className: 'new-appointment__disable',
    },
    {
      name: 'appointment_address_street_line_2',
      formType: 'text',
      label: 'Address Line 2',
      initialValue: '',
      type: string(),
      className: 'new-appointment__disable',
    },
    {
      name: 'appointment_zipcode',
      formType: 'text',
      label: 'ZIP Code',
      initialValue: '',
      type: string(),
      className: 'new-appointment__disable',
    },
    {
      name: 'appointment_city',
      formType: 'text',
      label: 'City',
      initialValue: '',
      type: string(),
      className: 'new-appointment__disable',
    },
    {
      name: 'appointment_state',
      formType: 'text',
      label: 'State',
      initialValue: '',
      type: string(),
      className: 'new-appointment__disable',
    },
    {
      name: 'appointment_country',
      formType: 'text',
      label: 'Country',
      initialValue: '',
      type: string(),
      className: 'new-appointment__disable',
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, {
      method: 'post',
      action: '../new-extended',
      encType: 'application/json',
    });
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  // Set customer related fields
  useCustomerFormData({ customers, formik });

  return (
    <LBOffCanvas.Wrapper>
      <LBOffCanvas.Left>
        <div>
          <LBOffCanvas.Header>
            <h2 className="new-appointment__header">Add appointment</h2>
            <h3 className="new-appointment__sub-header">
              For{' '}
              {currentDate.toLocaleDateString('en-US', { dateStyle: 'full' })}
            </h3>
          </LBOffCanvas.Header>

          <FormWrapper
            id="new-appointment"
            handleSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
            label="Create"
            portalId="offcanvas-action-group-left"
            dirty={formik.dirty}
            isValid={formik.isValid}
          >
            <CreateForm structure={fields} {...formik} />
            {formik.values.customer_id && (
              <div className="new-appointment__read-only">
                <h4>Address</h4>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                    `${formik.values.appointment_address_street_line_1} ${formik.values.appointment_state} ${formik.values.appointment_city} ${formik.values.appointment_country}` ||
                      ''
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ul>
                    <li>{formik.values.appointment_address_street_line_1},</li>
                    {formik.values.appointment_address_street_line_2 && (
                      <li>
                        {formik.values.appointment_address_street_line_2},
                      </li>
                    )}
                    <li>
                      {formik.values.appointment_city},{' '}
                      {formik.values.appointment_state}{' '}
                      {formik.values.appointment_zipcode}
                    </li>
                  </ul>
                </a>
                <h4>Phone</h4>
                <a href={`tel:${formik.values.contact_phone}`}>
                  {formatPhoneNumberIntl(formik.values.contact_phone)}
                </a>
              </div>
            )}
          </FormWrapper>
          {document.getElementById('offcanvas-action-group-left') &&
            createPortal(
              <Button
                variant="secondary"
                as={Link}
                to="../new-extended"
                state={{ values: formik.values }}
              >
                More options
              </Button>,
              document.getElementById('offcanvas-action-group-left')
            )}
        </div>
      </LBOffCanvas.Left>
    </LBOffCanvas.Wrapper>
  );
}

export default NewAppointmentModal;
