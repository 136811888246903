import React from 'react';
import { Outlet } from 'react-router-dom';
import Scheduler from './Scheduler';
import AppointmentCalendar from './Calendar/AppointmentCalendar';
import AppointmentsActions from './AppointmentsActions';
import LBOffCanvas from '../UI/LBOffCanvas';
import useModal from '../UI/LBOffCanvas/useModal';

function DayView() {
  // We are in /appointments (root)
  const { show, size, handleCloseModal } = useModal('appointments');
  return (
    <>
      <div className="container-fluid">
        <div className="row gx-0 gx-lg-4 gy-4">
          <div className="col-12 col-xl-5 order-xl-2 mb-4 w-lg-auto">
            <div className="appointments__sidebar">
              <AppointmentCalendar />
              <AppointmentsActions />
            </div>
          </div>
          <div className="col">
            <Scheduler />
          </div>
        </div>
      </div>
      <LBOffCanvas show={show} size={size} handleCloseModal={handleCloseModal}>
        <Outlet />
      </LBOffCanvas>
    </>
  );
}

export default DayView;
