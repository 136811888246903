import React from 'react';
import { Provider } from '@radix-ui/react-tooltip';
import { Outlet, useFetcher } from 'react-router-dom';
import GeneralHeader from '../components/NavigationMenu/Header/GeneralHeader';
import Navigation from '../components/NavigationMenu/Navigation/Navigation';
// import PromoBar from '../components/Layout/PromoBar';

// Create new context
export const SelectedColorsContext = React.createContext();

function RootPage() {
  // Colors logic
  const fetcher = useFetcher();

  const [selectedColors, setSelectedColors] = React.useState({
    color1: '',
    color2: '',
    color3: '',
  });

  // call submit or load in a useEffect
  React.useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load('/settings/templates');
    }
    if (fetcher.state === 'idle' && fetcher.data) {
      const colours = fetcher.data.data;
      setSelectedColors((prev) => ({
        ...prev,
        color1: colours?.primary_color_bg,
        color2: colours?.secondary_color_bg,
        color3: colours?.third_color_bg,
      }));
    }
  }, [fetcher]);

  /* eslint-disable react/jsx-no-constructed-context-values */
  const selectedValues = { selectedColors, setSelectedColors };
  // const message =
  //   'Suscription expired. Please renew your subscription to continue using our services.';
  // const url = 'https://leadsboxinvoice.com/subscription/';
  return (
    <SelectedColorsContext.Provider value={selectedValues}>
      <Provider delayDuration={0}>
        <div className="base-layout">
          <Navigation />
          <main className="main-content">
            <GeneralHeader />
            <div className="h-100">
              <Outlet />
            </div>
          </main>
        </div>
        {/* <PromoBar message={message} url={url} /> */}
      </Provider>
    </SelectedColorsContext.Provider>
  );
}

export default RootPage;
