import React from 'react';
import TableWidget from './TableWidget';

function TablePlaceholder() {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 7].map((row) => (
        <TableWidget.Item
          key={row}
          style={{
            animation: 'breathe 0.4s infinite both ease-in-out',
            alignContent: 'space-between',
          }}
        >
          <div
            style={{
              justifySelf: 'start',
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
            }}
          >
            {[140, 180, 120].map((item) => (
              <div
                key={item}
                style={{
                  height: '0.8rem',
                  width: item,
                  borderRadius: '8px',
                  backgroundColor: 'var(--bs-gray-300)',
                }}
              />
            ))}
          </div>
          <div
            style={{
              justifySelf: 'end',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            {[0, 1, 2].map((item) => (
              <div
                key={item}
                style={{
                  height: '2.2rem',
                  width: '2.2rem',
                  borderRadius: '8px',
                  backgroundColor: 'var(--bs-gray-300)',
                }}
              />
            ))}
          </div>
        </TableWidget.Item>
      ))}
    </>
  );
}

export default TablePlaceholder;
