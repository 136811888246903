import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { parseISO } from 'date-fns';
import { I_Colors, I_Data } from '../propTypes';

const s = StyleSheet.create({
  container: {
    width: '100%',
    padding: '20px 40px 20px 40px',
    marginTop: 6,
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 9,
    fontWeight: 300,
    gap: 4,
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 8,
    fontWeight: 500,
  },
  label: {
    fontSize: 8,
    fontWeight: 600,
  },
  name: {
    fontSize: 18,
    fontWeight: 600,
  },
});

function BillingTo({ data, colors }: { data: I_Data; colors: I_Colors }) {
  return (
    <View
      style={{
        ...s.container,
        backgroundColor: colors.color1,
        color: colors.color1text,
      }}
    >
      <View style={{ ...s.col, width: '66%', gap: 8 }}>
        <View style={{ ...s.col, gap: 2 }}>
          <Text>Billing to:</Text>
          <Text style={s.name}>{data.customer.name}</Text>
        </View>
        <View style={{ ...s.col, gap: 2 }}>
          <Text>{data.customer.address.street_address_1}</Text>
          {data.customer.address?.street_address_2 && (
            <Text>{data.customer.address.street_address_2}</Text>
          )}
          <Text>
            {data.customer.address.city}, {data.customer.address.state}
          </Text>
          <Text>
            {data.customer.address.country}, {data.customer.address.zipcode}
          </Text>
        </View>
      </View>
      <View style={{ ...s.col, width: '34%' }}>
        <View style={s.infoRow}>
          <Text>Number</Text>
          <Text>{data.number}</Text>
        </View>
        <View style={s.infoRow}>
          <Text>Date</Text>
          <Text>
            {parseISO(data.dates.dateCreated).toLocaleDateString('en-US', {
              year: 'numeric',
              day: '2-digit',
              month: '2-digit',
            })}
          </Text>
        </View>
        {data.type !== 'receipt' ? (
          <View style={s.infoRow}>
            <Text>{data.type === 'invoice' ? 'Due date' : 'Expires'}</Text>
            <Text>
              {parseISO(data.dates.dateDue).toLocaleDateString('en-US', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
              })}
            </Text>
          </View>
        ) : null}
      </View>
    </View>
  );
}

export default BillingTo;
