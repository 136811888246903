import React from 'react';
import Proptypes from 'prop-types';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import InformationWithIcon from './InformationWithIcon';
import envelope from '../../img/icon-envelop.png';
import iconPhone from '../../img/icon-phone.png';
import iconName from '../../img/sidebar/icon-customers-b.png';
import location from '../../img/icon-location.png';

function InfoBox({
  name = 'No name register',
  address = 'No address register',
  email = 'No email register',
  phone = 'No phone register',
  mobile = 'No phone register',
}) {
  const info = [
    {
      label: 'Name',
      data: name,
      icon: iconName,
    },
    {
      label: 'Address',
      data: address,
      icon: location,
    },
    {
      label: 'Email',
      data: email,
      icon: envelope,
    },
    {
      label: 'Phone',
      data:
        phone === 'No phone register' ? null : (
          <a href={`tel:${phone}`}>{formatPhoneNumberIntl(phone)}</a>
        ),
      icon: iconPhone,
    },
    {
      label: 'Mobile',
      data:
        mobile === 'No phone register' ? null : (
          <a href={`tel:${mobile}`}>{formatPhoneNumberIntl(mobile)}</a>
        ),
      icon: iconPhone,
    },
  ];

  return info.map(({ label, data, icon }) => (
    <InformationWithIcon key={label} label={label} data={data} icon={icon} />
  ));
}

InfoBox.propTypes = {
  name: Proptypes.string,
  address: Proptypes.string,
  email: Proptypes.string,
  phone: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
  mobile: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
};

InfoBox.defaultProps = {
  name: 'No name register',
  address: 'No address register',
  email: 'No email register',
  phone: 'No phone register',
  mobile: 'No phone register',
};

export default InfoBox;
