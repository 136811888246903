import React from 'react';
import PropTypes from 'prop-types';
import './onboard-grid.styles.scss';

function OnboardGrid({ children }) {
  return <div className="row onboardgrid__container">{children}</div>;
}

OnboardGrid.propTypes = {
  children: PropTypes.node,
};

function OnboardGridHeader({ children }) {
  return <header className="p-0 pb-4">{children}</header>;
}

OnboardGridHeader.propTypes = {
  children: PropTypes.node,
};

function OnboardGridLeft({ children }) {
  return (
    <div className="col-xl-5 pb-4 d-flex flex-column gap-3">{children}</div>
  );
}

OnboardGridLeft.propTypes = {
  children: PropTypes.node,
};

function OnboardGridRight({ children }) {
  return (
    <div className="col-xl-7 pb-4">
      <div className="sticky-container">{children}</div>
    </div>
  );
}

OnboardGridRight.propTypes = {
  children: PropTypes.node,
};

OnboardGrid.Header = OnboardGridHeader;
OnboardGrid.Left = OnboardGridLeft;
OnboardGrid.Right = OnboardGridRight;

export default OnboardGrid;
