import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from './styles';

function ExpensesPDF({
  title = 'Insert a Title',
  from = '',
  to = '',
  currentDate = '',
  timezone = '',
  fetchedData = {},
  expensesCategory = false,
  expensesInvoice = false,
  expensesMerchant = false,
  expensesVendor = false,
  userCompany,
}) {
  const getAmounts =
    fetchedData.data?.length > 0
      ? fetchedData.data?.map((item) => item.amount)
      : 0;
  const subtotalValue =
    fetchedData.data?.length > 0
      ? getAmounts.reduce((acc, currentValue) => {
          return acc + currentValue;
        }, 0)
      : 0;

  let totalExpense = 0;
  if (fetchedData.data && fetchedData.data.length > 0) {
    totalExpense = fetchedData.data[0].total_expense;
  }

  // EXPENSES BY CATEGORY
  if (expensesCategory)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>

          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View style={styles.categoriesBar}>
              <Text style={styles.textCategories}>Category</Text>
              <Text style={styles.textCategories}>Merchant/Vendor</Text>
              <Text style={styles.invoiceCategories}>Description</Text>
              <Text style={styles.textCategories}>Recurrent?</Text>
              <Text style={styles.textCategories}>Amount</Text>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={styles.content}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.category_id}
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    paddingLeft: 20,
                  }}
                >
                  <Text style={styles.categoryText}>{item.category}</Text>
                  <Text style={styles.vendorText}>{item.vendorMerchant}</Text>
                  <Text style={styles.descriptionText}>{item.description}</Text>
                  <Text style={styles.recurrentText}>{item.recurrence}</Text>
                  <Text style={styles.amountText}>${item.amount}</Text>
                </View>
              );
            })}
            <View style={styles.bottomStrongDivider} />
            <View style={styles.totalSales}>
              <Text style={styles.totalSalesTxt}>Total Sales:</Text>
              <Text style={styles.totalSalesPriceTxt}>${subtotalValue}</Text>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );

  // EXPENSES BY INVOICE
  if (expensesInvoice)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>

          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View style={styles.categoriesBar}>
              <Text style={styles.textCategories}>Invoice #</Text>
              <View>
                <Text style={styles.textCategories}>Expense</Text>
                <Text style={styles.textCategories}>Category</Text>
              </View>
              <View>
                <Text style={styles.invoiceCategories}>Merchant /</Text>
                <Text style={styles.invoiceCategories}>Vendor</Text>
              </View>
              <Text style={styles.textCategories}>Description</Text>
              <Text style={styles.textCategories}>Amount</Text>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={{ ...styles.content, top: 180 }}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.invoice_id}
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    paddingLeft: 20,
                  }}
                >
                  <Text style={styles.categoryText}>{item.invoice_number}</Text>
                  {item.invoice_expenses?.map((subitem) => (
                    <View
                      key={item.invoice_id}
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      <Text style={styles.vendorText}>{subitem.category}</Text>
                      <Text style={styles.recurrentText}>
                        {subitem.vendorMerchant}
                      </Text>
                      <Text style={styles.descriptionText}>
                        {subitem.description}
                      </Text>
                      <Text style={styles.amountText}>${subitem.amount}</Text>
                    </View>
                  ))}
                </View>
              );
            })}
            <View style={styles.bottomStrongDivider} />
            <View style={styles.totalSales}>
              <Text style={styles.totalSalesTxt}>Total Sales:</Text>
              <Text style={styles.totalSalesPriceTxt}>${totalExpense}</Text>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );

  // EXPENSES BY MERCHANT
  if (expensesMerchant)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>

          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View
              style={{
                ...styles.categoriesBar,
                justifyContent: 'space-around',
              }}
            >
              <Text style={styles.textCategories}>Merchant</Text>
              <Text style={styles.textCategories}>Amount</Text>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={styles.content}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.id}
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    paddingLeft: 30,
                  }}
                >
                  <Text style={{ ...styles.categoryText, width: 400 }}>
                    {item.name}
                  </Text>
                  <Text style={styles.amountText}>${item.amount}</Text>
                </View>
              );
            })}
            <View style={styles.bottomStrongDivider} />
            <View style={styles.totalSales}>
              <Text style={styles.totalSalesTxt}>Total Expenses:</Text>
              <Text style={styles.totalSalesPriceTxt}>${subtotalValue}</Text>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );

  // EXPENSES BY MERCHANT
  if (expensesVendor)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>

          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View
              style={{
                ...styles.categoriesBar,
                justifyContent: 'space-around',
              }}
            >
              <Text style={styles.textCategories}>Vendor</Text>
              <Text style={styles.textCategories}>Amount</Text>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={styles.content}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.vendor_id}
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    paddingLeft: 30,
                  }}
                >
                  <Text style={{ ...styles.categoryText, width: 400 }}>
                    {item.name}
                  </Text>
                  <Text style={styles.amountText}>${item.amount}</Text>
                </View>
              );
            })}
            <View style={styles.bottomStrongDivider} />
            <View style={styles.totalSales}>
              <Text style={styles.totalSalesTxt}>Total Expenses:</Text>
              <Text style={styles.totalSalesPriceTxt}>${subtotalValue}</Text>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
}

ExpensesPDF.propTypes = {
  title: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  fetchedData: PropTypes.shape({}),
  currentDate: PropTypes.string,
  timezone: PropTypes.string,
  expensesCategory: PropTypes.bool,
  expensesInvoice: PropTypes.bool,
  expensesMerchant: PropTypes.bool,
  expensesVendor: PropTypes.bool,
  userCompany: PropTypes.string,
};

export default ExpensesPDF;
