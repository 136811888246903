import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line camelcase
import { useBlocker } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

function BlockerModal({ exceptions = [] }) {
  const [show, setShow] = React.useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const preventBlocker = exceptions.some(
      (exception) => nextLocation.pathname === exception
    );
    if (preventBlocker) return false;
    return currentLocation.pathname !== nextLocation.pathname;
  });
  const handleClose = (e) => {
    if (e?.target?.value === 'proceed') {
      setShow(false);
      return blocker.proceed();
    }
    setShow(false);
    return blocker.reset();
  };

  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      setShow(true);
    }
  }, [blocker.state]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Discard current changes?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        If you leave now, the changes you made won&apos;t be saved
      </Modal.Body>
      <Modal.Footer>
        <Button
          value="cancel"
          variant="secondary"
          onClick={(e) => handleClose(e)}
        >
          Cancel
        </Button>
        <Button
          value="proceed"
          variant="primary"
          onClick={(e) => handleClose(e)}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

BlockerModal.propTypes = {
  exceptions: PropTypes.arrayOf(PropTypes.string),
};

export default BlockerModal;
