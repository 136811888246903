/* eslint-disable react/prop-types */
import React from 'react';
import { useSubmit } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import LayoutSelector from './Selectors/Layout/LayoutSelector';
import ColorsSelector from './Selectors/ColorsSelector/ColorsSelector';
import SloganSelector from './Selectors/SloganSelector';
import TypeSelector from './Selectors/TypeSelector';
import TemplateAccordion from './Selectors/TemplateAccordion';

function Selector({
  colors,
  handleLayoutChange,
  initialColors,
  layoutNumber,
  setType,
  setSlogan,
  slogan,
  type,
  updateColor,
}) {
  const submit = useSubmit();
  const handleSubmit = () => {
    const nextSubmission = {
      primary_color_bg: colors.color1,
      primary_color_text: colors.color1text,
      secondary_color_bg: colors.color2,
      secondary_color_text: colors.color2text,
      third_color_bg: colors.color3,
      third_color_text: colors.color3text,
      invoice_type: layoutNumber,
      company_slogan: slogan,
    };
    submit(nextSubmission, { method: 'post' });
  };

  const accordionLayout = [
    {
      header: 'Company slogan',
      component: <SloganSelector slogan={slogan} setSlogan={setSlogan} />,
    },
    {
      header: 'Preview document type',
      component: <TypeSelector type={type} setType={setType} />,
    },
    {
      header: 'Select document layout',
      component: (
        <LayoutSelector
          handleLayoutChange={handleLayoutChange}
          layoutNumber={layoutNumber}
        />
      ),
    },
    {
      header: 'Select document colors',
      component: (
        <ColorsSelector
          currentColors={colors}
          updateColor={updateColor}
          layoutNumber={layoutNumber}
          initialColors={initialColors}
        />
      ),
    },
  ];
  return (
    <>
      <Button variant="primary" className="w-100 mb-3" onClick={handleSubmit}>
        Save template settings
      </Button>
      <TemplateAccordion accordionLayout={accordionLayout} />
    </>
  );
}

export default Selector;
