/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { createItem } from '../../utils';
import ActionButton from '../../../buttons/ActionButton';
import ItemField from './ItemField';

export default function AddItem({ columns, onAddItem }) {
  const [newItem, setNewItem] = useState(() => createItem(columns));
  const [added, setAdded] = useState(false);
  const [error, setError] = useState(null);
  const firstItemRef = useRef(null);

  const onChange = (value, accessorKey) => {
    setNewItem((old) => ({
      ...old,
      [accessorKey]: value,
    }));
  };

  const validator = Yup.object().shape({
    description: Yup.string().required('Description field cannot be blank'),
    invoice: Yup.string(),
    amount: Yup.number()
      .typeError('Please enter a amount. Must be a number.')
      .required('Please enter a amount'),
  });

  const onClickAdd = () => {
    validator
      .validate(newItem, { abortEarly: false })
      .then(() => {
        onAddItem(newItem);
        setAdded(true);
        setNewItem(createItem(columns));
        firstItemRef.current.focus();

        setError(null);
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (added) {
      setAdded(false);
    }
  }, [added]);

  return (
    <div
      className="lb_table-row add-check__row"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClickAdd();
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      {columns.map(({ columnDef }) => (
        <ItemField
          firstItemRef={firstItemRef}
          key={columnDef.accessorKey}
          column={columnDef}
          newItem={newItem[columnDef.accessorKey]}
          setNewItem={(value) => onChange(value, columnDef.accessorKey)}
          clear={added}
          label
        />
      ))}
      <div aria-label="add" className="mobile__actions add-check__col">
        <ActionButton action="add" handler={onClickAdd} />
      </div>

      {error && (
        <div
          style={{ gridColumn: `1 / -1`, padding: '0 20px 10px 20px' }}
          className="text-danger"
        >
          {error.errors.map((err) => (
            <div key={err}>{err}</div>
          ))}
        </div>
      )}
    </div>
  );
}
