import React from 'react';
import PropTypes from 'prop-types';

import './ContactFormModal.scss';

function MessagePreview({ company, children }) {
  return (
    <div className="contact-form__message-preview__container mb-3">
      <h5 className="contact-form__message-preview__title mb-2">
        Message Preview
      </h5>

      <div className="contact-form__message-preview__message rounded-2 p-3">
        {company && <p className="mb-1">Message from {company}:</p>}
        <p>{children}</p>
      </div>
    </div>
  );
}

MessagePreview.propTypes = {
  company: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default MessagePreview;
