/* eslint react/prop-types: 0 */
import React from 'react';
import DataSelect from '../td/DataSelect';
import { sanitize } from '../utils';
import DataInput from '../td/DataInput';

export default function Td({ column, newItem, setNewItem, label = false }) {
  const { header, className, mobileLabel, type } = column;
  const sanitizedHeader = sanitize(header);

  const onChange = (e) => {
    if (e !== newItem) {
      setNewItem(e);
    }
  };

  return (
    <td
      className={`${className || ''} mobile__${sanitizedHeader}`}
      style={{
        gridArea: sanitizedHeader,
      }}
    >
      {!label && !!newItem && mobileLabel && (
        <span className="accesor">{header}:</span>
      )}
      {label && <span className="accesor">{header}:</span>}
      {type === 'input' && (
        <DataInput
          className="table__input--add"
          onChange={(e) => onChange(e)}
          value={newItem}
          type={column?.inputType || 'text'}
        />
      )}
      {type === 'select' && (
        <DataSelect
          options={column?.options}
          value={newItem}
          onChange={(e) => onChange(e)}
          prefix="add-select"
        />
      )}
    </td>
  );
}
