import React from 'react';
import { Navigate, Outlet, useRouteLoaderData } from 'react-router-dom';

import Widgets from '../components/Dashboard/Widgets';
import QuickLinks from '../components/Dashboard/QuickLinks';
import LBOffCanvas from '../components/UI/LBOffCanvas';
import useModal from '../components/UI/LBOffCanvas/useModal';

function DashboardPage() {
  const { show, size, handleCloseModal } = useModal('/', []);
  const {
    user: { onboarding_helpers: helpers },
  } = useRouteLoaderData('root');

  const {
    customers_count: customersCount,
    prod_and_serv_count: prodAndServCount,
    show_tutorial: showTutorial,
  } = helpers;

  const noCustomers = customersCount < 1;
  const noProdOrServ = prodAndServCount < 1;

  if (showTutorial && (noCustomers || noProdOrServ)) {
    return <Navigate to="onboarding" />;
  }

  return (
    <>
      <QuickLinks />
      <Widgets />

      <LBOffCanvas show={show} size={size} handleCloseModal={handleCloseModal}>
        <Outlet />
      </LBOffCanvas>
    </>
  );
}

export default DashboardPage;
