const RANGE_OPTIONS = [
  {
    label: 'Custom Range',
    value: 'customRange',
  },
  {
    label: 'Last Month',
    value: 'lastMonth',
  },
  {
    label: 'Last Week',
    value: 'lastWeek',
  },
  {
    label: 'This Week',
    value: 'thisWeek',
  },
  {
    label: 'This Month',
    value: 'thisMonth',
  },
];

export default RANGE_OPTIONS;
