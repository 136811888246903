import React from 'react';
import { useLoaderData, useFetcher, useNavigate } from 'react-router-dom';
import ReactTablev8 from '../UI/Table/ReactTablev8';
import ActionMenu from '../UI/buttons/ActionMenu';

function Vendors() {
  // HOOKS
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const loaderData = useLoaderData();

  const { merchants } = loaderData;

  // HANDLERS
  const handleRowClick = (rowId) => {
    navigate(`${rowId}/update`);
  };
  const handleDelete = (id) => {
    if (fetcher.state === 'submiting') return;
    const url = `/expenses/merchants/${id}/delete`;
    fetcher.submit({}, { method: 'post', action: url });
  };

  const handleEdit = (id) => {
    navigate(`${id}/update`);
  };

  // TABLE
  // table data
  const nextMerchants = merchants.map((merchant) => ({
    id: merchant.id,
    name: merchant.label,
    more: (
      <ActionMenu
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        id={merchant.id}
      />
    ),
  }));

  const data = React.useMemo(() => nextMerchants, [merchants]);

  // table scheme
  const tableSetup = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Actions',
      accessorKey: 'more',
    },
  ];

  // Setup initial (default) sort
  const initialMerchantSort = React.useMemo(() => {
    return [
      {
        id: 'name',
        desc: false,
      },
    ];
  }, []);

  return (
    <ReactTablev8
      dataTable={data}
      tableLayout={tableSetup}
      tableAction={handleRowClick}
      initialSortBy={initialMerchantSort}
      mobileClass="merchants_table"
    />
  );
}

export default Vendors;
