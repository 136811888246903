/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import './link-button.styles.scss';

/*  
  "list" espera un array de objetos:

  [
    {
      id: number,
      pre: "Lo que aparece en negrita",
      body: "El contenido del bullet",
    }
  ]
*/

function LinkButton({ title = 'Add a Title', onClick }) {
  return (
    <div className="linkbtn-container">
      <div className="button-container">
        <button type="button" onClick={onClick} className="list-button">
          {title}
        </button>
        <p className="greater-than-icon">&gt;</p>
      </div>
      <div className="underline" />
    </div>
  );
}

LinkButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default LinkButton;
