import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import { I_Colors, I_Data } from './propTypes';

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: 16,
    gap: 28,
  },
  serviceRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
  },
  serviceLabel: {
    fontWeight: 600,
  },
  notes: {
    fontSize: 8,
    lineHeight: 1.75,
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 4,
    marginTop: 12,
  },
  authorizedSign: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: 48,
    fontSize: 10,
    fontWeight: 600,
  },
});

function Notes({ data, layoutNumber }: { data: I_Data; layoutNumber: number }) {
  return (
    <View
      style={{
        ...s.container,
        paddingLeft: layoutNumber === 3 ? 40 : 0,
        paddingRight: layoutNumber === 3 ? 40 : 0,
      }}
    >
      <View style={{ width: '80%' }}>
        {data.type === 'receipt' ? (
          <Text
            style={{
              paddingVertical: 16,
              marginBottom: 16,
              fontSize: 12,
            }}
          >
            Thank you for your payment!
          </Text>
        ) : null}

        {data.type === 'invoice' &&
        data.notes.customerNotes &&
        !data.notes.serviceInfo?.show_service_info ? (
          <View style={s.notes}>
            <Text style={s.title}>Customer Notes</Text>
            <Text>{data.notes.customerNotes}</Text>
          </View>
        ) : null}

        {data.type === 'invoice' &&
        data.notes.serviceInfo?.show_service_info ? (
          <View style={s.notes}>
            <Text style={s.title}>Service Info</Text>
            {data.notes.serviceInfo?.project_manager ? (
              <View style={s.serviceRow}>
                <Text style={s.serviceLabel}>Project Manager:</Text>
                <Text>{data.notes.serviceInfo.project_manager}</Text>
              </View>
            ) : null}
            {data.notes.serviceInfo?.name ? (
              <View style={s.serviceRow}>
                <Text style={s.serviceLabel}>Name:</Text>
                <Text>{data.notes.serviceInfo.name}</Text>
              </View>
            ) : null}
            {data.notes.serviceInfo?.address ? (
              <View style={s.serviceRow}>
                <Text style={s.serviceLabel}>Address:</Text>
                <Text>{data.notes.serviceInfo.address}</Text>
              </View>
            ) : null}
            {data.notes.serviceInfo?.estimate ? (
              <View style={s.serviceRow}>
                <Text style={s.serviceLabel}>Estimate:</Text>
                <Text>{data.notes.serviceInfo.estimate}</Text>
              </View>
            ) : null}
          </View>
        ) : null}

        {data.notes.statementNotes ? (
          <View style={s.notes}>
            <Text style={s.title}>Statement Notes</Text>
            <Text>{data.notes.statementNotes}</Text>
          </View>
        ) : null}
      </View>

      <View style={{ width: '20%' }}>
        {data.type !== 'receipt' ? (
          <View style={s.authorizedSign}>
            <View
              style={{ width: 120, borderTop: '1px solid gray', paddingTop: 8 }}
            >
              <Text>Authorized Sign</Text>
            </View>
          </View>
        ) : null}
      </View>
    </View>
  );
}

export default Notes;
