/* eslint-disable react/prop-types */

import React from 'react';
import { Link } from 'react-router-dom';
import { calculateGridPosition, findAppointmentsByDate } from './utils/helpers';
import useAppointmentContext from './Context/useAppointmentContext';

function Appointment({ appointment, interval }) {
  const { gridRowStart, gridRowEnd } = calculateGridPosition(
    appointment.start_date,
    appointment.end_date,
    interval
  );

  const customStyles = {
    '--appointment-start': gridRowStart,
    '--appointment-end': gridRowEnd,
  };

  return (
    <Link
      to={`/appointments/${appointment.id}`}
      style={customStyles}
      className={`appointment-overlay__item ${appointment.status}__appointment`}
    >
      <strong>
        {new Date(appointment.start_date).toLocaleTimeString('en-US', {
          minute: '2-digit',
          hour: '2-digit',
        })}{' '}
        -{' '}
        {new Date(appointment.end_date).toLocaleTimeString('en-US', {
          minute: '2-digit',
          hour: '2-digit',
        })}
      </strong>{' '}
      | {appointment.name}
    </Link>
  );
}

function AppointmentOverlay() {
  const { currentDate, appointments } = useAppointmentContext();
  const todayAppointments = findAppointmentsByDate(currentDate, appointments);
  const intervalInMinutes = 1;

  // "11 * 60" It's the number of available minutes (in 11 hours) in the calendar
  // It could made sense to split this in 12 hour halves (to allow for
  // customers that have work during the night)
  const numRows = Math.ceil((24 * 60) / intervalInMinutes);

  return (
    <div
      style={{ '--rows': numRows }}
      className="appointment-overlay__container"
    >
      {todayAppointments.map((appointment) => (
        <Appointment
          key={appointment.id}
          appointment={appointment}
          interval={intervalInMinutes}
        />
      ))}
    </div>
  );
}

export default AppointmentOverlay;
