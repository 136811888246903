/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import './step-list.styles.scss';

/*

  stepArray espera un array así:
  [
    {
      id: number,
      body: Component / (JSX),
    },
  ]

*/

function StepList({ stepArray }) {
  return (
    <div className="step-list">
      <h3>How should you do it?</h3>
      {stepArray.map(({ body, id }, index) => (
        <Item key={id} step={index + 1} body={body} />
      ))}
    </div>
  );
}

StepList.propTypes = {
  stepArray: PropTypes.array,
};

function Item({ step, body }) {
  return (
    <article className="step">
      <h3>Step {step}</h3>
      {body}
    </article>
  );
}

Item.propTypes = {
  step: PropTypes.number,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default StepList;
