import fetchAPI from '../fetchUtils';

const expensesAPI = {
  getAll: async (customerId) =>
    fetchAPI.getWithAuth(
      customerId ? `/get-expenses?customerId=${customerId}` : '/get-expenses'
    ),
  create: async (body) => fetchAPI.postWithAuth('/create-expense', body),
  update: async (body) => fetchAPI.postWithAuth('/update-expense', body),
  delete: async (id) => fetchAPI.getWithAuth(`/delete-expense/${id}`),
  get: async (id) => fetchAPI.getWithAuth(`/get-expense-details/${id}`),
  deleteFile: async (body) =>
    fetchAPI.postWithAuth(`/delete-expense-files`, body),
};

export default expensesAPI;
