import fetchAPI from '../fetchUtils';

const merchantsAPI = {
  get: async (id) =>
    fetchAPI.getWithAuth(`/get-vendor-merchant-details/merchant/${id}`),
  getAll: async () => fetchAPI.getWithAuth('/expense-vendor-merchant-list'),
  create: async (body) => fetchAPI.postWithAuth('/create-merchant', body),
  update: async (body) => fetchAPI.postWithAuth('/update-merchant', body),
  delete: async (id) => fetchAPI.getWithAuth(`/delete-merchant/${id}`),
};

export default merchantsAPI;
