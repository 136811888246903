import React from 'react';
import { useLoaderData } from 'react-router-dom';
import GetExpenseForm from './GetExpenseForm';

export default function GetExpense() {
  const loaderData = useLoaderData();
  const TODAY = new Date().toISOString().substring(0, 10);
  const data = {
    amount: loaderData?.expense.amount || 0,
    as_at: loaderData?.expense.as_at || TODAY,
    category_id: loaderData?.expense.category_id || '',
    merchant_id: loaderData?.expense.merchant_id || '',
    invoice_id: loaderData?.expense.invoice_id
      ? `${loaderData?.expense.invoice_id}`
      : '',
    recurrent: loaderData?.expense.recurr || 0,
    recur_frequency: loaderData?.expense_recur_info?.recur_frequency || '',
    recur_day: loaderData?.expense_recur_info?.recur_day || '',
    recur_date: loaderData?.expense_recur_info?.recur_date || '',
    recur_till: loaderData?.expense_recur_info?.recur_till || '',
    notes: loaderData?.expense.notes || '',
    files: loaderData?.expense.files || '',
    expense_id: loaderData?.expense.expense_id || '',
    vendor_id: loaderData?.expense.vendor_id || '',
  };

  return <GetExpenseForm data={data} />;
}
