/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './MileagesPage.scss';

function getTotal(start, end) {
  if (Number.isNaN(start) || Number.isNaN(end) || !end) return '-';

  return `${(Number(end) - Number(start)).toFixed(2)} mi`;
}

function MileagesTable({ mileages }) {
  return (
    <tr className="mileage__table-container">
      <td colSpan={5} className="mileage__table">
        <div className="container-fluid w-100">
          <Row>
            <Col>
              <h6
                style={{
                  fontSize: '1rem',
                  marginBottom: '1rem',
                  marginLeft: '-0.6rem',
                }}
              >
                Mileages
              </h6>
            </Col>
          </Row>
          <Row className="mileage__table-header">
            <Col>Year</Col>
            <Col>Start Mileage</Col>
            <Col>End Mileage</Col>
            <Col>Total</Col>
          </Row>
          {mileages.map(({ start, end, year }) => (
            <Row
              key={year}
              className={
                year === new Date().getFullYear()
                  ? 'mileage__table-current-year'
                  : 'mileage__table-row'
              }
            >
              <Col className="mileage__table-col mileage__table-col-year">
                {year}
              </Col>
              <Col className="mileage__table-col mileage__table-col-start">
                {start.toFixed(2) || '0'} mi
              </Col>
              <Col className="mileage__table-col mileage__table-col-end">
                {end.toFixed(2) || '0'} mi
              </Col>
              <Col className="mileage__table-col mileage__table-col-total">
                <strong>{getTotal(start, end)}</strong>
              </Col>
            </Row>
          ))}
        </div>
      </td>
    </tr>
  );
}

export default MileagesTable;
