/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Alert } from 'react-bootstrap';
import { useFetcher, useLocation } from 'react-router-dom';
import { string } from 'yup';
import FormWrapper from '../../Forms/builders/FormWrapper';
import CreateForm from '../../Forms/CreateForm';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import LBOffCanvas from '../../UI/LBOffCanvas';

function VendorModal() {
  const { state: historyState, pathname } = useLocation();
  const vendorId = historyState?.id;

  const getVendor = useFetcher();
  const updateVendor = useFetcher();

  React.useEffect(() => {
    if (!vendorId) return;

    if (getVendor.state === 'idle' && !getVendor.data) {
      getVendor.load(`/vendors/${vendorId}`);
    }

    if (getVendor?.data) {
      const {
        vendor_name: firstName,
        vendor_last: lastName,
        address_1: streetAddress1,
        address_2: streetAddress2,
        city,
        state,
        country,
        zipcode,
      } = getVendor.data.data;
      setValue('first_name', firstName || '');
      setValue('last_name', lastName || '');
      setValue('street_address', streetAddress1 || '');
      setValue('street_address_2', streetAddress2 || '');
      setValue('city', city || '');
      setValue('state', state || '');
      setValue('zipcode', zipcode || '');
      setValue('country', country || '');
    }
  }, [getVendor]);

  const fields = [
    {
      formGroup: 'Name asd',
      groupChildren: [
        {
          name: 'first_name',
          label: 'First Name',
          initialValue: '',
          type: string().required('Please complete the field'),
          required: true,
        },
        {
          name: 'last_name',
          label: 'Last Name',
          initialValue: '',
          type: string().required(),
          required: true,
        },
      ],
    },
    {
      formGroup: 'Billing Address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          className: 'flex__span-12',
          initialValue: '',
          type: string(),
          formType: 'autocompleteAddress',
        },
        {
          name: 'street_address',
          label: 'Street Address',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
          autocomplete: 'address-line1',
        },
        {
          name: 'street_address_2',
          label: 'Street Address 2',
          initialValue: '',
          type: string(),
          autocomplete: 'address-line2',
        },
        {
          name: 'city',
          label: 'City',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
        },
        {
          name: 'state',
          label: 'State',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
        },
        {
          name: 'zipcode',
          label: 'Zip code',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
        },
        {
          name: 'country',
          label: 'Country',
          type: string().required('This field is mandatory'),
          required: true,
          initialValue: '',
        },
      ],
    },
  ];

  const submitAction = (values) => {
    const { city, state, country, zipcode } = values;
    const {
      ein_ssn: ein,
      email,
      mobile,
      phone,
      vendor_trade: trade,
      notes,
      vendor_photo: photo,
      insurance,
      w_9: w9,
    } = getVendor.data.data;

    const nextValues = {
      ein_ssn: ein || '',
      email: email || '',
      mobile: mobile || '',
      phone: phone || '',
      vendor_trade: trade || '',
      notes: notes || '',
      vendor_photo: photo,
      insurance: insurance || '',
      w_9: w9 || '',
      vendor_name: values.first_name,
      vendor_last: values.last_name,
      address_1: values.street_address,
      address_2: values.street_address_2,
      city,
      state,
      country,
      zipcode,
      id: vendorId,
      modal_redirect: pathname.substring(0, pathname.lastIndexOf('/')),
    };

    updateVendor.submit(nextValues, {
      method: 'post',
      action: `/vendors/${vendorId}/update`,
    });
  };

  //   Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: true,
  });

  async function setValue(field, value) {
    if (!field === undefined || !value === undefined)
      throw new Error('Value and field are required arguments');

    formik.setFieldValue(field, value);
  }

  if (!vendorId)
    return (
      <LBOffCanvas.Wrapper>
        <LBOffCanvas.Left>
          <LBOffCanvas.Header>Oops!</LBOffCanvas.Header>
          <Alert variant="danger">
            Error, couldn&apos;t find a target, go back and select a customer,
            vendor or merchant, if it&apos;s missing any fields you will be able
            to complete them in this modal.
          </Alert>
        </LBOffCanvas.Left>
      </LBOffCanvas.Wrapper>
    );

  return (
    <LBOffCanvas.Wrapper>
      <LBOffCanvas.Left>
        <LBOffCanvas.Header>Update Vendor</LBOffCanvas.Header>
        <Alert variant="info" style={{ fontSize: '0.8rem' }}>
          Keep in mind that the following changes will be applied to the
          merchant itself, and not just on this particular check.
        </Alert>
        {getVendor.state === 'loading' || updateVendor.state === 'loading' ? (
          <p>Loading...</p>
        ) : (
          <FormWrapper
            id="update-vendor-checks"
            className="flex__form-wrapper"
            handleSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
            label="Update Vendor"
            dirty={formik.dirty}
            isValid={formik.isValid}
            portalId="offcanvas-action-group-left"
          >
            <CreateForm structure={fields} {...formik} />
          </FormWrapper>
        )}
      </LBOffCanvas.Left>
    </LBOffCanvas.Wrapper>
  );
}

export default VendorModal;
