// Loaders and actions
import { estimatesLoader } from './estimatesLA';
// Components
// Routes
import GenericPage from '../../pages/GenericPage';
import createEstimate from './estimateCreate';

const estimatesRoutes = [
  {
    path: 'estimates',
    element: <GenericPage />,
    handle: {
      crumb: () => 'Estimates',
    },
    loader: estimatesLoader,
    children: [...createEstimate('estimateData')],
  },
];

export default estimatesRoutes;
