import { differenceInDays, isSameDay, isValid, parseISO } from 'date-fns';

const checkIfPastDay = (dateString, currentDate = null) => {
  const selectedDate = parseISO(dateString);

  // We check if the arguments are valid dates (or have been parsed into valid dates)
  if (!isValid(selectedDate) || (currentDate && !isValid(currentDate)))
    throw new Error(
      'The date string passed to checkIfPastDay (YUP Test) resulted in an invalid date'
    );

  const today = new Date();

  // First we check that we don't have a currentDate that's in the
  // same day as the selectedDate
  if (currentDate && isSameDay(currentDate, selectedDate)) {
    return true;
  }

  // Check if the day difference is less than 0
  if (differenceInDays(selectedDate, today) < 0) {
    return false;
  }

  // Default (validation pass)
  return true;
};

export default checkIfPastDay;
