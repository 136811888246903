import React from 'react';
import Proptypes from 'prop-types';
import FieldLabel from './FieldLabel';
import FieldData from './FieldData';

function TextField({ label, children, dataVariant, ...delegated }) {
  return (
    <div className="text-field" style={{ ...delegated.style }}>
      <FieldLabel>{label}</FieldLabel>
      <FieldData variant={dataVariant}>{children}</FieldData>
    </div>
  );
}
TextField.propTypes = {
  label: Proptypes.string.isRequired,
  children: Proptypes.oneOfType([Proptypes.string, Proptypes.node]).isRequired,
  dataVariant: Proptypes.string,
};
TextField.defaultProps = {
  dataVariant: '',
};
export default TextField;
