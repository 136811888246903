import expensesAPI from '../../API/routesAPI/expensesAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

export async function expensesLoader({ params }) {
  const { customerId } = params;
  const response = await expensesAPI.getAll(customerId);
  return handleResposeAbstraction({
    response,
  });
}

export async function createExpenseAction({ request }) {
  const body = await request.formData();
  const response = await expensesAPI.create(body);
  const result = await response.json();
  const redirectTo = '..';
  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
    showSuccess: true,
  });
}

export async function updateExpenseAction({ request }) {
  const body = await request.formData();
  const response = await expensesAPI.update(body);
  const result = await response.json();
  const redirectTo = '../..';
  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
    showSuccess: true,
  });
}

export async function expenseLoader({ params }) {
  const { expenseId } = params;
  const response = await expensesAPI.get(expenseId);
  return handleResposeAbstraction({
    response,
  });
}

export async function deleteExpenseAction({ params }) {
  const { expenseId } = params;
  const response = await expensesAPI.delete(expenseId);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
  });
}

export async function deleteExpenseFileAction({ request }) {
  const body = await request.formData();
  const response = await expensesAPI.deleteFile(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
  });
}
