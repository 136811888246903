import { useFormik } from 'formik';
import * as Yup from 'yup';
import extractValues from '../../Helpers/extractValues';

export default function useFormikTemplate({
  initial,
  yupValues,
  submitAction,
  enableReinitialize = true,
}) {
  // Initial values
  const initialValues = extractValues('initialValue', [...initial]);

  // YUP
  const SchemaObject = extractValues('type', [...yupValues]);
  const formSchema = Yup.object().shape(SchemaObject);

  // Formik setup
  const formik = useFormik({
    initialValues,
    enableReinitialize,
    validationSchema: formSchema,
    onSubmit: () => {
      formik.setSubmitting(true);
      submitAction(formik.values);
      formik.setSubmitting(false);
    },
  });

  return [formik];
}
