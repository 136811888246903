/* eslint-disable react/prop-types */
import React from 'react';
import Table from '../../UI/Table/EditTable.jsx/Table';
import schema from './Schema';
import getSelectData from '../../Forms/Select/getSelectData';

export default function ChecksTable({ items, setItems, errors }) {
  const options = getSelectData('/invoices');
  const data = React.useMemo(() => items, [items]);
  const layout = React.useMemo(() => schema(options), [options]);

  return (
    <Table
      data={data}
      schema={layout}
      mobileClass="checks_table"
      setItems={setItems}
      errors={errors}
      showHeader={false}
    />
  );
}
