/* eslint-disable react/prop-types */
import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';

const styles = StyleSheet.create({
  col: {
    padding: '8px 12px',
  },

  text: {
    fontSize: '8px',
  },

  moneyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },

  textArea: {
    fontSize: 7,
  },

  headerText: {
    fontSize: '8px',
    fontWeight: 600,
  },
});

function PDFCol({ children, colSetup, header }) {
  let content;

  if (header) {
    content = <Text style={styles.headerText}>{children}</Text>;
  } else if (colSetup?.type === 'money')
    content = (
      <View style={styles.moneyWrapper}>
        <Text style={styles.text}>$</Text>
        <Text style={styles.text}>{formatCurrency(children, 2, false)}</Text>
      </View>
    );
  else if (colSetup?.type === 'textArea') {
    content = <Text style={styles.textArea}>{children}</Text>;
  } else if (colSetup?.type === 'date') {
    content = (
      <Text style={styles.text}>
        {new Date(children).toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}
      </Text>
    );
  } else {
    content = <Text style={styles.text}>{children}</Text>;
  }

  return <View style={{ ...styles.col, width: colSetup.size }}>{content}</View>;
}

export default PDFCol;
