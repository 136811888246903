import React from 'react';
import { Link } from 'react-router-dom';
import OnboardGrid from '../../UI/OnboardGrid/OnboardGrid';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import BulletList from '../../UI/BulletList/BulletList';
import StepList from '../../UI/StepList/StepList';
import image from '../../../img/assets/images/c-userOnboard-estimates.svg';

import './estimatesOnBoard.styles.scss';

export const estimatesBullets = [
  {
    id: 0,
    pre: 'Create and Send Estimate',
    body: 'Send estimates vía Email, Text and Whatsapp.',
  },
  {
    id: 1,
    pre: 'Convert to Invoice',
    body: 'Once the estimates is approved, you can convert it into a invoice.',
  },
  {
    id: 2,
    pre: 'Follow up',
    body: 'Follow up notifications when estimates is reaching expiration date.',
  },
];

export const estimatesSteps = [
  {
    id: 0,
    body: (
      <>
        <p>
          Before creating your first estimate, make sure you have created the
          products or services that you are going to include in the estimate
        </p>
        <p>
          Not product or Services yet?{' '}
          <Link to="/products/new">Click here</Link>
        </p>
      </>
    ),
  },
  {
    id: 1,
    body: (
      <p>
        Select your customer, select expiration date. Select Product or
        Services.
      </p>
    ),
  },
  {
    id: 2,
    body: <p>Save and Send.</p>,
  },
];

function EstimatesOnboard() {
  return (
    <CardWrapper>
      <OnboardGrid>
        <OnboardGrid.Header>
          <h2>Generate more jobs with our estimates</h2>
        </OnboardGrid.Header>
        <OnboardGrid.Left>
          <BulletList
            list={estimatesBullets}
            title="Estimates module features:"
          />
          <StepList stepArray={estimatesSteps} />
        </OnboardGrid.Left>
        <OnboardGrid.Right>
          <img
            className="page-image"
            src={image}
            alt="vector illustration of a person viewing data visualizations"
          />
          <div className="action-group">
            <Link
              className="support-link btn btn-primary p-2 mb-3"
              variant="secondary"
              to="/estimates/new"
            >
              Create new estimate
            </Link>
          </div>
          <div className="support-wrapper">
            <p className="support">Have Questions?</p>
            <a
              className="support-link"
              href="mailto:support@leadsbox.com?subject=Support needed"
            >
              Go to support
            </a>
          </div>
        </OnboardGrid.Right>
      </OnboardGrid>
    </CardWrapper>
  );
}

export default EstimatesOnboard;
