import fetchAPI from '../fetchUtils';

const estimatesAPI = {
  getAll: async (customerId) =>
    fetchAPI.getWithAuth(
      customerId ? `/get-estimates?customerId=${customerId}` : '/get-estimates'
    ),
  get: async (id) => fetchAPI.getWithAuth(`/get-estimate-details/${id}`),
  create: async (body) =>
    fetchAPI.postWithAuth('/create-estimate', body, 'application/json'),
  update: async (body) =>
    fetchAPI.postWithAuth('/update-estimate', body, 'application/json'),
  delete: async (id) => fetchAPI.getWithAuth(`/delete-estimate/${id}`),
  nextNumber: async () => fetchAPI.getWithAuth(`/latest-estimate-number`),
  decline: async (id) => fetchAPI.getWithAuth(`/decline-estimate/${id}`),
  reactivate: async (id) => fetchAPI.getWithAuth(`/reactivate-estimate/${id}`),
};

export default estimatesAPI;
