import fetchAPI from '../fetchUtils';

const appointmentsAPI = {
  getAppointment: async (id) =>
    fetchAPI.getWithAuth(`/get-appointment-details/${id}`),
  getAppointments: async (month, year) =>
    fetchAPI.getWithAuth(`/get-appointments?month=${month}&year=${year}`),
  createAppointment: async (body) =>
    fetchAPI.postWithAuth('/create-appointment', body, 'application/json'),
  updateAppointment: async (body) =>
    fetchAPI.postWithAuth('/update-appointment', body, 'application/json'),
  deleteAppointment: async (id) =>
    fetchAPI.getWithAuth(`/delete-appointment/${id}`),
};

export default appointmentsAPI;
