/* eslint-disable react/prop-types */
import React from 'react';
import LBToggleButton from '../../../UI/LBToogleButton/LBToggleButton';
import { capitalize } from '../../../Helpers/stringFunctions';

function TypeSelector({ type, setType }) {
  const documentTypes = ['invoice', 'estimate', 'receipt'];
  return documentTypes.map((documentType) => (
    <LBToggleButton
      key={documentType}
      color="blue"
      name="documentTypeSelector"
      value={documentType}
      onChange={(e) => setType(e.target.value)}
      checked={type === documentType}
    >
      {capitalize(documentType)}
    </LBToggleButton>
  ));
}

export default TypeSelector;
