import fetchAPI from '../fetchUtils';

const expensesCategoriesAPI = {
  getAll: async () => fetchAPI.getWithAuth('/get-expense-categories'),
  create: async (body) =>
    fetchAPI.postWithAuth('/create-expense-category', body),
  update: async (body) =>
    fetchAPI.postWithAuth('/update-expense-category', body),
  delete: async (id) => fetchAPI.getWithAuth(`/delete-expense-category/${id}`),
};

export default expensesCategoriesAPI;
