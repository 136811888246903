import React, { useEffect, useState } from 'react';
import { useActionData, useLoaderData, useSubmit } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import ReportsSearcher from '../../ReportsSearcher/ReportsSearcher';
import SalesPDF from '../../../PDF/ReportsPDFGenerator/SalesPDF/SalesPDF';
import { getCurrentFormattedDate, getCurrentTimeZone } from '../utils/helpers';
import {
  getCompanyInfo,
  getProducts,
} from '../../../../routes/reports/reportsLA';

function ProductsServicesList() {
  const { categories } = useLoaderData();
  const [fetchedData, setFetchedData] = useState(false);
  const [data, setData] = useState({});
  const [products, setProducts] = useState([]);
  const [selectOption, setSelectOption] = useState('none');
  const [secondOptions, setSecondOptions] = useState('none');
  const [enablePrintDownload, setEnablePrintDownload] = useState(false);
  const [userCompany, setUserCompany] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectOption && secondOptions !== 'none') {
      setEnablePrintDownload(true);
    }
  }, [selectOption, secondOptions]);

  useEffect(() => {
    if (selectOption !== 'none') {
      setSecondOptions('none');
      setFetchedData(false);
    }
  }, [selectOption]);

  const categoriesOption = categories.map((item) => ({
    id: item.family_id,
    title: item.family_name,
  }));

  const productsArray = products.filter(
    (item) => item.family_id === Number(selectOption)
  );
  const productsOptions = productsArray.map((item) => ({
    id: item.product_id,
    title: item.product_name,
  }));

  const submit = useSubmit();
  const actionData = useActionData();

  useEffect(() => {
    if (actionData !== undefined) {
      setData(actionData);
      setFetchedData(true);
    }
  }, [actionData]);

  const handleRunReport = async () => {
    setLoading(true);
    // const values = new FormData();
    // values.append('category', selectOption);
    // values.append('product', secondOptions);
    const values = {
      category: selectOption,
      product: secondOptions,
    };
    submit(values, { method: 'post', encType: 'application/json' });
    setLoading(false);
  };

  const handleGetProducts = async () => {
    const response = await getProducts();
    setProducts(response.products);
  };

  useEffect(() => {
    handleGetProducts();
  }, []);

  const getUserCompany = async () => {
    const { user } = await getCompanyInfo();
    setUserCompany(user.user_company);
  };

  useEffect(() => {
    getUserCompany();
  }, []);

  const pdfFile = (
    <SalesPDF
      fetchedData={data}
      title="Products and Services List Report"
      productsServices
      currentDate={getCurrentFormattedDate()}
      timezone={getCurrentTimeZone()}
      userCompany={userCompany}
    />
  );
  return (
    <div>
      <ReportsSearcher
        pdfFileName="sales-by-services-report"
        options={categoriesOption}
        secondaryOptions={productsOptions}
        setSelectOption={setSelectOption}
        selectOption={selectOption}
        pdfChildren={pdfFile}
        enablePrintDownload={enablePrintDownload}
        twoOptions
        secondOptions={secondOptions}
        setSecondOptions={setSecondOptions}
        handleRunReport={enablePrintDownload ? handleRunReport : () => {}}
        loading={loading}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 40,
        }}
      >
        {fetchedData && (
          <PDFViewer height={705} width={500} showToolbar={false}>
            {pdfFile}
          </PDFViewer>
        )}
      </div>
    </div>
  );
}

export default ProductsServicesList;
