import React from 'react';
import PropTypes from 'prop-types';

function CreateFormTitle({
  formGroup,
  children,
  width = 'form-two',
  showTitle = true,
}) {
  return (
    <>
      {showTitle && (
        <h5 className="mb-4 mt-4 form__group-header">{formGroup}</h5>
      )}
      <div className={width}>{children}</div>
    </>
  );
}

CreateFormTitle.propTypes = {
  showTitle: PropTypes.bool,
  formGroup: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.string,
};

export default CreateFormTitle;
