/* eslint react/prop-types: 0 */
/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

import Row from './Row';
import Info from './Info';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';

export default function Body({
  columns,
  rows,
  onChange,
  onClick,
  onDelete,
  errors,
}) {
  const total = rows.reduce((acc, row) => +acc + +row.getValue('amount'), 0);
  return (
    <tbody>
      {rows.length === 0 ? (
        <Info
          className="edit-table__empty-alert"
          message="This check is empty, add some items to visualize them here."
          colspan={columns.length + 1}
        />
      ) : (
        rows?.map((row, index) => (
          <Row
            key={row.id}
            row={row}
            index={index}
            onChange={(value) => onChange('items', index, value)}
            onDeleteItem={() => onDelete('items', index)}
            onClick={onClick}
            errors={errors && errors[index]}
          />
        ))
      )}
      {total ? (
        <Info
          message={
            <p>
              Total:
              <span className="total__value">{formatCurrency(total, 2)}</span>
            </p>
          }
          colspan={columns.length}
          className="total__row"
        />
      ) : null}
    </tbody>
  );
}
