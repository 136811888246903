import dashboardAPI from '../../API/routesAPI/dashboardAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

export const notificationsLoader = async () => {
  const response = await dashboardAPI.getNotifications();
  return handleResposeAbstraction({
    response,
  });
};

export const notificationsAction = async ({ request }) => {
  const body = await request.json();
  const stringBody = JSON.stringify(body);
  const response = await dashboardAPI.setSeenNotifications(stringBody);
  return handleResposeAbstraction({
    response,
  });
};

export const notificationsDeleteAction = async ({ request }) => {
  const { searchParams } = new URL(request.url);
  const id = searchParams.get('id');
  const isAll = searchParams.get('all') === 'true';

  if (!id && !isAll)
    throw new Error(
      'You need to specify a notification to delete or set "all" query string'
    );

  const response = await dashboardAPI.deleteNotifications(
    id || (isAll && 'all')
  );

  return handleResposeAbstraction({
    response,
    showSuccess: true,
  });
};

export const quickLinksLoader = async () => {
  const response = await dashboardAPI.getQuickLinks();
  return handleResposeAbstraction({
    response,
    mapFt: (result) =>
      result.data.map((item) => ({ id: item.id, count: item.count })),
  });
};

export const quickLinksAction = async ({ request }) => {
  const body = await request.json();
  const newBody = JSON.stringify(body);
  const response = await dashboardAPI.setQuickLinks(newBody);
  const data = await handleResposeAbstraction({
    response,
  });

  return data;
};
export const widgetsLoader = async ({ request }, endpoint) => {
  const range = new URL(request.url).searchParams.get('range');

  const response = await dashboardAPI.getWidgets(
    `/dashboard/${endpoint}/${range}`
  );

  // This is because the backend is sending inconsistent key names
  // in the response objects for one widget
  const { data, list, message } = await handleResposeAbstraction({ response });
  return {
    message,
    data: data || list,
  };
};

export const reviewModalLoader = async () => {
  const response = await dashboardAPI.getReviewMessage();
  return handleResposeAbstraction({ response });
};

export const reviewModalAction = async ({ request }) => {
  const body = await request.json();
  const stringBody = JSON.stringify(body);
  const response = await dashboardAPI.sendReviewMessage(stringBody);
  const result = await response.json();

  return handleResposeAbstraction({
    response,
    result,
    showSuccess: true,
    redirectTo: '/',
  });
};

export const contactModalLoader = async () => {
  const response = await dashboardAPI.getContactMessage();
  return handleResposeAbstraction({ response });
};

export const contactModalAction = async ({ request }) => {
  const body = await request.json();
  const stringBody = JSON.stringify(body);
  const response = await dashboardAPI.sendContactMessage(stringBody);
  const result = await response.json();

  return handleResposeAbstraction({
    response,
    result,
    showSuccess: true,
    redirectTo: '/',
  });
};
