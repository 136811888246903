import { useEffect, useState } from 'react';
import { useFetcher } from 'react-router-dom';

export default function useWidgetFilter(endpoint, defaultFilter) {
  const [filter, setFilter] = useState(defaultFilter || 'thisYear');
  const fetcher = useFetcher();

  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher?.data) {
      fetcher.load(`/dashboard/widgets/${endpoint}?range=${filter}`);
    }
  }, [fetcher]);

  const handleSelectChange = (e) => {
    setFilter(e.target.value);
    fetcher.submit(
      { range: e.target.value, endpoint },
      {
        method: 'get',
        action: `/dashboard/widgets/${endpoint}`,
      }
    );
  };

  return {
    filter,
    handleSelectChange,
    serverMessage: fetcher.data?.message,
    fetcherData: fetcher.data?.data,
    fetcherState: fetcher.state,
    fetcher,
  };
}
