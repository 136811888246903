import React from 'react';
// import './styles/StripeLinkSuccess.scss';
// import { useLoaderData } from 'react-router-dom';

function StripePaymentSuccess() {
  //   const loaderInfo = useLoaderData();
  //   console.log('LOADER INFO PAYMENT', loaderInfo);
  //   const navigate = useNavigate();
  //   const handleNavigatePayments = () => {
  //     navigate('/payments');
  //   };

  return (
    <div>
      <div className="stripe-success-container">
        <p className="credit-card-payment-success">
          Payment with Credit Card has been successfully sent. Please verify it
          on Payments section.
        </p>
      </div>
    </div>
  );
}

export default StripePaymentSuccess;
