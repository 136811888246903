/* eslint-disable react/prop-types */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import FONT_STYLES from '../../PDF/FontStyles';
import PDFBoilerplate from '../../PDF/PDFBoilerplate';
import PDFError from '../../PDF/PDFError';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';

const rootStyles = getComputedStyle(document.body);

const styles = StyleSheet.create({
  ...FONT_STYLES,
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '100%',
    fontSize: 8,
  },
  headingRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '6px 12px',
    marginTop: 6,
    fontSize: 11,
    width: '100%',
    borderBottom: `1px solid ${rootStyles.getPropertyValue('--bs-gray-200')}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 8px',
    width: '100%',
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '6px 8px',
    borderRadius: '3px',
    width: '100%',
    backgroundColor: rootStyles.getPropertyValue('--bs-gray-200'),
    fontWeight: 600,
  },
  netIncomeRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 8px',
    borderRadius: '3px',
    color: rootStyles.getPropertyValue('--bs-white'),
    fontWeight: 600,
    fontSize: 11,
  },
  col: {
    padding: '0 4px',
  },
});

function ProfitAndLossPDF({ dateFrom, dateTo, pdfData, userData }) {
  if (!pdfData || !userData) return <PDFError />;

  const netIncome = pdfData.total_invoice - pdfData.total_expense;

  return (
    <PDFBoilerplate
      title="Profit and Loss Report"
      dateFrom={dateFrom}
      dateTo={dateTo}
      logoBase64={userData.user.company_logo_base64}
      companyName={userData.user.user_company}
    >
      <View style={styles.container}>
        <View style={styles.headingRow}>
          <Text>Incomes</Text>
        </View>

        <View style={styles.row}>
          <View style={{ ...styles.col, width: '60%' }}>
            <Text>Products</Text>
          </View>
          <View style={{ ...styles.col, width: '40%' }}>
            <Text>$ {formatCurrency(pdfData.incomes.product, 2, false)}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ ...styles.col, width: '60%' }}>
            <Text>Services</Text>
          </View>
          <View style={{ ...styles.col, width: '40%' }}>
            <Text>$ {formatCurrency(pdfData.incomes.service, 2, false)}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ ...styles.col, width: '60%' }}>
            <Text>Collected Tax</Text>
          </View>
          <View style={{ ...styles.col, width: '40%' }}>
            <Text>$ {formatCurrency(pdfData.collected_tax, 2, false)}</Text>
          </View>
        </View>
        <View style={styles.totalRow}>
          <View style={{ ...styles.col, width: '60%' }}>
            <Text>Total Sales (Gross Profit)</Text>
          </View>
          <View style={{ ...styles.col, width: '40%' }}>
            <Text>$ {formatCurrency(pdfData.total_invoice, 2, false)}</Text>
          </View>
        </View>

        <View style={styles.headingRow}>
          <Text>Expenses</Text>
        </View>

        {pdfData.expenses.map((expense) => (
          <View key={expense.id} style={styles.row}>
            <View style={{ ...styles.col, width: '60%' }}>
              <Text>{expense.name}</Text>
            </View>
            <View style={{ ...styles.col, width: '40%' }}>
              <Text>$ {formatCurrency(expense.amount, 2, false)}</Text>
            </View>
          </View>
        ))}
        <View style={styles.totalRow}>
          <View style={{ ...styles.col, width: '60%' }}>
            <Text>Total Expenses</Text>
          </View>
          <View style={{ ...styles.col, width: '40%' }}>
            <Text>$ {formatCurrency(pdfData.total_expense, 2, false)}</Text>
          </View>
        </View>

        <View
          style={{
            ...styles.netIncomeRow,
            backgroundColor:
              netIncome > 0
                ? rootStyles.getPropertyValue('--bs-success')
                : rootStyles.getPropertyValue('--bs-danger'),
          }}
        >
          <View style={{ ...styles.col, width: '60%' }}>
            <Text>Net Income</Text>
          </View>
          <View style={{ ...styles.col, width: '40%' }}>
            <Text>$ {formatCurrency(netIncome, 2, false)}</Text>
          </View>
        </View>
      </View>
    </PDFBoilerplate>
  );
}

export default ProfitAndLossPDF;
