import fetchAPI from '../fetchUtils';

const dashboardAPI = {
  getQuickLinks: async () => fetchAPI.getWithAuth('/dashboard/quick-links'),
  setQuickLinks: async (body) =>
    fetchAPI.postWithAuth('/dashboard/quick-links', body, 'application/json'),
  getWidgets: async (url) => fetchAPI.getWithAuth(url),
  getContactMessage: async () => fetchAPI.getWithAuth('/get-c-form-settings'),
  sendContactMessage: async (body) =>
    fetchAPI.postWithAuth('/create-form/send-sms', body, 'application/json'),
  getReviewMessage: async () => fetchAPI.getWithAuth('/get-review-settings'),
  sendReviewMessage: async (body) =>
    fetchAPI.postWithAuth('/send-review-message', body, 'application/json'),
  getNotifications: async () => fetchAPI.getWithAuth('/get-notifications'),
  setSeenNotifications: async (body) =>
    fetchAPI.postWithAuth('/notification-seen', body, 'application/json'),
  deleteNotifications: async (param) =>
    fetchAPI.getWithAuth(`/delete-notification/${param}`),
};

export default dashboardAPI;
