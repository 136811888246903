export default function createFileForm(values, formData = {}) {
  const newFormData = new FormData();
  Object.keys(values).forEach((key) => {
    newFormData.append(key, values[key] ? values[key] : '');
  });
  const formDataKeys = Object.keys(formData);
  if (formDataKeys.length > 0) {
    formDataKeys.forEach((key) => {
      newFormData.delete(key);
      newFormData.append(key, formData[key], formData[key].name);
    });
  }
  return newFormData;
}
