import React from 'react';
import Proptypes from 'prop-types';
import './StatusIndicator.scss';

function StatusIndicator({ children, color = 'blue' }) {
  return (
    <span
      className="table-status-chip text-nowrap"
      style={{ '--status-color': `var(--bs-${color})` }}
    >
      {children}
    </span>
  );
}

StatusIndicator.propTypes = {
  children: Proptypes.string.isRequired,
  color: Proptypes.string,
};

export default StatusIndicator;
