import React from 'react';
import Proptypes from 'prop-types';

function ModalTitle({ title, subtitle }) {
  return (
    <h1 className="lb-modal__title">
      {title} <span id="estimatesId">{subtitle}</span>
    </h1>
  );
}
ModalTitle.propTypes = {
  title: Proptypes.string,
  subtitle: Proptypes.string,
};
ModalTitle.defaultProps = {
  title: 'title',
  subtitle: '',
};

export default ModalTitle;
