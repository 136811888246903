/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useLoaderData, useSubmit, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import FormWrapper from '../Forms/builders/FormWrapper';
import CreateForm from '../Forms/CreateForm/CreateForm';
import { isPhoneNumber } from '../Forms/yup/customValidations';

function CustomersNew({ modal = false }) {
  const loaderData = useLoaderData();
  const data = loaderData?.data || {};

  // Use this to determine where to redirect if this is a customer
  // created from the modal
  const [searchParams] = useSearchParams();
  const customerType = searchParams.get('customerType');
  const serviceClientId = JSON.parse(searchParams.get('serviceClientId'));
  const customerId = JSON.parse(searchParams.get('customerId'));

  const fields = [
    {
      formGroup: 'Customer Information',
      showTitle: !modal,
      groupChildren: [
        {
          name: 'first_name',
          label: 'First Name',
          initialValue: data?.name?.first_name || '',
          type: Yup.string().required('Please complete the field'),
          required: true,
          className: modal ? 'flex__span-12' : '',
        },
        {
          name: 'last_name',
          label: 'Last Name',
          initialValue: data?.name?.last_name || '',
          type: Yup.string(),
          className: modal ? 'flex__span-12' : '',
        },
        {
          name: 'email',
          label: 'Email',
          initialValue: data?.contact?.email || '',
          type: Yup.string().email().required('Please add email'),
          formType: 'email',
          required: true,
          className: modal ? 'flex__span-12' : '',
        },
        {
          name: 'mobile',
          label: 'Mobile',
          formType: 'phone',
          initialValue: data?.contact?.mobile || '',
          type: isPhoneNumber.required('Please add phone number'),
          required: true,
          className: modal ? 'flex__span-12' : '',
        },
        {
          name: 'phone',
          label: 'Phone',
          formType: 'phone',
          initialValue: data?.contact?.phone || '',
          type: isPhoneNumber.nullable(),
          className: modal ? 'd-none' : '',
        },
        {
          name: 'fax',
          label: 'Fax',
          formType: 'phone',
          initialValue: data?.contact?.fax || '',
          type: isPhoneNumber.nullable(),
          className: modal ? 'd-none' : '',
        },
        {
          name: 'company',
          label: 'Company',
          initialValue: data?.company || '',
          type: Yup.string(),
          className: modal ? 'd-none' : '',
        },
      ],
    },
    {
      formGroup: modal ? 'Address' : 'Billing Address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          className: 'flex__span-12',
          initialValue: '',
          type: Yup.string(),
          formType: 'autocompleteAddress',
        },
        {
          name: 'street_address',
          label: 'Street Address',
          initialValue: data?.address?.street_address_1 || '',
          type: Yup.string().required('This field is mandatory'),
          required: true,
          autocomplete: 'address-line1',
          className: modal ? 'flex__span-12' : '',
        },
        {
          name: 'street_address_2',
          label: 'Street Address 2',
          initialValue: data?.address?.street_address_2 || '',
          type: Yup.string(),
          autocomplete: 'address-line2',
          className: modal ? 'flex__span-12' : '',
        },
        {
          name: 'city',
          label: 'City',
          initialValue: data?.address?.city || '',
          type: Yup.string().required('This field is mandatory'),
          required: true,
          className: modal ? 'flex__span-12' : '',
        },
        {
          name: 'state',
          label: 'State',
          initialValue: data?.address?.state || '',
          type: Yup.string().required('This field is mandatory'),
          required: true,
          className: modal ? 'flex__span-12' : '',
        },
        {
          name: 'zipcode',
          label: 'Zip code',
          initialValue: data?.address?.zipcode || '',
          type: Yup.string().required('This field is mandatory'),
          required: true,
          className: modal ? 'flex__span-12' : '',
        },
        {
          name: 'country',
          label: 'Country',
          type: Yup.string().required('This field is mandatory'),
          required: true,
          initialValue: data?.address?.country || '',
          className: modal ? 'flex__span-12' : '',
        },
      ],
    },
    {
      formGroup: 'Notes',
      showTitle: !modal,
      groupChildren: [
        {
          name: 'ref_info', // this should be called notes
          label: 'Customer details notes',
          initialValue: data?.notes || '',
          type: Yup.string(),
          formType: 'textarea',
          className: modal ? 'd-none' : 'flex__span-12',
        },
      ],
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(
      {
        ...values,
        customerType,
        serviceClientId,
        customerId,
      },
      { method: 'post' }
    );
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  return (
    <>
      <ActionErrorInformation />
      <FormWrapper
        id="new-customer"
        className="flex__form-wrapper"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        label={loaderData ? 'Save changes' : 'Create customer'}
        dirty={formik.dirty}
        isValid={formik.isValid}
        portalId={
          !modal
            ? 'new-customer-submit-container'
            : 'offcanvas-action-group-left'
        }
      >
        <CreateForm structure={fields} {...formik} />
      </FormWrapper>

      {!modal && (
        <div style={{ position: 'sticky', bottom: 0, right: 0 }}>
          <div
            className="sticky__submit-container"
            id="new-customer-submit-container"
          />
        </div>
      )}
    </>
  );
}

CustomersNew.propTypes = {
  modal: PropTypes.bool,
};

export default CustomersNew;
