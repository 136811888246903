/* eslint-disable react/jsx-props-no-spreading */

import { Spinner } from 'react-bootstrap';

/* eslint-disable react/prop-types */
function PDFViewer({
  title,
  style,
  className,
  instance,
  innerRef,
  showToolbar = true,
  ...props
}) {
  // const [instance, updateInstance] = usePDF({ document: children });

  // useEffect(updateInstance, [children]);

  return instance.loading || !instance ? (
    <div
      style={{
        display: 'grid',
        placeContent: 'center',
        backgroundColor: 'var(--bs-gray-100)',
        ...style,
      }}
    >
      <Spinner />
    </div>
  ) : (
    <iframe
      title={title}
      ref={innerRef}
      style={style}
      src={
        instance.url
          ? `${instance.url}#toolbar=${showToolbar ? '1' : '0'}&view=FitH`
          : null
      }
      className={className}
      {...props}
    />
  );
}

export default PDFViewer;
