/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import PropTypes from 'prop-types';

import * as Popover from '@radix-ui/react-popover';
import { Button } from 'react-bootstrap';
import IconButton from '../../UI/IconButton/IconButton';
import { Reschedule } from '../../UI/Icons/Icons';
import { selectHours, selectMidday, selectMinutes } from './constants';
import useRoveFocus from './useRoveFocus';

function ListItem({
  name,
  value,
  currentValue,
  changeHandler,
  focus,
  setFocus,
  index,
  children,
}) {
  const ref = React.useRef(null);

  // We scroll to the selected <li>
  React.useEffect(() => {
    const scrollToTarget = () => {
      if (ref.current && currentValue === value) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    // Scroll to the target element on component load
    scrollToTarget();
  }, []);

  React.useEffect(() => {
    if (focus) {
      // Move element into view when it is focused
      ref.current.focus();
    }
  }, [focus]);

  const handleSelect = React.useCallback(
    (e) => {
      if (document.activeElement === ref.current) {
        // setting focus to that element when it is selected
        setFocus(index);

        if (e.type === 'click' || e.code === 'Enter') {
          e.stopPropagation();
          const fakeEvent = {
            target: {
              value: e.target.dataset.value,
              type: 'select-one',
            },
          };

          changeHandler(fakeEvent);
        }
      }
    },
    [currentValue, index, setFocus]
  );

  return (
    <li
      ref={ref}
      role="option"
      aria-label={`${value} ${name}${value > 1 ? 's' : ''}`}
      aria-selected="false"
      data-value={value}
      onClick={(e) => handleSelect(e)}
      onKeyDown={(e) => handleSelect(e)}
      className={`faux-select__item ${focus ? 'focused' : null} ${
        currentValue === value ? 'selected' : null
      }`}
      tabIndex={focus ? 0 : -1}
    >
      {children}
    </li>
  );
}

function List({
  name,
  selectName,
  currentValue,
  changeHandler,
  selectOptions,
}) {
  const getInitialValue = (val) => {
    const index = selectOptions.findIndex((obj) => obj.value === val);
    return index >= 0 ? index : 0;
  };
  const listRef = React.useRef(null);
  const initialValue = getInitialValue(currentValue);
  const [focus, setFocus] = useRoveFocus(
    listRef,
    selectOptions.length,
    initialValue
  );

  return (
    <div className="faux-select__overflow-wrapper" tabIndex="-1">
      <ul
        ref={listRef}
        role="listbox"
        tabIndex="-1"
        aria-label={`Select ${name} ${selectName}`}
        className="faux-select__list"
      >
        {selectOptions.map((optionValue, index) => (
          <ListItem
            focus={focus === index}
            setFocus={setFocus}
            index={index}
            key={`${name}-${selectName}-${optionValue.value}`}
            name={name}
            value={optionValue.value}
            changeHandler={changeHandler}
            currentValue={currentValue}
            selectOptions={selectOptions}
          >
            {optionValue.value}
          </ListItem>
        ))}
      </ul>
    </div>
  );
}

function TimePickerPopover({
  name,
  hours,
  minutes,
  handleHours,
  handleMinutes,
  midday,
  handleMidday,
}) {
  const [show, setShow] = React.useState(false);
  const containerRef = React.useRef(null);

  return (
    <>
      <Popover.Root open={show} onOpenChange={(open) => setShow(open)} modal>
        <Popover.Anchor
          style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
        />
        <Popover.Trigger asChild>
          <IconButton
            size={18}
            style={{ marginLeft: 'auto' }}
            onClick={() => setShow(true)}
          >
            <Reschedule />
          </IconButton>
        </Popover.Trigger>
        <Popover.Portal container={containerRef.current}>
          <Popover.Content
            onOpenAutoFocus={(event) => event.preventDefault()}
            className="time-picker__selector shadow"
            sideOffset={64}
            onKeyDown={(e) => {
              if (e.code === 'Escape') {
                setShow(false);
                e.stopPropagation();
              }
            }}
            onPointerDownOutside={(e) => {
              e.stopPropagation();
              setShow(false);
            }}
          >
            <div className="time-selector__container">
              <List
                selectOptions={selectHours}
                name={name}
                selectName="hours"
                currentValue={hours}
                changeHandler={handleHours}
              />

              <List
                selectOptions={selectMinutes}
                name={name}
                selectName="minutes"
                currentValue={minutes}
                changeHandler={handleMinutes}
              />
              <List
                selectOptions={selectMidday}
                name={name}
                selectName="midday"
                currentValue={midday}
                changeHandler={handleMidday}
              />
            </div>
            <div className="time-selector__action-group">
              <Popover.Close asChild>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShow((prevShow) => !prevShow);
                  }}
                >
                  Confirm
                </Button>
              </Popover.Close>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <div ref={containerRef} />
    </>
  );
}

List.propTypes = {
  name: PropTypes.string,
  selectName: PropTypes.string,
  currentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  changeHandler: PropTypes.func,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
};

ListItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  changeHandler: PropTypes.func,
  children: PropTypes.node,
  focus: PropTypes.bool,
  setFocus: PropTypes.func,
  index: PropTypes.number,
};

TimePickerPopover.propTypes = {
  name: PropTypes.string,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  handleHours: PropTypes.func,
  handleMinutes: PropTypes.func,
  midday: PropTypes.string,
  handleMidday: PropTypes.func,
};

export default TimePickerPopover;
