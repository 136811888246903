/* eslint-disable max-classes-per-file */
/* eslint-disable  lines-between-class-members */
import React, { useState } from 'react';
import { useLoaderData, Outlet } from 'react-router-dom';
import { parseISO } from 'date-fns';
import LateralModal from '../UI/LateralModal';
import InvoicesOnboard from './InvoicesOnboard/InvoicesOnboard';
import InvoicesTable from './InvoicesTable';

// creates an array of objects with the invoices that are recurrent
function transformArrayToObject(initialState) {
  const RecurrentRow = class RecurrentRow {
    subRows = [];
    status = '';
    invoice_total = 0;
    is_recurrent = 1;
    invoice_balance = 0;
    status_label = '';
  };
  const newState = {
    noRecurrent: [],
  };

  initialState.forEach((item) => {
    const nextItem = { ...item };
    if (nextItem.is_recurrent === 0 && nextItem.recurrent_from === null) {
      newState.noRecurrent.push(nextItem);
      return;
    }
    const recurFrom = nextItem.recurrent_from || nextItem.invoice_id;
    if (nextItem.recurrent_from === null) nextItem.is_recurrent = 2;

    if (!newState[recurFrom]) {
      newState[recurFrom] = new RecurrentRow();
    }
    const number = nextItem.invoice_total * 1;
    newState[recurFrom].subRows.push(nextItem);
    newState[recurFrom].invoice_total += number;
    newState[recurFrom].invoice_balance += nextItem.invoice_balance * 1;
    newState[recurFrom].client_name = nextItem.client_name;
    newState[recurFrom].client_id = nextItem.client_id;
  });
  const nextStateKeys = Object.keys(newState);

  let nextState = nextStateKeys
    .filter((items) => items !== 'noRecurrent')
    .map((key) => {
      const nextItem = newState[key];
      nextItem.recurrent_id = key;
      // eslint-disable-next-line
      return nextItem;
    });
  nextState = [...nextState, ...newState.noRecurrent];
  return nextState;
}

class TableRow {
  constructor({
    client_id: clientId,
    client_name: clientName,
    invoice_balance: invoiceBalance = 0,
    invoice_date_created: invoiceDateCreated,
    invoice_date_due: invoiceDateDue,
    invoice_id: invoiceId,
    invoice_number: invoiceNumber,
    is_recurrent: invoiceRecurrent,
    invoice_total: invoiceTotal = 0,
    invoice_email_status_id: isSent,
    status_class: statusClass,
    status_label: statusLabel,
    subRows = [],
  }) {
    this.status = statusLabel
      ? {
          label: statusLabel,
          color: statusClass,
        }
      : null;
    this.filterChips = `filter__${statusLabel}`;
    this.invoiceNumber = invoiceNumber;
    this.send = isSent
      ? {
          code: isSent ? `message${isSent}` : 'message1',
          label: isSent === 2 ? 'sent' : 'not sent',
          type: 'message',
        }
      : null;

    this.dateCreated = invoiceDateCreated ? parseISO(invoiceDateCreated) : '';
    this.dateDue = invoiceDateDue ? parseISO(invoiceDateDue) : '';
    this.amount = +invoiceTotal;
    this.id = invoiceId;
    this.balance = +invoiceBalance;
    this.customerName = {
      href: `/customers/${clientId}`,
      label: clientName,
    };
    this.recurrent = invoiceRecurrent;
    this.filterClient = clientName;
    this.subRows = subRows.map((subRowInvoice) => new TableRow(subRowInvoice));
  }
}

const createTable = (invoices) => {
  const newInvoices = transformArrayToObject(invoices); // transform array to object

  const invoicesNext = newInvoices.map((invoice) => new TableRow(invoice));
  return invoicesNext;
};

function Invoices() {
  const loaderData = useLoaderData(); // get loader data
  const { invoices, invoice_statuses: statuses } = loaderData; // get invoices and statuses from loader data
  const invoicesMockUp = createTable(invoices);

  // Format filter array
  const columnFilter = [];
  Object.keys(statuses).forEach((key) => {
    columnFilter.push({
      label: statuses[key].label,
      color: statuses[key].class,
      count: statuses[key].count,
    });
  });

  const data = React.useMemo(() => invoicesMockUp, [invoices]);

  // Modal status
  const [isExpanded, setIsExpanded] = useState(false);
  if (invoices.length === 0) return <InvoicesOnboard />; // if there are no invoices, render onboarding component
  return (
    <>
      <LateralModal expanded={isExpanded} setIsExpanded={setIsExpanded}>
        <Outlet context={[setIsExpanded, isExpanded]} />
      </LateralModal>
      <InvoicesTable dataTable={data} columnFilter={columnFilter} />
    </>
  );
}
export default Invoices;
