/* eslint-disable react/prop-types */
import React from 'react';

function DataInput({ onChange, className = '', value, firstItemRef }) {
  return (
    <input
      ref={firstItemRef}
      className={`table__input ${className}`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

export default DataInput;
