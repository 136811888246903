import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';
import { I_Colors } from '../propTypes';

function Background({ colors }: { colors: I_Colors }) {
  return (
    <Svg style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
      <Path
        fill={colors.color1}
        d="M612.72 0 612.72 113.45 385.46 113.45 367.33 107.89 0 107.89 0 0 612.72 0z"
      />
      <Path
        fill={colors.color2}
        d="M612.72 113.45 612.72 120.56 408.67 120.56 385.47 113.45 612.72 113.45z"
      />
    </Svg>
  );
}

export default Background;
