/* eslint-disable react/prop-types */
import React from 'react';
import { Form } from 'react-bootstrap';

function LBCheckInput({
  element,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}) {
  return (
    <Form.Check
      label={element.label}
      disabled={element?.disabled}
      isInvalid={touched[element.name] && !!errors[element.name]}
      isValid={touched[element.name] && !errors[element.name]}
      name={element.name}
      onBlur={handleBlur}
      onChange={(e) => {
        if (e.nativeEvent.data === ',')
          e.target.value = e.target.value.replace(/,/g, '.');
        // if (element?.inputMode === 'numeric') e.target.value = +e.target.value;
        handleChange(e);
        if (element?.change) element.change(e);
      }}
      required={element?.required}
      type={element.formType}
      checked={Boolean(values[element.name])}
    />
  );
}

export default LBCheckInput;
