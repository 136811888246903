import { parseISO, set, toDate } from 'date-fns';

export const setCurrentDay = ({
  initialStartDate,
  initialEndDate,
  dateValue,
}) => {
  if (!initialStartDate || !initialEndDate) return {};

  const startDate = new Date(initialStartDate);
  const endDate = new Date(initialEndDate);
  const day = toDate(parseISO(dateValue));

  // Set the day of the start and end dates to match the current_day field
  const startTime = set(startDate, {
    month: day.getMonth(),
    date: day.getDate(),
    year: day.getFullYear(),
  });
  const endTime = set(endDate, {
    month: day.getMonth(),
    date: day.getDate(),
    year: day.getFullYear(),
  });

  return { day, startTime, endTime };
};

export const setFormikValues = ({ updateCurrentDay, formik, nextDate }) => {
  updateCurrentDay(nextDate.day);
  formik.setFieldValue('start_date', nextDate.startTime);
  formik.setFieldValue('end_date', nextDate.endTime);
  formik.setTouched({
    ...formik.touched,
    end_date: false,
    start_date: false,
    current_day: false,
  });
};
