// This component will be used to create or update products categories
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  useSubmit,
  useParams,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateForm from '../../Forms/CreateForm/CreateForm';
import extractValues from '../../Helpers/extractValues';

function CreateProductCategory() {
  const { name } = useParams();
  const navigate = useNavigate();
  const submit = useSubmit();
  const navigation = useNavigation();

  const fields = [
    {
      name: 'family_name',
      label: 'Category name',
      initialValue: name || '',
      type: Yup.string().required('Please complete the field'),
      required: true,
    },
  ];

  // Initial values
  const initialValues = extractValues('initialValue', fields);
  // YUP
  const SchemaObject = extractValues('type', fields);
  const formSchema = Yup.object().shape(SchemaObject);
  // Submit action

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async () => {
      submit(formik.values, { method: 'post' });
    },
  });

  // close modal on cancel
  const handleClose = (e) => {
    e.preventDefault();
    navigate('/products/categories');
  };

  return (
    <Form
      className="mt-2"
      noValidate
      onSubmit={formik.handleSubmit}
      id="CreateProductCategory"
    >
      <CreateForm structure={fields} {...formik} />
      <hr className="mt-5" />
      <div className="d-flex flex-column justify-content-end">
        <div className="d-flex justify-content-end gap-2 ">
          <Button variant="secondary" onClick={(e) => handleClose(e)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={navigation.state !== 'idle'}
            form="CreateProductCategory"
          >
            {navigation.state !== 'idle' ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default CreateProductCategory;
