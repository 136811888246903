/* eslint-disable react/prop-types */
import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import { parseISO } from 'date-fns';

import './FontSetup';
import FONT_STYLES from './FontStyles';

const rootStyles = getComputedStyle(document.body);

const styles = StyleSheet.create({
  ...FONT_STYLES,
  header: {
    display: 'flex',
    paddingLeft: '12px',
    flexDirection: 'column',
    gap: '6px',
    marginBottom: '36px',
  },

  headingText: {
    fontSize: '20px',
  },

  date: {
    fontSize: '10px',
    color: rootStyles.getPropertyValue('--bs-gray-600'),
  },
});

function PDFHeader({ title, dateFrom, dateTo }) {
  return (
    <View style={styles.header}>
      <Text style={styles.headingText}>{title}</Text>
      {dateFrom && dateTo && (
        <Text style={{ ...styles.date, ...styles.italic }}>
          <Text>
            {parseISO(dateFrom).toLocaleDateString('en-US', {
              dateStyle: 'long',
            })}
          </Text>{' '}
          to{' '}
          {parseISO(dateTo).toLocaleDateString('en-US', {
            dateStyle: 'long',
          })}
        </Text>
      )}
    </View>
  );
}

export default PDFHeader;
