import React from 'react';
import EasyCropper from 'react-easy-crop';
import { useImageCropContext } from './ImageCropProvider';

function Cropper() {
  const {
    image,
    zoom,
    minZoom,
    setZoom,
    rotation,
    setRotation,
    crop,
    setCrop,
    onCropComplete,
  } = useImageCropContext();

  return (
    <EasyCropper
      image={image || undefined}
      crop={crop}
      zoom={zoom}
      rotation={rotation}
      cropShape="rect"
      minZoom={minZoom}
      aspect={1}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
      setRotation={setRotation}
      showGrid={false}
      cropSize={{ width: 185, height: 185 }}
      restrictPosition={false}
      style={{
        containerStyle: {
          height: 299,
          width: 299,
        },
      }}
    />
  );
}

export default Cropper;
