import React from 'react';
import { useLoaderData } from 'react-router-dom';
import AccountForm from './AccountForm';

function GetAccount() {
  const loader = useLoaderData();

  return <AccountForm data={loader} />;
}

export default GetAccount;
