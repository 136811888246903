// Modal in which the user can add a new category. Theres moments problem for the submit button and close so the modal visibilite its handled by the routes and parent component.
import React from 'react';
import propTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

function ModalForActions({ children, show, onHide, title }) {
  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

ModalForActions.propTypes = {
  children: propTypes.node.isRequired,
  show: propTypes.bool.isRequired,
  onHide: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
};

export default ModalForActions;
