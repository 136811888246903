const schema = () => [
  { header: 'Filter', accessorKey: 'filterChips', className: 'd-none' },
  {
    header: 'Id',
    accessorKey: 'number',
    mobileLabel: 'true',
  },
  {
    header: 'State',
    accessorKey: 'print_state',
    type: 'statusIndicator',
  },
  {
    header: 'To',
    accessorKey: 'to',
    mobileLabel: 'true',
  },
  {
    header: 'Payment Date',
    accessorKey: 'payment_date',
    type: 'date',
    mobileLabel: 'true',
  },
  //   {
  //     header: 'Actions',
  //     accessorKey: 'more',
  //     className:
  //       filter === 'filter__Printed' || filter === 'filter__Tray' ? 'd-none' : '',
  //     enableSorting: false,
  //   },

  {
    header: 'Total',
    accessorKey: 'total',
    type: 'money',
  },
  {
    header: '',
    accessorKey: 'print_actions',
    className: 'd-flex justify-content-end',
    // className: filter === 'filter__Printed' ? 'd-none' : '',
    enableSorting: false,
  },
];

export const printStates = [
  {
    id: 0,
    label: 'Not Printed',
    color: 'red',
  },
  {
    id: 1,
    label: 'Tray',
    color: 'blue',
  },
  {
    id: 2,
    label: 'Printed',
    color: 'green',
  },
];

export default schema;
