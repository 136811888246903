/* eslint-disable no-shadow */
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import {
  useRouteLoaderData,
  useSubmit,
  useNavigate,
  useLoaderData,
} from 'react-router-dom';
import { usePDF } from '@react-pdf/renderer';
import CheckPDF from '../PDF/CheckPDF';
import ReactTablev8 from '../../UI/Table/ReactTablev8';
import PDFViewer from '../PDF/lbPDFViewer';
import IconButton from '../../UI/IconButton/IconButton';
import { Download, Print, RemoveTray } from '../../UI/Icons/Icons';
import ConfirmPrintModal from '../ConfirmPrintModal';
import ModalController from '../ModalController';
import schema from './schema';
import useGetCheckTableData from '../useGetCheckTableData';

const dateTag = new Date()
  .toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })
  .replaceAll(' ', '_')
  .replaceAll(',', '-')
  .toLowerCase();

export default function Tray() {
  // States
  const [show, setShow] = React.useState(false);
  const [selected, setSelected] = React.useState(0);

  // Hooks
  const iFrameRef = React.useRef(null);

  // RRDOM hooks
  const submit = useSubmit();
  const navigate = useNavigate();
  const { check_statuses: statuses } = useRouteLoaderData('checks');
  const { result: dbChecks } = useLoaderData();
  const checksToPrint = dbChecks?.filter((check) => check.print_state === 1);

  const nextChecks = useGetCheckTableData({ dbChecks, statuses });
  const data = React.useMemo(() => nextChecks, [nextChecks]);

  // PDF hooks
  const [instance] = usePDF({
    document: <CheckPDF data={dbChecks} />,
  });

  // React.useEffect(
  //   () => updateInstance(<CheckPDF data={dbChecks} />),
  //   [recipients.data]
  // );

  const fileName = `checks-${dateTag}.pdf`;

  const handlePrint = () => {
    if (!iFrameRef.current) return;
    if (instance.loading) return;
    iFrameRef.current.focus();
    iFrameRef.current.contentWindow.print();
    setShow(true);
  };

  const clearTray = () => {
    const checksIds = checksToPrint.map((check) => check.id);
    submit(
      { checks: checksIds },
      { method: 'POST', encType: 'application/json' }
    );
  };

  const handleSuccess = () => {
    clearTray();
    setShow(false);
  };

  return (
    <>
      <ConfirmPrintModal
        show={show}
        setShow={setShow}
        handleSuccess={handleSuccess}
      />
      <ModalController selected={selected} />
      <div className="container-fluid p-1">
        <Row className="gx-4 gy-5">
          {data.length > 0 ? (
            <>
              <Col xl={6} xxl={5}>
                <div>
                  <h3 className="mb-3">Preview</h3>
                  <div
                    className="d-flex flex-column "
                    style={{
                      width: '100%',
                      height: 'auto',
                      aspectRatio: '8.5 / 11',
                    }}
                  >
                    {/* {recipients?.data ? ( */}
                    <PDFViewer
                      title="test.pdf"
                      instance={instance}
                      innerRef={iFrameRef}
                      style={{
                        marginTop: 18,
                        flex: '1 0 100%',
                      }}
                      showToolbar={false}
                    />
                    {/* ) : (
                      <div
                        style={{
                          backgroundColor: 'var(--bs-gray-100)',
                          border: '1px solid var(--bs-gray-200)',
                          borderRadius: 8,
                        }}
                        className="position-relative w-100 h-100"
                      >
                        <p className="position-absolute top-50 start-50 translate-middle">
                          Loading...
                        </p>
                      </div>
                    )} */}
                  </div>
                </div>
              </Col>
              <Col xl={6} xxl={7}>
                <h3 className="mb-3">Printing Tray</h3>
                {/* {recipients?.data ? ( */}
                <ReactTablev8
                  dataTable={data}
                  tableLayout={schema}
                  tableAction={(e) => {
                    setSelected(e);
                    navigate(`${e}`);
                  }}
                  tableOptions={{
                    search: false,
                    pagination: {
                      show: false,
                    },
                    sorting: false,
                  }}
                  mobileClass="checks__tray"
                />
                {/* ) : (
                  <div
                    style={{
                      backgroundColor: 'var(--bs-gray-100)',
                      border: '1px solid var(--bs-gray-200)',
                      borderRadius: 8,
                    }}
                    className="position-relative w-100 h-100"
                  >
                    <p className="position-absolute top-50 start-50 translate-middle">
                      Loading...
                    </p>
                  </div>
                )} */}
              </Col>
            </>
          ) : (
            <Col>
              <Alert>
                The printing tray is empty, try adding some checks to the tray
                to start printing!
              </Alert>
            </Col>
          )}
        </Row>
        <div style={{ height: '80px' }} />
      </div>
      <div
        className="position-fixed p-3"
        style={{
          backgroundColor: 'var(--bs-white)',
          borderTop: '1px solid var(--bs-gray-200)',
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <div className="d-flex justify-content-end gap-3">
          <IconButton
            style={{ fontSize: '0.85rem' }}
            strokeWidth={2.85}
            as="a"
            color="primary"
            outline={false}
            text="Download"
            href={instance.url}
            download={fileName}
            target="_blank"
            onClick={() => setShow(true)}
            disabled={instance.loading || instance.error}
          >
            <Download />
          </IconButton>
          <IconButton
            style={{ fontSize: '0.85rem' }}
            strokeWidth={2.85}
            color="green"
            outline={false}
            text="Print"
            onClick={handlePrint}
            disabled={instance.loading || instance.error}
          >
            <Print />
          </IconButton>
          <IconButton
            style={{ fontSize: '0.85rem' }}
            strokeWidth={2.85}
            color="blue"
            outline={false}
            text="Mark all as Printed"
            onClick={setShow}
          >
            <RemoveTray />
          </IconButton>
        </div>
      </div>
    </>
  );
}
