import React, { useMemo } from 'react';
import { useRouteLoaderData, useNavigate } from 'react-router-dom';
import ReactTablev8 from '../../UI/Table/ReactTablev8';
import tableSetup from './TableSchema';
import useTableData from './TableData';

function Products() {
  const { products, filter } = useRouteLoaderData('products');
  // const { products, filter } = data;

  // Row click handler
  const navigate = useNavigate();
  const handleRowClick = (rowId) => navigate(`update/${rowId}`);

  // Table setup
  const productsTable = useTableData(products);
  const dataTable = useMemo(() => productsTable, [productsTable]);
  const table = useMemo(() => tableSetup, [tableSetup]);

  return (
    <ReactTablev8
      className=""
      dataTable={dataTable}
      tableLayout={table}
      columnfilter={filter}
      tableAction={handleRowClick}
      mobileClass="products_table"
    />
  );
}

export default Products;
