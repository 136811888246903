import ContactFormLayout from '../../components/ContactForm/ContactFormLayout';
import ContactFormSuccess from '../../components/ContactForm/ContactFormSuccess';
import ContactFormPage from '../../pages/ContactFormPage';
import { contactFormAction, contactFormLoader } from './contactFormLA';

const contactFormRoutes = [
  {
    path: '',
    element: <ContactFormLayout />,
    children: [
      {
        path: '/:userId/create-form',
        loader: contactFormLoader,
        action: contactFormAction,
        element: <ContactFormPage />,
      },
      {
        path: '/:userId/create-form/success',
        element: <ContactFormSuccess />,
      },
    ],
  },
];

export default contactFormRoutes;
