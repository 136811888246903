/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSubmit, Outlet, useFetcher } from 'react-router-dom';
import { string } from 'yup';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import CreateForm from '../../Forms/CreateForm';
import FormWrapper from '../../Forms/builders/FormWrapper';
import ReportDisplay from '../ReportDisplay';

// Utils
import getRange from '../utils/getRange';
import getDateFields from '../utils/rangeFields';

// Constants
import { BUSINESS_OVERVIEW_TOGGLE } from '../constants/toggleOptions';
import RANGE_OPTIONS from '../constants/rangeOptions';

// SCSS
import '../Reports.scss';

function BusinessOverview() {
  const submit = useSubmit();
  const customerFetcher = useFetcher();
  const [reportType, setReportType] = React.useState('sales');
  const [dateRange, setDateRange] = React.useState('customRange');
  const [displayValues, setDisplayValues] = React.useState({});

  React.useEffect(() => {
    customerFetcher.load('/customers');
  }, []);

  const customers = customerFetcher?.data?.customers?.map((customer) => ({
    value: customer.id,
    label: customer.name,
  })) || [{ value: 'Loading', label: 'Loading' }];

  const reportTypeField = {
    name: 'report_type',
    label: 'Type',
    initialValue: BUSINESS_OVERVIEW_TOGGLE[0].value,
    formType: 'toggleGroup',
    options: BUSINESS_OVERVIEW_TOGGLE,
    type: string().required(),
    required: true,
    className: 'flex__span-12',
    change: (e) => {
      setReportType(e.target.value);
      return null;
    },
  };

  const customerFilterField = {
    name: 'customer_filter',
    label: 'Filter Customer',
    initialValue: 'all',
    formType: 'select',
    options: [{ value: 'all', label: 'All' }, ...customers],
    type: string().required(),
    disabled: customerFetcher.state !== 'idle',
    isClearable: false,
    required: true,
    className: 'flex__span-12',
  };

  const rangeFields = [
    {
      name: 'select_range',
      label: 'Range',
      formType: 'select',
      initialValue: RANGE_OPTIONS[0].value,
      options: RANGE_OPTIONS,
      type: string(),
      isClearable: false,
      className: 'flex__span-3',
      change: async (e) => {
        setDateRange(e);
        if (e === 'customRange') return null;
        const range = getRange(e);
        await formik.setFieldValue('date_from', range.startDate, true);
        await formik.setFieldValue('date_to', range.endDate, true);
        return null;
      },
    },
    ...getDateFields({ dateRange, reportType }),
  ];

  const submitAction = (values) => {
    setDisplayValues((prev) => ({
      ...prev,
      dateTo: values.date_to,
      dateFrom: values.date_from,
      reportType: values.report_type,
    }));

    submit(
      {
        to: values.date_to,
        from: values.date_from,
        customerFilter: values.customer_filter,
      },
      {
        method: 'POST',
        action: values.report_type.replaceAll('_', '-'),
        encType: 'application/json',
      }
    );
  };

  const [formik] = useFormikTemplate({
    initial: [...rangeFields, reportTypeField, customerFilterField],
    yupValues: [...rangeFields, reportTypeField, customerFilterField],
    submitAction,
    enableReinitialize: false,
  });

  return (
    <div className="reports__wrapper">
      <div className="reports__wrapper__sticky-container">
        <FormWrapper
          id="businessOverviewForm"
          label="Run Report"
          handleSubmit={formik.handleSubmit}
          isSubmitting={formik.isSubmitting}
          dirty={formik.dirty}
          isValid={formik.isValid}
          portalId="report-submit"
          className="flex__form-wrapper reports__wrapper__action-wrapper p-4 rounded shadow-sm"
        >
          <div className="form-two">
            <CreateForm structure={[reportTypeField]} {...formik} />

            {formik?.values?.report_type === 'profit_and_loss_by_customer' && (
              <CreateForm structure={[customerFilterField]} {...formik} />
            )}

            <CreateForm structure={rangeFields} {...formik} />
            <div
              id="report-submit"
              className="flex__span-3"
              style={{ paddingTop: '1.25rem' }}
            />
          </div>
        </FormWrapper>
      </div>

      <ReportDisplay reportValue={displayValues.reportType}>
        <Outlet
          context={{
            reportValue: displayValues.reportType,
            dateTo: displayValues.dateTo,
            dateFrom: displayValues.dateFrom,
          }}
        />
      </ReportDisplay>
    </div>
  );
}

export default BusinessOverview;
