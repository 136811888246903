import React from 'react';
import {
  useActionData,
  useOutletContext,
  useRouteLoaderData,
} from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import '../../Reports.scss';
import ReportHeader from '../../ReportHeader';
import { BUSINESS_OVERVIEW_TOGGLE } from '../../constants/toggleOptions';
import ProfitAndLossPDF from './ProfitAndLossPDF';
import MoneyWrapper from '../../utils/MoneyWrapper';
import ReportFooter from '../../ReportFooter';

function ProfitAndLoss() {
  const actionData = useActionData();
  const rootLoader = useRouteLoaderData('root');
  const { dateTo, dateFrom } = useOutletContext();

  if (actionData?.message !== 'success' || !actionData)
    return <p className="card p-4 mt-4 border-0">Error generating report</p>;

  const netIncome = actionData.total_invoice - actionData.total_expense;
  return (
    <>
      <ReportHeader
        reportSection={BUSINESS_OVERVIEW_TOGGLE}
        pdfDocument={
          <ProfitAndLossPDF
            dateTo={dateTo}
            dateFrom={dateFrom}
            pdfData={actionData}
            userData={rootLoader}
          />
        }
      />
      <div className="reports__display container-fluid">
        <Row className="reports__display-header">
          <Col>Income</Col>
        </Row>
        <Row className="reports__display-regular">
          <Col>Products</Col>
          <Col>
            <MoneyWrapper>{actionData.incomes.product}</MoneyWrapper>
          </Col>
        </Row>
        <Row className="reports__display-regular">
          <Col>Services</Col>
          <Col>
            <MoneyWrapper>{actionData.incomes.service}</MoneyWrapper>
          </Col>
        </Row>
        <Row className="reports__display-regular">
          <Col>Collected Tax</Col>
          <Col>
            <MoneyWrapper>{actionData.collected_tax}</MoneyWrapper>
          </Col>
        </Row>
        <Row className="reports__display-total">
          <Col>Total Sales (Gross Profit)</Col>
          <Col>
            <MoneyWrapper>{actionData.total_invoice}</MoneyWrapper>
          </Col>
        </Row>
        <Row className="reports__display-header">
          <Col>Expenses</Col>
        </Row>
        {actionData.expenses.map((expense) => (
          <Row key={expense.id} className="reports__display-regular">
            <Col>{expense.name}</Col>
            <Col>
              <MoneyWrapper>{expense.amount}</MoneyWrapper>
            </Col>
          </Row>
        ))}
        <Row className="reports__display-total">
          <Col>Total Expenses</Col>
          <Col>
            <MoneyWrapper>{actionData.total_expense}</MoneyWrapper>
          </Col>
        </Row>
        <Row
          className="reports__display-net-income"
          style={{
            '--net-color':
              netIncome > 0 ? 'var(--bs-success)' : 'var(--bs-danger)',
          }}
        >
          <Col>Net Income</Col>
          <Col>
            <MoneyWrapper>{netIncome}</MoneyWrapper>
          </Col>
        </Row>
        <Row>
          <ReportFooter border={false} />
        </Row>
      </div>
    </>
  );
}

export default ProfitAndLoss;
