/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */

import React from 'react';
import {
  Cell,
  Pie,
  ResponsiveContainer,
  Label,
  Legend,
  PieChart,
} from 'recharts';

import { CHART_COLORS } from '../testData';
import { CustomLabel, renderActiveShape } from '../utils/CenterLabel';

import './PieCharts.scss';
import formatCurrency from '../utils/formatCurrency';

// This is the data we would get from the DB
// const TEST_DATA = [
//   {
//     name: 'Paid',
//     value: 1800,
//   },
//   {
//     name: 'Unpaid',
//     value: 200,
//   },
// ];

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="row-legend">
      {payload.map((entry) => {
        return (
          <li
            key={`item-${entry.payload.name}`}
            className="item"
            style={{ '--legend-color': entry.color }}
          >
            <span className="value">
              {formatCurrency(entry.payload.payload.value)}
            </span>
            <span className="field-name">{entry.value}</span>
          </li>
        );
      })}
      <li className="item" style={{ '--legend-color': 'var(--bs-primary)' }}>
        <span className="value">{formatCurrency(props.totalValue)}</span>
        <span className="field-name">Total</span>
      </li>
    </ul>
  );
};

function Single({ data }) {
  const [activeIndex, setActiveIndex] = React.useState(-1);

  const formattedData = React.useMemo(() => {
    if (!data) return null;
    return data.filter((item) => item.name !== 'Total');
  }, [data]);

  const totalValue = React.useMemo(() => {
    if (!data) return null;
    return data.find((item) => item.name === 'Total').value;
  }, [data]);

  const onPieEnter = React.useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const onPieExit = React.useCallback(() => {
    setActiveIndex(-1);
  }, [setActiveIndex]);

  return (
    <div style={{ width: '100%', height: 380 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={formattedData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius="60%"
            activeShape={renderActiveShape}
            activeIndex={activeIndex}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieExit}
            startAngle={-270}
          >
            {formattedData.map((entry, index) => {
              if (entry.name === 'Total') return false;

              return (
                <Cell
                  key={`cell-${entry}`}
                  fill={CHART_COLORS[index % CHART_COLORS.length]}
                />
              );
            })}
            {activeIndex === -1 && (
              <Label
                content={CustomLabel}
                offset={0}
                position="center"
                totalValue={totalValue}
              />
            )}
          </Pie>
          <Legend
            verticalAlign="bottom"
            content={renderLegend}
            totalValue={totalValue}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Single;
