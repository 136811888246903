import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { login } from '../../routes/paths';

function ProtectedPage() {
  const token = localStorage.getItem('token');
  return token ? <Outlet /> : <Navigate to={`${login}`} />;
}

export default ProtectedPage;
