import React from 'react';
import PropTypes from 'prop-types';

import './InvoiceBalance.scss';
import { Link } from 'react-router-dom';

function Entry({ children, label, highlight, color }) {
  return (
    <button type="button" className="invoice-balance__entry">
      <span
        style={{ '--balance-highlight-color': color }}
        className={`lb-modal__label ${highlight ? 'highlight' : undefined}`}
      >
        {label}
      </span>
      <span
        style={{ '--balance-highlight-color': color }}
        className={`modal__value ${highlight ? 'highlight' : undefined}`}
      >
        {children}
      </span>
    </button>
  );
}

Entry.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  color: PropTypes.string,
  highlight: PropTypes.bool,
};

function InvoiceBalance({ isAuthorized = true, children }) {
  if (!isAuthorized) {
    return (
      <div className="invoice-balance__container p-3">
        <p className="text-center" style={{ fontSize: '0.8rem' }}>
          <Link to="/settings/subscription">Subscribe</Link> to our{' '}
          <strong className="text-primary">BOOST</strong> or{' '}
          <strong className="text-primary">ELITE</strong> plans to access the
          invoice balance panel here!
        </p>
      </div>
    );
  }

  return <div className="invoice-balance__container">{children}</div>;
}

InvoiceBalance.propTypes = {
  isAuthorized: PropTypes.bool,
  children: PropTypes.node,
};

InvoiceBalance.Entry = Entry;

export default InvoiceBalance;
