import React from 'react';
import { Link, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import { login } from '../../routes/paths';
import TextTitle from '../UI/text/TextTitle';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import GenericForm from '../Forms/builders/GenericForm';

function ResetPasswordRequest() {
  const ForgotPasswordEmailFields = [
    {
      name: 'email',
      label: 'Email',
      placeholder: 'name@email.com',
      initialValue: '',
      type: Yup.string().email().required('Please add your email'),
      formType: 'email',
      required: true,
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post' });
  };

  return (
    <>
      <TextTitle>
        <h1>Enter your asociated email</h1>
        <p className="lb-text__subtitle mt-2">
          You will get an email with a link to reset your password.
        </p>
      </TextTitle>
      <ActionErrorInformation />
      <GenericForm
        fields={ForgotPasswordEmailFields}
        submitAction={submitAction}
        formId="forgotPasswordEmailForm"
        submitLabel="Send"
      />
      <div className="mt-5">
        <Link to={login} className="small">
          Go to login
        </Link>
        <div className="d-flex mt-2">
          <p className="small">Don&#39;t have an account?&nbsp;</p>
          <a href="https://leadsboxinvoice.com/subscription/" className="small">
            Subscribe now!
          </a>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordRequest;
