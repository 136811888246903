import React from 'react';

export function InvoiceIcon() {
  return (
    <g>
      <line x1="88.03" y1="60.04" x2="88.03" y2="38.28" />
      <path d="M115.72,24.45v19.17c0,1.24-1.01,2.25-2.25,2.25h-25.44v-21.05c0-7.36,5.6-13.72,12.94-14.19,4.19-.27,7.99,1.33,10.69,4.02,2.51,2.51,4.05,5.97,4.05,9.8Z" />
      <path d="M88.03,111.7v3.97c0,.96-.78,1.73-1.73,1.73H11.02c-.96,0-1.73-.78-1.73-1.73V38.28h0v-13.84c0-7.65,6.2-13.85,13.84-13.85h78.75c-7.65,0-13.85,6.2-13.85,13.85v13.84" />
      <line x1="55.81" y1="105.88" x2="47.64" y2="105.88" />
      <line x1="67.79" y1="105.88" x2="59.61" y2="105.88" />
      <line x1="74.5" y1="105.88" x2="71.59" y2="105.88" />
      <path d="M69.99,93.81H23.68c-1.84,0-3.33-1.49-3.33-3.33v-29.78c0-1.84,1.49-3.33,3.33-3.33h51.37c1.33,0,2.4,1.08,2.4,2.4v6.93" />
      <line x1="20.35" y1="84.65" x2="68.43" y2="84.49" />
      <line x1="20.35" y1="66.69" x2="77.46" y2="66.69" />
      <line x1="20.35" y1="75.59" x2="69.99" y2="75.59" />
      <rect
        x="49.25"
        y="25.34"
        width="28.21"
        height="5.67"
        rx="1.83"
        ry="1.83"
      />
      <line x1="54.71" y1="36.88" x2="76.31" y2="36.88" />
      <line x1="54.71" y1="45.87" x2="76.31" y2="45.87" />
      <line x1="57.54" y1="41.37" x2="76.31" y2="41.37" />
      <circle cx="96.87" cy="85.65" r="21.84" />
      <line x1="96.87" y1="75.59" x2="96.87" y2="96.48" />
      <line x1="100.14" y1="75" x2="93.59" y2="75" />
      <line x1="100.14" y1="96.48" x2="93.59" y2="96.48" />
      <rect
        x="20.77"
        y="26.17"
        width="16.76"
        height="16.76"
        rx="5.89"
        ry="5.89"
      />
    </g>
  );
}

export function EstimateIcon() {
  return (
    <g>
      <line x1="88.03" y1="60.04" x2="88.03" y2="38.28" />
      <path d="M115.72,24.45v19.17c0,1.24-1.01,2.25-2.25,2.25h-25.44v-21.05c0-7.36,5.6-13.72,12.94-14.19,4.19-.27,7.99,1.33,10.69,4.02,2.51,2.51,4.05,5.97,4.05,9.8Z" />
      <path d="M88.03,111.7v3.97c0,.96-.78,1.73-1.73,1.73H11.02c-.96,0-1.73-.78-1.73-1.73V38.28h0v-13.84c0-7.65,6.2-13.85,13.84-13.85h78.75c-7.65,0-13.85,6.2-13.85,13.85v13.84" />
      <line x1="55.81" y1="105.88" x2="47.64" y2="105.88" />
      <line x1="67.79" y1="105.88" x2="59.61" y2="105.88" />
      <line x1="74.5" y1="105.88" x2="71.59" y2="105.88" />
      <path d="M69.99,93.81H23.68c-1.84,0-3.33-1.49-3.33-3.33v-29.78c0-1.84,1.49-3.33,3.33-3.33h51.37c1.33,0,2.4,1.08,2.4,2.4v6.93" />
      <line x1="20.35" y1="84.65" x2="68.43" y2="84.49" />
      <line x1="20.35" y1="66.69" x2="77.46" y2="66.69" />
      <line x1="20.35" y1="75.59" x2="69.99" y2="75.59" />
      <rect
        x="49.25"
        y="25.34"
        width="28.21"
        height="5.67"
        rx="1.83"
        ry="1.83"
      />
      <line x1="54.71" y1="36.88" x2="76.31" y2="36.88" />
      <line x1="54.71" y1="45.87" x2="76.31" y2="45.87" />
      <line x1="57.54" y1="41.37" x2="76.31" y2="41.37" />
      <circle cx="96.87" cy="85.65" r="21.84" />
      <polyline points="103.23 75.59 90.3 75.59 90.3 96.48 103.23 96.48" />
      <line x1="96.86" y1="85.69" x2="90.3" y2="85.69" />
      <rect
        x="20.35"
        y="25.62"
        width="16.76"
        height="16.76"
        rx="5.89"
        ry="5.89"
      />
    </g>
  );
}
