import React from 'react';
import Button from 'react-bootstrap/Button';

// eslint-disable-next-line react/prop-types
function AddProductButton({ length, setShow }) {
  return (
    <td
      onClick={() => setShow(true)}
      colSpan={length !== 0 ? 4 : 8}
      // className="p-0 fw-bold pointer td-add pt-4 product-table"
      className="add-item"
    >
      <Button
        variant="light"
        className="border border-primary w-100"
        data-testid="addItemButton"
      >
        {length === 0 ? 'Add item' : 'Add more items'}
      </Button>
    </td>
  );
}

export default AddProductButton;
