/* eslint-disable react/prop-types */
import React from 'react';

function MiniSwatch({ hex }) {
  const customStyles = {
    backgroundColor: hex,
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    border: 'white 1px solid',
  };
  return <span style={customStyles} />;
}

export default MiniSwatch;
