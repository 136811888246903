import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import GOOGLE_MAPS_OPTIONS from './mapConstants';

function Map({ address }) {
  const { isLoaded, loadError } = useJsApiLoader(GOOGLE_MAPS_OPTIONS);

  const [mapComponent, setMapComponent] = useState(null);

  const renderMap = () => {
    return new Promise((resolve, reject) => {
      let center = { lat: 18.52043, lng: 73.856743 };
      const checkGoogle = setInterval(() => {
        if (window.google) {
          clearInterval(checkGoogle);
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK') {
              center = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              };
              const component = (
                <GoogleMap
                  center={center}
                  mapContainerClassName="map-container"
                  zoom={15}
                >
                  <Marker position={results[0].geometry.location} />
                </GoogleMap>
              );
              resolve(component);
            } else {
              reject(status);
            }
          });
        }
      }, 100);
    });
  };

  useEffect(() => {
    renderMap().then((component) => {
      setMapComponent(component);
    });
  }, []);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return <div>{mapComponent}</div>;
}

Map.propTypes = {
  address: propTypes.string,
};
Map.defaultProps = {
  address: 'Pune',
};
export default Map;
