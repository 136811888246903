export function filterByItemValue(options, value) {
  return options.filter((item) => `${item.value}` === `${value}`)[0];
}

export function getSelectedValue(options, value) {
  if (!value || !options?.length) return null;

  const grouped = Object.keys(options[0]).includes('options');

  if (!grouped) {
    return {
      ...filterByItemValue(options, value),
      className: value,
    };
  }

  const [result] = options
    .map((group) => {
      const match = filterByItemValue(group.options, value);
      if (match) {
        return {
          ...match,
          className: value,
        };
      }
      return null;
    })
    .filter((item) => item);
  return result;
}
