import React from 'react';
import { Button } from 'react-bootstrap';
import IconWrapper from '../Customers/CustomersCsv/IconWrapper';
import { ProductIcon, ServiceIcon } from './icons';

import './ProductsAndServices.scss';

// eslint-disable-next-line react/prop-types
function SelectItemType({ setValue, setItemType, narrow = false }) {
  return (
    <div className="h-100 container gx-0">
      <header className="row text-start mb-4">
        <div className="col">
          {!narrow ? (
            <>
              <h2>Select the item type</h2>
              <p>
                You&apos;ll be able to change this later if you change your
                mind.
              </p>
            </>
          ) : (
            <h4 style={{ fontSize: '1.3rem' }}>
              Are you creating a product or a service?
            </h4>
          )}
        </div>
      </header>

      <div className="row gy-3">
        <div className={`d-flex ${narrow ? 'col-12' : 'col-md-6'}`}>
          <Button
            variant="secondary"
            className="ps__custom-button"
            onClick={() => {
              setItemType('Service');
              setValue('item_type', 'Service');
            }}
          >
            <IconWrapper size={64} viewBox={128}>
              <ServiceIcon />
            </IconWrapper>
            <span className="d-flex flex-column">
              <span className="ps__custom-button__title">Service</span>
              <p className="ps__custom-button__body">
                Choose the way you want to calculate the rate and profit for
                your service, add a category, description to easily manage your
                services.
              </p>
            </span>
          </Button>
        </div>
        <div className={`d-flex ${narrow ? 'col-12' : 'col-md-6'}`}>
          <Button
            variant="secondary"
            className="ps__custom-button"
            onClick={() => {
              setItemType('Product');
              setValue('item_type', 'Product');
            }}
          >
            <IconWrapper size={64} viewBox={128}>
              <ProductIcon />
            </IconWrapper>
            <span className="d-flex flex-column">
              <span className="ps__custom-button__title">Product</span>
              <p className="ps__custom-button__body">
                Same features as Services, but you can also specify a provider,
                unit and sale quantity for your products.
              </p>
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SelectItemType;
