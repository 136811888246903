import React from 'react';

function PageNotFound() {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column mt-5">
      <div style={{ maxWidth: '400px', textAlign: 'center' }}>
        <h1>
          Oops!
          <br /> Page not found
        </h1>
        <p className="mt-2">Sorry, an unexpected error has occurred.</p>
        <p className="mt-3">
          <i className="small">Page not found 404</i>
        </p>
      </div>
    </div>
  );
}

export default PageNotFound;
