/* eslint react/prop-types: 0 */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SelectedProductsItem from './SelectedProductsItem';
import TableHeader from '../../UI/TableHeader';
import Footer from './Footer';

const tableHeaders = [
  '#',
  'Category',
  'Product | service',
  'Description',
  'Qty',
  'Rate | price',
  'Total',
];

function SelectedProductsTable({
  feedback,
  items,
  setItemToChange,
  setCurrentItems,
  setShow,
}) {
  const setItemToEdit = (index) => {
    const nextItem = { ...items[index], index };
    setItemToChange(nextItem);
  };

  const removeItem = (index) => {
    const nextItems = [...items];
    nextItems.splice(index, 1);
    setCurrentItems(nextItems);
  };

  return (
    <table className="selected_products__table">
      <TableHeader headers={tableHeaders} />
      <tbody>
        {items.length > 0 ? (
          items.map((item, index) => (
            <tr className="table-hover-edit" key={item.item_id}>
              <SelectedProductsItem
                index={index}
                onDelete={removeItem}
                onModify={setItemToEdit}
                item={item}
              />
            </tr>
          ))
        ) : (
          <tr>
            <td
              className={feedback ? `input_items_errors` : ''}
              colSpan="100%"
              style={{ gridColumn: '1 / -1' }}
            >
              Not products added yet
              <span className="text-danger">*</span>
            </td>
          </tr>
        )}
      </tbody>
      <Footer setShow={setShow} items={items} />
    </table>
  );
}

export default SelectedProductsTable;
