import React from 'react';
import { useMatches, Link } from 'react-router-dom';
import FormSubmitPortal from '../Forms/builders/FormSubmitPortal';

export default function Breadcrumbs() {
  const matches = useMatches();
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => {
      const label = match.handle?.crumb(match);
      return {
        to: match.pathname,
        id: match.id,
        label,
      };
    });

  return (
    <>
      <div>
        <ul className="d-flex list-unstyled mb-0 p-0 gap-2">
          {crumbs.length > 0 && (
            <li>
              <Link
                to={crumbs[crumbs.length - 1].to}
                className="text-decoration-none"
              >
                {crumbs[crumbs.length - 1].label}
              </Link>
            </li>
          )}
        </ul>
      </div>
      <FormSubmitPortal portalId="breadcrumbsPortal">
        <ul className="d-flex list-unstyled gap-2 breadcrumb-ul">
          {crumbs.map(
            ({ id, to, label }, index, { length }) =>
              index < length - 1 &&
              length > 2 && (
                <li key={id} className="breadcrumb-li">
                  <Link to={to} className="text-decoration-none">
                    {label}
                  </Link>
                  {index !== length - 2 && (
                    <span className="text-muted"> |</span>
                  )}
                </li>
              )
          )}
        </ul>
      </FormSubmitPortal>
    </>
  );
}
