import React from 'react';
import { useSubmit } from 'react-router-dom';
import { Button, ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import useStickyState from '../components/Helpers/useStickyState';

function SubscriptionTierManager() {
  const [isOverriden, setIsOverriden] = useStickyState(
    false,
    'override_subscription'
  );
  const [selectedTier, setSelectedTier] = useStickyState(
    'tier_3',
    'tier_subscription'
  );
  const [currentInvoices, setCurrentInvoices] = useStickyState(
    0,
    'current_invoices'
  );

  const tiers = ['unsubscribed', 'tier_1', 'tier_2', 'tier_3', 'expired'];

  const submit = useSubmit();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    submit(formData, { method: 'POST' });
  };

  return (
    <Form
      className="card p-5 d-flex flex-column gap-3 mx-auto"
      style={{ maxWidth: 800 }}
      onSubmit={(e) => handleSubmit(e)}
    >
      <Form.Group
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 8,
        }}
      >
        <Form.Label>Override BE values?</Form.Label>
        <Form.Check
          name="is_overriden"
          value={isOverriden}
          checked={isOverriden === true}
          onChange={() => setIsOverriden(!isOverriden)}
          type="checkbox"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label
          style={{ flex: '0 0 auto', marginBottom: 0, marginRight: 10 }}
        >
          Current Month Invoices <strong>({currentInvoices})</strong>:{' '}
        </Form.Label>
        <Form.Range
          name="current_invoices"
          step={1}
          min={0}
          max={3}
          value={currentInvoices}
          onChange={(e) => setCurrentInvoices(e.target.value)}
        />
      </Form.Group>
      <Form.Group
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
        }}
      >
        <Form.Label>Tier</Form.Label>
        <ButtonGroup>
          {tiers.map((tier) => (
            <ToggleButton
              id={`radio-${tier}`}
              key={tier}
              name="tier"
              type="radio"
              variant={selectedTier === tier ? 'primary' : 'light'}
              checked={tier === selectedTier}
              value={tier}
              onChange={() => setSelectedTier(tier)}
            >
              {tier.charAt(0).toUpperCase() + tier.slice(1).replace('_', ' ')}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </Form.Group>

      <Button className="mt-5" type="submit">
        Save settings
      </Button>
    </Form>
  );
}

export default SubscriptionTierManager;
