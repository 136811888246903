import { handleResposeAbstraction } from '../../API/fetchUtils';
import contactFormAPI from '../../API/routesAPI/contactFormAPI';

export const contactFormAction = async ({ request }) => {
  const body = await request.json();
  const stringBody = JSON.stringify(body);
  const response = await contactFormAPI.createCustomer(stringBody);
  return handleResposeAbstraction({
    response,
    redirectTo: './success',
  });
};

export const contactFormLoader = async ({ request }) => {
  const token = new URL(request.url).searchParams.get('token');
  const mobile = new URL(request.url).searchParams.get('mobile');
  return { token, mobile };
};
