import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from './styles';

function SalesPDF({
  title = 'Insert a Title',
  from = '',
  to = '',
  currentDate = '',
  timezone = '',
  fetchedData = {},
  sales = false,
  salesCustomer = false,
  salesProducts = false,
  salesServices = false,
  productsServices = false,
  userCompany,
}) {
  const getAmounts =
    fetchedData.data?.length > 0
      ? fetchedData?.data.map((item) => item.total)
      : 0;

  const getSubTotal =
    fetchedData.data?.length > 0
      ? getAmounts?.reduce((acc, currentValue) => {
          return acc + currentValue;
        }, 0)
      : 0;

  const getTaxes =
    fetchedData.data?.length > 0
      ? fetchedData.data?.map((item) => item.tax)
      : 0;

  const getTotalTaxes =
    fetchedData.data?.length > 0
      ? getTaxes?.reduce((acc, currentValue) => {
          return acc + currentValue;
        }, 0)
      : 0;

  if (salesCustomer)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>

          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View style={styles.categoriesBar}>
              <Text style={styles.textCategories}>Customer</Text>
              <Text style={styles.textCategories}>Date</Text>
              <Text style={styles.invoiceCategories}>Invoice</Text>
              <Text style={styles.taxCategories}>Tax</Text>
              <Text style={styles.textCategories}>Amount</Text>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={styles.content}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.invoice_id}
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    paddingLeft: 20,
                  }}
                >
                  <Text break style={styles.customerText}>
                    {item.customer}
                  </Text>
                  <Text style={styles.dateText}>{item.date}</Text>
                  <Text style={styles.invoiceTextCustomer}>
                    {item.invoice_id}
                  </Text>
                  <Text style={styles.taxTextCustomer}>
                    ${item.tax.toFixed(2)}
                  </Text>
                  <Text style={styles.amountTextCustomer} break>
                    ${item.total.toFixed(2)}
                  </Text>
                </View>
              );
            })}
            <View style={styles.bottomSoftDivider} />
            <View style={styles.subTotal}>
              <Text style={{ fontSize: 15, fontFamily: 'Montserrat' }}>
                Sub-Total
              </Text>
              <Text
                style={{
                  marginLeft: 80,
                  fontSize: 15,
                  fontFamily: 'Montserrat',
                }}
              >
                ${fetchedData.total_subtotal}
              </Text>
            </View>
            <View style={styles.taxTotal}>
              <Text style={{ fontSize: 15, fontFamily: 'Montserrat' }}>
                Tax Total
              </Text>
              <Text
                style={{
                  marginLeft: 80,
                  fontSize: 15,
                  fontFamily: 'Montserrat',
                }}
              >
                ${fetchedData.total_tax}
              </Text>
            </View>
            <View style={styles.bottomStrongDivider} />
            <View style={styles.totalSales}>
              <Text style={styles.totalSalesTxt}>Total Sales:</Text>
              <Text style={styles.totalSalesPriceTxt}>
                ${fetchedData.total_sales}
              </Text>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );

  if (sales)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>
          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View style={styles.columnCategories}>
              <Text style={styles.textCategories}>Date</Text>
              <Text style={styles.invoiceCategories}>Invoice</Text>
              <Text style={styles.taxCategories}>Tax</Text>
              <Text style={styles.textCategories}>Amount</Text>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={styles.content}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.id}
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    paddingLeft: 30,
                  }}
                >
                  <Text style={styles.dateText}>{item.date}</Text>
                  <Text style={styles.invoiceTextSales}>{item.invoice_id}</Text>
                  <Text style={styles.taxTextSales}>
                    ${item.tax.toFixed(2)}
                  </Text>
                  <Text style={styles.amountTextSales} break>
                    ${item.total.toFixed(2)}
                  </Text>
                </View>
              );
            })}
            <View style={styles.bottomSoftDivider} />
            <View style={styles.subTotal}>
              <Text style={{ fontSize: 15, fontFamily: 'Montserrat' }}>
                Sub-Total
              </Text>
              <Text
                style={{
                  marginLeft: 80,
                  fontSize: 15,
                  fontFamily: 'Montserrat',
                }}
              >
                ${parseFloat(getSubTotal).toFixed(2)}
              </Text>
            </View>
            <View style={styles.taxTotal}>
              <Text style={{ fontSize: 15, fontFamily: 'Montserrat' }}>
                Tax Total
              </Text>
              <Text
                style={{
                  marginLeft: 80,
                  fontSize: 15,
                  fontFamily: 'Montserrat',
                }}
              >
                ${parseFloat(getTotalTaxes).toFixed(2)}
              </Text>
            </View>
            <View style={styles.bottomStrongDivider} />
            <View style={styles.totalSales}>
              <Text style={styles.totalSalesTxt}>Total Sales:</Text>
              <Text style={styles.totalSalesPriceTxt}>
                ${parseFloat(getSubTotal + getTotalTaxes).toFixed(2)}
              </Text>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );

  if (salesProducts)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>
          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View style={styles.columnCategories}>
              <Text style={styles.textCategories}>Product</Text>
              <Text style={{ ...styles.textCategories, marginLeft: 20 }}>
                Quantity
              </Text>
              <Text style={styles.textCategories}>Amount</Text>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={styles.content}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.id}
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    paddingLeft: 80,
                  }}
                >
                  <Text style={styles.customerText}>{item.name}</Text>
                  <Text
                    style={{ ...styles.dateText, width: 75, marginLeft: 80 }}
                  >
                    {item.total_qty}
                  </Text>
                  <Text style={{ ...styles.amountText, marginLeft: 80 }} break>
                    ${item.total_price.toFixed(2)}
                  </Text>
                </View>
              );
            })}

            <View style={styles.bottomStrongDivider} />
            <View style={styles.totalSales}>
              <Text style={styles.totalSalesTxt}>Total Sales:</Text>
              <Text style={styles.totalSalesPriceTxt}>
                ${fetchedData.total_sales}
              </Text>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );

  if (salesServices)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
            <Text
              style={{ marginTop: 5, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {from} to {to}
            </Text>
          </View>
          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View style={styles.columnCategories}>
              <Text style={styles.textCategories}>Service</Text>
              <Text style={{ ...styles.textCategories, marginLeft: 20 }}>
                Tax
              </Text>
              <Text style={styles.textCategories}>Amount</Text>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={styles.content}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.id}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    marginTop: 5,
                    paddingRight: 30,
                  }}
                >
                  <Text style={styles.customerText}>{item.name}</Text>
                  <Text style={{ ...styles.dateText, width: 75 }}>
                    {item.total_tax}
                  </Text>
                  <Text style={styles.amountText} break>
                    ${item.total_price}
                  </Text>
                </View>
              );
            })}

            <View style={styles.bottomStrongDivider} />
            <View style={styles.totalSales}>
              <Text style={styles.totalSalesTxt}>Total Sales:</Text>
              <Text style={styles.totalSalesPriceTxt}>
                ${fetchedData.total_sales}
              </Text>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );

  if (productsServices)
    return (
      <Document pageMode="fullScreen">
        <Page size="A4" style={styles.page}>
          <View style={styles.header} fixed>
            <Text
              style={{ marginTop: 20, fontFamily: 'Montserrat', fontSize: 14 }}
            >
              {userCompany}
            </Text>
            <Text style={styles.titleText}>{title}</Text>
          </View>
          <View style={styles.body} fixed>
            <View style={styles.strongDivider} />
            <View style={styles.columnCategories}>
              <Text style={{ ...styles.textCategories, fontSize: 13 }}>
                Products Or Services
              </Text>
              <Text style={{ ...styles.textCategories, fontSize: 13 }}>
                SKU
              </Text>
              <Text style={{ ...styles.textCategories, fontSize: 13 }}>
                Description
              </Text>
              <Text
                style={{
                  ...styles.textCategories,
                  fontSize: 13,
                  marginLeft: 10,
                }}
              >
                Tax
              </Text>
              <Text style={{ ...styles.textCategories, fontSize: 13 }}>
                Amount
              </Text>
            </View>
            <View style={styles.softDivider} />
          </View>

          {/* CONTENT START */}
          <View style={{ ...styles.content, top: 150 }}>
            {fetchedData.data?.map((item) => {
              return (
                <View
                  key={item.product_id}
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    paddingHorizontal: 30,
                  }}
                >
                  <Text style={{ ...styles.customerText, marginRight: 20 }}>
                    {item.name}
                  </Text>
                  <Text style={styles.skuText}>{item.sku}</Text>
                  <Text style={styles.descriptionText}>{item.description}</Text>
                  <Text style={styles.taxText}>{item.tax}</Text>
                  <Text style={styles.amountText}>${item.product_price}</Text>
                </View>
              );
            })}

            <View style={styles.bottomStrongDivider} />
            <View style={styles.totalSales}>
              <Text style={{ ...styles.totalSalesTxt, marginLeft: 50 }}>
                Total Products:
              </Text>
              <Text style={{ ...styles.totalSalesPriceTxt, marginLeft: 10 }}>
                {fetchedData.total_products}
              </Text>
              <Text style={{ ...styles.totalSalesTxt, marginLeft: 50 }}>
                Total Services:
              </Text>
              <Text style={{ ...styles.totalSalesPriceTxt, marginLeft: 10 }}>
                {fetchedData.total_services}
              </Text>
              <Text style={{ ...styles.totalSalesTxt, marginLeft: 50 }}>
                Total:
              </Text>
              <Text style={{ ...styles.totalSalesPriceTxt, marginLeft: 10 }}>
                {fetchedData.total}
              </Text>
            </View>
            <View style={styles.footer}>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                Report Generated on
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                }}
              >
                {currentDate}
              </Text>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}
              >
                {timezone}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
}

SalesPDF.propTypes = {
  title: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  fetchedData: PropTypes.shape({}),
  sales: PropTypes.bool,
  salesCustomer: PropTypes.bool,
  salesProducts: PropTypes.bool,
  salesServices: PropTypes.bool,
  productsServices: PropTypes.bool,
  currentDate: PropTypes.string,
  timezone: PropTypes.string,
  userCompany: PropTypes.string,
};

export default SalesPDF;
