import * as Yup from 'yup';

const TODAY = new Date().toISOString().substring(0, 10);
export default function schema(initialValues) {
  return [
    {
      formGroup: 'Payment Information',
      groupChildren: [
        {
          initialValue: initialValues?.recipient
            ? `${initialValues.recipient.type}_${initialValues.recipient.id}`
            : '',
          label: 'Payee',
          name: 'recipient_code',
          type: Yup.string().required('Please complete the field'),
          formType: 'toCheckSelect',
          required: true,
        },
        {
          initialValue: initialValues.category_id || '',
          name: 'category_id',
          label: 'Expense category',
          type: Yup.string().required('Please complete the field'),
          formType: 'expenseCategory',
          required: true,
        },

        {
          name: 'payment_date',
          initialValue: initialValues.payment_date || TODAY,
          label: 'Payment Date',
          formType: 'date',
          type: Yup.string(),
          required: true,
        },
        {
          name: 'memo',
          initialValue: initialValues.memo || '',
          label: 'Memo',
          required: true,
          type: Yup.string().max(30).required('Please complete the field'),
        },
        {
          name: 'items',
          formType: 'ignore',
          initialValue: initialValues?.items ? [...initialValues.items] : [],
          label: 'Items',
          type: Yup.array()
            .of(
              Yup.object().shape({
                description: Yup.string()
                  .max(50)
                  .required('Description field cannot be blank'),
                invoice: Yup.string(),
                amount: Yup.number()
                  .typeError('Please enter a amount. Must be a number.')
                  .required('Please enter a amount'),
              })
            )
            .min(1, 'Add at least one item'),
        },
      ],
    },
  ];
}
