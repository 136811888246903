export const estimates = {
  next: '/estimates/lastEstimateNumber',
  messages: '/settings/messages/estimates',
};

export const invoices = {
  next: '/invoices/nextInvoiceNumber',
  messages: '/settings/messages/invoices',
};

export default null;
