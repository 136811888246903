/* eslint react/prop-types: 0 */
import React from 'react';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';

function DataCurrency({ value }) {
  return (
    <span className="table__money numbers">
      {formatCurrency(value, 2, false)}
    </span>
  );
}

export default DataCurrency;
