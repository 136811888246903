import {
  AppointmentsAlt,
  Calculator,
  Check,
  Coins,
  Customers,
  Dashboard,
  EditAlt,
  Gear,
  Microphone,
  PriceTag,
  Reports,
  Vendors,
} from '../../UI/Icons/Icons';

const routes = [
  {
    label: 'Dashboard',
    path: '/',
    icon: <Dashboard />,
  },
  {
    label: 'Customers',
    path: '/customers',
    icon: <Customers />,
  },
  {
    label: 'Estimates',
    path: '/estimates',
    icon: <EditAlt />,
  },
  {
    label: 'Invoices',
    path: '/invoices',
    icon: <Microphone />,
  },
  {
    label: 'Products\xa0&\xa0Services',
    path: '/products',
    icon: <PriceTag />,
  },
  {
    label: 'Expenses',
    path: '/expenses',
    icon: <Calculator />,
  },
  {
    label: 'Vendors',
    path: '/vendors',
    icon: <Vendors />,
  },
  {
    label: 'Payments',
    path: '/payments',
    icon: <Coins />,
  },
  {
    label: 'Appointments',
    path: '/appointments',
    icon: <AppointmentsAlt />,
  },
  {
    label: 'Reports',
    path: '/reports/business-overview',
    icon: <Reports />,
  },
  {
    label: 'Checks',
    path: '/checks',
    icon: <Check />,
  },
  {
    label: 'Settings',

    path: '/settings',
    icon: <Gear />,
  },
];

export default routes;
