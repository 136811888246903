import React from 'react';
import { NavLink, Link, useNavigate, useMatches } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import SectionContent from '../UI/SectionContent';
import iconLeft from '../../img/icon-left-arrow.png';
import Breadcrumbs from '../UI/BreadCrumbs';
import HelpTooltip from '../HelpTooltip/HelpTooltip.tsx';

import './PageHeader.scss';

function PageHeader({ children }) {
  const matches = useMatches();
  const tabsArr = matches
    .filter((match) => match.handle?.sublinks)
    .map((match) => match.handle.sublinks(match));
  const tabs = tabsArr.length > 0 ? tabsArr[tabsArr.length - 1] : null;
  const lastIndexOfMatch = matches[matches.length - 1];
  const lastMatch = lastIndexOfMatch.handle?.linkTo
    ? lastIndexOfMatch
    : matches.filter((match) => match.handle?.linkTo?.end)[0];

  const linkTo = lastMatch && {
    to:
      lastMatch.handle?.linkTo &&
      `${lastMatch?.pathname}${lastMatch?.handle?.linkTo?.to}`,
    label: lastMatch.handle?.linkTo.label,
    state:
      lastMatch.handle?.linkTo?.state &&
      lastMatch.handle?.linkTo?.state(lastMatch),
    toolTip: lastMatch.handle?.linkTo?.toolTip,
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="generic-page">
      <div id="breadcrumbsPortal" />
      <div className="header_card">
        <div className="d-flex justify-content-between align-items-center header_min-heigth">
          <div className="d-flex align-items-center">
            <Button
              type="button"
              variant="ligth"
              onClick={goBack}
              className="p-0"
            >
              <img src={iconLeft} alt="" style={{ width: '20px' }} />
            </Button>
            <h5 className="mb-0 ms-2">
              <Breadcrumbs />
            </h5>
          </div>
          {linkTo && !lastMatch.handle.linkTo.noShow && (
            <div className="d-flex align-items-center gap-3">
              {lastMatch.handle.linkTo?.toolTip && (
                <HelpTooltip>{lastMatch.handle.linkTo.toolTip}</HelpTooltip>
              )}
              <Link
                variant="primary"
                className="btn btn-primary header-btn"
                to={linkTo.to}
                state={linkTo.state}
              >
                {linkTo.label}
              </Link>
            </div>
          )}
        </div>

        <nav className="tabs">
          {tabs && (
            <ul className="nav">
              {tabs.map(({ to, label, end = false }) => (
                <li key={to}>
                  <NavLink to={to} className="nav-link nav-item" end={end}>
                    {label}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </nav>
      </div>

      <SectionContent className="mt-4">{children}</SectionContent>
    </div>
  );
}

PageHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeader;
