import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';
import { I_Colors } from '../propTypes';

function Background({ colors }: { colors: I_Colors }) {
  return (
    <Svg style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
      <Path
        style={{ fill: colors.color1 }}
        d="M410.74,81H0V19.67h332.05c16.7,0,32.94,5.98,45.3,17.2.08.07.15.14.23.21,12.96,11.85,20.65,24.1,28.08,35.95,1.69,2.69,3.36,5.36,5.08,7.97Z"
      />
      <Path
        style={{ fill: colors.color2 }}
        d="M432.9,105.33H0v-13.66h418.4c4.14,5.16,8.81,9.83,14.5,13.66Z"
      />
      <Path
        style={{ fill: colors.color3 }}
        d="M612,0v94.83h-139.44c-8.42,0-15.03-1.09-20.5-3.16-6.2-2.35-10.94-5.96-15.18-10.67-4.64-5.14-8.69-11.59-13.43-19.14-7.8-12.42-16.64-26.5-31.7-40.27-.71-.65-1.43-1.29-2.16-1.92-9.11-7.9-19.63-14.37-30.91-19.67h253.32Z"
      />
    </Svg>
  );
}

export default Background;
