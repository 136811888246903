import reportsAPI from '../../API/routesAPI/reportsAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

// Businesss overview
export const profitAndLossAction = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postProfitLoss(JSON.stringify(body));

  return handleResposeAbstraction({
    response,
  });
};

export const profitAndLossByCustomerAction = async ({ request }) => {
  const body = await request.json();

  const formattedBody = {
    from: body.from,
    to: body.to,
    customer: body.customerFilter === 'all' ? '' : body.customerFilter,
  };

  const response = await reportsAPI.postCustomerProfitLoss(
    JSON.stringify(formattedBody)
  );

  return handleResposeAbstraction({
    response,
  });
};

// Sales
export const salesAction = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postSales(JSON.stringify(body));

  return handleResposeAbstraction({
    response,
  });
};

export const salesByCustomerAction = async ({ request }) => {
  const body = await request.json();
  const formattedBody = {
    from: body.from,
    to: body.to,
    customer: body.customerFilter === 'all' ? '' : body.customerFilter,
  };

  const response = await reportsAPI.postCustomerSales(
    JSON.stringify(formattedBody)
  );

  return handleResposeAbstraction({
    response,
  });
};

export const salesByProductAction = async ({ request }) => {
  const body = await request.json();
  const formattedBody = {
    from: body.from,
    to: body.to,
    product: body.productFilter === 'all' ? '' : body.productFilter,
  };

  const response = await reportsAPI.postSalesProducts(
    JSON.stringify(formattedBody)
  );

  return handleResposeAbstraction({
    response,
  });
};

export const salesByServiceAction = async ({ request }) => {
  const body = await request.json();
  const formattedBody = {
    from: body.from,
    to: body.to,
    service: body.productFilter === 'all' ? '' : body.productFilter,
  };

  const response = await reportsAPI.postSalesServices(
    JSON.stringify(formattedBody)
  );

  return handleResposeAbstraction({
    response,
  });
};

export const productsAndServicesAction = async ({ request }) => {
  const body = await request.json();
  const formattedBody = {
    category: body.comboCategory,
    product: body.comboProduct,
  };

  const response = await reportsAPI.postProductServiceList(
    JSON.stringify(formattedBody)
  );

  return handleResposeAbstraction({
    response,
  });
};

// Expenses
export const expensesByCategoryAction = async ({ request }) => {
  const body = await request.json();
  const formattedBody = {
    from: body.from,
    to: body.to,
    expense_category:
      body.expenseCategory === 'all' ? '' : body.expenseCategory,
  };

  const response = await reportsAPI.postExpensesCategory(
    JSON.stringify(formattedBody)
  );

  return handleResposeAbstraction({
    response,
  });
};

export const expensesByInvoiceAction = async ({ request }) => {
  const body = await request.json();
  const formattedBody = {
    from: body.from,
    to: body.to,
    invoice: body.invoice === 'all' ? '' : body.invoice,
  };

  const response = await reportsAPI.postExpenseInvoice(
    JSON.stringify(formattedBody)
  );

  return handleResposeAbstraction({
    response,
  });
};

export const expensesByVendorAction = async ({ request }) => {
  const body = await request.json();
  const formattedBody = {
    from: body.from,
    to: body.to,
    vendor: body.vendor === 'all' ? '' : body.vendor,
  };

  const response = await reportsAPI.postExpenseVendor(
    JSON.stringify(formattedBody)
  );

  return handleResposeAbstraction({
    response,
  });
};

export const expensesByMerchantAction = async ({ request }) => {
  const body = await request.json();
  const formattedBody = {
    from: body.from,
    to: body.to,
    merchant: body.merchant === 'all' ? '' : body.merchant,
  };

  const response = await reportsAPI.postExpenseMerchant(
    JSON.stringify(formattedBody)
  );

  return handleResposeAbstraction({
    response,
  });
};

export const reportsLoader = async () => {
  const response = await reportsAPI.getAll();
  return handleResposeAbstraction({ response });
};
export const getAllCustomers = async () => {
  const response = await reportsAPI.getAllCustomers();
  return handleResposeAbstraction({ response });
};
export const getAllProducts = async () => {
  const response = await reportsAPI.getAllProducts();
  return handleResposeAbstraction({ response });
};
export const getCategories = async () => {
  const response = await reportsAPI.getCategories();
  return handleResposeAbstraction({ response });
};
export const getAllInvoices = async () => {
  const response = await reportsAPI.getAllInvoices();
  return handleResposeAbstraction({ response });
};
export const getAllVendorMerchants = async () => {
  const response = await reportsAPI.getAllVendorMerchants();
  return handleResposeAbstraction({ response });
};
export const getExpensesCategories = async () => {
  const response = await reportsAPI.getExpensesCategories();
  return handleResposeAbstraction({ response });
};

// USER COMPANY
export const getCompanyInfo = async () => {
  const response = await reportsAPI.getCompanyInfo();
  return handleResposeAbstraction({ response });
};

// GET PRODUCTS
export const getProducts = async () => {
  const response = await reportsAPI.getAllProducts();
  return handleResposeAbstraction({ response });
};

// BUSINESS OVERVIEW
export const postCustomerProfitLoss = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postCustomerProfitLoss(
    JSON.stringify(body)
  );
  return handleResposeAbstraction({ response });
};
export const postProfitLoss = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postProfitLoss(JSON.stringify(body));
  return handleResposeAbstraction({ response });
};

// SALES
export const postCustomerSales = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postCustomerSales(JSON.stringify(body));

  return handleResposeAbstraction({ response });
};
export const postSales = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postSales(JSON.stringify(body));
  return handleResposeAbstraction({ response });
};
export const postProductServiceList = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postProductServiceList(body);
  return handleResposeAbstraction({ response });
};
export const postSalesProducts = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postSalesProducts(JSON.stringify(body));
  return handleResposeAbstraction({ response });
};
export const postSalesServices = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postSalesServices(JSON.stringify(body));
  return handleResposeAbstraction({ response });
};

// EXPENSES
export const postExpensesCategory = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postExpensesCategory(JSON.stringify(body));
  return handleResposeAbstraction({ response });
};
export const postExpenseInvoice = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postExpenseInvoice(JSON.stringify(body));
  return handleResposeAbstraction({ response });
};
export const postExpenseVendor = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postExpenseVendor(JSON.stringify(body));
  return handleResposeAbstraction({ response });
};
export const postExpenseMerchant = async ({ request }) => {
  const body = await request.json();
  const response = await reportsAPI.postExpenseMerchant(JSON.stringify(body));
  return handleResposeAbstraction({ response });
};
