/* eslint-disable no-use-before-define  */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import Calendar from 'react-calendar';
import { isSameDay, isWeekend } from 'date-fns';
import useAppointmentContext from '../Context/useAppointmentContext';
import { Carat, DoubleCarat } from '../../UI/Icons/Icons';
import LBTooltip from '../../UI/LBTooltip/LBTooltip';
import { findAppointmentsByDate } from '../utils/helpers';

import './AppointmentCalendar.scss';

function Icon({
  children,
  size = 16,
  color = 'gray',
  strokeWidth = 3.5,
  rotation = '0',
}) {
  return (
    <svg
      className="calendar__navigation-icon"
      fill="transparent"
      height={size}
      width={size}
      viewBox="0 0 48 48"
      strokeWidth={strokeWidth}
      stroke={color}
      style={{ '--rotation': `${rotation}deg` }}
    >
      {children}
    </svg>
  );
}

function CalendarTooltip({ date }) {
  const { appointments } = useAppointmentContext();

  const tooltipAppointments = findAppointmentsByDate(date, appointments);

  const containerStyles = {};

  const itemStyles = {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
  };

  return (
    <div className="react-calendar__tooltip">
      <LBTooltip
        delayDuration={500}
        tooltipContent={
          <div style={containerStyles}>
            {tooltipAppointments.map((app) => (
              <span key={app.id} style={itemStyles}>
                {app.name}
              </span>
            ))}
          </div>
        }
        side="bottom"
        sideOffset={4}
      >
        <div className="tooltip-placeholder" />
      </LBTooltip>
    </div>
  );
}

function AppointmentCalendar() {
  const { currentDate, updateCurrentDay, appointments } =
    useAppointmentContext();

  const today = new Date();
  const datesWithAppointments = appointments?.length
    ? appointments.map((app) => new Date(app.start_date))
    : [];

  const tileClassName = React.useCallback(
    ({ date, view }) => {
      let result = '';
      // Add class to tiles in month view only
      if (view === 'month') {
        // Format today
        if (isSameDay(today, date)) {
          result += 'react-calendar__today ';
        }

        // Format weekend
        if (isWeekend(date)) {
          result += 'react-calendar__weekend ';
        }

        // Format selected date
        if (isSameDay(date, currentDate)) {
          result += 'react-calendar__selected ';
        }

        // Format dates with appointments
        if (
          datesWithAppointments &&
          datesWithAppointments.find((dDate) => isSameDay(dDate, date))
        ) {
          result += 'react-calendar__has-appointment ';
        }
      }
      return result;
    },
    [appointments, currentDate]
  );

  const tileContent = React.useCallback(
    ({ date, view }) => {
      // Add class to tiles in month view only
      if (view === 'month') {
        // Check if a date React-Calendar wants to check is on the list of dates to add class to
        if (datesWithAppointments.find((dDate) => isSameDay(dDate, date))) {
          return <CalendarTooltip date={date} />;
        }
      }

      return null;
    },
    [appointments]
  );

  const calendarProps = {
    value: currentDate,
    onChange: (e) => updateCurrentDay(e),
    nextLabel: (
      <Icon size={12} strokeWidth={5}>
        <Carat />
      </Icon>
    ),
    next2Label: (
      <Icon size={12} strokeWidth={5}>
        <DoubleCarat />
      </Icon>
    ),
    prevLabel: (
      <Icon size={12} strokeWidth={5} rotation="180">
        <Carat />
      </Icon>
    ),
    prev2Label: (
      <Icon size={12} strokeWidth={5} rotation="180">
        <DoubleCarat />
      </Icon>
    ),
    onActiveStartDateChange: (e) => {
      updateCurrentDay(e.activeStartDate);
    },
  };

  return (
    <div
      style={{
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        flex: '0 0 auto',
      }}
    >
      <Calendar
        {...calendarProps}
        tileClassName={tileClassName}
        tileContent={tileContent}
      />
    </div>
  );
}

export default AppointmentCalendar;
