import messagesRoutes from '../messages/messagesRoutes';
import EstimateDocument from '../../components/Estimates/EstimateDocument.tsx';
import ManageEstimate from '../../components/Estimates/ManageEstimate';
import ErrorMesage from '../../pages/ErrorMesage';
import { deleteEstimateAction, reactivateEstimateAction } from './estimatesLA';
import SavingEstimateAndPdfUpload from '../../components/Estimates/SavingEstimateAndPdfUpload';
import DownloadEstimate from '../../components/Estimates/DownloadEstimate';

const estimatesListAndModal = [
  ...messagesRoutes,
  {
    path: 'pdf-preview',
    element: <EstimateDocument />,
    errorElement: <ErrorMesage message="Estimate preview unavailable" />,
  },
  {
    path: 'manage',
    element: <ManageEstimate />,
    errorElement: <ErrorMesage />,
    children: [
      {
        path: 'delete',
        action: deleteEstimateAction,
      },
      {
        path: 'activate',
        loader: reactivateEstimateAction,
      },
      {
        path: 'save',
        element: <SavingEstimateAndPdfUpload />,
      },
      {
        path: 'pdownload',
        element: <DownloadEstimate />,
      },
    ],
  },
];

export default estimatesListAndModal;
