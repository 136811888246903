import React from 'react';
// import './styles/PaymentSetUp.scss';
import { useLoaderData } from 'react-router-dom';
import stripe from '../../img/Stripe.webp';

function PaymentSetUp() {
  const { url } = useLoaderData();

  return (
    <div className="d-flex justify-content-center">
      <a
        className="btn btn-primary p-2 mb-3 me-3 "
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <div className="d-flex justify-content-center">
          <img
            alt="stripe"
            src={stripe}
            style={{ height: '90px', margin: '0, auto' }}
          />
        </div>

        <p className="gateway-setup-text">Gateway Setup</p>
      </a>
    </div>
  );
}

export default PaymentSetUp;
