import React from 'react';
import propTypes from 'prop-types';
import DataCurrency from '../../UI/Table/td/DataCurrency';

function TotalsTable({ totals: { tax, total } }) {
  return (
    <>
      <td className="footer-spacer" aria-label="label" />
      <td className="totals-labels">
        TAX TOTAL
        <br />
        TOTAL
      </td>
      <td colSpan={2} className="totals" aria-label="label">
        <DataCurrency value={tax} />
        <DataCurrency value={total} />
      </td>
    </>
  );
}

TotalsTable.propTypes = {
  totals: propTypes.shape({
    tax: propTypes.number.isRequired,
    total: propTypes.number.isRequired,
  }).isRequired,
};

export default TotalsTable;
