/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'react-bootstrap';
import DragFileConfirm from './DragFileConfirm';

function DragFilePreview({ name, values, handleRemove, image }) {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isStaging = process.env.NODE_ENV === 'development';

  return (
    <>
      <div className="mb-5 File__preview File__wrapper">
        <img
          src={
            image ||
            `https://${
              isStaging ? 'staging' : 'api'
            }.leadsboxinvoice.com/${values}`
          }
          width={150}
          alt={name}
        />
        <p>{values}</p>
        <Button variant="danger" className="mt-2" onClick={handleShow}>
          Remove
        </Button>
      </div>
      <DragFileConfirm
        show={show}
        handleClose={handleClose}
        handleRemove={handleRemove}
      />
    </>
  );
}

export default DragFilePreview;
