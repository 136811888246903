// export async function fetchData(url) {
//   const response = await fetch(url);
//   if (!response.ok) {
//     throw new Error(`Failed to fetch data from ${url}`);
//   }
//   return response.json();
// }
import { redirect } from 'react-router-dom';
import { login } from '../routes/paths';

export async function fetchData(url) {
  if (localStorage.getItem('token') === null) {
    return redirect(`${login}`);
  }
  const myHeaders = new Headers();
  const token = localStorage.getItem('token');
  // const token = parseToken.split('.')[1];
  myHeaders.append('auth_token', token);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error(`Failed to get data`);
  }
  const result = await response.json();
  if (result.message === 'invalid_token') {
    localStorage.clear();
    return redirect(`${login}`);
  }
  return result;
}

export async function fetchWithToken(url, request) {
  // auth token handler
  const token = localStorage.getItem('token');
  if (token === null) {
    localStorage.clear();
    return redirect(`${login}`);
  }
  // const token = parseToken.split('.')[1];
  request.headers.append('auth_token', token);

  // fetch handler
  const response = await fetch(url, request);
  if (!response.ok) {
    throw new Error(`Failed to get data`);
  }
  const result = await response.json();
  if (result.message === 'invalid_token') {
    localStorage.clear();
    return redirect(`${login}`);
  }
  return result;
}

export async function postWithToken(url, body, contentType = null) {
  const HEADERS = new Headers();
  if (contentType) {
    HEADERS.append('Content-Type', contentType);
  }
  const REQUEST = {
    method: 'POST',
    headers: HEADERS,
    body,
  };
  const RESULT = await fetchWithToken(url, REQUEST);
  return RESULT;
}

export const BASE_URL = `https://63ed21433d9c852c3f569c64.mockapi.io/user/1`;
