import { useEffect } from 'react';
import { useFetcher } from 'react-router-dom';

export default function useFetcherOnce(url, condition = true) {
  const fetcher = useFetcher();
  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data && condition)
      fetcher.load(url);
  }, [fetcher]);
  return fetcher;
}
