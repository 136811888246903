import React from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import BillingTo from './BillingTo';

import { I_Colors, I_Data } from '../propTypes';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '-30px -40px 0px -40px',
  },
  superHeader: {
    height: 90,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20,
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  infoContainer: {
    color: 'white',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    top: 28,
    left: 425,
    width: 140,
    fontSize: 7,
    fontWeight: 400,
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '34%',
  },
  type: {
    fontSize: 30,
    letterSpacing: 1.08,
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: 1,
  },
  companyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 14,
    width: '66%',
  },
  companyLogo: {
    height: 56,
    width: 56,
    overflow: 'hidden',
    borderRadius: 4,
  },
  companyCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
  },
  companyName: {
    fontSize: 14,
    fontWeight: 500,
  },
  companySlogan: {
    fontSize: 12,
    fontWeight: 300,
  },
});

function Header({ data, colors }: { data: I_Data; colors: I_Colors }) {
  return (
    <View style={styles.container}>
      <View
        style={{
          ...styles.superHeader,
          backgroundColor: colors.color1,
          color: colors.color1text,
        }}
      >
        <View style={styles.companyContainer}>
          <Image style={styles.companyLogo} src={data.user.company.logo} />
          <View style={{ ...styles.companyCol, color: colors.color1text }}>
            <Text style={styles.companyName}>{data.user.company.name}</Text>
            <Text style={styles.companySlogan}>{data.user.company.slogan}</Text>
          </View>
        </View>
        <View style={styles.typeContainer}>
          <Text style={styles.type}>{data.type}</Text>
        </View>
      </View>

      <BillingTo data={data} colors={colors} />
      {/* <View style={styles.companyContainer}>
        <View style={{ ...styles.companyCol, color: colors.color1text }}>
          <Image style={styles.companyLogo} src={data.user.company.logo} />
        </View>
        <View style={{ ...styles.companyCol, color: colors.color1text }}>
          <Text style={styles.companyName}>{data.user.company.name}</Text>
          <Text style={styles.companySlogan}>{data.user.company.slogan}</Text>
        </View>
      </View>
      <View style={{ ...styles.infoContainer, color: colors.color1text }}>
        <Text style={styles.type}>{typeEnum[data.type]}</Text>
        <View style={styles.infoRow}>
          <Text>Number</Text>
          <Text>{data.number}</Text>
        </View>
        <View style={styles.infoRow}>
          <Text>Date</Text>
          <Text>
            {parseISO(data.dates.dateCreated).toLocaleDateString('en-US', {
              year: 'numeric',
              day: '2-digit',
              month: '2-digit',
            })}
          </Text>
        </View>
        {data.type !== 'receipt' ? (
          <View style={styles.infoRow}>
            <Text>{data.type === 'invoice' ? 'Due date' : 'Expires'}</Text>
            <Text>
              {parseISO(data.dates.dateDue).toLocaleDateString('en-US', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
              })}
            </Text>
          </View>
        ) : null}
      </View>

       */}
    </View>
  );
}

export default Header;
