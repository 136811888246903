/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useOutletContext,
  useFetcher,
  useParams,
} from 'react-router-dom';
import SendToButtons from '../UI/buttons/SendToButtons';
import isPdfAllowed from '../PDF/IsPdfAllowed';

function SendTo() {
  const { pathname } = useLocation();
  const { estimateId, invoiceId, paymentId } = useParams();
  const url = pathname.split('/');
  const end = url.pop();
  const { phone, email, subject } = useOutletContext();
  const fetcher = useFetcher();

  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      if (estimateId) {
        fetcher.load(`/messages/email/estimate/${estimateId}`);
      }
      if (invoiceId) {
        fetcher.load(`/messages/email/invoice/${invoiceId}`);
      }
      if (paymentId) {
        fetcher.load(`/messages/email/payment/${paymentId}`);
      }
    }
  }, [fetcher, estimateId]);
  const messagesData =
    (fetcher?.data?.data?.length > 0 &&
      fetcher?.data?.data.map((messageData, index) => (
        <React.Fragment key={index}>
          <hr />
          <p className="lb-modal__data mb-4 mt-2">
            Sent to: {messageData.email}{' '}
          </p>
        </React.Fragment>
      ))) ||
    'No messages yet';
  const preFillData = isPdfAllowed('To send documents');
  return preFillData[0] ? (
    <div className="d-flex flex-column side-top-overflow h-md-100 mt-5 mb-5">
      <div
        className={`d-flex flex-column justify-content-center   ${
          end === 'send' ? '' : 'd-none'
        }`}
      >
        <SendToButtons />
        <section className="mt-5 mb-5">
          <h3>History</h3>

          {fetcher.data ? messagesData : 'Loading...'}
        </section>
      </div>

      <Outlet context={{ phone, email, subject }} />
    </div>
  ) : (
    <div className="d-flex flex-column align-items-center h-100 justify-content-center">
      {preFillData[1]}
    </div>
  );
}

export default SendTo;
