import React from 'react';
import { Link } from 'react-router-dom';
import OnboardGrid from '../../UI/OnboardGrid/OnboardGrid';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import BulletList from '../../UI/BulletList/BulletList';
import StepList from '../../UI/StepList/StepList';
import image from '../../../img/assets/images/c-userOnboard-expenses.svg';

import './expensesOnboard.styles.scss';

export const expensesBullets = [
  {
    id: 0,
    pre: 'Create Expenses Categories',
    body: 'Know exactly where the money goes, like office expenses, materials store, vendors, insurance, etc... These categories can be included in the Profit and Loss Report.',
  },
  {
    id: 1,
    pre: 'Create Merchants',
    body: 'This “Merchants” terms is used to refer to business that provide some type of service to your business such as insurance broker, telephone service, rent, sales of materials, etc...',
  },
  {
    id: 2,
    pre: 'Add Expenses to Individual Invices',
    body: 'Stay in control of work expenses.',
  },
  {
    id: 3,
    pre: 'Recurrent Expenses',
    body: 'Program recurring expenses per month and day such as insurance payment, car payment, rent, utilities, etc...',
  },
  {
    id: 4,
    pre: 'Attachments',
    body: 'Add documents and receipts to expenses. Stay organized for tax season.',
  },
  {
    id: 5,
    pre: 'Notes',
    body: 'Add notes to expenses.',
  },
  {
    id: 6,
    pre: 'Create Vendors',
    body: 'The term "Vendor" is used to refer to freelancers who do work for you but are not employees. They have their own insurance and receive the 1099 form for their taxes. Add vendor photo, insurance document and W-g.',
  },
];

export const expensesSteps = [
  {
    id: 0,
    body: <p>Create expense categories.</p>,
  },
  {
    id: 1,
    body: (
      <p>
        Create Merchants, Start with the ones you normally use. Example:
        Insurance broker, Bank, hardware store, gas station, rentals, etc.
      </p>
    ),
  },
  {
    id: 2,
    body: (
      <p>
        Optional, If you have vendors, create their profile and keep all their
        information in one place.
      </p>
    ),
  },
  {
    id: 3,
    body: (
      <p>
        Create Expense; fill out all the blanks, select if is recurrent or not,
        add notes and attachments is optional. Then Save.
      </p>
    ),
  },
];

function ExpensesOnboard() {
  return (
    <CardWrapper>
      <OnboardGrid>
        <OnboardGrid.Header>
          <h2>Keep track of your Expenses</h2>
        </OnboardGrid.Header>
        <OnboardGrid.Left>
          <BulletList
            list={expensesBullets}
            title="Expenses module features:"
          />
          <StepList stepArray={expensesSteps} />
        </OnboardGrid.Left>
        <OnboardGrid.Right>
          <img
            className="page-image"
            src={image}
            alt="vector illustration of a person viewing data visualizations"
          />
          <div className="action-group">
            <Link
              className="support-link btn btn-primary p-2 mb-3"
              variant="secondary"
              to="/expenses/new"
            >
              Add your first expense
            </Link>
          </div>
          <div className="support-wrapper">
            <p className="support">Have Questions?</p>
            <a
              className="support-link"
              href="mailto:support@leadsbox.com?subject=Support needed"
            >
              Go to support
            </a>
          </div>
        </OnboardGrid.Right>
      </OnboardGrid>
    </CardWrapper>
  );
}

export default ExpensesOnboard;
