/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from '../../../UI/Accordion/AccordionItem';

export default function TemplateAccordion({ accordionLayout }) {
  return (
    <Accordion defaultActiveKey={['0']}>
      {accordionLayout.map(({ header, component }, index) => (
        <AccordionItem
          key={index}
          eventKey={index.toString()}
          header={header}
          className="d-flex flex-wrap gap-2"
        >
          {component}
        </AccordionItem>
      ))}
    </Accordion>
  );
}
