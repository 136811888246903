/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  useLoaderData,
  Link,
  useFetcher,
  Outlet,
  useOutletContext,
  useLocation,
} from 'react-router-dom';
import { ModalTitle, Button } from 'react-bootstrap';
import TextField from '../../UI/text/TextField';
import { transformDateHook } from '../../Helpers/toDates';
import ModalNavigation from '../../UI/ModalNavigation';

export default function PaymentModal() {
  const [setIsExpanded] = useOutletContext();
  const {
    data: { payment, customer, invoice },
  } = useLoaderData();
  const fetcher = useFetcher();
  const handleDelete = () => {
    if (fetcher.state !== 'idle') return;
    const url = `./delete`;
    fetcher.submit({}, { method: 'post', action: url });
  };
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.includes('pdf') || pathname.includes('send')) {
      setIsExpanded(true);
    }
  }, [pathname]);

  const navigation = [
    { label: 'Preview receipt', to: 'pdf' },
    { label: 'Send receipt', to: 'send' },
  ];

  return (
    <div className="modal-layout">
      <div className="main-top">
        <section>
          <ModalTitle className="modal-title">
            Payment {payment.number}
          </ModalTitle>
        </section>
        <section className="d-flex align-items-end justify-content-between">
          <TextField label="Customer name">
            {customer.name ? (
              <Link
                to={`/customers/${customer.id}`}
                className="link"
                target="_blank"
              >
                {customer.name}
              </Link>
            ) : (
              'missing'
            )}
          </TextField>
          <TextField label="Invoice">
            {invoice ? (
              <Link
                to={`/invoices/${invoice.id}`}
                className="link"
                target="_blank"
              >
                {invoice.number}
              </Link>
            ) : (
              'missing'
            )}
          </TextField>
          <TextField label="Invoice date">
            {invoice.date ? transformDateHook(invoice.date) : 'missing'}
          </TextField>
        </section>
        <section className="d-flex align-items-end justify-content-between">
          <TextField label="Payment method">
            {payment.method || 'missing'}
          </TextField>
          <TextField label="Payment date">
            {payment.date ? transformDateHook(payment.date) : 'missing'}
          </TextField>

          <TextField label="Amount">
            <span className="table__money numbers">
              {payment.amount || 'missing'}
            </span>
          </TextField>
        </section>
        <section className="d-flex flex-column">
          <Link to="./update" className="btn btn-primary">
            Edit
          </Link>
          <Button variant="danger" className="mt-3" onClick={handleDelete}>
            Delete
          </Button>
        </section>
        {navigation.map((item) => (
          <ModalNavigation setIsExpanded {...item} key={item.label} />
        ))}
      </div>
      <div className="side-top">
        <Outlet
          context={{
            payment,
            customer,
            invoice,
            email: customer.email,
            subject: `Your receipt ${payment.number} is ready to view`,
          }}
        />
      </div>
      <div
        className="side-footer d-flex justify-content-between"
        id="modalBottom"
      />
    </div>
  );
}
