import GetExpense from '../../components/Expenses/GetExpense';
import GetExpenseForm from '../../components/Expenses/GetExpenseForm';
import {
  createExpenseAction,
  deleteExpenseAction,
  expenseLoader,
  updateExpenseAction,
} from './expensesLA';

const createExpense = [
  {
    path: 'new',
    element: <GetExpenseForm />,
    action: createExpenseAction,
    handle: {
      crumb: () => 'New Expense',
      linkTo: {
        noShow: true,
      },
    },
  },
  {
    path: ':expenseId',
    element: <GetExpense />,
    loader: expenseLoader,
    children: [
      {
        path: 'update',
        element: <GetExpenseForm />,
        action: updateExpenseAction,
        handle: {
          crumb: () => 'Update Expense',
          linkTo: {
            noShow: true,
          },
        },
      },
      {
        path: 'delete',
        action: deleteExpenseAction,
      },
    ],
  },
];

export default createExpense;
