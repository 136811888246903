/* eslint react/prop-types: 0 */

import React from 'react';
import { createPortal } from 'react-dom';
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';
import Header from '../Header';
import Body from './Body';
import TableWrapper from './TableWrapper';
import AddItemWrapper from './AddItemForm/AddItemWrapper';
// import Foot from './Foot';

import './EditTable.scss';

function Table({
  data,
  schema,
  mobileClass,
  setItems,
  onClick = () => {},
  errors,
  showHeader = true,
}) {
  const table = useReactTable({
    columns: schema,
    data,
    getCoreRowModel: getCoreRowModel(),
  });
  const { rows } = table.getRowModel();
  const columns = table.getAllColumns();

  return (
    <>
      <TableWrapper mobileClass={mobileClass}>
        {showHeader && <Header table={table} flexRender={flexRender} />}
        <Body
          columns={columns}
          rows={rows}
          onChange={setItems.update}
          onClick={onClick}
          onDelete={setItems.remove}
          errors={errors}
        />
      </TableWrapper>
      {document.getElementById('add-item-portal') &&
        createPortal(
          <AddItemWrapper
            columns={columns}
            rows={rows}
            onAdd={(item) => setItems.push('items', item)}
          />,
          document.getElementById('add-item-portal')
        )}
    </>
  );
}

export default Table;
