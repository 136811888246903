/* eslint-disable no-restricted-globals */
import React from 'react';
import { Form, Link, useParams, useOutletContext } from 'react-router-dom';
import IconButton from '../UI/IconButton/IconButton.tsx';
import { Copy, Edit, Pay, TrashCan } from '../UI/Icons/Icons';

// "invoice_statuses": {
//   "1": {
//       "label": "Unpaid",
//       "class": "red",
//       "href": "invoices/status/unpaid"
//   },
//   "2": {
//       "label": "Partial P",
//       "class": "orange",
//       "href": "invoices/status/partial"
//   },
//   "3": {
//       "label": "Paid",
//       "class": "green",
//       "href": "invoices/status/paid"
//   }
// }
function ManageInvoice() {
  const { invoiceId: documentNumber } = useParams();

  const { status: statusId } = useOutletContext();
  const unpaid = statusId === 1;

  return (
    <div className="d-flex flex-column h-100 justify-content-center gap-2">
      <IconButton
        className="icon-button__modal"
        size={22}
        strokeWidth={3}
        text="Make payment"
        alignText="left"
        color="primary"
        as={Link}
        to="../../../payments/new"
        state={{ invoiceId: documentNumber }}
        outline={false}
      >
        <Pay />
      </IconButton>
      <IconButton
        className="icon-button__modal"
        size={22}
        strokeWidth={3}
        text="Edit"
        alignText="left"
        color="primary"
        as={Link}
        to="../edit"
        outline={false}
      >
        <Edit />
      </IconButton>

      {unpaid && (
        <Form
          method="post"
          action="delete"
          onSubmit={(event) => {
            if (!confirm('Please confirm you want to delete this record.')) {
              event.preventDefault();
            }
          }}
        >
          <IconButton
            className="icon-button__modal"
            size={22}
            strokeWidth={3}
            text="Delete"
            alignText="left"
            color="red"
            type="submit"
            outline={false}
          >
            <TrashCan />
          </IconButton>
        </Form>
      )}

      <IconButton
        className="icon-button__modal"
        text="Copy"
        alignText="left"
        color="primary"
        as={Link}
        to="../copy"
        outline={false}
      >
        <Copy />
      </IconButton>
    </div>
  );
}

export default ManageInvoice;
