import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
} from 'recharts';
import { CHART_COLORS } from '../../Dashboard/Widgets/testData';
import SinglePlaceholder from '../../Dashboard/Widgets/PieCharts/SinglePlaceholder';
import {
  CustomLabel,
  renderActiveShape,
} from '../../Dashboard/Widgets/utils/CenterLabel';
import formatCurrency from '../../Dashboard/Widgets/utils/formatCurrency';

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="row-legend">
      {payload.map((entry, index) => {
        return (
          <li
            // eslint-disable-next-line react/no-array-index-key
            key={`item-${index}`}
            className="item"
            style={{ '--legend-color': entry.color }}
          >
            <span className="value">
              {formatCurrency(entry.payload.payload.value)}
            </span>
            <span className="field-name">{entry.value}</span>
          </li>
        );
      })}
      <li className="item" style={{ '--legend-color': 'var(--bs-primary)' }}>
        <span className="value">
          {formatCurrency(
            payload.reduce((acc, cur) => acc + cur.payload.payload.value, 0)
          )}
        </span>
        <span className="field-name">Total</span>
      </li>
    </ul>
  );
};

function ReportsPieChart({ data, totalValue, loading }) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const onPieEnter = React.useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const onPieExit = React.useCallback(() => {
    setActiveIndex(-1);
  }, [setActiveIndex]);

  if (!data || loading) return <SinglePlaceholder />;

  return (
    <div style={{ width: '100%', height: 380 }}>
      {totalValue === 0 ? (
        <div className="widgets__empty-widget rounded-2">
          No entries for the selected range
        </div>
      ) : (
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius="60%"
              activeShape={renderActiveShape}
              activeIndex={activeIndex}
              onMouseEnter={onPieEnter}
              onMouseLeave={onPieExit}
              startAngle={-270}
            >
              {data.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${entry}`}
                    fill={CHART_COLORS[index % CHART_COLORS.length]}
                  />
                );
              })}
              {activeIndex === -1 && (
                <Label
                  content={CustomLabel}
                  offset={0}
                  position="center"
                  totalValue={totalValue}
                />
              )}
            </Pie>
            <Legend verticalAlign="bottom" content={renderLegend} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

ReportsPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  totalValue: PropTypes.number,
  loading: PropTypes.bool,
};

export default ReportsPieChart;
