import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useActionData, useRouteLoaderData } from 'react-router-dom';
import { SALES_TOGGLE } from '../../constants/toggleOptions';

import ReactTablev8 from '../../../UI/Table/ReactTablev8';
import ReportHeader from '../../ReportHeader';
import SalesByCustomerPDF from './ProductsAndServicesListPDF';
import ReportFooter from '../../ReportFooter';

function ProductsAndServicesList() {
  const actionData = useActionData();
  const rootLoader = useRouteLoaderData('root');
  if (actionData?.message !== 'success' || !actionData)
    return <p className="card p-4 mt-4 border-0">Error generating report</p>;

  const tableData = actionData?.data.map((item) => ({
    item_name: item.name,
    sku: item.sku,
    description: item.description,
    tax: item.tax,
    price: item.product_price,
    id: item.product_id,
  }));

  const tableSetup = [
    {
      header: 'Product/Service',
      accessorKey: 'item_name',
      enableSorting: false,
    },
    {
      header: 'SKU',
      accessorKey: 'sku',
      enableSorting: false,
      mobileLabel: true,
    },
    {
      header: 'Description',
      accessorKey: 'description',
      enableSorting: false,
      type: 'textArea',
      mobileLabel: true,
    },
    {
      header: 'Tax',
      accessorKey: 'tax',
      enableSorting: false,
      mobileLabel: true,
    },
    {
      header: 'Price',
      accessorKey: 'price',
      type: 'money',
      enableSorting: false,
    },
  ];

  const tableOptions = {
    search: false,
    pagination: {
      show: false,
    },
    sorting: false,
  };

  return (
    <>
      <ReportHeader
        reportSection={SALES_TOGGLE}
        pdfDocument={
          <SalesByCustomerPDF
            pdfData={{ tableData, tableSetup }}
            userData={rootLoader}
          />
        }
      />
      <div className="container-fluid">
        {tableData.length === 0 ? (
          <Row>
            <Col className="alert alert-secondary" role="alert">
              No sales registered for this date range/product
            </Col>
          </Row>
        ) : (
          <div className="reports__display-react-table">
            <ReactTablev8
              dataTable={tableData}
              tableLayout={tableSetup}
              tableOptions={tableOptions}
              mobileClass="reports_sales_item_list_table"
            />
            <ReportFooter border={false} />
          </div>
        )}
      </div>
    </>
  );
}

export default ProductsAndServicesList;
