/* eslint-disable react/prop-types */

import QL_ICONS from './LinkContainer/iconDefs';

function QuickLinkIcon({ iconId }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      {QL_ICONS[iconId]}
    </svg>
  );
}

export default QuickLinkIcon;
