/* eslint-disable prefer-template */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import toWordsCheck from './toWordsCheck';
import Address from './Address';
import formatCurrency from '../../Dashboard/Widgets/utils/formatCurrency';
import CHECK_DATA_SHAPE from './types';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '244px',
    padding: '60px 36px 28px 36px',
    borderBottom: '1px dashed #AAAAAA',
  },
  date: {
    width: '100%',
    paddingRight: '24px',
    alignSelf: 'flex-end',
    textAlign: 'right',
  },
  nameAndAmount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '25px',
    marginLeft: '47px',
    paddingRight: '8px',
  },
  amount: {
    fontFamily: 'Courier-Bold',
    marginTop: '11px',
    overflow: 'hidden',
  },
  message: {
    marginTop: 'auto',
    marginLeft: '27px',
  },
});

function Main({ data }) {
  const { recipient } = data;
  const totalInWords = toWordsCheck(data.total);
  const fillLength = 78 - totalInWords.length;

  return (
    <View style={styles.container}>
      <View style={styles.date}>
        <Text>
          {new Date(data.payment_date).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </Text>
      </View>
      <View style={styles.nameAndAmount}>
        <View>
          <Text>
            {recipient.name} {recipient?.lastname || ''}
          </Text>
        </View>
        <View style={{ fontFamily: 'Courier-Bold' }}>
          <Text>**{formatCurrency(data.total, 2, false)}</Text>
        </View>
      </View>
      <View style={styles.amount}>
        <Text>{toWordsCheck(data.total) + ' ' + '*'.repeat(fillLength)}</Text>
      </View>
      <Address
        address={recipient.address}
        name={recipient.name}
        lastName={recipient.lastname}
      />
      <View style={styles.message}>
        <Text>{data.memo}</Text>
      </View>
    </View>
  );
}

Main.propTypes = {
  data: CHECK_DATA_SHAPE,
};

export default Main;
