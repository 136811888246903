/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import DragFileInput from './DragFileInput';
import DragFilePreview from './DragFilePreview';
import { cropImage, dataUrlToFile, resizeAndCropImage } from './utils';
import supported from './supported';

import './File.scss';

// helpers
const isValidType = (file, types) => types.includes(file.type);

const isValidSize = (file, size) => Math.round(file.size / size) < size;

export const isValidDimensions = (image, support) => {
  const isLessThenMax = image.width < support.max && image.height < support.max;
  const isMoreThenMin = image.width > support.min && image.height > support.min;
  return isLessThenMax && isMoreThenMin;
};

export const readUploadedFileAsImage = (inputFile, setFileError) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      setFileError('Problem parsing input file.');
      reject(setFileError('Problem parsing input file.'));
    };

    temporaryFileReader.onload = (event) => {
      const fileImage = new Image();
      fileImage.src = event.target.result;
      fileImage.onload = () => {
        resolve(fileImage);
      };
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

export default function LBFile({
  value,
  name,
  label,
  setFormData,
  setFieldValue,
  deleteFile,
  allowed = 'image',
  resize = { type: 'crop', x: 500, y: 500 },
}) {
  const support = supported[allowed];
  // controls the state of the preview image when its uploaded by the user
  const [preview, setPreview] = useState(null);

  // Inner error state
  const [fileError, setFileError] = useState(null);

  const handleFile = async (file, formName = name) => {
    const { size, type, dimensions } = support;
    let image = file;
    if (!isValidType(file, type.types)) {
      setFileError(type.error);
      return;
    }
    if (!isValidSize(file, size.max)) {
      setFileError(size.error);
      return;
    }

    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      const fileImage = await readUploadedFileAsImage(file, setFileError);
      if (!isValidDimensions(fileImage, dimensions)) {
        setFileError(dimensions.error);
        return;
      }

      const dataURL =
        resize.type === 'crop'
          ? cropImage(fileImage, resize.x, resize.y, file.type)
          : resizeAndCropImage(fileImage, resize.x, resize.y, file.type);

      image = await dataUrlToFile(dataURL, image.name);
    }
    setFileError(null);
    setPreview(URL.createObjectURL(image));
    setFieldValue(formName, image.name);
    setFormData((prev) => ({ ...prev, [name]: image }));
  };

  const handleRemove = () => {
    if (preview) setPreview(null);
    deleteFile(name);
    setFieldValue(name, '');
    setFormData((prev) => {
      const next = { ...prev };
      if (prev[name]) {
        delete next[name];
      }
      return next;
    });
  };
  return (
    <>
      <p className="small">{label}</p>
      <div className="position-relative">
        {fileError && (
          <Alert
            className="position-absolute start-50 translate-middle-x small w-100"
            style={{
              padding: '8px',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            variant="danger"
          >
            {fileError}
          </Alert>
        )}

        {!value && (
          <DragFileInput
            handleFile={handleFile}
            label={label}
            name={name}
            support={support}
          />
        )}
        {value &&
          (value.split('.').pop() === 'png' ||
          value.split('.').pop() === 'jpg' ? (
            <DragFilePreview
              name={name}
              values={value}
              handleRemove={handleRemove}
              image={preview}
            />
          ) : (
            <div className="mb-5 File__preview File__wrapper">
              File added: {value}
              <Button variant="danger" className="mt-2" onClick={handleRemove}>
                Remove
              </Button>
            </div>
          ))}
      </div>
    </>
  );
}
