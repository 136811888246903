import React from 'react';
import PropTypes from 'prop-types';
import SelectWithDefault from '../Select/SelectWithDefault';
import CreateSelect from '../Select/CreateSelect';
import SelectMerchantOrVendor from '../Select/SelectMerchantOrVendor';
import SelectPredefined from '../Select/SelectPredefined';
import SelectCustomer from '../Select/SelectCustomer';
import SelectProductByCategory from '../Select/SelectProductByCategory';
import SelectByCategory from '../Select/SelectByCategory';
import SelectRecipient from '../Select/SelectRecipient';
import SelectVehicle from '../Select/SelectVehicle';

function CreateFormSelect({
  categoryId = 0,
  formType,
  handleChange,
  inputName,
  isInvalid,
  options,
  setFieldValue,
  value,
  isClearable,
  isDisabled,
  hasAll,
  config,
}) {
  const handleChangeSelect = async (e) => {
    const nextValue = e?.value ? e.value : '';
    await setFieldValue(`${inputName}`, `${nextValue}`);
    if (nextValue !== value[inputName] && handleChange) handleChange(nextValue);
  };

  const handleChangeRecipients = (res) => {
    const nextValue = res?.event ? res?.event : '';
    setFieldValue(`${inputName}`, `${nextValue}`);
    if (nextValue !== value && handleChange) handleChange(res?.find);
  };
  const handleChangeSelectReturnsObject = (res) => {
    const nextValue = res?.event ? res?.event : '';
    setFieldValue(`${inputName}`, `${nextValue}`);
    if (nextValue !== value[inputName] && handleChange) handleChange(res?.find);
  };

  const formTypesSelector = (form) => {
    switch (form) {
      case 'trades':
      case 'productCategory':
      case 'expenseCategory':
        return (
          <CreateSelect
            createTarget={form}
            handleChange={(e) => handleChangeSelect(e)}
            isInvalid={isInvalid}
            name={inputName}
            value={value}
          />
        );
      case 'merchantsAndVendors':
        return (
          <SelectMerchantOrVendor
            isInvalid={isInvalid}
            name={inputName}
            setFieldValue={setFieldValue}
            value={value}
          />
        );

      case 'invoiceSelect':
        return (
          <SelectPredefined
            formType={form}
            handleChangeSelect={(e) => handleChangeSelect(e)}
            inputName={inputName}
            isInvalid={isInvalid}
            hasAll={hasAll}
            value={value}
          />
        );
      case 'customer':
        return (
          <SelectCustomer
            handleChange={(e) => handleChangeSelect(e)}
            isInvalid={isInvalid}
            name={inputName}
            setFieldValue={setFieldValue}
            value={value}
            config={config}
          />
        );
      case 'productCombo':
        return (
          <SelectProductByCategory
            categoryId={categoryId}
            inputName={inputName}
            value={value}
            isInvalid={isInvalid}
            isDisabled={isDisabled}
            handleChangeSelect={(e) => handleChangeSelectReturnsObject(e)}
          />
        );
      case 'productCategoryCombo':
        return (
          <SelectByCategory
            categoryId={categoryId}
            inputName={inputName}
            value={value}
            isInvalid={isInvalid}
            handleChangeSelect={(e) => handleChangeSelectReturnsObject(e)}
          />
        );
      case 'toCheckSelect':
        return (
          <SelectRecipient
            handleChange={(e) => handleChangeRecipients(e)}
            isInvalid={isInvalid}
            name={inputName}
            options={options}
            value={value}
            setFieldValue={setFieldValue}
          />
        );
      case 'vehicleSelect':
        return (
          <SelectVehicle
            handleChange={(e) => handleChangeSelect(e)}
            isInvalid={isInvalid}
            name={inputName}
            setFieldValue={setFieldValue}
            value={value}
            options={options}
          />
        );

      default:
        return (
          <SelectWithDefault
            handleChange={(e) => handleChangeSelect(e)}
            isInvalid={isInvalid}
            name={inputName}
            options={options}
            value={value}
            isClearable={isClearable}
          />
        );
    }
  };
  return <>{formTypesSelector(formType)}</>;
}

CreateFormSelect.propTypes = {
  formType: PropTypes.string,
  inputName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInvalid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasAll: PropTypes.bool,
  config: PropTypes.shape({}),
};

export default CreateFormSelect;
