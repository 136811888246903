/* eslint react/prop-types: 0 */
import React from 'react';
import { sanitize } from '../../utils';
import DataInput from '../../td/DataInput';
import DataSelect from '../../td/DataSelect';

export default function ItemField({
  column,
  newItem,
  setNewItem,
  firstItemRef,
  label = false,
}) {
  const { header, className, type } = column;
  const sanitizedHeader = sanitize(header);

  const onChange = (e) => {
    if (e !== newItem) {
      setNewItem(e);
    }
  };

  return (
    <div
      className={`${className || ''} add-check__col mobile__${sanitizedHeader}`}
      style={{
        gridArea: sanitizedHeader,
      }}
    >
      {label && (
        <span className="d-block mb-2" style={{ fontWeight: 400 }}>
          {header}:
        </span>
      )}
      {type === 'input' && (
        <DataInput
          firstItemRef={
            sanitizedHeader === 'description' ? firstItemRef : undefined
          }
          className="table__input--add"
          onChange={(e) => onChange(e)}
          value={newItem}
          type={column?.inputType || 'text'}
        />
      )}
      {type === 'select' && (
        <DataSelect
          options={column?.options}
          value={newItem}
          onChange={(e) => onChange(e)}
          prefix="add-select"
        />
      )}
    </div>
  );
}
