import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf',
      fontWeight: '700',
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    marginTop: 30,
    fontWeight: '700',
    fontFamily: 'Montserrat',
    fontSize: 16,
  },
  body: {
    marginTop: 20,
  },
  strongDivider: {
    borderTop: 2,
    marginHorizontal: 20,
    marginVertical: 5,
  },
  categoriesBar: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  columnCategories: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  textCategories: {
    // marginLeft: 40,
    fontWeight: '700',
    fontFamily: 'Montserrat',
  },
  invoiceCategories: {
    // marginLeft: 55,
    fontWeight: '700',
    fontFamily: 'Montserrat',
  },
  taxCategories: {
    // marginLeft: 45,
    fontWeight: '700',
    fontFamily: 'Montserrat',
  },

  softDivider: {
    borderTop: 1,
    marginHorizontal: 20,
    marginVertical: 5,
  },
  bottomSoftDivider: {
    borderTop: 1,
    marginVertical: 5,
    marginHorizontal: 20,
  },
  bottomStrongDivider: {
    borderTop: 2,
    marginVertical: 5,
    marginHorizontal: 20,
  },
  subTotal: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 40,
    marginTop: 5,
  },
  taxTotal: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 40,
    marginTop: 20,
  },
  totalSales: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 40,
    marginTop: 5,
  },
  footer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: 35,
  },

  content: {
    position: 'absolute',
    top: 170,
    width: '100%',
  },
  customerText: {
    fontSize: 14,
    width: 145,
    fontFamily: 'Montserrat',
  },
  skuText: {
    fontSize: 14,
    width: 85,
    fontFamily: 'Montserrat',
    overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },
  descriptionText: {
    fontSize: 14,
    width: 145,
    fontFamily: 'Montserrat',
  },
  dateText: {
    fontSize: 14,
    width: 95,
    marginLeft: 5,
    fontFamily: 'Montserrat',
  },
  invoiceTextCustomer: {
    fontSize: 14,
    width: 125,
    fontFamily: 'Montserrat',
  },
  taxTextCustomer: {
    fontSize: 14,
    width: 80,
    fontFamily: 'Montserrat',
  },
  amountTextCustomer: {
    fontSize: 14,
    fontFamily: 'Montserrat',
  },
  invoiceTextSales: {
    fontSize: 14,
    width: 125,
    fontFamily: 'Montserrat',
    marginLeft: 50,
  },
  taxTextSales: {
    fontSize: 14,
    width: 80,
    fontFamily: 'Montserrat',
    marginLeft: 30,
  },
  amountTextSales: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    marginLeft: 40,
  },
  amountText: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    marginLeft: 40,
  },
  taxText: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    marginLeft: 10,
  },

  totalSalesTxt: {
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  totalSalesPriceTxt: {
    fontWeight: 'bold',
    marginLeft: 80,
    fontFamily: 'Montserrat',
  },
});

export default styles;
