import React from 'react';
import propTypes from 'prop-types';
import formatCurrency from '../../Dashboard/Widgets/utils/formatCurrency';

function MoneyWrapper({ children }) {
  const numberValue = Number(children);

  if (Number.isNaN(numberValue))
    throw new Error('Please provide a numeric value');

  return (
    <span>
      <span style={{ marginRight: '0.8rem' }}>$</span>
      {formatCurrency(numberValue, 2, false)}
    </span>
  );
}

MoneyWrapper.propTypes = {
  children: propTypes.oneOfType([propTypes.number, propTypes.string]),
};

export default MoneyWrapper;
