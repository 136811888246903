import React from 'react';
import { createPortal } from 'react-dom';
import { Form, Link, Outlet, useRouteLoaderData } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import LBOffCanvas from '../../UI/LBOffCanvas';
import IconButton from '../../UI/IconButton/IconButton';
import { Edit, TrashCan } from '../../UI/Icons/Icons';
import LBTooltip from '../../UI/LBTooltip/LBTooltip';

import './MileagesPage.scss';

function Vehicles() {
  const { vehicles } = useRouteLoaderData('vehicles');

  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    // This code runs after the component has mounted
    setIsMounted(true);

    // Cleanup function to run when the component unmounts
    return () => {
      setIsMounted(false);
    };
  }, []); // Runs only once (probably twice in dev mode)

  return (
    <>
      <LBOffCanvas.Wrapper>
        <LBOffCanvas.Left>
          <LBOffCanvas.Header>All Vehicles</LBOffCanvas.Header>

          {vehicles?.length > 0 ? (
            <ul className="vehicles__list">
              {vehicles.map((vehicle) => (
                <li key={vehicle.id}>
                  <span className="d-flex flex-column align-items-start gap-1">
                    {vehicle.licensePlate && (
                      <span className="vehicles__list-license">
                        {vehicle.licensePlate}
                      </span>
                    )}
                    <span className="vehicles__list-name">
                      <strong>{vehicle.brand}</strong> | {vehicle.model}{' '}
                      {vehicle.productionYear > 0 && (
                        <span className="vehicles__list-year">
                          ({vehicle.productionYear})
                        </span>
                      )}
                    </span>
                  </span>
                  <span className="d-flex gap-2">
                    <LBTooltip
                      tooltipText="Edit vehicle"
                      style={{ zIndex: 5000 }}
                    >
                      <IconButton
                        color="blue"
                        as={Link}
                        to={`./update-vehicle?vehicleId=${vehicle.id}`}
                      >
                        <Edit />
                      </IconButton>
                    </LBTooltip>
                    <LBTooltip
                      tooltipText="Delete vehicle"
                      style={{ zIndex: 5000 }}
                    >
                      <Form
                        method="post"
                        action={`./delete-vehicle?vehicleId=${vehicle.id}`}
                        replace
                      >
                        <IconButton type="submit" color="red">
                          <TrashCan />
                        </IconButton>
                      </Form>
                    </LBTooltip>
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No vehicles</p>
          )}
        </LBOffCanvas.Left>
        <LBOffCanvas.Right>
          <Outlet />
        </LBOffCanvas.Right>
      </LBOffCanvas.Wrapper>

      {isMounted &&
        document.getElementById('offcanvas-action-group-left') &&
        createPortal(
          <>
            <Button as={Link} to="./new-vehicle" variant="primary">
              Add Vehicle
            </Button>
            <Button as={Link} to=".." variant="secondary">
              Back
            </Button>
          </>,
          document.getElementById('offcanvas-action-group-left')
        )}
    </>
  );
}

export default Vehicles;
