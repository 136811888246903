import React, { useState, useEffect } from 'react';
import proptypes from 'prop-types';
import SelectWithDefault from './SelectWithDefault';
import useFetcherOnce from '../../Invoices/useFetcherOnce';

function SelectProductByCategory({
  categoryId,
  inputName,
  value,
  isInvalid,
  handleChangeSelect,
  isDisabled,
}) {
  const [options, setOptions] = useState([]);
  const fetcher = useFetcherOnce('/products');
  const products = fetcher?.data?.products;

  useEffect(() => {
    if (!categoryId || !products) return;
    const productList = products
      .filter((product) => product.category.id === +categoryId)
      .map((product) => ({
        label: product.name,
        value: product.id,
      }));

    setOptions(productList);
  }, [categoryId, products]);

  const returnSelectedProduct = (selectedValue) => {
    const selected =
      products?.find((product) => product.id === selectedValue?.value) || {};
    return {
      event: selectedValue?.value,
      find: selected,
    };
  };
  if (fetcher?.state === 'loading') return <p>Loading...</p>;
  if (fetcher?.data?.message !== 'success')
    return <p>Data not available, try again later.</p>;
  return (
    <SelectWithDefault
      handleChange={(e) => handleChangeSelect(returnSelectedProduct(e))}
      isInvalid={isInvalid}
      name={inputName}
      options={options}
      value={value}
      isDisabled={isDisabled}
    />
  );
}

SelectProductByCategory.propTypes = {
  value: proptypes.oneOfType([proptypes.string, proptypes.number]),
  handleChangeSelect: proptypes.func.isRequired,
  isInvalid: proptypes.bool,
  inputName: proptypes.string.isRequired,
  categoryId: proptypes.oneOfType([proptypes.string, proptypes.number])
    .isRequired,
  isDisabled: proptypes.bool,
};

SelectProductByCategory.defaultProps = {
  value: '',
  isInvalid: false,
};

export default SelectProductByCategory;
