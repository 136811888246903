import React from 'react';

const useCustomerFormData = ({ customers, formik }) => {
  const [customer, setCustomer] = React.useState({});
  React.useEffect(() => {
    if (!customers) return;
    if (customer?.id?.toString() !== formik.values.customer_id) {
      const customerObject = customers.find(
        (item) => item.id === Number(formik.values.customer_id)
      );
      if (customerObject) {
        setCustomer(customerObject);
        formik.setFieldValue('contact_phone', customerObject.mobile);
        formik.setFieldValue(
          'appointment_address_street_line_1',
          customerObject.address.street_address_1 || ''
        );
        formik.setFieldValue(
          'appointment_address_street_line_2',
          customerObject.address.street_address_2 || ''
        );
        formik.setFieldValue(
          'appointment_city',
          customerObject.address.city || ''
        );
        formik.setFieldValue(
          'appointment_state',
          customerObject.address.state || ''
        );
        formik.setFieldValue(
          'appointment_zipcode',
          customerObject.address.zipcode || ''
        );
        formik.setFieldValue(
          'appointment_country',
          customerObject.address.country || ''
        );
        formik.setFieldValue('contact_name', customerObject.name || '');
        return;
      }
      setCustomer({});
      formik.setFieldValue('contact_phone', '');
      formik.setFieldValue('contact_name', '');
      formik.setFieldValue('appointment_address_street_line_1', '');
      formik.setFieldValue('appointment_address_street_line_2', '');
      formik.setFieldValue('appointment_zipcode', '');
      formik.setFieldValue('appointment_city', '');
      formik.setFieldValue('appointment_state', '');
      formik.setFieldValue('appointment_country', '');
    }
  }, [formik.values.customer_id]);
  return customer;
};

export default useCustomerFormData;
