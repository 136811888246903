/* eslint-disable react/prop-types */
import React from 'react';
import { InputGroup } from 'react-bootstrap';
import LBFormControl from '../CreateForm/LBFormControl';

function LBInputGroup({
  element,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}) {
  const isInvalid = touched[element.name] && !!errors[element.name];
  return (
    <InputGroup
      style={{ isolation: 'isolate' }}
      className={`${isInvalid ? 'is-invalid' : ''}`}
    >
      {element?.config?.leftLabel && (
        <InputGroup.Text>{element?.config?.leftLabel}</InputGroup.Text>
      )}
      <LBFormControl
        element={element}
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      {element?.config?.rightLabel && (
        <InputGroup.Text>{element?.config?.rightLabel}</InputGroup.Text>
      )}
    </InputGroup>
  );
}

export default LBInputGroup;
