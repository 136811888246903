import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';

function PDFError() {
  return (
    <Document>
      <Page size="A4">
        <View>
          <Text>Error generating PDF 😓</Text>
        </View>
      </Page>
    </Document>
  );
}

export default PDFError;
