/* eslint-disable react/prop-types */
import React from 'react';
import { Document, Page, StyleSheet } from '@react-pdf/renderer';

// Layout 1
import Header1 from './layout1/Header';
import ItemsTable1 from './layout1/ItemsTable';
import Footer1 from './layout1/Footer';

// Layout 2
import Header2 from './layout2/Header';
import ItemsTable2 from './layout2/ItemsTable';
import Footer2 from './layout2/Footer';

// Layout 3
import Header3 from './layout3/Header';
import ItemsTable3 from './layout3/ItemsTable';
import Footer3 from './layout3/Footer';

import '../../Reports/PDF/FontSetup';
import { I_PDF_Document } from './propTypes';
import PaymentInfo from './layout3/PaymentInfo';

const styles = StyleSheet.create({
  pdfMainContainer: {
    fontFamily: 'Montserrat',
    padding: '30px 40px 48px 40px',
  },
});

function PDFDocument({ data, colors, layoutNumber }: I_PDF_Document) {
  let content;

  if (layoutNumber === 1) {
    content = (
      <Page style={styles.pdfMainContainer} size="LETTER">
        <Header1 data={data} colors={colors} />
        <ItemsTable1 data={data} colors={colors} layoutNumber={layoutNumber} />
        <Footer1 data={data} colors={colors} />
      </Page>
    );
  } else if (layoutNumber === 2) {
    content = (
      <Page style={styles.pdfMainContainer} size="LETTER">
        <Header2 data={data} colors={colors} />
        <ItemsTable2 data={data} colors={colors} layoutNumber={layoutNumber} />
        <Footer2 data={data} colors={colors} />
      </Page>
    );
  } else if (layoutNumber === 3) {
    content = (
      <Page style={styles.pdfMainContainer} size="LETTER">
        <Header3 data={data} colors={colors} />
        {data.type === 'receipt' ? <PaymentInfo /> : null}
        <ItemsTable3 data={data} colors={colors} layoutNumber={layoutNumber} />
        <Footer3 data={data} colors={colors} />
      </Page>
    );
  } else {
    content = null;
  }
  return <Document>{content}</Document>;
}

export default PDFDocument;
