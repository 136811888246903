// import { redirect } from 'react-router-dom';
import { handleResposeAbstraction } from '../../API/fetchUtils';
import checksAPI from '../../API/routesAPI/checksAPI';

// Conection to the API
export const clearTray = async ({ request }) => {
  const values = await request.json();
  const response = await checksAPI.clearTray(JSON.stringify(values));
  return handleResposeAbstraction({ response });
};

export const createCheckAction = async ({ request }) => {
  const values = await request.json();
  const response = await checksAPI.createCheck(JSON.stringify(values));
  return handleResposeAbstraction({ response, redirectTo: '/checks' });
};

export const getCheck = async ({ params }) => {
  const { id } = params;
  const response = await checksAPI.getCheck(id);
  return handleResposeAbstraction({ response });
};

export const getChecksLoader = async () => {
  const response = await checksAPI.getChecks();
  return handleResposeAbstraction({ response });
};

export const getRecipientsLoader = async () => {
  const response = await checksAPI.getRecipients();
  return handleResposeAbstraction({ response });
};

export const toggleCheckStatus = async ({ request }) => {
  const { id } = await request.json();
  const response = await checksAPI.toggleCheck(id);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
  });
};

export const voidCheck = async ({ request }) => {
  const { id } = await request.json();
  const response = await checksAPI.void(id);
  return handleResposeAbstraction({ response, showSuccess: true });
};

export const updateCheckAction = async ({ request }) => {
  const values = await request.json();
  const response = await checksAPI.updateCheck(JSON.stringify(values));
  return handleResposeAbstraction({ response, redirectTo: '/checks' });
};

export const deleteCheckAction = async ({ params }) => {
  const { id } = params;
  const response = await checksAPI.deleteCheck(id);
  return handleResposeAbstraction({
    response,
    redirectTo: '/checks',
    showSuccess: true,
  });
};

export const printingTraychecks = async () => {
  const response = await checksAPI.getChecksInTray();
  return handleResposeAbstraction({ response });
};

// checks local storage
// export const deleteCheck = async ({ params }) => {
//   const checks = window.localStorage.getItem('checks');
//   const displayChecks = JSON.parse(checks);
//   const nextChecks = displayChecks.filter((check) => +check.id !== +params.id);
//   window.localStorage.setItem('checks', JSON.stringify(nextChecks));
//   return redirect('/checks');
// };

// export const deleteCheckAction = async ({ params }) => {
//   const { id } = params;
//   const checks = window.localStorage.getItem('checks');
//   const displayChecks = JSON.parse(checks);
//   const nextChecks =
//     displayChecks.length === 1
//       ? []
//       : displayChecks.filter((check) => +check.id !== +id);

//   window.localStorage.setItem('checks', JSON.stringify(nextChecks));
//   return null;
// };

// export const updateCheckState = async ({ request }) => {
//   const { id, state } = await request.json();
//   const checks = window.localStorage.getItem('checks');
//   const displayChecks = JSON.parse(checks);
//   const nextChecks = displayChecks.map((check) => {
//     if (+check.id === +id) {
//       return { ...check, printState: state };
//     }
//     return check;
//   });
//   window.localStorage.setItem('checks', JSON.stringify(nextChecks));
//   return null;
// };

// export const updateCheckAction = async ({ request }) => {
//   const values = await request.json();
//   const checks = window.localStorage.getItem('checks');
//   const displayChecks = JSON.parse(checks);
//   const nextValues = displayChecks.map((check) => {
//     if (+check.id === +values.id) {
//       return values;
//     }
//     return check;
//   });
//   window.localStorage.setItem('checks', JSON.stringify(nextValues));
//   return redirect('/checks');
// };
