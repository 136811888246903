import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useModal(root, fullPaths) {
  const [size, setSize] = useState('closed');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pathEnd = pathname.substring(pathname.lastIndexOf('/'));

  // CONSOLE LOG (DEBUG)
  // const includesEdit = fullPaths.some((path) => pathname.includes(path));
  // console.table({ root, pathEnd, pathname });

  useEffect(() => {
    // Check if we are not nested in the root path

    // Check if we are in the root path (modal => closed)
    if (pathEnd === `/${root === '/' ? '' : root}`) return setSize('closed');

    // Added to prevent errors when manually adding "/" at the end of root route
    if (pathEnd === '/') return setSize('closed');

    // Check if we are in one of the "full" paths
    if (fullPaths && fullPaths.some((path) => pathname.includes(path))) {
      return setSize('full');
    }

    // If none of those conditions were met, we must be
    //  in one of the "half" paths
    return setSize('half');
  }, [pathname]);

  // Show is derived from state (no need to create another state)
  const show = size === 'half' || size === 'full';

  const handleCloseModal = async (locationState) => {
    setSize('closed');
    // Prevent using the "event" object that is sometimes passed
    // automatically as an argument
    const options =
      locationState && !locationState?.target
        ? { relative: true, state: locationState }
        : { relative: true };

    return setTimeout(navigate('.', options), 300);
  };

  return {
    show,
    size,
    handleCloseModal,
  };
}
