import { useRouteLoaderData } from 'react-router-dom';

// export default function useIsAuthorized({ rolesAllowed = [] }) {
//   const {
//     user: { roles },
//   } = useRouteLoaderData('root');
//   return roles?.some((role) => rolesAllowed.includes(role.id));
// }

export default function useIsAuthorized({ tiersAllowed = [] }) {
  const {
    user: { subscription },
  } = useRouteLoaderData('root');
  return tiersAllowed.includes(subscription.tier);
}
