import React from 'react';
import { useParams } from 'react-router-dom';
import SavingInvoiceAndPdfUpload from '../Invoices/SavingInvoiceAndPdfUpload';
import SavingEstimateAndPdfUpload from '../Estimates/SavingEstimateAndPdfUpload';
import SavingPaymentAndPdfUpload from '../Payments/SavingPaymentAndPdfUpload';

export default function UploadBeforeSend() {
  const { estimateId, invoiceId, paymentId } = useParams();
  return (
    (invoiceId && <SavingInvoiceAndPdfUpload />) ||
    (estimateId && <SavingEstimateAndPdfUpload />) ||
    (paymentId && <SavingPaymentAndPdfUpload />)
  );
}
