import React from 'react';
import Proptypes from 'prop-types';

function FieldLabel({ children, variant }) {
  return <p className={`lb-modal__label ${variant}`}>{children}</p>;
}

FieldLabel.propTypes = {
  children: Proptypes.string.isRequired,
  variant: Proptypes.string,
};

FieldLabel.defaultProps = {
  variant: '',
};
export default FieldLabel;
