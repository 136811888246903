import { handleResposeAbstraction } from '../../API/fetchUtils';
import tradesAPI from '../../API/routesAPI/tradesAPI';

export const getAllTradesLoader = async () => {
  const response = await tradesAPI.getAllTrades();
  return handleResposeAbstraction({ response });
};

export const createTradeAction = async ({ request }) => {
  const body = await request.formData();
  const response = await tradesAPI.createTrade(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
  });
};

export const deleteTradeAction = async ({ request }) => {
  const body = await request.formData();
  const tradeId = body.get('tradeId');
  const response = await tradesAPI.deleteTrade(tradeId);

  const result = await response.json();

  if (response.status === 200) {
    result.id = tradeId;
  }
  return handleResposeAbstraction({
    response,
    result,
    showSuccess: true,
  });
};
