/* eslint-disable consistent-return */
import { useCallback, useState, useEffect } from 'react';

function useRoveFocus(containerRef, size, initialValue) {
  const [currentFocus, setCurrentFocus] = useState(initialValue);

  const handleKeyDown = useCallback(
    (e) => {
      if (!containerRef.current) return null;
      if (!document.activeElement.parentElement === containerRef.current) {
        return null;
      }
      if (e.keyCode === 40) {
        // Down arrow
        e.preventDefault();
        e.stopPropagation();
        setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
      } else if (e.keyCode === 38) {
        // Up arrow
        e.preventDefault();
        e.stopPropagation();
        setCurrentFocus(currentFocus === 0 ? size - 1 : currentFocus - 1);
      }
    },
    [size, currentFocus, setCurrentFocus]
  );

  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.addEventListener('keydown', handleKeyDown, false);
    return () => {
      if (!containerRef.current) return;
      containerRef.current.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return [currentFocus, setCurrentFocus];
}

export default useRoveFocus;
