import React from 'react';
import { Link } from 'react-router-dom';
import OnboardGrid from '../../UI/OnboardGrid/OnboardGrid';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import BulletList from '../../UI/BulletList/BulletList';
import StepList from '../../UI/StepList/StepList';
import image from '../../../img/assets/images/c-userOnboard-invoices.svg';

import './invoicesOnboard.styles.scss';

export const invoicesBullets = [
  {
    id: 0,
    pre: 'Create and Send Invoice',
    body: 'Send invoice vía Email, Text and Whatsapp.',
  },
  {
    id: 1,
    pre: 'Follow up',
    body: 'Follow up notifications when invoices is overdue.',
  },
  {
    id: 2,
    pre: 'Invoice Message and Note',
    body: 'Create special notes for the invoices.',
  },
  {
    id: 3,
    pre: 'Attachments',
    body: 'Add attachments to the invoice like, photos and documents.',
  },
  {
    id: 4,
    pre: 'Recurrent Invoice',
    body: 'Charge customers for goods or services at regular intervals. Features is available in the App’s Box.',
  },
  {
    id: 5,
    pre: 'Notifications',
    body: 'Stay on the top of your overdue invoice.',
  },
  {
    id: 6,
    pre: 'Automated Payment Remind',
    body: 'Customers with overdue invoices will receive an automated email on the following day of due date.',
  },
];

export const invoicesSteps = [
  {
    id: 0,
    body: (
      <>
        <p>
          Before creating your first estimate, make sure you have created the
          products or services that you are going to include in the estimate
        </p>
        <p>
          Not product or Services yet?{' '}
          <Link to="/products/new">Click here</Link>
        </p>
      </>
    ),
  },
  {
    id: 1,
    body: (
      <p>
        Select your customer, select expiration date. Select Product or
        Services.
      </p>
    ),
  },
  {
    id: 2,
    body: <p>Save and Send.</p>,
  },
];

function InvoicesOnboard() {
  return (
    <CardWrapper>
      <OnboardGrid>
        <OnboardGrid.Header>
          <h2>What you really need in a Invoice</h2>
        </OnboardGrid.Header>
        <OnboardGrid.Left>
          <BulletList list={invoicesBullets} title="Invoice module features:" />
          <StepList stepArray={invoicesSteps} />
        </OnboardGrid.Left>
        <OnboardGrid.Right>
          <img
            className="page-image"
            src={image}
            alt="vector illustration of a person viewing data visualizations"
          />
          <div className="action-group">
            <Link
              className="support-link btn btn-primary p-2 mb-3"
              variant="secondary"
              to="/invoices/new"
            >
              Create new invoice
            </Link>
          </div>
          <div className="support-wrapper">
            <p className="support">Have Questions?</p>
            <a
              className="support-link"
              href="mailto:support@leadsbox.com?subject=Support needed"
            >
              Go to support
            </a>
          </div>
        </OnboardGrid.Right>
      </OnboardGrid>
    </CardWrapper>
  );
}

export default InvoicesOnboard;
