import authAPI from '../../API/routesAPI/authAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

export const authAction = async ({ request }) => {
  const body = await request.formData();
  const response = await authAPI.login(body);
  const result = await response.json();
  if (response?.ok) {
    localStorage.setItem('token', result.token);
  }
  return handleResposeAbstraction({
    response,
    result,
    redirectTo: '/',
  });
};

export const registerAction = async ({ request }) => {
  const body = await request.formData();
  const response = await authAPI.register(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
  });
};

export const resetPasswordRequestAction = async ({ request }) => {
  const body = await request.formData();
  const response = await authAPI.resetPasswordRequest(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
    redirectTo: '/auth/reset-password-request-success',
  });
};

export const resetPasswordAction = async ({ request, params }) => {
  const body = await request.formData();
  const { token } = params;
  body.append('token', token);
  const response = await authAPI.resetPassword(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
    redirectTo: '/auth/reset-password-success',
  });
};
