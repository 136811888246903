import React from 'react';
import WidgetContainer, {
  EmptyWidget,
} from './WidgetContainer/WidgetContainer';
import Single from './PieCharts/Single';
import useWidgetFilter from './utils/useWidgetFilter';
import SinglePlaceholder from './PieCharts/SinglePlaceholder';

function Invoices() {
  const {
    serverMessage,
    fetcherData,
    fetcherState,
    filter,
    handleSelectChange,
  } = useWidgetFilter('invoices');

  return (
    <WidgetContainer>
      <WidgetContainer.Header
        title="Invoices"
        url="/invoices"
        cta="Go to invoices"
      >
        <WidgetContainer.Select
          value={filter}
          onChangeHandler={handleSelectChange}
        >
          <option value="today">Today</option>
          <option value="lastSevenDays">Last 7 days</option>
          <option value="lastMonth">Last Month</option>
          <option value="thisYear">This Year</option>
          <option value="lastYear">Last Year</option>
        </WidgetContainer.Select>
      </WidgetContainer.Header>
      <WidgetContainer.Body
        fetcherState={fetcherState}
        entries={fetcherData?.length}
        loadingComponent={<SinglePlaceholder />}
        serverMessage={serverMessage}
      >
        {fetcherData?.find((item) => item.name === 'Total').value <= 0 ? (
          <EmptyWidget />
        ) : (
          <Single data={fetcherData} />
        )}
      </WidgetContainer.Body>
    </WidgetContainer>
  );
}

export default Invoices;
