import React from 'react';
import { toast } from 'react-toastify';
import propTypes from 'prop-types';

export function ToastLayout({ alert = 'Unknow error', status, errors }) {
  return (
    <>
      <div className="toast-header">
        <strong className="me-auto">{alert}</strong>
        <small>STATUS: {status}</small>
      </div>
      {errors.length > 0 && (
        <div className="toast-body">
          {errors.map((error) => (
            <span>{error}</span>
          ))}
        </div>
      )}
    </>
  );
}

export const toastSuccess = (result) => {
  toast.success(result?.alert);
};

export const toastError = (result) => {
  let errors = [];
  const currentUrl = window.location.href;
  if (result?.message === 'invalid_token' && currentUrl.includes('auth'))
    return;

  if (result?.errors) {
    errors = Object.keys(result.errors).map((key) => result.errors[key]);
  }

  if (result?.message === 'invalid_token') {
    const error = `${result?.alert ? result.alert : 'Unknown error. '}${
      result?.errors ? '- ' : ''
    } ${result?.errors ? errors.join(', ') : ''}`;
    toast.error(error, {
      toastId: 'sessionExpired',
    });
  } else {
    toast.error(
      `${result?.alert ? result.alert : 'Unknown error. '} ${
        result?.errors ? '- ' : ''
      }  ${result?.errors ? errors.join(', ') : ''}`
    );
  }
};

export const toastHandler = ({ ok, result, showSuccess = false }) => {
  if (!ok) {
    toastError(result);
  } else if (showSuccess) {
    toastSuccess(result);
  }
};
ToastLayout.propTypes = {
  alert: propTypes.string,
  status: propTypes.string,
  errors: propTypes.shape([propTypes.string]),
};

ToastLayout.defaultProps = {
  alert: 'Unknow error',
  status: 'Unknow status',
  errors: [],
};

export default ToastLayout;
