// import {
//   createProducts,
//   deleteProduct,
//   getProducts,
// } from '../components/API/productsEndpoints';
import GenericPage from '../../pages/GenericPage';
import Products from '../../components/ProductsAndServices/ProductsTable/Products';
import CreateProduct from '../../components/ProductsAndServices/CreateProduct';
import productsCategoriesRoutes from '../productsCategories/productsCategories';
import {
  createProductAction,
  deleteProductAction,
  getProductsAction,
  updateProductAction,
} from './productsLA';

const productsRoutes = [
  {
    path: 'products',
    element: <GenericPage />,
    loader: getProductsAction,
    id: 'products',
    handle: {
      crumb: () => 'Products & Services',
      sublinks: () => [
        {
          label: 'Products & Services',
          to: '/products',
          end: true,
        },
        {
          label: 'Categories',
          to: '/products/categories',
        },
      ],
    },
    children: [
      {
        path: '',
        element: <Products />,
        handle: {
          linkTo: {
            label: 'New',
            to: 'new',
          },
        },
      },
      {
        path: 'update/:productId',
        element: <CreateProduct />,
        action: updateProductAction,
        handle: {
          crumb: () => 'Edit',
        },
      },
      {
        path: 'new',
        element: <CreateProduct />,
        action: createProductAction,
        handle: {
          crumb: () => 'New',
        },
      },
      {
        path: 'delete/:productId',
        loader: deleteProductAction,
      },
      ...productsCategoriesRoutes,
    ],
  },
];

export default productsRoutes;
