import Onboarding from '../../components/Onboarding/Onboarding.tsx';
import QuickStart from '../../components/Onboarding/QuickStart.tsx';
import WelcomeView from '../../components/Onboarding/WelcomeView.tsx';
import CustomerStep from '../../components/Onboarding/CustomerStep/CustomerStep.tsx';
import {
  createCustomerAction,
  createProductAction,
  getProductsLoader,
  toggleTutorialAction,
} from './onboardingLA';
import ProdOrServStep from '../../components/Onboarding/ProductStep/ProdOrServStep.tsx';
import FinishStep from '../../components/Onboarding/FinishStep/FinishStep.tsx';
import { getCustomersLoader } from '../customers/customersLA';
import StepError from '../../components/Onboarding/StepError.tsx';

const onboardingRoutes = {
  path: 'onboarding',
  element: <Onboarding />,
  children: [
    {
      path: '',
      index: true,
      element: <WelcomeView />,
    },
    {
      path: 'toggle-tutorial',
      action: toggleTutorialAction,
    },
    {
      path: '',
      element: <QuickStart />,
      children: [
        {
          path: 'customer',
          loader: getCustomersLoader,
          action: createCustomerAction,
          errorElement: <StepError />,
          element: <CustomerStep />,
        },
        {
          path: 'prod-and-serv',
          loader: getProductsLoader,
          action: createProductAction,
          errorElement: <StepError />,
          element: <ProdOrServStep />,
        },
        {
          path: 'finish',
          errorElement: <StepError />,
          element: <FinishStep />,
        },
      ],
    },
  ],
};

export default onboardingRoutes;
