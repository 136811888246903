import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf',
      fontWeight: '700',
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    marginTop: 30,
    fontWeight: '700',
    fontFamily: 'Montserrat',
    fontSize: 16,
  },
  body: {
    marginTop: 20,
  },
  strongDivider: {
    borderTop: 2,
    marginHorizontal: 20,
    marginVertical: 5,
  },
  categoriesBar: {
    flexDirection: 'row',
  },

  softDivider: {
    borderTop: 1,
    marginHorizontal: 20,
    marginVertical: 5,
  },
  incomeContent: {
    paddingHorizontal: 60,
  },
  textCategories: {
    marginLeft: 40,
    fontWeight: '700',
    fontFamily: 'Montserrat',
    fontSize: 13,
  },

  productsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  servicesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  totalGrossContainer: {
    paddingHorizontal: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  totalSalesGrossPriceTxt: {
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    fontSize: 16,
  },
  netIncomeContainer: {
    paddingLeft: 40,
    paddingRight: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  totalSalesTxt: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },

  expensesContent: {
    paddingHorizontal: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  customer: {
    width: 250,
    justifyContent: 'center',
    alignItems: 'center',
  },
  otherCategoriesContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  categoryBarText: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  categoryBarContainer: {
    width: 100,
    alignItems: 'center',
    borderLeft: 1,
  },
  bottomSoftDivider: {
    borderTop: 1,
    marginVertical: 5,
    marginHorizontal: 20,
  },
  bottomStrongDivider: {
    borderTop: 2,
    marginVertical: 5,
    marginHorizontal: 20,
  },
  altFooter: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 35,
  },
  footer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: 35,
  },

  content: {
    position: 'absolute',
    top: 170,
    width: '100%',
  },
  customerText: {
    fontSize: 14,
    width: 145,
    fontFamily: 'Montserrat',
  },
  categoryText: {
    fontSize: 12,
    width: 120,
    fontFamily: 'Montserrat',
  },
  contentText: {
    width: 230,
    fontFamily: 'Montserrat',
    fontSize: 14,
    paddingLeft: 40,
  },
  verticalDivider: {
    borderRight: 1,
    marginVertical: -5,
  },
});

export default styles;
