import React from 'react';
import PropTypes from 'prop-types';

function IconWrapper({
  children,
  fill = 'transparent',
  stroke = 'var(--bs-gray-600)',
  size = 16,
  viewBox = 48,
  strokeWidth = 3,
  offsetY = 0,
  offsetX = 0,
  ...delegated
}) {
  return (
    <svg
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      height={size}
      width={size}
      viewBox={`0 0 ${viewBox} ${viewBox}`}
      vectorEffect="non-scaling-stroke" // Not doing anything
      strokeLinecap="round"
      strokeLinejoin="round"
      className={delegated.className}
      style={{
        transform: `translate(${offsetX}, ${offsetY})`,
        flexBasis: size,
        flexShrink: 0,
        ...delegated.style,
      }}
    >
      {children}
    </svg>
  );
}

IconWrapper.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  size: PropTypes.number,
  viewBox: PropTypes.number,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  strokeWidth: PropTypes.number,
};

export default IconWrapper;
