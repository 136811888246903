/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import CreateFormLabel from '../CreateForm/CreateFormLabel';

import './CSVFileInput.scss';

// Helper functions
const checkType = (file, types) => types.includes(file.type);
const checkSize = (file, size) => {
  return Math.round(file.size < size);
};

// Constants
const ALLOWED_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

function CSVFileInput({
  name,
  label,
  value,
  placeholder,
  disabled,
  errors,
  touched,
  required,
  setFormData,
  setFieldValue,
  setFieldTouched = async () => null,
}) {
  // This ref helps us grab the actual file and not
  // the "fakepath" value
  const inputRef = React.useRef();
  const [fileError, setFileError] = React.useState([]);

  const resetField = async (e) => {
    e.stopPropagation();
    await setFieldValue(name, '');
    await setFieldTouched(name, false);
    setFormData((prev) => ({
      ...prev,
      [name]: undefined,
    }));
  };

  const handleFile = (e) => {
    setFileError([]);
    setFieldTouched(name, true);
    setFieldValue(name, e.target.value || '');
    const isValidType = checkType(inputRef.current.files[0], ALLOWED_TYPES);
    const isValidSize = checkSize(inputRef.current.files[0], 1048576); // Size in bytes
    const nextErrors = [];

    if (!isValidType) nextErrors.push('Invalid file type');

    if (!isValidSize) nextErrors.push('Maximum size of 1MB');

    if (!isValidSize || !isValidType) {
      setFieldValue(name, '');
      setFileError(nextErrors);
      return null;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: inputRef.current.files[0] || '',
    }));

    return null;
  };

  return (
    <>
      <CreateFormLabel
        errors={errors[name]}
        inputName={name}
        label={label}
        required={required}
        labelAction={
          <button
            type="button"
            className="csv-input__reset"
            onClick={resetField}
            disabled={!value}
          >
            Reset
          </button>
        }
      >
        <Form.Control
          value={value}
          placeholder={placeholder}
          name={name}
          type="file"
          accept={ALLOWED_TYPES.join(', ')}
          disabled={disabled}
          isInvalid={touched[name] && !!errors[name]}
          isValid={touched[name] && !errors[name]}
          onChange={(e) => handleFile(e)}
          required={required}
          ref={inputRef}
          className="csv-input__button"
        />
      </CreateFormLabel>
      <small
        style={{
          justifySelf: 'flex-end',
          color: 'var(--bs-gray-600)',
          fontSize: '0.8rem',
        }}
      >
        Allowed formats: .csv, .xls, .xlsx
      </small>
      {fileError?.length > 0 && (
        <Alert
          className="small w-100 mt-3"
          style={{
            whiteSpace: 'pre',
          }}
          variant="danger"
        >
          {fileError.map((error) =>
            fileError?.length > 1 ? error + '\n' : error
          )}
        </Alert>
      )}
    </>
  );
}

export default CSVFileInput;
