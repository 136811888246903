import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import {
  useNavigate,
  useOutletContext,
  useRouteLoaderData,
} from 'react-router-dom';
import { I_QuickStartContext } from './QuickStart';

function StepToggler() {
  const { step, stepChangeHanlder, customer } =
    useOutletContext() as I_QuickStartContext;
  const {
    user: {
      onboarding_helpers: { prod_and_serv_count: prodAndServCount },
    },
  } = useRouteLoaderData('root') as any;
  const navigate = useNavigate();

  return (
    <ToggleButtonGroup
      name="step"
      type="radio"
      value={step}
      onChange={stepChangeHanlder}
      className="mb-2 align-self-start"
    >
      <ToggleButton
        id="step-1"
        variant="outline-dark"
        onClick={() => navigate('/onboarding/customer')}
        value={1}
      >
        1
      </ToggleButton>
      <ToggleButton
        id="step-2"
        variant={!customer?.id ? 'dark' : 'outline-dark'}
        onClick={() => navigate('/onboarding/prod-and-serv')}
        value={2}
        disabled={!customer?.id}
      >
        2
      </ToggleButton>

      <ToggleButton
        id="step-3"
        variant={!customer?.id || !prodAndServCount ? 'dark' : 'outline-dark'}
        onClick={() => navigate('/onboarding/finish')}
        value={3}
        disabled={!customer?.id || !prodAndServCount}
      >
        3
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default StepToggler;
