import React from 'react';
import { useNavigate } from 'react-router-dom';
import WidgetContainer from '../../Dashboard/Widgets/WidgetContainer/WidgetContainer';
import LinkButton from '../LinkButton/LinkButton';

function BusinessOverviewReport() {
  const navigate = useNavigate();

  const navigateScreenshot = () => {
    navigate('/old_reports/business_screenshot');
  };
  const navigateProfitLossCustomer = () => {
    navigate('/old_reports/profit_loss_customer');
  };
  const navigateProfitLossReport = () => {
    navigate('/old_reports/profit_loss_report');
  };

  return (
    <WidgetContainer className="widgets__twocol-item-container p-4 shadow-sm">
      <h3>Business Overview</h3>
      <div style={{ marginTop: 20 }}>
        <LinkButton title="Business Screenshot" onClick={navigateScreenshot} />
        <LinkButton
          title="Profit and Loss by Customer"
          onClick={navigateProfitLossCustomer}
        />
        <LinkButton
          title="Profit and Loss Report"
          onClick={navigateProfitLossReport}
        />
      </div>
    </WidgetContainer>
  );
}

export default BusinessOverviewReport;
