import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

function TableLink({ url, label }) {
  return (
    <Link to={url} className="table__click" target="_blank">
      {label}
    </Link>
  );
}

TableLink.propTypes = {
  url: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
};

export default TableLink;
