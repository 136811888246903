import React from 'react';
import propTypes from 'prop-types';
import { useLoaderData, useNavigate } from 'react-router-dom';
import ReactTablev8 from '../UI/Table/ReactTablev8';
import ActionMenu from '../UI/buttons/ActionMenu';

function ExpenseCategoriesTable({ handleEdit, handleDelete }) {
  const { result: expensesCategories } = useLoaderData();
  const navigate = useNavigate();

  // HANDLERS
  const handleRowClick = (rowId) => {
    navigate(`update/${rowId}`);
  };

  const nextExpensesCategory = expensesCategories?.map((expenseCategory) => {
    return {
      name: expenseCategory.expense_category_name,
      description: expenseCategory?.description,
      id: expenseCategory.expense_category_id,
      more: expenseCategory.expense_category_name !== 'Merchants' &&
        expenseCategory.expense_category_name !== 'Vendors' && (
          <ActionMenu
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            disabled={expenseCategory.count !== 0}
            id={expenseCategory.expense_category_id}
          />
        ),
    };
  });

  const tableSetup = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      mobileLabel: true,
    },
    {
      header: 'More',
      accessorKey: 'more',
    },
  ];

  // Setup initial (default) sort
  const initialExpenseCatSort = React.useMemo(() => {
    return [
      {
        id: 'name',
        desc: false,
      },
    ];
  }, []);

  return (
    <ReactTablev8
      className=""
      dataTable={nextExpensesCategory}
      tableLayout={tableSetup}
      tableAction={handleRowClick}
      initialSortBy={initialExpenseCatSort}
      mobileClass="expense_categories_table"
    />
  );
}

ExpenseCategoriesTable.propTypes = {
  handleEdit: propTypes.func.isRequired,
  handleDelete: propTypes.func.isRequired,
};
export default ExpenseCategoriesTable;
