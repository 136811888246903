import fetchAPI from '../fetchUtils';

const vendorsAPI = {
  getAll: async () => fetchAPI.getWithAuth('/expense-vendor-merchant-list'),
  delete: async (id) => fetchAPI.getWithAuth(`/delete-vendor/${id}`),
  create: async (body) => fetchAPI.postWithAuth('/create-vendor', body),
  update: async (body) => fetchAPI.postWithAuth('/update-vendor', body),
  get: async (id) =>
    fetchAPI.getWithAuth(`/get-vendor-merchant-details/vendor/${id}`),
  updateNotes: async (body, id) =>
    fetchAPI.postWithAuth(`/update-vendor-notes/${id}`, body),
  getPayments: async (body, id) =>
    fetchAPI.postWithAuth(`/get-vendor-payments/${id}`, body),
};

export default vendorsAPI;
