/* eslint-disable react/prop-types */

import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { set, differenceInMinutes, getYear } from 'date-fns';
import useAppointmentContext from './Context/useAppointmentContext';
import { calculateSlots, findAppointmentsByDate } from './utils/helpers';
import AppointmentOverlay from './AppointmentOverlay';
import AppointmentsHeader from './AppointmentsHeader';

const TimeSlot = React.forwardRef(
  ({ startTime, endTime, disabled, last }, forwardedRef) => {
    return (
      <Button
        ref={forwardedRef || undefined}
        type="button"
        variant="seconday"
        className={`scheduler__timeslot ${disabled ? 'disabled' : null}`}
        disabled={disabled}
        as={Link}
        to={`new?startHours=${startTime.getHours()}&startMinutes=${startTime.getMinutes()}`}
      >
        <span className="scheduler__start-time">
          {startTime.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            second: undefined,
          })}
        </span>
        {last && (
          <span className="scheduler__end-time">
            {endTime.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
              second: undefined,
            })}
          </span>
        )}
      </Button>
    );
  }
);

function Scheduler() {
  const { currentDate, appointments } = useAppointmentContext();
  const sevenAmSlotRef = React.useRef(null);
  const timeSlotContainerRef = React.useRef(null);

  // We scroll to the 7am timeslot on load
  React.useEffect(() => {
    // const scrollToTarget = () => {
    //   if (sevenAmSlotRef.current) {
    //     sevenAmSlotRef.current.scrollIntoView({
    //       behavior: 'smooth',
    //       block: 'start',
    //     });
    //   }
    // };
    const scrollToTarget = () => {
      if (sevenAmSlotRef.current && timeSlotContainerRef.current) {
        timeSlotContainerRef.current.scrollTo(
          0,
          sevenAmSlotRef.current.offsetTop
        );
      }
    };

    // Scroll to the target element on component load
    scrollToTarget();
  }, []); // Empty dependency array ensures that this effect runs only once after the initial render

  // We determine the amount of steps/slots
  const intervalInMinutes = 1000 * 60 * 30; // 30 minutes
  const slotsArray = calculateSlots(intervalInMinutes);

  // We filter appointments on the current date
  const todayAppointments = findAppointmentsByDate(currentDate, appointments);

  // We detect which slots are currently occupied by an appointment
  const slotsWithAppointments = slotsArray.map((slot) => {
    const overlappingAppointments = todayAppointments.filter((app) => {
      const appointmentStart = new Date(app.start_date);
      const appointmentEnd = new Date(app.end_date);
      const slotStart = new Date(slot.startTime);
      const slotEnd = new Date(slot.endTime);

      const dayStart = set(currentDate, {
        date: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: getYear(currentDate),
        hours: 0,
        minutes: 0,
        milliseconds: 0,
      });
      const todaySlotStart = set(slotStart, {
        date: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: getYear(currentDate),
      });
      const todaySlotEnd = set(slotEnd, {
        date: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: getYear(currentDate),
      });

      const slotStartTime = differenceInMinutes(todaySlotStart, dayStart);
      const slotEndTime = differenceInMinutes(todaySlotEnd, dayStart);
      const appStartTime = differenceInMinutes(appointmentStart, dayStart);
      const appEndTime = differenceInMinutes(appointmentEnd, dayStart);

      // Overlap boolean logic
      const overlapsWithSlot =
        (appStartTime >= slotStartTime && appStartTime < slotEndTime) ||
        (appEndTime > slotStartTime && appEndTime <= slotEndTime) ||
        (appStartTime < slotStartTime &&
          appEndTime > slotEndTime &&
          slotEndTime !== 0) ||
        (slotEndTime === 0 && appEndTime > slotStartTime);
      return overlapsWithSlot;
    });

    return {
      ...slot,
      isOverlapping: overlappingAppointments.length > 0,
    };
  });

  return (
    <div className="scheduler__container">
      <AppointmentsHeader />
      <div ref={timeSlotContainerRef} className="scheduler__overflow-container">
        <div className="scheduler__timeslot-container">
          {slotsWithAppointments.map((slot, index) => (
            <TimeSlot
              key={slot.startTime.getTime()}
              ref={
                slot.startTime.getHours() === 7 &&
                slot.startTime.getMinutes() === 0
                  ? sevenAmSlotRef
                  : undefined
              }
              startTime={slot.startTime}
              endTime={slot.endTime}
              disabled={slot.isOverlapping}
              last={slotsWithAppointments.length === index + 1}
            />
          ))}
          <AppointmentOverlay />
        </div>
      </div>
    </div>
  );
}

export default Scheduler;
