export function ProductIcon() {
  return (
    <g>
      <polyline points="64 75.67 64 124 22.61 103.99 22.61 81.1" />
      <polygon points="52.87 95.73 11.48 75.72 22.61 55.66 64 75.67 52.87 95.73" />
      <polygon points="75.13 95.73 116.52 75.72 105.39 55.66 64 75.67 75.13 95.73" />
      <polyline points="64 75.67 64 124 105.39 103.99 105.39 81.1" />
      <line x1="22.61" y1="55.66" x2="35.58" y2="49.34" />
      <line x1="92.84" y1="49.54" x2="105.39" y2="55.66" />
      <line x1="34.86" y1="20.03" x2="39.51" y2="22.83" />
      <line x1="64" y1="9.36" x2="64" y2="4" />
      <line x1="50.21" y1="14.32" x2="46.83" y2="8.52" />
      <line x1="93.33" y1="20.03" x2="88.68" y2="22.83" />
      <line x1="77.98" y1="14.32" x2="81.36" y2="8.52" />
      <path d="M66.97,16.77l5.76,10.68,11.94,2.18c2.64.48,3.69,3.7,1.83,5.64l-8.38,8.78,1.62,12.03c.36,2.66-2.38,4.65-4.8,3.49l-10.94-5.26-10.94,5.26c-2.42,1.16-5.16-.83-4.8-3.49l1.62-12.03-8.38-8.78c-1.85-1.94-.81-5.16,1.83-5.64l11.94-2.18,5.76-10.68c1.27-2.36,4.66-2.36,5.94,0Z" />
    </g>
  );
}

export function ServiceIcon() {
  return (
    <g>
      <polyline points="53.42 54.97 26.75 28.3 21.33 26.99 18.61 18.93 20.06 17.48 21.51 16.04 29.56 18.77 30.87 24.18 57.54 50.86" />
      <polyline points="77.03 70.35 82.26 75.57 78.14 79.69 72.91 74.46" />
      <path
        d="M75.93,76.62h13.46c1.72,0,3.12,1.4,3.12,3.12v.71c0,1.72-1.4,3.12-3.12,3.12h-13.46c-1.72,0-3.12-1.4-3.12-3.12v-.71c0-1.72,1.4-3.12,3.12-3.12Z"
        transform="translate(-32.42 81.9) rotate(-45)"
      />
      <path d="M90.11,77.54l-5,5-5,5,2.29,5.35c.43,1.01,1.05,1.92,1.82,2.69l14.45,14.45c3.85,3.85,10.08,3.85,13.93,0h0c3.85-3.85,3.85-10.08,0-13.93l-14.45-14.45c-.77-.77-1.69-1.39-2.69-1.82l-5.35-2.29Z" />
      <line x1="90.11" y1="93.07" x2="103.43" y2="106.38" />
      <line x1="92.87" y1="90.3" x2="106.19" y2="103.62" />
      <line x1="95.64" y1="87.54" x2="108.95" y2="100.86" />
      <path d="M103.77,14.19l-13.68,13.68-6.9-6.9c-.31-.31-.66-.56-1.03-.74,3.22-4.54,8.52-7.52,14.53-7.52,2.52,0,4.91.53,7.09,1.47Z" />
      <path
        d="M20.84,96.25h10.49v18.6h-10.49c-4.61,0-8.35-3.74-8.35-8.35v-1.9c0-4.61,3.74-8.35,8.35-8.35Z"
        transform="translate(-68.22 46.4) rotate(-45)"
      />
      <circle cx="21.99" cy="105.46" r="3.18" />
      <path d="M99.43,48.08s-6.03-.86-11.57,4.68l-52.71,52.7-13.15-13.15,52.71-52.7s4.78-4.26,4.02-12.09l6.94,6.88c-.91.12-1.79.54-2.49,1.23-1.69,1.69-1.69,4.44,0,6.14l2.29,2.29c.85.85,1.96,1.27,3.07,1.27s2.23-.43,3.08-1.27c.71-.71,1.11-1.59,1.23-2.52l6.58,6.53Z" />
      <rect
        x="44.26"
        y="61.02"
        width="38.39"
        height="5.95"
        rx="2.67"
        ry="2.67"
        transform="translate(-26.67 63.61) rotate(-45)"
      />
      <path d="M114.47,30.51c0,8.89-6.52,16.25-15.04,17.57l-6.57-6.55s0,0,0,0c.16-1.28-.26-2.62-1.23-3.6l-2.29-2.29c-.86-.85-1.97-1.27-3.08-1.27-.18,0-.36,0-.54.04l-6.6-6.59-1.37-1.37c-1.51-1.51-1.51-3.96,0-5.46.76-.76,1.74-1.13,2.72-1.13.59,0,1.17.13,1.7.4.38.18.73.43,1.03.74l16.47,16.47,13.53-13.53c.82,2.04,1.27,4.26,1.27,6.59Z" />
      <path d="M92.84,41.51s0,0,0,0c0,0,0,.02,0,.02-.12.92-.53,1.81-1.23,2.52-.86.85-1.97,1.27-3.08,1.27s-2.23-.43-3.07-1.27l-2.29-2.29c-1.69-1.7-1.69-4.45,0-6.14.7-.7,1.57-1.11,2.49-1.23h.05c.18-.03.36-.04.54-.04,1.11,0,2.23.43,3.08,1.27l2.29,2.29c.97.97,1.39,2.32,1.23,3.6Z" />
    </g>
  );
}
