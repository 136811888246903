import React from 'react';
import propTypes from 'prop-types';
import ReactTablev8 from '../../UI/Table/ReactTablev8';

function VehiclesTable({ vehicles }) {
  const dataTable = vehicles.map((v) => ({
    ...v,
    expand: v?.mileages?.length ? 1 : 0,
  }));

  // Table scheme definition
  const tableSetup = [
    { header: 'Filter', accessorKey: 'filterChips', className: 'd-none' },
    {
      header: 'Expand',
      accessorKey: 'expand',
      isExpandable: true,
      type: 'expand',
      enableGlobalFilter: false,
      //   className: `${!isRecurrent && 'd-none'}`,
    },
    {
      header: 'License Plate',
      accessorKey: 'licensePlate',
      mobileLabel: true,
    },
    {
      header: 'Brand',
      accessorKey: 'brand',
    },
    {
      header: 'Model',
      accessorKey: 'model',
    },
    {
      header: 'Year',
      accessorKey: 'productionYear',
      mobileLabel: true,
    },
  ];
  return (
    <ReactTablev8
      tableLayout={tableSetup}
      dataTable={dataTable}
      mobileClass="vehicles_table"
    />
  );
}

export const vehiclesType = propTypes.arrayOf(
  propTypes.shape({
    id: propTypes.number,
    brand: propTypes.string,
    model: propTypes.string,
    productionYear: propTypes.number,
    mileages: propTypes.arrayOf(
      propTypes.shape({
        year: propTypes.number,
        start: propTypes.number,
        end: propTypes.number,
      })
    ),
  })
);

VehiclesTable.propTypes = {
  vehicles: vehiclesType,
};

export default VehiclesTable;
