const IMAGE_QUALITY = 0.5;
const ENCODE_TYPE = 'image/png';

// This util scales and crops the image to the desired size, leaving a transparent
// background in places the image leaves empty
export const resizeAndCropImage = (
  fileImage,
  targetWidth,
  targetHeight,
  encode = ENCODE_TYPE,
  quality = IMAGE_QUALITY
) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set the canvas size for the final cropped image
  canvas.width = targetWidth;
  canvas.height = targetHeight;

  // This is maybe reduntant since apparently the default bg is transparent
  // Switched to white since we're using jpg for now
  ctx.fillStyle = 'transparent';
  ctx.fillRect(0, 0, targetWidth, targetHeight);
  ctx.clearRect(0, 0, targetWidth, targetHeight);

  // Get the scale
  const scale = Math.min(
    canvas.width / fileImage.width,
    canvas.height / fileImage.height
  );
  // Get the top left position of the image
  const cropStartX = canvas.width / 2 - (fileImage.width / 2) * scale;
  const cropStartY = canvas.height / 2 - (fileImage.height / 2) * scale;

  // Get the resulting width/height after scaling
  const resizedWidth = fileImage.width * scale;
  const resizedHeight = fileImage.height * scale;

  // Draw the resized and cropped image onto the canvas
  ctx.drawImage(fileImage, cropStartX, cropStartY, resizedWidth, resizedHeight);

  // Return the cropped image as a data URL
  return canvas.toDataURL(encode, quality);
};

// This will create a crop based on the smallest side of an image
export const cropImage = (
  fileImage,
  sizeX,
  sizeY = sizeX,
  encode = ENCODE_TYPE
) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Ensure canvas size is square
  canvas.width = sizeX;
  canvas.height = sizeY;

  // Calculate the center of the original image
  const centerX = fileImage.width / 2;
  const centerY = fileImage.height / 2;

  // Calculate the cropping area
  const cropSize = Math.min(fileImage.width, fileImage.height);
  const startX = centerX - cropSize / 2;
  const startY = centerY - cropSize / 2;

  // Draw the cropped image onto the canvas
  ctx.drawImage(
    fileImage,
    startX,
    startY,
    cropSize,
    cropSize,
    0,
    0,
    sizeX,
    sizeY
  );

  // Return the cropped image as a data URL
  return canvas.toDataURL(encode, IMAGE_QUALITY);
};

export const dataUrlToFile = async (dataURL, fileName) => {
  const result = await fetch(dataURL)
    .then((response) => response.blob())
    .then((blob) => {
      return new File([blob], fileName, { type: blob.type });
    });

  return result;
};
