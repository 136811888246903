/* eslint-disable react/jsx-boolean-value */
import { Navigate } from 'react-router-dom';
import CreateProductCategory from '../../components/ProductsAndServices/Categories/CreateProductCategory';
import ProductsCategories from '../../components/ProductsAndServices/Categories/ProductsCategories';
import {
  createProductCategoryAction,
  createProductCategoryUtility,
  deleteProductCategoryAction,
  updateProductCategoryAction,
  getProductsCategoriesLoader,
} from './productsCategoriesLA';

const productsCategoriesRoutes = [
  {
    path: 'categories',
    element: <ProductsCategories />,
    loader: getProductsCategoriesLoader,
    id: 'categories',
    handle: {
      crumb: () => 'Categories',
      linkTo: {
        label: 'New',
        to: '/new',
      },
    },
    children: [
      {
        path: 'update/:categoryId/:name',
        element: <CreateProductCategory />,
        action: updateProductCategoryAction,
        handle: {
          crumb: () => 'Edit category',
        },
      },
      {
        path: 'new',
        element: <CreateProductCategory />,
        action: createProductCategoryAction,
        handle: {
          crumb: () => 'New',
        },
      },
      { path: 'new-utility', action: createProductCategoryUtility },
      { path: 'load-utility', loader: getProductsCategoriesLoader },
      {
        path: 'delete/:categoryId',
        action: deleteProductCategoryAction,
      },
      {
        path: '*',
        element: <Navigate to="/dashboard" replace={true} />,
      },
    ],
  },
];

export default productsCategoriesRoutes;
