/* eslint-disable no-nested-ternary */
import React from 'react';
import propTypes from 'prop-types';
// import { debounce } from 'lodash-es';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Spinner } from 'react-bootstrap';
import GOOGLE_MAPS_OPTIONS from '../Customers/mapConstants';

const DEFAULT_CONTAINER_STYLE = { height: '300px' };
const DEFAULT_CONTAINER_CLASSNAME = 'rounded-2';

function LBMapContainer({
  address,
  mapContainerStyle = DEFAULT_CONTAINER_STYLE,
  mapContainerClassName = DEFAULT_CONTAINER_CLASSNAME,
}) {
  const { isLoaded, loadError } = useJsApiLoader(GOOGLE_MAPS_OPTIONS);
  const geocoderRef = React.useRef(null);
  const [coords, setCoords] = React.useState({});

  React.useEffect(() => {
    if (
      geocoderRef.current ||
      !isLoaded ||
      !window.google ||
      !window.google.maps
    ) {
      return;
    }

    if (loadError) {
      // Report error
      return;
    }

    geocoderRef.current = new window.google.maps.Geocoder();
  }, [isLoaded]);

  // Still not 100% sure this is indeed preventing the extra
  // API request, would need some further testing
  // const geocodeAddress = () => {
  //   if (!geocoderRef.current) return;
  //   console.log('Geocoding');
  //   geocoderRef.current.geocode({ address }, (results, status) => {
  //     if (status === 'OK') {
  //       setCoords((prev) => ({
  //         ...prev,
  //         lat: results[0].geometry.location.lat(),
  //         lng: results[0].geometry.location.lng(),
  //         literal: results[0].geometry.location,
  //       }));
  //     }
  //   });
  // };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!geocoderRef.current) return;
      geocoderRef.current.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          setCoords((prev) => ({
            ...prev,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          }));
        }
      });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [address, isLoaded]);

  if (isLoaded && coords.lat && coords.lng) {
    return (
      <GoogleMap
        center={coords}
        zoom={15}
        mapContainerClassName={mapContainerClassName}
        mapContainerStyle={mapContainerStyle}
      >
        <MarkerF position={coords} />
      </GoogleMap>
    );
  }

  function getMessage() {
    if (!address.trim()) return 'Select address';
    if (!isLoaded || !Object.keys(coords).length) {
      return <Spinner className="align-self-center" />;
    }
    if (loadError) {
      return 'Error loading map';
    }
    return 'Invalid coordinates';
  }

  // Old ternary, kept in case my if chain is wrong 🤡
  // !address.trim()
  //   ? 'Select an address'
  //   : !isLoaded || !Object.keys(coords).length
  //     ? 'Loading...'
  //     : loadError
  //       ? 'Error loading map'
  //       : 'Invalid coordinates'

  return (
    <div
      className="rounded-2 d-flex align-items-center justify-content-center"
      style={{
        height: '300px',
        backgroundColor: 'var(--bs-gray-200)',
        border: '1px solid var(--bs-gray-400)',
        color: 'var(--bs-gray-500)',
      }}
    >
      {getMessage()}
    </div>
  );
}

LBMapContainer.propTypes = {
  address: propTypes.string,
  mapContainerStyle: propTypes.shape({}),
  mapContainerClassName: propTypes.string,
};

export default LBMapContainer;
