import React from 'react';
import propTypes from 'prop-types';

function TableHeader({ headers }) {
  return (
    <thead>
      <tr>
        {headers.map((header, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <th key={index} style={{ whiteSpace: 'nowrap' }}>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  headers: propTypes.arrayOf(propTypes.string).isRequired,
};

export default TableHeader;
