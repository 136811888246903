import fetchAPI from '../fetchUtils';

const reportsAPI = {
  getAllCustomers: async () => fetchAPI.getWithAuth('/get-customers/active'),
  getAllProducts: async () => fetchAPI.getWithAuth('/get-products'), // FOR SERVICES NEED TO FILTER
  getCategories: async () => fetchAPI.getWithAuth('/get-categories'),
  getAllInvoices: async () => fetchAPI.getWithAuth('/get-invoices'),
  getAllVendorMerchants: async () =>
    fetchAPI.getWithAuth('/expense-vendor-merchant-list'),
  getExpensesCategories: async () =>
    fetchAPI.getWithAuth('/get-expense-categories'),
  getCompanyInfo: async () => fetchAPI.getWithAuth(`/get-user`),

  // BUSINESS OVERVIEW
  postCustomerProfitLoss: async (body) =>
    fetchAPI.postWithAuth(
      `/report/search-customer-profit-loss`,
      body,
      'application/json'
    ),
  postProfitLoss: async (body) =>
    fetchAPI.postWithAuth(`/report/profit-loss`, body, 'application/json'),

  // SALES
  postSales: async (body) =>
    fetchAPI.postWithAuth(`/report/search-sales`, body, 'application/json'),
  postCustomerSales: async (body) =>
    fetchAPI.postWithAuth(`/report/customer-sales`, body, 'application/json'),
  postProductServiceList: async (body) =>
    fetchAPI.postWithAuth(
      `/report/search-product-service-list`,
      body,
      'application/json'
    ),
  postSalesProducts: async (body) =>
    fetchAPI.postWithAuth(
      `/report/search-product-sales`,
      body,
      'application/json'
    ),
  postSalesServices: async (body) =>
    fetchAPI.postWithAuth(
      `/report/search-service-sales`,
      body,
      'application/json'
    ),

  // EXPENSES
  postExpensesCategory: async (body) =>
    fetchAPI.postWithAuth(
      `/report/search-expense-category`,
      body,
      'application/json'
    ),
  postExpenseInvoice: async (body) =>
    fetchAPI.postWithAuth(
      `/report/search-invoice-expenses`,
      body,
      'application/json'
    ),
  postExpenseVendor: async (body) =>
    fetchAPI.postWithAuth(
      `/report/search-vendor-expenses`,
      body,
      'application/json'
    ),
  postExpenseMerchant: async (body) =>
    fetchAPI.postWithAuth(
      `/report/search-merchant-expenses`,
      body,
      'application/json'
    ),
};

export default reportsAPI;
