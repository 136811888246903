/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { useFetcher, useNavigate } from 'react-router-dom';
import ActionMenu from '../UI/buttons/ActionMenu';
import ActionButton from '../UI/buttons/ActionButton';

export default function ChecksActions({ id, state }) {
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const handleDelete = (id) => {
    fetcher.submit({}, { method: 'POST', action: `/checks/${id}/delete` });
  };

  return (
    <div className="d-flex">
      {state === 0 && (
        <ActionMenu
          handleDelete={handleDelete}
          handleEdit={() => navigate(`edit`)}
          id={+id}
        />
      )}
      {state === 1 && (
        <ActionButton action="goToTray" handler={() => navigate('../tray')} />
      )}
    </div>
  );
}

// const nextChecks = checks.splice(id, 1);
// window.localStorage.setItem('checks', JSON.stringify(checks));
