/* eslint react/prop-types: 0 */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */

import React, { useState } from 'react';
import * as Yup from 'yup';
import FormWrapper from '../../Forms/builders/FormWrapper';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import CreateForm from '../../Forms/CreateForm/CreateForm';
import { clear, add } from './AddProductAux';
import { clearFields, addFields, setAmountAndTax } from './formikFns';

export default function AddProduct({ item, onClose, onAddItem }) {
  const [itemAdded, setItemAdded] = React.useState(false);

  const [selectedCategory, setSelectedCategory] = useState(
    item?.category_id || ''
  );

  const handleAmountChange = async (event, label) => {
    // Prevent NaN from appearing as a value
    if (Number.isNaN(+event.target.value)) {
      formik.setFieldError(label, 'Must be a number');
      await formik.setFieldValue(label, 0);
      return;
    }

    const { value } = event.target;
    const values = { ...formik.values };
    values[label] = value;
    setAmountAndTax(values, formik.setFieldValue);
  };

  const handleCategorySelection = (category) => {
    if (!category) return;
    setSelectedCategory(category.family_id);
    addFields(add.category, formik.setFieldValue, category);
    clearFields(clear.category, formik.setFieldValue);
    formik.setFieldValue('quantity', '1');
  };

  const handleProductSelection = (product) => {
    if (!product?.category?.id)
      clearFields(clear.productClear, formik.setFieldValue);
    else {
      clearFields(clear.productChange, formik.setFieldValue);
      formik.setFieldValue('quantity', '1');
      setAmountAndTax(
        { ...formik.values, price_before_tax: product.amount.price_before_tax },
        formik.setFieldValue
      );
      // formik.validateForm();
      addFields(add.product, formik.setFieldValue, product);
    }
  };

  const fields = [
    {
      name: 'category_id',
      label: 'Category',
      formType: 'productCategoryCombo',
      initialValue: item?.category_id || '',
      type: Yup.string().required('Please complete the field'),
      required: true,
      change: (e) => handleCategorySelection(e),
    },
    {
      name: 'product_id',
      label: 'Product or service',
      initialValue: item?.product_id || '',
      type: Yup.string().required('Please complete the field'),
      required: true,
      formType: 'productCombo',
      categoryId: selectedCategory,
      change: (e) => handleProductSelection(e),
    },
    {
      name: 'product_name',
      initialValue: item?.product_name || '',
      type: Yup.string(),
      disabled: true,
      className: 'd-none',
    },
    {
      name: 'category_name',
      initialValue: item?.category_name || '',
      type: Yup.string(),
      disabled: true,
      className: 'd-none',
    },
    {
      name: 'description',
      label: 'Description',
      initialValue: item?.description || '',
      type: Yup.string(),
      formType: 'textarea',
    },
    {
      formGroup: 'Values',
      groupChildren: [
        {
          name: 'quantity',
          label: 'Quantity',
          initialValue: item?.quantity || '1',
          type: Yup.number(),
          formType: 'number',
          change: (e) => handleAmountChange(e, 'quantity'),
          className: 'flex__span-3',
          inputMode: 'numeric',
        },
        {
          name: 'price_before_tax',
          label: 'Rate | Price',
          initialValue: item?.price_before_tax || '',
          type: Yup.number(),
          inputMode: 'numeric',
          className: 'flex__span-3',
          change: (e) => handleAmountChange(e, 'price_before_tax'),
        },
        {
          name: 'item_price_before_tax',
          label: 'Total',
          initialValue: item?.item_price_before_tax || '',
          type: Yup.number(),
          formType: 'number',
          disabled: true,
          readOnly: true,
          plaintext: true,
          className: 'flex__span-3',
        },
        {
          name: 'tax_rate',
          label: 'Tax',
          initialValue: item?.tax_rate || '',
          type: Yup.number(),
          formType: 'number',
          disabled: true,
          readOnly: true,
          plaintext: true,
          className: 'flex__span-3',
        },
        {
          name: 'item_id',
          label: 'item_id',
          initialValue: item?.item_id || '',
          type: Yup.string(),
          formType: 'number',
          disabled: true,
          readOnly: true,
          plaintext: true,
          className: 'd-none',
        },
      ],
    },
  ];

  const submitAction = () => {
    const nextItem = {
      ...formik.values,
      item_id: item?.item_id || Math.floor(Math.random() * 1001),
    };
    if (item?.index || item?.index === 0) nextItem.index = item.index;
    onAddItem(nextItem);
    setItemAdded(true);
    onClose();
  };

  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
  });
  // console.log('formik', formik.isValid, formik.dirty);

  return (
    <FormWrapper
      className="flex__form-wrapper"
      handleSubmit={formik.handleSubmit}
      id="add-product-form"
      isSubmitting={formik.isSubmitting || itemAdded}
      dirty={formik.dirty}
      isValid
      label="Add product"
    >
      <CreateForm structure={fields} {...formik} />
    </FormWrapper>
  );
}
