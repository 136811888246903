// Loaders and actions
import { deleteInvoiceAction, invoiceLoader } from './invoicesLA';
// Components
import InvoiceDocument from '../../components/Invoices/InvoiceDocument';
import ManageInvoice from '../../components/Invoices/ManageInvoice';
import InvoicesSplits from '../../components/Invoices/InvoicesSplits';
import ErrorMesage from '../../pages/ErrorMesage';
// Routes
import messagesRoutes from '../messages/messagesRoutes';

const invoicesListAndModal = [
  ...messagesRoutes,
  {
    path: 'pdf-preview',
    element: <InvoiceDocument />,
    loader: invoiceLoader,
    errorElement: <ErrorMesage message="Invoice preview unavailable" />,
  },

  {
    path: 'manage',
    element: <ManageInvoice />,
    errorElement: <ErrorMesage />,
    children: [
      {
        path: 'delete',
        action: deleteInvoiceAction,
      },
    ],
  },
  {
    path: 'split',
    element: <InvoicesSplits />,
  },
  {
    errorElement: <ErrorMesage message="Invoice unavailable" />,
  },
];

export default invoicesListAndModal;
