import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { I_Colors, I_Data } from '../propTypes';
import ItemRow from './ItemRow';
import TableFooter from './TableFooter';
import NotesDefault from '../NotesDefault';

const s = StyleSheet.create({
  container: {
    marginTop: 200,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    fontWeight: 600,
    gap: 3,
    marginBottom: 3,
  },
  headerCol: {
    padding: '10px 12px',
  },
  notes: {
    fontSize: 8,
    marginBottom: 16,
    lineHeight: 1.75,
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 4,
  },
});

function ItemsTable({
  data,
  colors,
  layoutNumber,
}: {
  data: I_Data;
  colors: I_Colors;
  layoutNumber: number;
}) {
  return (
    <View style={s.container}>
      {data.type === 'estimate' && data.notes.description ? (
        <View style={s.notes}>
          <Text style={s.title}>Project Description</Text>
          <Text>{data.notes.description}</Text>
        </View>
      ) : null}
      <View
        style={{
          ...s.headerContainer,
          color: colors.color2text,
        }}
      >
        <View
          style={{
            ...s.headerCol,
            width: '60%',
            backgroundColor: colors.color1,
            color: colors.color1text,
            borderLeft: `6px solid ${colors.color2}`,
            paddingLeft: '8px',
          }}
        >
          <Text>Name</Text>
        </View>
        <View
          style={{
            ...s.headerCol,
            width: '10%',
            backgroundColor: colors.color2,
          }}
        >
          <Text>Qty</Text>
        </View>
        <View
          style={{
            ...s.headerCol,
            width: '15%',
            backgroundColor: colors.color2,
            textAlign: 'right',
          }}
        >
          <Text>Rate</Text>
        </View>
        <View
          style={{
            ...s.headerCol,
            width: '15%',
            backgroundColor: colors.color2,
            textAlign: 'right',
          }}
        >
          <Text>Amount</Text>
        </View>
      </View>
      <View style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {data?.items.map((item) => (
          <ItemRow key={item.item_id} item={item} colors={colors} />
        ))}
        <TableFooter data={data} colors={colors} />
      </View>
      <NotesDefault data={data} layoutNumber={layoutNumber} />
    </View>
  );
}

export default ItemsTable;
