/* eslint-disable react/prop-types */

import React from 'react';
// import EditIcon from '../EditIcon';
import QUICK_LINKS from '../defs';
import LBToggleButton from '../../../UI/LBToogleButton/LBToggleButton';
import QuickLinkIcon from '../QuickLinkIcon';
import IconButton from '../../../UI/IconButton/IconButton';
import { Edit } from '../../../UI/Icons/Icons';
import LBTooltip from '../../../UI/LBTooltip/LBTooltip';

function ToggleGroup({ modalState, setModalState, quickLinks, onToggle }) {
  return (
    <div className="quick-links__action-group">
      {/* <button
        onClick={() => setModalState((prevState) => !prevState)}
        className="btn btn-secondary btn-sm quick-links__button"
        type="button"
      >
        <EditIcon className="edit-icon" />
        {modalState ? 'Lock' : 'Customize'}
      </button> */}
      <IconButton
        size={20}
        text={modalState ? 'Lock' : 'Customize'}
        onClick={() => setModalState((prevState) => !prevState)}
      >
        <Edit />
      </IconButton>
      <div
        className="action--group_container"
        style={{
          pointerEvents: modalState ? 'revert' : 'none',
          '--x': modalState ? '0%' : '100%',
          '--o': modalState ? 1 : 0,
          '--h': modalState ? 'auto' : 0,
        }}
      >
        {
          //  Here we use the static object as a base for the icons
          //  since if we use the dynamic state array the icons move
          //  around like crazy when we toggle them
          Object.keys(QUICK_LINKS).map((staticId) => {
            const isChecked = quickLinks.find((link) => link.id === staticId);

            return (
              <LBTooltip
                key={staticId}
                tooltipText={
                  QUICK_LINKS[staticId].cta || QUICK_LINKS[staticId].stat.label
                }
              >
                <LBToggleButton
                  type="checkbox"
                  color={QUICK_LINKS[staticId].color}
                  className="action--group_icon-wrapper"
                  style={{
                    '--stroke-color': `var(--bs-${QUICK_LINKS[staticId].color})`,
                  }}
                  name={staticId}
                  value={staticId}
                  onChange={onToggle}
                  checked={!!isChecked}
                >
                  <QuickLinkIcon iconId={staticId} />{' '}
                  {/* {quickLinks[stateIndex].stat.label || quickLinks[stateIndex].cta} */}
                </LBToggleButton>
              </LBTooltip>
            );
          })
        }
      </div>
    </div>
  );
}

export default ToggleGroup;
