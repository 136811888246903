/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import CreateForm from '../CreateForm/CreateForm';
import FormWrapper from './FormWrapper';
import useFormikTemplate from '../hooks/useFormikTemplate';

function GenericForm({
  fields,
  submitAction,
  formId,
  navigation = false,
  submitLabel,
  className = '',
}) {
  // Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
  });

  return (
    <FormWrapper
      id={formId}
      handleSubmit={formik.handleSubmit}
      isSubmitting={navigation}
      dirty={formik.dirty}
      isValid={formik.isValid}
      label={submitLabel}
      className={className}
    >
      <CreateForm structure={fields} {...formik} />
    </FormWrapper>
  );
}

export default GenericForm;
