import React from 'react';
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { useFetcher, useParams } from 'react-router-dom';
import File from '../Forms/FileDragAndDrop/File';
import CreateForm from '../Forms/CreateForm/CreateForm';

function VendorImages({ fields, setFormData, insuranceExpires, handleForms }) {
  const { vendorId } = useParams();
  const fetcher = useFetcher();

  const deleteFile = (name, initialValue) => {
    if (
      vendorId &&
      initialValue === handleForms?.values[name] &&
      initialValue !== ''
    ) {
      if (fetcher.state === 'submiting') return;
      fetcher.submit(
        {
          module: 'vendor',
          id: `${vendorId}`,
          file: name,
        },
        { method: 'post', action: '/expenses/delete-expense-files' }
      );
    }
  };

  return (
    <div className="row">
      <h5 className="mb-4 mt-5">Files</h5>

      {fields.map((field) => (
        <div className="col-md-4" key={field.name}>
          <File
            value={handleForms?.values[field.name]}
            setFieldValue={handleForms?.setFieldValue}
            setFormData={setFormData}
            name={field.name}
            label={field.label}
            deleteFile={() => deleteFile(field.name, field.initialValue)}
            allowed={field.allowed}
            resize={field.resize}
          />
        </div>
      ))}

      {handleForms?.values.insurance && (
        <CreateForm structure={insuranceExpires} {...handleForms} />
      )}
    </div>
  );
}

export default VendorImages;
