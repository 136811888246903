/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Alert } from 'react-bootstrap';
import { useFetcher, useLocation } from 'react-router-dom';
import { string } from 'yup';
import FormWrapper from '../../Forms/builders/FormWrapper';
import CreateForm from '../../Forms/CreateForm';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import LBOffCanvas from '../../UI/LBOffCanvas';

function MerchantModal() {
  const { state: historyState, pathname } = useLocation();
  const merchantId = historyState?.id;

  const getMerchant = useFetcher();
  const updateMerchant = useFetcher();

  React.useEffect(() => {
    if (!merchantId) return;

    if (getMerchant.state === 'idle' && !getMerchant.data) {
      getMerchant.load(`/expenses/merchants/${merchantId}`);
    }

    if (getMerchant.data) {
      const { name } = getMerchant.data.data;
      const {
        street_address_1: streetAddress1,
        street_address_2: streetAddress2,
        city,
        state,
        zipcode,
        country,
      } = getMerchant.data.data.address;
      setValue('name', name || '');
      setValue('address_street_address_1', streetAddress1 || '');
      setValue('address_street_address_2', streetAddress2 || '');
      setValue('address_city', city || '');
      setValue('address_state', state || '');
      setValue('address_zipcode', zipcode || '');
      setValue('address_country', country || '');
    }
  }, [getMerchant]);

  const fields = [
    {
      name: 'name',
      label: 'Name',
      initialValue: '',
      type: string().required('Please complete the field'),
      required: true,
      className: 'flex__span-12',
    },
    {
      formGroup: 'Billing Address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          className: 'flex__span-12',
          initialValue: '',
          type: string(),
          formType: 'autocompleteAddress',
          fieldNames: {
            street: 'address_street_address_1',
            city: 'address_city',
            state: 'address_state',
            zipcode: 'address_zipcode',
            country: 'address_country',
          },
        },
        {
          name: 'address_street_address_1',
          label: 'Street Address',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
          autocomplete: 'address-line1',
        },
        {
          name: 'address_street_address_2',
          label: 'Street Address 2',
          initialValue: '',
          type: string(),
          autocomplete: 'address-line2',
        },
        {
          name: 'address_city',
          label: 'City',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
        },
        {
          name: 'address_state',
          label: 'State',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
        },
        {
          name: 'address_zipcode',
          label: 'Zip code',
          initialValue: '',
          type: string().required('This field is mandatory'),
          required: true,
        },
        {
          name: 'address_country',
          label: 'Country',
          type: string().required('This field is mandatory'),
          required: true,
          initialValue: '',
        },
      ],
    },
  ];

  const submitAction = (values) => {
    const nextValues = {
      ...values,
      website: getMerchant.data.data.website || '',
      contact_person: getMerchant.data.data.contact_person || '',
      phone: getMerchant.data.data.phone || '',
      notes: getMerchant.data.data.notes || '',
      id: getMerchant.data.data.id,
      modal_redirect: pathname.substring(0, pathname.lastIndexOf('/')),
    };

    updateMerchant.submit(nextValues, {
      method: 'post',
      action: `/expenses/merchants/${merchantId}/update`,
    });
  };

  //   Initial values
  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  async function setValue(field, value) {
    if (!field === undefined || !value === undefined)
      throw new Error('Value and field are required arguments');

    formik.setFieldValue(field, value);
  }

  if (!merchantId)
    return (
      <LBOffCanvas.Wrapper>
        <LBOffCanvas.Left>
          <LBOffCanvas.Header>Oops!</LBOffCanvas.Header>
          <Alert variant="danger">
            Error, couldn&apos;t find a target, go back and select a customer,
            vendor or merchant, if it&apos;s missing any fields you will be able
            to complete them in this modal.
          </Alert>
        </LBOffCanvas.Left>
      </LBOffCanvas.Wrapper>
    );

  return (
    <LBOffCanvas.Wrapper>
      <LBOffCanvas.Left>
        <LBOffCanvas.Header>Update Merchant</LBOffCanvas.Header>
        <Alert variant="info" style={{ fontSize: '0.8rem' }}>
          Keep in mind that the following changes will be applied to the
          merchant itself, and not just on this particular check.
        </Alert>
        {getMerchant.state === 'loading' ||
        updateMerchant.state === 'loading' ? (
          <p>Loading...</p>
        ) : (
          <FormWrapper
            id="update-merchant-checks"
            className="flex__form-wrapper"
            handleSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
            label="Update Merchant"
            dirty={formik.dirty}
            isValid={formik.isValid}
            portalId="offcanvas-action-group-left"
          >
            <CreateForm structure={fields} {...formik} />
          </FormWrapper>
        )}
      </LBOffCanvas.Left>
    </LBOffCanvas.Wrapper>
  );
}

export default MerchantModal;
