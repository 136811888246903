const clear = {
  category: [
    'item_price_before_tax',
    'description',
    'product_id',
    'product_name',
    'price_before_tax',
    'tax_rate',
  ],
  productClear: [
    'description',
    'price_before_tax',
    'tax_rate_id',
    'item_price_before_tax',
    'product_name',
  ],
  productChange: [
    'quantity',
    'item_price_before_tax',
    'tax_rate',
    'description',
  ],
};

const add = {
  amounts: ['item_price_before_tax', 'quantity', 'rate', 'tax_rate'],
  product: [
    ['product_name', 'name'],
    'description',
    ['price_before_tax', 'amount.price_before_tax'],
    ['tax_rate', 'amount.tax_rate'],
  ],
  category: [['category_name', 'family_name']],
};

export { clear, add };
