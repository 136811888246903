// Returns the completion date if the status is "completed"
export function completionCheck(status) {
  if (status === 'completed') {
    return new Date().toISOString();
  }
  return null;
}

// This function takes the response from "get-appointment-details"
// and formats it in the way the server expects updates in "update-appointment"
export function transformAppointment(data) {
  return {
    name: data.name || null,
    id: data.id || null,
    start_date: data.start_date || null,
    end_date: data.end_date || null,
    performer_name: data.performer.name || null,
    customer_id: data.customer_id || null,
    notes: data.notes || null,
    contact_phone: data.contact_phone || null,
    appointment_address_street_line_1: data.address.street_address_1 || null,
    appointment_address_street_line_2: data.address.street_address_2 || null,
    appointment_zipcode: data.address.zipcode || null,
    appointment_city: data.address.city || null,
    appointment_state: data.address.state || null,
    appointment_country: data.address.country || null,
    contact_name: data.contact_name || null,
    status: data.status || null,
    creation_date: data.creation_date || null,
  };
}
