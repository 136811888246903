/* eslint-disable react/prop-types */
import React from 'react';
import { StyleSheet, View, Text, Image } from '@react-pdf/renderer';

import './FontSetup';
import FONT_STYLES from './FontStyles';

const rootStyles = getComputedStyle(document.body);

const styles = StyleSheet.create({
  ...FONT_STYLES,

  pdfFooter: {
    fontFamily: 'Montserrat',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,

    position: 'absolute',
    bottom: '0px',
    left: '0px',
    right: '0px',
    padding: '0 52px',
    borderTop: `1px solid ${rootStyles.getPropertyValue('--bs-gray-300')}`,
    height: '50px',
  },

  companyInfo: {
    flex: '1 0 100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
  },

  companyName: {
    fontSize: '8px',
  },

  timestampContainer: {
    flex: '0 0 auto',
    minWidth: 230,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },

  timestampHeading: {
    fontSize: '7px',
    color: rootStyles.getPropertyValue('--bs-gray-600'),
  },

  pageNumberContainer: {
    flex: '1 0 100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
    gap: 6,
    fontSize: 7,
    color: rootStyles.getPropertyValue('--bs-gray-600'),
  },
  pageNumberText: {
    fontSize: 8,
  },
  footerImage: {
    flex: '0 0 20px',
    minWidth: 20,
    height: '20px',
    width: '20px',
    borderRadius: '3px',
    overflow: 'hidden',
  },
});

function PDFFooter({ companyName, logoBase64 }) {
  return (
    <View style={styles.pdfFooter} fixed>
      <View style={styles.companyInfo}>
        <Image style={styles.footerImage} src={logoBase64} />
        <Text style={styles.companyName}>{companyName}</Text>
      </View>

      <View style={styles.timestampContainer}>
        <Text style={styles.timestampHeading}>Report generated on:</Text>
        <Text style={{ ...styles.companyName, ...styles.bold }}>
          {new Date().toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZoneName: 'longOffset',
          })}
        </Text>
      </View>

      <View style={styles.pageNumberContainer}>
        <Text
          style={styles.pageNumberText}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </View>
  );
}

export default PDFFooter;
