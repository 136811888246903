import React from 'react';
import { Link, useActionData } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CustomerList from './CustomerList';

function ImportResult() {
  const actionData = useActionData();

  /* Early return */
  if (actionData?.message !== 'success') {
    return (
      <>
        <div className="d-flex flex-column align-items-start">
          <h2 className="csv__heading">Error loading the CSV file</h2>
          <p className="csv__intro-p">
            We weren&apos;t able to add any customers to the database
          </p>
          <p className="csv__info-p">
            Try reviewing your CSV file to ensure the required format is being
            used and that at least one customer meets the required fields
            conditions.
          </p>
          <p className="csv__info-p">The following fields are required:</p>
          <ul className="csv__ul">
            <li>Name</li>
            <li>Email</li>
            <li>Phone*</li>
            <li>Street Address</li>
            <li>City</li>
            <li>State</li>
            <li>Zip code</li>
            <li>Country*</li>
          </ul>
          <small className="csv__disclaimer">
            Country will be defaulted as “United States” if the field is
            missing, and phone numbers will be converted to international format
            using +1 as the prefix.
          </small>
          <Button className="mt-4" as={Link} to="../form">
            Try Importing Another CSV
          </Button>

          <button type="button" className="csv__link mt-5">
            Download Example CSV File
          </button>
        </div>
        <div>
          {actionData?.duplicated_customers?.length > 0 ? (
            <>
              <h5 style={{ fontSize: '1.1rem' }}>Duplicated customers:</h5>
              <ul className="mt-3">
                {actionData?.duplicated_customers.map(
                  ({ name, email }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} style={{ fontSize: '0.9rem' }}>
                      ⚠️ {name} -{' '}
                      <span style={{ color: 'var(--bs-gray-600)' }}>
                        {email}
                      </span>
                    </li>
                  )
                )}
              </ul>
              <small className="csv__disclaimer">Email must be unique</small>
            </>
          ) : (
            <div
              className="h-100 d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: 'var(--bs-gray-100)',
                color: 'var(--bs-gray-600)',
              }}
            >
              <p>Failed to add any customers to the database.</p>
            </div>
          )}
        </div>
      </>
    );
  }

  /* Regular return */
  return (
    <>
      <div>
        <h2 className="csv__heading">Success!</h2>
        <h3 className="csv__success-heading my-4">
          <svg
            id="a"
            xmlns="http://www.w3.org/2000/svg"
            height={32}
            width={32}
            viewBox="0 0 27.52 27.52"
          >
            <circle fill="var(--bs-success)" cx="13.76" cy="13.76" r="13.76" />
            <polyline
              fill="transparent"
              stroke="white"
              strokeWidth={2}
              strokeLinecap="round"
              points="6.66 15.67 10.01 19.02 19.55 9.48"
            />
          </svg>
          Added {actionData?.saved_customers?.length} customer
          {`${actionData?.saved_customers?.length > 1 ? 's' : ''}`} to the
          database
        </h3>
        <Button variant="secondary" as={Link} to="../form">
          Load Another CSV File
        </Button>

        {actionData?.duplicated_customers?.length > 0 && (
          <>
            <h5 className="mt-5" style={{ fontSize: '1.1rem' }}>
              Duplicated customers:
            </h5>
            <ul className="mt-3">
              {actionData?.duplicated_customers.map(
                ({ name, email }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index} style={{ fontSize: '0.9rem' }}>
                    ⚠️ {name} -{' '}
                    <span style={{ color: 'var(--bs-gray-600)' }}>{email}</span>
                  </li>
                )
              )}
            </ul>
            <small className="csv__disclaimer">Email must be unique</small>
          </>
        )}

        {actionData?.failed_entries?.length > 0 && (
          <>
            <h5 className="mt-5" style={{ fontSize: '1.1rem' }}>
              Failed to load the following customers:
            </h5>
            <ul className="mt-3">
              {actionData?.failed_entries.map(({ name }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index} style={{ fontSize: '0.9rem' }}>
                  ❌ {name}
                </li>
              ))}
            </ul>
            <small className="csv__disclaimer">
              Review that the required fields are correctly filled for those
              customers and try again, or you can load them manually later to
              our system, sorry for the inconvenience!
            </small>
          </>
        )}
      </div>
      {!actionData?.saved_customers && <div>Error loading customer data</div>}
      {actionData?.saved_customers?.length > 0 && (
        <div>
          <h3 className="csv__heading mb-4">Added customers:</h3>
          <CustomerList>
            {actionData?.saved_customers.map(
              ({ name, address, mobile, id }) => (
                <CustomerList.Item
                  key={id}
                  name={name}
                  address={address?.full_address}
                  phone={mobile}
                />
              )
            )}
          </CustomerList>
        </div>
      )}
    </>
  );
}

export default ImportResult;
