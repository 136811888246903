import React from 'react';
import { useNavigate } from 'react-router-dom';
import WidgetContainer from '../../Dashboard/Widgets/WidgetContainer/WidgetContainer';
import LinkButton from '../LinkButton/LinkButton';

function ExpensesReport() {
  const navigate = useNavigate();
  // const navigateEmployees = () => {
  //   navigate('/reports/employees');
  // };
  const navigateExpensesCategory = () => {
    navigate('/old_reports/by_category');
  };
  const navigateExpensesInvoice = () => {
    navigate('/old_reports/by_invoice');
  };
  const navigateExpensesMerchant = () => {
    navigate('/old_reports/by_merchant');
  };
  const navigateExpensesVendor = () => {
    navigate('/old_reports/by_vendor');
  };

  return (
    <WidgetContainer className="widgets__twocol-item-container p-4 shadow-sm">
      <h3>Expenses</h3>
      <div style={{ marginTop: 20 }}>
        {/* <LinkButton title="Employees" onClick={navigateEmployees} /> */}
        <LinkButton
          title="Expenses by Category"
          onClick={navigateExpensesCategory}
        />
        <LinkButton
          title="Expenses by Invoice"
          onClick={navigateExpensesInvoice}
        />
        <LinkButton
          title="Expenses by Merchant"
          onClick={navigateExpensesMerchant}
        />
        <LinkButton
          title="Expenses by Vendor"
          onClick={navigateExpensesVendor}
        />
      </div>
    </WidgetContainer>
  );
}

export default ExpensesReport;
