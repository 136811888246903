/* eslint-disable react/prop-types */

import React from 'react';
import {
  Cell,
  Pie,
  ResponsiveContainer,
  Label,
  Legend,
  PieChart,
} from 'recharts';

import './PieCharts.scss';
import formatCurrency from '../utils/formatCurrency';

export function SimpleLabel({ color, viewBox, main }) {
  const { cx, cy } = viewBox;
  const x = cx;
  const y = cy;

  return (
    <text offset={0} x={x} y={y} textAnchor="middle" className="custom-label">
      <tspan
        className="main"
        x={x}
        dy="0.5em"
        style={{ fill: color || 'var(--bs-primary)' }}
      >
        {main}
      </tspan>
    </text>
  );
}

const renderLegend = (props) => {
  const { fillColor, legendValue, payload } = props;

  return (
    <ul className="row-legend">
      <li className="item" style={{ '--legend-color': fillColor }}>
        <span className="value">{formatCurrency(legendValue)}</span>
        <span className="field-name">{payload[1].value}</span>
      </li>
    </ul>
  );
};

function calculatePercentage(part, whole) {
  return (part / whole) * 100;
}

function OneThird({ valueName, partValue, wholeValue, fillColor }) {
  const percentageValue = calculatePercentage(partValue, wholeValue);
  const restValue = 100 - percentageValue;

  const innerData = [
    {
      name: 'Rest',
      value: restValue,
      color: 'var(--bs-gray-200)',
    },
    {
      name: valueName,
      value: percentageValue,
      color: fillColor,
    },
  ];

  return (
    <div style={{ height: 350, width: '33%' }}>
      <ResponsiveContainer>
        <PieChart margin={{ top: -5, right: -5, left: -5, bottom: -5 }}>
          <Pie
            startAngle={-270}
            endAngle={90}
            data={innerData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius="60%"
          >
            {innerData.map((entry) => (
              <Cell key={`cell-${entry}`} fill={entry.color} />
            ))}
            <Label
              content={SimpleLabel}
              offset={0}
              // Replace with real color
              color={fillColor}
              position="center"
              main={`${Math.round(percentageValue)}%`}
            />
          </Pie>
          <Legend
            // height={50}
            // verticalAlign=""
            wrapperStyle={{ top: 'clamp(210px, 38vw, 290px)' }}
            content={renderLegend}
            legendValue={partValue}
            fillColor={fillColor}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default OneThird;
