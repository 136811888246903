/* eslint-disable react/no-array-index-key */
import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { I_Colors, I_Item } from '../propTypes';
import formatCurrency from '../../../Dashboard/Widgets/utils/formatCurrency';

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1px',
    borderTopWidth: '1px',
    marginTop: -1,
  },
  col: {
    padding: '10px 12px',
    borderRightWidth: '1px',
  },
});

function ItemRow({ item, colors }: { item: I_Item; colors: I_Colors }) {
  return (
    <View
      style={{
        ...s.container,
        color: colors.bodyText,
        borderColor: colors.color2,
      }}
      wrap={false}
    >
      <View style={{ ...s.row, borderColor: colors.color2 }}>
        <View
          style={{ ...s.col, width: '60%', borderRightColor: colors.color2 }}
        >
          <Text>{item.product_name}</Text>
        </View>
        <View
          style={{ ...s.col, width: '10%', borderRightColor: colors.color2 }}
        >
          <Text>{item.quantity}</Text>
        </View>
        <View
          style={{
            ...s.col,
            width: '15%',
            borderRightColor: colors.color2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>$</Text>
          <Text>{formatCurrency(item.price_before_tax, 2, false)}</Text>
        </View>
        <View
          style={{
            ...s.col,
            width: '15%',
            borderRight: 'none',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>$</Text>
          <Text>{formatCurrency(item.item_price_before_tax, 2, false)}</Text>
        </View>
      </View>
      {item?.description && (
        <View style={{ ...s.row, borderColor: colors.color2 }}>
          <View
            style={{
              ...s.col,
              width: '70%',
              borderRight: 'none',
              fontSize: 7,
            }}
          >
            <Text style={{ marginBottom: '6px' }}>Description:</Text>
            {item.description
              .split('\n')
              .filter((line) => line.trim() !== '')
              .map((entry, index) => (
                <Text
                  key={index}
                  style={{ lineHeight: 1.5, textIndent: 10, marginTop: 6 }}
                >
                  {entry}
                </Text>
              ))}
          </View>
        </View>
      )}
    </View>
  );
}

export default ItemRow;
