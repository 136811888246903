import React from 'react';
import { useNavigate } from 'react-router-dom';
import ActionMenu from '../../UI/buttons/ActionMenu';

export default function useTableData(products) {
  const [productsTable, setproductsTable] = React.useState([]);
  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/products/update/${id}`);
  };
  const handleDelete = (id) => {
    navigate(`/products/delete/${id}`);
  };
  React.useEffect(() => {
    if (!products) return;
    const nextProductsTable = products.map((product) => {
      return {
        productName: product.name,
        category: product.category.name,
        sku: product.sku,
        type: product.type,
        product_tax:
          product.type === 'Product' ? `${product.amount.tax_rate} %` : '-',
        description: product.description,
        price: product.amount.price_before_tax,
        id: product.id,
        units:
          product.type === 'Product'
            ? `${product.unit.quantity} ${product.unit.type}`
            : '-',
        filterChips: `filter__${product.type}s`,
        more: (
          <ActionMenu
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            id={product.id}
          />
        ),
      };
    });
    setproductsTable(nextProductsTable);
  }, [products]);

  return productsTable;
}
