import invoicesAPI from '../../API/routesAPI/invoicesAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

const nextInvoice = (api) => ({
  message: api.message,
  data: {
    current: api.current,
    customer: api.customer,
    invoice: {
      id: api.invoice.invoice_id,
      number: api.invoice.invoice_number,
      status: api.invoice.invoice_status_id,
      pdf: api.invoice.invoice_pdf,
      amount: {
        balance: api.invoice_amount.invoice_balance,
        paid: api.invoice_amount.invoice_paid,
        subtotal: Number(api.invoice_amount.invoice_item_subtotal)
          ? api.invoice_amount.invoice_item_subtotal
          : api.invoice_items.reduce(
              (acc, item) => Number(item.item_price) + acc,
              0
            ),
        tax: api.invoice_amount.invoice_tax_total,
        total: api.invoice_amount.invoice_total,
      },
      dates: {
        dateCreated: api.invoice.invoice_date_created,
        dateDue: api.invoice.invoice_date_due,
        terms: api.invoice.invoice_terms,
      },
      items: api.invoice_items.map((item) => ({
        item_price_before_tax: item.item_price,
        category_id: item.item_cat_id,
        category_name: item.item_cat_name,
        description: item.item_description,
        product_id: item.item_product_id,
        item_id: item.item_id,
        product_name: item.item_product_name,
        quantity: item.item_quantity,
        price_before_tax: item.item_rate,
        tax_amount: item.item_tax_amount,
        tax_rate: item.item_tax,
        unit_quantity: item.item_quantity,
        unit_type: item.unit_type,
      })),
      notes: {
        serviceInfo: {
          show_service_info: Boolean(api.service_info.show_service_info),
          service_client_id: api.service_info.service_client_id,
          name: api.service_info.name,
          project_manager: api.service_info.project_manager,
          address: api.service_info.address,
          estimate: api.service_info.estimate,
        },
        statementNotes: api.invoice.statement_notes,
        customerNotes: api.invoice.customer_notes,
      },
    },
    payment: {
      method: api.invoice.payment_method,
      link: api.invoice.payment_link,
    },
    send: {
      is_sent: api.invoice.is_sent,
      email_status: api.invoice.invoice_email_status_id,
    },
    template: api?.template_settings
      ? {
          type: api.template_settings.invoice_type,
          primary_color_bg: api.template_settings.primary_color_bg,
          primary_color_text: api.template_settings.primary_color_text,
          secondary_color_bg: api.template_settings.secondary_color_bg,
          secondary_color_text: api.template_settings.secondary_color_text,
          third_color_bg: api.template_settings.third_color_bg,
          third_color_text: api.template_settings.third_color_text,
        }
      : null,
    user: {
      company: {
        name: api.user_details.company,
        slogan: api?.template_settings
          ? api.template_settings.company_slogan
          : null,
        logo: api.user_details.logo,
      },
      contact: {
        address: {
          street_address_1: api.user_details.address.street_address_1,
          street_address_2: api.user_details.address.street_address_2,
          city: api.user_details.address.city,
          state: api.user_details.address.state,
          country: api.user_details.address.country,
          zipcode: api.user_details.address.zipcode,
          full_address: api.user_details.address.full_address,
        },
        phone: api.user_details.phone,
        mobile: api.user_details.mobile,
        email: api.user_details.email,
      },
    },
    recurrence: {
      isRecurrent: api.invoice.invoice_recur,
      from: api.invoice.recur_from,
      frequency: api.invoice_recur_info.recur_frequency,
      day: api.invoice_recur_info.recur_day,
      date: api.invoice_recur_info.recur_date,
      till: api.invoice_recur_info.recur_till,
    },
  },
});

export const invoicesLoader = async ({ params }) => {
  const { customerId } = params;
  const response = await invoicesAPI.getAll(customerId);

  return handleResposeAbstraction({
    response,
  });
};

export const invoiceLoader = async ({ params }) => {
  // We probably don't need to look for copyId, as we no longer
  // use that param.
  const { invoiceId, copyId } = params;
  const response = await invoicesAPI.get(invoiceId || copyId);
  let result = await response.json();
  if (result?.invoice) {
    result = nextInvoice(result);
  }
  return handleResposeAbstraction({
    response,
    result,
  });
};

export const createInvoiceAction = async ({
  request,
  params,
  copy = false,
}) => {
  const body = await request.formData();
  const updates = Object.fromEntries(body);
  const { customerId } = params;
  const invoiceClientId = JSON.parse(updates.json).client_id.toString();
  const { json } = updates;
  const response = await invoicesAPI.create(json);
  const result = await response.json();

  // // Redirect galore (sorry UwU)
  let redirectTo;

  if (copy) {
    if (!customerId || customerId === invoiceClientId) {
      redirectTo = `../../${result.invoice_id}`;
    } else {
      redirectTo = `../../../../${invoiceClientId}/invoices/${result.invoice_id}`;
    }
  }

  if (!copy) {
    if (!customerId || customerId === invoiceClientId) {
      redirectTo = `../${result.invoice_id}`;
    } else {
      redirectTo = `../../../${invoiceClientId}/invoices/${result.invoice_id}`;
    }
  }

  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
  });
};

export const updateInvoiceAction = async ({ request, params }) => {
  const body = await request.formData();
  const updates = Object.fromEntries(body);
  const { json } = updates;
  const response = await invoicesAPI.update(json);
  const { invoiceId, customerId } = params;
  const invoiceClientId = JSON.parse(updates.json).client_id.toString();
  const redirectTo =
    !customerId || customerId === invoiceClientId
      ? `..`
      : `../../../../${invoiceClientId}/invoices/${invoiceId}`;
  return handleResposeAbstraction({
    response,
    redirectTo,
  });
};

export const deleteInvoiceAction = async ({ params }) => {
  const { invoiceId } = params;
  const response = await invoicesAPI.delete(invoiceId);
  return handleResposeAbstraction({
    response,
    redirectTo: `../../../`,
    showSuccess: true,
  });
};

export const nextInvoiceNumberLoader = async () => {
  const response = await invoicesAPI.nextNumber();
  return handleResposeAbstraction({
    response,
  });
};

export const convertEstimateAction = async ({ request, params }) => {
  const { estimateId, customerId } = params;
  const body = await request.formData();
  const updates = Object.fromEntries(body);
  const { json } = updates;
  const response = await invoicesAPI.convertEstimate(json, estimateId);
  const result = await response.json();
  const invoiceClientId = JSON.parse(updates.json).client_id.toString();
  const redirectTo =
    !customerId || customerId === invoiceClientId
      ? `../../../invoices/${result.invoice_id}`
      : `../../../../${invoiceClientId}/invoices/${result.invoice_id}`;
  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
  });
};
