const tableSetup = [
  {
    header: 'Name',
    accessorKey: 'productName',
  },
  { header: 'Filter', accessorKey: 'filterChips', className: 'd-none' },
  {
    header: 'Category',
    accessorKey: 'category',
    mobileLabel: true,
  },
  {
    header: 'SKU',
    accessorKey: 'sku',
    mobileLabel: true,
  },
  {
    header: 'Type',
    accessorKey: 'type',
    mobileLabel: true,
  },
  {
    header: 'Description',
    accessorKey: 'description',
    className: 'table__small w-100',
    type: 'textArea',
    mobileLabel: true,
  },
  {
    header: 'Tax rate',
    accessorKey: 'product_tax',
    mobileLabel: true,
  },
  {
    header: 'Units',
    accessorKey: 'units',
    mobileLabel: true,
  },
  {
    header: 'Price | Rate',
    accessorKey: 'price',
    type: 'money',
    mobileLabel: true,
  },
  {
    header: 'More',
    accessorKey: 'more',
  },
];

export default tableSetup;
