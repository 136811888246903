import React from 'react';
import PropTypes from 'prop-types';
import {
  useLoaderData,
  useLocation,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import InvoiceNew from './InvoiceNew';
import useFetcherOnce from './useFetcherOnce';
import { invoices as paths } from '../../API/routesPaths';

export default function InvoiceNewInitializer({ copy = false }) {
  // HOOKS DEFINITION
  const { invoiceId, customerId } = useParams();
  const loader = useLoaderData();
  const nextNumber = useFetcherOnce(paths.next, !invoiceId || copy);
  const message = useFetcherOnce(paths.messages, !invoiceId);
  const customerIdRoute = customerId && useRouteLoaderData('getCustomer');
  const { state } = useLocation();

  // CONSTANTS DEFINITION
  const TODAY = new Date().toISOString().substring(0, 10);

  // DATA DEFINITION
  const customerLoader = loader?.data?.customer;
  const customerRoute = customerIdRoute?.data;
  const defaultStamentNotes = message?.data?.data?.statement_notes || '';
  const documentNumber = nextNumber?.data?.invoice_number || '';
  const invoice = loader?.data?.invoice || {};
  const recurrence = loader?.data?.recurrence || {};

  /* 
    INVOICE VARIABLE DATA
   | If the invoiceId exists, the invoice is being edited
   | If the copyId exists, the invoice is being copied
   | If estimate exists, the invoice is being created from an converted estimate
   | If none of the above, the invoice is being created from scratch
  */
  invoice.dates = {
    ...invoice?.dates,
    dateCreated: invoiceId ? invoice?.dates?.dateCreated : TODAY,
    dateDue: invoice?.dates?.dateDue || '',
  };
  invoice.items =
    loader?.data?.invoice?.items || loader?.data?.estimate?.items || [];
  invoice.notes = {
    ...invoice?.notes,
    statementNotes: invoice?.notes?.statementNotes || defaultStamentNotes,
  };

  invoice.number = copy || !invoice?.number ? documentNumber : invoice.number;

  // check next two lines
  recurrence.isRecurrent = recurrence?.isRecurrent ? 1 : 0;
  recurrence.frequency = recurrence?.frequency ? `${recurrence.frequency}` : '';

  /*
    CUSTOMER VARIABLE DATA
    | If the customerId exists, the action is bieng done from the customer page
    | If location state is present, we are coming from onboarding (for example)
  */
  let customer = {
    id: '',
    email: '',
    fullAddress: '',
  };

  if (customerLoader?.id) {
    customer = {
      id: customerLoader?.id,
      email: customerLoader?.email,
      fullAddress: customerLoader?.address?.full_address,
    };
  }

  if (customerRoute?.data?.id) {
    customer = {
      id: customerRoute?.data?.id,
      email: customerRoute?.data?.email,
      fullAddress: customerRoute?.data?.address?.full_address,
    };
  }

  if (state?.customer?.id) {
    customer = {
      id: state?.customer?.id,
      email: state?.customer?.email,
      fullAddress: state?.customer?.fullAddress,
    };
  }

  // We are preventing the initialization of the formik component before we
  // get the invoice number. This way we can set enableReinitialize to "false"
  // Setting it to "true" breaks some of the functionality
  if (!invoice.number)
    return (
      <div
        style={{
          display: 'grid',
          width: '100%',
          height: '100%',
          placeContent: 'center',
          backgroundColor: 'var(--bs-gray-400)',
          borderRadius: 8,
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <InvoiceNew
      invoice={invoice}
      customer={customer}
      recurrence={recurrence}
      copy={copy}
    />
  );
}

InvoiceNewInitializer.propTypes = {
  copy: PropTypes.bool,
};
