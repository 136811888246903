import React from 'react';
import './styles/StripeLinkSuccess.scss';

function StripeLinkSuccess() {
  return (
    <div className="stripe-success-container">
      <p>Stripe Account was successfully generated.</p>
    </div>
  );
}

export default StripeLinkSuccess;
