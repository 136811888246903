import React from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import ActionButton from './ActionButton';

function ActionMenu({ handleDelete, handleEdit, disabled = false, id, print }) {
  const toastError = () =>
    toast.error("You can't delete categories that are currently being used");

  return (
    <div className="gap-1 d-flex ps-1" style={{ minWidth: 'fit-content' }}>
      <ActionButton action="edit" handler={() => handleEdit(id)} />
      {disabled ? (
        <ActionButton action="deleteDisabled" handler={toastError} />
      ) : (
        <ActionButton action="delete" handler={() => handleDelete(id)} />
      )}
      {print && <ActionButton action="print" handler={print} />}
    </div>
  );
}

ActionMenu.propTypes = {
  handleEdit: propTypes.func.isRequired,
  handleDelete: propTypes.func.isRequired,
  disabled: propTypes.bool,
  id: propTypes.number,
  print: propTypes.func,
};

export default ActionMenu;
