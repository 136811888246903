import fetchAPI from '../fetchUtils';

const loginAPI = {
  login: async (body) => fetchAPI.postAnonymous('/user-login', body),
  register: async (body) => fetchAPI.postAnonymous('/user-signup', body),
  resetPasswordRequest: async (body) =>
    fetchAPI.postAnonymous('/user-reset-password-request', body),
  resetPassword: async (body) =>
    fetchAPI.postAnonymous(`/user-reset-password`, body),
};

export default loginAPI;
