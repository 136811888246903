/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Form } from 'react-bootstrap';

function SloganSelector({ slogan, setSlogan }) {
  return (
    <Form.Control
      as="input"
      maxLength={150}
      name="slogan"
      onChange={(e) => setSlogan(e.target.value)}
      placeholder="select company slogan"
      type="input"
      value={slogan}
    />
  );
}

export default SloganSelector;
