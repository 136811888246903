import React from 'react';
import { Button } from 'react-bootstrap';
import { Form, useRouteLoaderData } from 'react-router-dom';
import CustomerList from './CustomerList';

function ExportCsv() {
  const { customers } = useRouteLoaderData('customers');

  return (
    <>
      <div className="csv__sticky-container">
        <h2 className="csv__heading">Submit your CSV file for proccessing</h2>
        <p className="csv__intro-p">
          By clicking the button we will export all your customer data, using a
          standarized format and field naming. First and Last Name will be
          merged into “Name” and the saved mobile phone will be exported as
          “Phone”
        </p>
        <Form method="POST">
          <Button className="mt-5" type="submit">
            Export CSV
          </Button>
        </Form>
      </div>
      {!customers && <div>Error loading customer data</div>}
      {customers?.length > 0 && (
        <div>
          <h3 className="csv__heading mb-4">
            Exporting {customers.length} customers:
          </h3>
          <CustomerList>
            {customers.map(({ name, address, mobile, id }) => (
              <CustomerList.Item
                key={id}
                name={name}
                address={address?.full_address}
                phone={mobile}
              />
            ))}
          </CustomerList>
        </div>
      )}
    </>
  );
}

export default ExportCsv;
