/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { parseISO } from 'date-fns';
import {
  Outlet,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';
import LateralModal from '../UI/LateralModal';
import EstimatesOnboard from './EstimatesOnboard/EstimatesOnboard';
import ReactTablev8 from '../UI/Table/ReactTablev8';

function Estimates() {
  // estimates its displayed in /estimates and /customers we use the customer id to know if we are in /customers
  const { customerId } = useParams();
  // gets the raw data from the db
  const { quotes: datas, quote_statuses: statuses } = useLoaderData();

  // Table data - transform the raw data to the table format
  const tableData = () => {
    const estimatesToShow = [];
    const nextEstimates = [...datas];

    nextEstimates.forEach((estimate) => {
      const newObject = {};
      newObject.status = estimate.status_label
        ? {
            label: estimate.status_label,
            color: estimate.status_class,
          }
        : null;

      newObject.filterChips = `filter__${estimate.status_label}`;
      newObject.estimate = estimate.quote_number;
      newObject.send = estimate?.quote_email_status_id
        ? {
            type: 'message',
            code: estimate?.quote_email_status_id
              ? `message${estimate.quote_email_status_id}`
              : 'message1',
            label: estimate?.quote_email_status_id === 2 ? 'sent' : 'notsent',
          }
        : null;
      newObject.date_start = parseISO(estimate.quote_date_created);
      newObject.date_end = parseISO(estimate.quote_due_date);
      newObject.id = estimate.quote_id;
      newObject.amount = estimate.quote_balance
        ? estimate.quote_balance
        : '0.00';
      newObject.client = {
        label: estimate.client_name,
        href: `/customers/${estimate.client_id}`,
      };
      newObject.filterClient = estimate.client_name;
      estimatesToShow.push(newObject);
    });
    return estimatesToShow;
  };

  // Sets up the filter chips configuration

  const columnFilter = [];
  Object.keys(statuses).forEach((key) => {
    columnFilter.push({
      label: statuses[key].label,
      color: statuses[key].class,
      count: statuses[key].count,
    });
  });

  // Memoize the table data to avoid re-renders
  const data = React.useMemo(() => tableData(datas), [datas]);

  // Table scheme setup
  const tableSetup = [
    // Filter chip, uses reactTable filter functionallty, but we need a row to filter. weel create the rows and hide them
    { header: 'Filter', accessorKey: 'filterChips', className: 'd-none' },
    // Fix a bug with search, cause the client row its an element, reactTable filter doesnt filter that, this is a fix for that we need a row to search, weel create the rows and hide them
    { header: 'Filter', accessorKey: 'filterClient', className: 'd-none' },
    {
      header: 'Status',
      accessorKey: 'status',
      type: 'statusIndicator',
      sortingFn: 'sortByLabel',
    },
    {
      header: 'Estimates',
      accessorKey: 'estimate',
      className: 'numbers text-nowrap',
    },
    {
      header: 'Created',
      accessorKey: 'date_start',
      className: 'numbers',
      type: 'date',
      sortingFn: 'datetime',
      mobileLabel: true,
    },
    {
      header: 'Expires',
      accessorKey: 'date_end',
      className: 'numbers',
      type: 'date',
      sortingFn: 'datetime',
      mobileLabel: true,
    },
    {
      header: 'Send',
      accessorKey: 'send',
      type: 'statusBadge',
      sortingFn: 'sortByLabel',
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      type: 'money',
    },
  ];
  // If we are in /customers we dont need to add the customer name column
  if (!customerId) {
    tableSetup.splice(4, 0, {
      header: 'Customer name',
      accessorKey: 'client',
      className: 'anchor',
      type: 'link',
      sortingFn: 'sortByLabel',
      mobileLabel: true,
    });
  }

  // Setup initial (default) sort
  const initialEstimateSort = React.useMemo(() => {
    return [
      {
        id: 'estimate',
        desc: true,
      },
    ];
  }, []);

  // Modal status controller
  const [isExpanded, setIsExpanded] = useState(false);

  // Action on row Click
  const navigate = useNavigate();
  const HandleRowClick = (rowId) => {
    navigate(`${rowId}`);
  };

  // [EARLY RETURN] Renders onboard
  if (datas.length === 0) return <EstimatesOnboard />;

  return (
    <>
      <LateralModal expanded={isExpanded} setIsExpanded={setIsExpanded}>
        <Outlet context={[setIsExpanded, isExpanded]} />
      </LateralModal>
      <ReactTablev8
        dataTable={data}
        tableLayout={tableSetup}
        tableAction={HandleRowClick}
        columnfilter={columnFilter}
        initialSortBy={initialEstimateSort}
        mobileClass="estimates_table"
      />
    </>
  );
}
export default Estimates;
