/* eslint-disable react/prop-types */
import React from 'react';
import { View } from '@react-pdf/renderer';
import PDFTableHeader from './PDFTableHeader';
import PDFRow from './PDFRow';

function PDFTable({ sizeArray, tableData, tableSetup }) {
  if (sizeArray.length !== tableSetup.length)
    throw Error(
      'Col sizes array must match the tableSetup accessor array length'
    );

  const pdfTableSetup = tableSetup.map((entry, index) => ({
    ...entry,
    size: sizeArray[index],
  }));

  return (
    <View>
      <PDFTableHeader tableSetup={pdfTableSetup} />
      {tableData.map((row) => (
        // eslint-disable-next-line react/no-array-index-key
        <PDFRow
          key={row.id}
          forwardKey={row.id}
          tableSetup={pdfTableSetup}
          rowData={row}
        />
      ))}
    </View>
  );
}

export default PDFTable;
