import PropTypes from 'prop-types';
import './Card.css';

function Card({ className, children }) {
  const classes = `card px-4 pb-5 ${className}`;
  return <div className={classes}>{children}</div>;
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Card.defaultProps = {
  className: '',
  children: <div />,
};
export default Card;
