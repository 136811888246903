import { UsePDFInstance } from '@react-pdf/renderer';
import { I_Data } from './propTypes';

interface I_UploadPdf {
  data: I_Data;
  instance: UsePDFInstance;
}

export default function uploadPdf({ data, instance }: I_UploadPdf) {
  // I can't figure out a more "elegant" way to determine if the Blob is generating, since
  // returning "null" from the PDF Document results in an error, but returning a Document containing
  // "null" ends up generating a corrupt Document (and the blob does exists, but it's just very small)
  // resulting in this clunky way to determine if the document hasn't loaded yet
  if (!instance.blob || instance.blob?.size < 1000) {
    // console.log('Is instance loading?', instance.loading, instance.blob);
    // console.warn(
    //   'No blob found, unable to create file. This is expected while the instance is loading.'
    // );
    return null;
  }

  try {
    // Create file object from blob
    const fileName = `${data.number}.pdf`;
    const fileObject = new File([instance.blob], fileName, {
      type: instance.blob.type,
      lastModified: new Date().getTime(),
    });

    // DEBUG LOG
    // console.log('Blob and File created:', instance.blob, fileObject);

    // Create FormData object
    const formData = new FormData();
    formData.append('file', fileObject, fileName);
    formData.append('type', data.type);
    formData.append('id', data.id.toString());

    console.log('FormData created:', formData);

    return formData;
  } catch (error) {
    console.error('Error while creating File from Blob:', error);
    return null;
  }
}
