/* eslint react/prop-types: 0 */
import React from 'react';
import ExpandCell from '../ExpandCell';

function DataExpand({ cell, row }) {
  return (
    <>
      {cell.getValue() === 1 && <ExpandCell row={row} />}
      {cell.getValue() === 2 && (
        <span style={{ fontSize: 10, color: 'green' }} className="p-2">
          Main item
        </span>
      )}
    </>
  );
}

export default DataExpand;
