import React from 'react';
import propTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import './Reports.scss';

function ReportFooter({ border = true }) {
  const { reportType } = useOutletContext();

  const date = React.useMemo(() => {
    return new Date().toLocaleString('en-US');
  }, [reportType]);

  return (
    <div
      className="reports__display-small-footer"
      style={{
        borderTop: border ? '1px solid var(--bs-gray-400)' : '',
        paddingTop: border ? '0.8rem' : '',
        marginTop: '1.2rem',
      }}
    >
      <small>
        Report generated at <strong>{date}</strong>
      </small>
    </div>
  );
}

ReportFooter.propTypes = {
  border: propTypes.bool,
};

export default ReportFooter;
