/* eslint react/prop-types: 0 */
/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import Form from 'react-bootstrap/Form';

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Form.Group controlId="search">
      <Form.Label className="small" />
      <Form.Control
        type="text"
        onChange={(e) => setValue(e.target.value)}
        value={value || ''}
        aria-label="date_start"
        name="search"
        {...props}
      />
    </Form.Group>
  );
}

export default function TableGlobalFilterV8({ globalFilter, setGlobalFilter }) {
  return (
    <DebouncedInput
      value={globalFilter ?? ''}
      onChange={(value) => setGlobalFilter(String(value))}
      className="p-2"
      placeholder="Search all columns..."
    />
  );
}
