/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint react/prop-types: 0 */
import React from 'react';
import sortDesc from '../../../img/sidebar/sort-desc.svg';
import sortAsc from '../../../img/sidebar/sort-asc.svg';
import sort from '../../../img/sidebar/sort.svg';

function Header({ table, flexRender }) {
  const arrows = {
    asc: <img src={sortAsc} className="icon-sort" alt="Sort ascending" />,
    desc: <img src={sortDesc} className="icon-sort" alt="Sort descending" />,
  };
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th
              key={header.id}
              className={header.column.columnDef.className}
              onClick={header.column.getToggleSortingHandler()}
              style={{ whiteSpace: 'nowrap' }}
            >
              {header.isPlaceholder ? null : (
                <>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {header.column.getCanSort()
                    ? arrows[header.column.getIsSorted()] ?? (
                        <img src={sort} className="icon-sort" alt="" />
                      )
                    : null}
                </>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

export default Header;
