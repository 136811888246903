import React from 'react';
import { useOutletContext } from 'react-router-dom';
import Map from '../Customers/Map';
import Card from '../UI/Card';
import InfoBox from '../UI/InfoBox';
import VendorNotes from './VendorNotes';
import Delete from '../UI/Delete';
import VendorPayments from './VendorPayments';

function VendorView() {
  const vendor = useOutletContext();

  return (
    <div className="row">
      <div className="col-lg-8 ">
        <Card>
          <h2 className="lb-modal__title">Vendor information</h2>
          <hr />
          <div className="row pt-3 pb-3">
            <div className="col-lg-6">
              <InfoBox
                name={vendor.name}
                address={vendor.address}
                email={vendor.email}
                phone={vendor.phone}
                mobile={vendor.mobile}
              />
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              {vendor?.completeAddress ? (
                <Map address={vendor.completeAddress} />
              ) : (
                <p>Address no available</p>
              )}
            </div>
          </div>
          <VendorNotes initialValue={vendor.notes} />
        </Card>
      </div>
      <div className="col-lg-4 mt-4 mt-lg-0">
        <VendorPayments />
        <Delete route={`/vendors/${vendor.id}/delete`} label="Delete vendor" />
      </div>
    </div>
  );
}

export default VendorView;
