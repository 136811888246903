/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React from 'react';
import { useLoaderData, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import ResetMessage from './ResetMessage';
import FormWrapper from '../../Forms/builders/FormWrapper';
import CreateForm from '../../Forms/CreateForm';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import resetMessageUtil from './resetMessageUtil';

function MessageSettingsEstimate() {
  const loaderData = useLoaderData();

  const fields = [
    {
      formGroup: 'Estimates default messages',
      groupChildren: [
        {
          name: 'statement_notes',
          label: 'Statement notes',
          required: true,
          initialValue: loaderData?.data?.statement_notes || '',
          type: Yup.string().required(),
          formType: 'textarea',
          className: 'input-wide',
          maxLength: 800,
          labelAction: (
            <ResetMessage
              handleClick={() =>
                resetMessageUtil(
                  'statement_notes',
                  loaderData?.data?.statement_notes_default_text,
                  formik
                )
              }
            >
              Reset
            </ResetMessage>
          ),
        },
        {
          name: 'email_content',
          label: 'Email Content',
          required: true,
          initialValue: loaderData?.data?.email_content || '',
          type: Yup.string().required(),
          formType: 'textarea',
          className: 'input-wide',
          maxLength: 1500,
          labelAction: (
            <ResetMessage
              handleClick={() =>
                resetMessageUtil(
                  'email_content',
                  loaderData?.data?.email_content_default_text,
                  formik
                )
              }
            >
              Reset
            </ResetMessage>
          ),
        },
        // {
        //   name: 'sms_content',
        //   label: 'SMS Content',
        //   required: true,
        //   initialValue: loaderData?.data?.sms_content || '',
        //   type: Yup.string(),
        //   formType: 'textarea',
        //   className: 'input-wide',
        // },
      ],
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post' });
  };

  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
  });

  return (
    <FormWrapper
      id="estimate-form-settings"
      handleSubmit={formik.handleSubmit}
      isSubmitting={formik.isSubmitting}
      dirty={formik.dirty}
      isValid={formik.isValid}
      label="Save"
    >
      <CreateForm structure={fields} {...formik} />
    </FormWrapper>
  );
}

export default MessageSettingsEstimate;
