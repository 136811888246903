import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextTitle from '../UI/text/TextTitle';
import { login } from '../../routes/paths';

function ResetPasswordRequestSuccess() {
  return (
    <>
      <TextTitle>
        <h1>Check your email</h1>
        <p>
          Check your mail. You&apos;ll recive an email with the instructions to
          reset your password.
        </p>
      </TextTitle>

      <Link to={login} className="mt-5 w-100">
        <Button type="primary" className="w-100">
          Go to login
        </Button>
      </Link>
    </>
  );
}

export default ResetPasswordRequestSuccess;
