import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import './LateralModal.scss';
import IconButton from './IconButton/IconButton';
import { Cross } from './Icons/Icons';
import LBTooltip from './LBTooltip/LBTooltip';

function LateralModal({ children, expanded, setIsExpanded }) {
  const navigate = useNavigate();
  let showModal = false;

  const { estimateId, invoiceId, categoryId, paymentId } = useParams();
  if (estimateId || invoiceId || categoryId || paymentId) showModal = true;

  const close = () => {
    setIsExpanded(false);
    navigate('');
  };
  const w = showModal ? '100%' : '0';
  const o = showModal ? 'visible' : 'hidden';

  return (
    <>
      {createPortal(
        <div className="modal_wrapper_for_safari_bug">
          <div
            className={`lb-modal__wrapper ${showModal ? 'opened' : ''} ${
              expanded ? 'expanded' : ''
            }`}
            id="lb-modal"
            aria-modal="true"
            role="dialog"
          >
            <div className="lb-modal__card" style={{ pointerEvents: 'all' }}>
              {children}
            </div>
            <div
              style={{
                position: 'fixed',
                top: '0',
                height: '100%',
                width: w,
                overflow: o,
                pointerEvents: 'none',
              }}
            >
              <LBTooltip tooltipText="Close">
                <IconButton
                  id="close"
                  color="primary"
                  outline={false}
                  className={`${showModal ? 'opened' : ''} ${
                    expanded ? 'expanded' : ''
                  }`}
                  onClick={close}
                >
                  <Cross />
                </IconButton>
              </LBTooltip>
            </div>
          </div>
        </div>,
        document.getElementById('static')
      )}
    </>
  );
}
LateralModal.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  setIsExpanded: PropTypes.func.isRequired,
};

LateralModal.defaultProps = {
  expanded: false,
};
export default LateralModal;
