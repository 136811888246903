import React from 'react';
import { useLoaderData, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import GenericForm from '../Forms/builders/GenericForm';

function SalesSettings() {
  const loaderData = useLoaderData();

  const structure = [
    {
      formGroup: 'Product Settings',
      groupChildren: [
        {
          name: 'sales_tax_percentage',
          label: 'Sales Tax',
          required: true,
          initialValue: loaderData?.sales_tax_percentage || '',
          formType: 'inputGroup',
          type: Yup.number()
            .typeError('Sales tax must be a number')
            .min(0, 'Sales tax must be greater than or equal to 0')
            .transform((value) => (value === '' ? '0' : value))
            .required('Required'),
          config: {
            rightLabel: '%',
          },
        },
        {
          name: 'product_profit_margin',
          label: 'Product Profit Margin',
          required: true,
          initialValue: loaderData?.sales_tax_percentage || '',
          formType: 'inputGroup',
          type: Yup.number()
            .typeError('Profit margin must be a number')
            .min(0, 'Profit margin must be greater than or equal to 0')
            .transform((value) => (value === '' ? '0' : value))
            .required('Required'),
          config: {
            rightLabel: '%',
          },
        },
      ],
    },
    {
      formGroup: 'Service Settings',
      groupChildren: [
        {
          name: 'service_profit_margin',
          label: 'Service Profit Rate',
          required: true,
          initialValue: loaderData?.sales_tax_percentage || '',
          formType: 'inputGroup',
          type: Yup.number()
            .typeError('Profit margin must be a number')
            .min(0, 'Profit margin must be greater than or equal to 0')
            .transform((value) => (value === '' ? '0' : value))
            .required('Required'),
          config: {
            rightLabel: '%',
          },
        },
      ],
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post' });
  };
  return (
    <GenericForm
      fields={structure}
      submitAction={submitAction}
      formId="taxFormSettings"
    />
  );
}
export default SalesSettings;
