const TransformDate = ({ value }) => {
  let dateAmericanFormat;
  if (value) {
    const [year, month, day] = value.split('-');
    dateAmericanFormat = `${month}/${day}/${year}`;
  }
  return { dateAmericanFormat };
};
export default TransformDate;

export function transformDateHook(date) {
  if (date && date.split('-').length !== 1) {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }
  return '';
}
