import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextTitle from '../UI/text/TextTitle';
import { login } from '../../routes/paths';

function ResetPasswordSuccess() {
  return (
    <>
      <TextTitle>
        <h1>Password updated!</h1>
        <p>Go to login now and enter your credentials</p>
      </TextTitle>

      <Link to={login} className="mt-5 w-100">
        <Button type="primary" className="w-100">
          Go to login
        </Button>
      </Link>
    </>
  );
}

export default ResetPasswordSuccess;
