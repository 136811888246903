import GenericPage from '../../pages/GenericPage';
import CreateVendor from '../../components/Vendors/CreateVendor';
import Vendor from '../../components/Vendors/Vendor';
import Vendors from '../../components/Vendors/Vendors';
import VendorView from '../../components/Vendors/VendorView';
import {
  createVendorAction,
  deleteVendorAction,
  getVendorPaymentsLoader,
  updateVendorAction,
  updateVendorNotesAction,
  vendorLoader,
  vendorsLoader,
} from './vendorsLA';
import tradesRoutes from '../trades/tradesRoutes';

const vendorsRoutes = [
  {
    path: 'vendors',
    element: <GenericPage />,
    handle: {
      crumb: () => 'Vendors',
    },
    children: [
      {
        path: '',
        index: true,
        element: <Vendors />,
        loader: vendorsLoader,
        handle: {
          linkTo: {
            label: 'New vendor',
            to: 'new',
          },
        },
      },
      {
        path: 'new',
        element: <CreateVendor />,
        action: createVendorAction,
        handle: {
          crumb: () => 'New',
        },
        children: [tradesRoutes],
      },
      {
        path: ':vendorId',
        element: <Vendor />,
        loader: vendorLoader,
        handle: {
          crumb: () => 'Vendor',
        },
        children: [
          {
            path: '',
            element: <VendorView />,
            handle: {
              linkTo: {
                label: 'Edit vendor',
                to: 'update',
              },
            },
            children: [
              {
                path: 'payments',
                action: getVendorPaymentsLoader,
              },
              {
                path: 'updateNotes',
                action: updateVendorNotesAction,
              },
              { path: 'delete', action: deleteVendorAction },
            ],
          },
          {
            path: 'update',
            element: <CreateVendor />,
            // action: createVendor, inthis case we call the fetcher from the function to avoid images upload errors
            action: updateVendorAction,
            handle: {
              crumb: () => 'Edit',
            },
            children: [tradesRoutes],
          },
        ],
      },
    ],
  },
];
export default vendorsRoutes;
