import React from 'react';
import PropTypes from 'prop-types';

function InformationWithIcon({ icon, label, data }) {
  return (
    <div className="lb-field lb-field--img">
      <img src={icon} alt="" />
      <p className="lb-label">{label}</p>
      {data ? (
        <p className="lb-data">{data}</p>
      ) : (
        <p className="lb-data">No {label.toLowerCase()} registered</p>
      )}
    </div>
  );
}

InformationWithIcon.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default InformationWithIcon;
