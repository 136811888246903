import { redirect } from 'react-router-dom';
import settingsAPI from '../../API/routesAPI/settings';
import { handleResposeAbstraction } from '../../API/fetchUtils';

// ACCOUNT
export const accountSettingsLoader = async () => {
  const response = await settingsAPI.getAccount();
  return handleResposeAbstraction({ response });
};

export const accountSettingsAction = async ({ request }) => {
  const body = await request.formData();
  const response = await settingsAPI.updateAccount(body);

  if (body.get('toggle_tutorial') !== body.get('show_tutorial')) {
    await settingsAPI.toggleTutorial();
  }

  return handleResposeAbstraction({ response, showSuccess: true });
};
// STRIPE
export const stripeUrlSettingsLoader = async () => {
  const response = await settingsAPI.getStripeUrl();
  return handleResposeAbstraction({ response });
};

export const generateStripeAccount = async ({ params }) => {
  const { stripeToken } = params;
  const response = await settingsAPI.stripeLinkSuccess(stripeToken);
  return handleResposeAbstraction({ response });
};

// TAX
export const taxSettingsAction = async ({ request }) => {
  const body = await request.formData();
  const response = await settingsAPI.updateTax(body);
  return handleResposeAbstraction({ response, showSuccess: true });
};

export const taxSettingsLoader = async () => {
  const response = await settingsAPI.getTax();
  return handleResposeAbstraction({ response });
};

// INVOICE
export const invoiceSettingsAction = async ({ request }) => {
  const body = await request.formData();
  const response = await settingsAPI.updateInvoice(body);
  return handleResposeAbstraction({ response, showSuccess: true });
};

export const invoiceSettingLoader = async () => {
  const response = await settingsAPI.getInvoice();
  return handleResposeAbstraction({ response });
};

// REVIEWS
export const reviewsSettingsLoader = async () => {
  const response = await settingsAPI.getReviews();
  return handleResposeAbstraction({ response });
};

export const reviewsSettingsAction = async ({ request }) => {
  const body = await request.formData();
  const response = await settingsAPI.updateReviews(body);
  return handleResposeAbstraction({ response, showSuccess: true });
};
// CONTACT
export const contactSettingsLoader = async () => {
  const response = await settingsAPI.getContact();
  return handleResposeAbstraction({ response });
};

export const contactSettingsAction = async ({ request }) => {
  const body = await request.formData();
  const response = await settingsAPI.updateContact(body);
  return handleResposeAbstraction({ response, showSuccess: true });
};
// RECEIPT

export const receiptSettingsLoader = async () => {
  const response = await settingsAPI.getReceipt();
  return handleResposeAbstraction({ response });
};

export const receiptSettingsAction = async ({ request }) => {
  const body = await request.formData();
  const response = await settingsAPI.updateReceipt(body);
  return handleResposeAbstraction({ response, showSuccess: true });
};

// ESTIMATES
export const estimatesSettingsLoader = async () => {
  const response = await settingsAPI.getEstimates();
  return handleResposeAbstraction({ response });
};

export const estimatesSettingsAction = async ({ request }) => {
  const body = await request.formData();
  const response = await settingsAPI.updateEstimates(body);
  return handleResposeAbstraction({ response, showSuccess: true });
};

// DOCUMENT TEMPLATES
export const documentTemplatesLoader = async () => {
  const response = await settingsAPI.getDocumentTemplates();
  return handleResposeAbstraction({ response });
};

export const documentTemplatesAction = async ({ request }) => {
  const body = await request.formData();
  const response = await settingsAPI.updateDocumentTemplates(body);
  return handleResposeAbstraction({ response, showSuccess: true });
};

export const deleteSettingsFilesAction = async ({ request }) => {
  const body = await request.formData();
  const response = await settingsAPI.deleteSettingsFiles(body);
  return handleResposeAbstraction({ response, showSuccess: true });
};

// ASSETS
export const vehiclesLoader = async () => {
  const response = await settingsAPI.getVehicles();
  return handleResposeAbstraction({ response });
};

export const vehicleLoader = async ({ request }) => {
  const url = new URL(request.url);
  const vehicleId = url.searchParams.get('vehicleId');
  const response = await settingsAPI.getVehicle(vehicleId);
  return handleResposeAbstraction({ response });
};

export const createVehicleAction = async ({ request }) => {
  const json = await request.json();
  const response = await settingsAPI.createVehicle(JSON.stringify(json));
  return handleResposeAbstraction({ response, redirectTo: '..' });
};

export const updateVehicleAction = async ({ request }) => {
  const json = await request.json();
  const vehicleId = json.id;
  const response = await settingsAPI.updateVehicle(
    JSON.stringify(json),
    vehicleId
  );
  return handleResposeAbstraction({ response, redirectTo: '..' });
};

export const updateMileageAction = async ({ request }) => {
  const json = await request.json();
  const vehicleId = json.id;
  const response = await settingsAPI.updateMileage(
    JSON.stringify(json),
    vehicleId
  );
  return handleResposeAbstraction({ response });
};

export const deleteVehicleAction = async ({ request }) => {
  const url = new URL(request.url);
  const vehicleId = url.searchParams.get('vehicleId');

  const response = await settingsAPI.deleteVehicle(vehicleId);
  return handleResposeAbstraction({ response, redirectTo: '..' });
};

// DEV SUBSCRIPTION MANAGEMENT
export const updateSubscriptionDev = async () => {
  return redirect('/settings/subscription');
};
