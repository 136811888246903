import React from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import CustomersOnboard from './CustomersOnboard/CustomersOnboard';
import ReactTablev8 from '../UI/Table/ReactTablev8';

function Customers() {
  const tableSetup = [
    {
      header: 'Customer name',
      accessorKey: 'name',
    },
    {
      header: 'Email Address',
      accessorKey: 'email',
      className: 'numbers text-nowrap',
      mobileLabel: true,
    },
    {
      header: 'Mobile',
      accessorKey: 'mobile',
      type: 'phoneNumber',
      className: 'numbers text-nowrap',
      mobileLabel: true,
    },
    {
      header: 'Phone number',
      accessorKey: 'phone',
      type: 'phoneNumber',
      className: 'numbers text-nowrap',
      mobileLabel: true,
    },
    {
      header: 'Balance',
      accessorKey: 'balance',
      type: 'money',
      className: 'numbers text-nowrap',
      mobileLabel: true,
    },
  ];

  const { customers } = useRouteLoaderData('customers');

  const sortPosts = () => {
    const customersL = customers.map((customer) => {
      return {
        ...customer,
        mobile: customer.mobile,
        phone: customer.phone,
        fullName: `${customer.name}  ${customer.last_name}`,
        balance: customer.balance,
      };
    });
    return customersL;
  };

  const data = React.useMemo(() => sortPosts(customers), [customers]);
  const navigate = useNavigate();
  const action = (rowId) => {
    navigate(`${rowId}`);
  };

  // Setup initial (default) sort
  const initialCustomerSort = React.useMemo(() => {
    return [
      {
        id: 'name',
        desc: false,
      },
    ];
  }, []);

  // [EARLY RETURN] Renders onboard
  if (customers.length === 0) return <CustomersOnboard />;

  return (
    <ReactTablev8
      dataTable={data}
      tableLayout={tableSetup}
      tableAction={action}
      initialSortBy={initialCustomerSort}
      mobileClass="customers_table"
    />
  );
}

export default Customers;
