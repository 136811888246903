import React from 'react';
import { useLoaderData, useFetcher, useNavigate } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { BASE_URL } from '../../API/constants';
import ExpensesOnboard from './ExpensesOnboard/ExpensesOnboard';
import ActionMenu from '../UI/buttons/ActionMenu';
import ReactTablev8 from '../UI/Table/ReactTablev8';

// creates an array of objects with the invoices that are recurrent
function transformArrayToObject(initialState) {
  const RecurrentRow = class RecurrentRow {
    subRows = [];

    status = '';

    amount = 0;

    recurrent = 1;

    status_label = '';
  };
  const newState = {
    noRecurrent: [],
  };

  initialState.forEach((item) => {
    const nextItem = { ...item };
    const noRecurrent = !nextItem.is_recurrent && !nextItem.recurrent_from;
    const isOriginal = !nextItem.recurrent_from;

    if (noRecurrent) {
      newState.noRecurrent.push(nextItem);
      return;
    }
    const recurFrom = nextItem.recurrent_from || nextItem.id;
    if (isOriginal) nextItem.recurrent = 2;

    if (!newState[recurFrom]) {
      newState[recurFrom] = new RecurrentRow();
    }
    // const number = nextItem.amount * 1;
    newState[recurFrom].subRows.push(nextItem);
    // newState[recurFrom].amount += number;
    newState[recurFrom].amount += nextItem.amount * 1;
    newState[recurFrom].category = nextItem.category;
    newState[recurFrom].vendor_name = nextItem.vendor_name;
  });
  const nextStateKeys = Object.keys(newState);

  let nextState = nextStateKeys
    .filter((items) => items !== 'noRecurrent')
    .map((key) => {
      const nextItem = newState[key];
      nextItem.recurrent_id = key;
      // eslint-disable-next-line
      return nextItem;
    });
  nextState = [...nextState, ...newState.noRecurrent];
  return nextState;
}

function ExpensesOld() {
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const { expenses } = loaderData;

  const fetcher = useFetcher();

  const handleRowClick = (rowId) => {
    if (rowId) navigate(`./${rowId}/update`);
  };
  const handleEdit = (id) => {
    navigate(`./${id}/update`);
  };

  const handleDelete = (id) => {
    if (fetcher.state === 'submiting') return;
    fetcher.submit({}, { method: 'post', action: `./${id}/delete` });
  };

  const tableButtons = (id) => (
    <ActionMenu
      handleDelete={() => handleDelete(id)}
      handleEdit={() => handleEdit(id)}
      id={id}
    />
  );

  const nextExpenses = expenses?.map((expense) => {
    return {
      date: parseISO(expense.date),
      attachment: {
        label: expense.attachment ? 'Yes' : 'No',
        href: expense.attachment
          ? `${BASE_URL.slice(0, -3)}${expense.attachment}`
          : '',
      },
      attachmentFilter: expense.attachment ? 'Yes' : 'No',
      invoiceNumber: expense.invoice_number,
      category: expense.category,
      vendorName: expense.vendor_name,
      amount: expense.amount,
      id: expense?.expense_id,
      more: tableButtons(expense?.expense_id),
      is_recurrent: expense.is_recurrent,
      recurrent_from: expense.recurrent_from,
      recurrent: expense.is_recurrent,
    };
  });
  const newExpenses = transformArrayToObject(nextExpenses);

  const data = React.useMemo(() => newExpenses, [newExpenses]);

  // Table scheme
  const tableSetup = [
    {
      header: 'Expand',
      accessorKey: 'recurrent',
      isExpandable: true,
      type: 'expand',
      enableGlobalFilter: false,
      mobileLabel: false,
    },
    {
      header: 'Date',
      accessorKey: 'date',
      mobileLabel: false,
      type: 'date',
      sortingFn: 'datetime',
    },
    {
      header: 'Attachment',
      accessorKey: 'attachment',
      type: 'link',
      sortingFn: 'sortByLabel',
      className: 'anchor',
      mobileLabel: true,
    },
    {
      header: 'Invoice Number',
      accessorKey: 'invoiceNumber',
      className: 'numbers text-nowrap',
      mobileLabel: true,
    },
    {
      header: 'Category',
      accessorKey: 'category',
      mobileLabel: true,
    },
    {
      header: 'Vendor Name',
      accessorKey: 'vendorName',
      mobileLabel: true,
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      type: 'money',
      mobileLabel: false,
    },
    {
      header: 'More',
      accessorKey: 'more',
      className: 'anchor',
      mobileLabel: false,
    },
  ];

  // Setup initial (default) sort
  const initialExpenseSort = React.useMemo(() => {
    return [
      {
        id: 'date',
        desc: true,
      },
    ];
  }, []);

  // [EARLY RETURN] Renders onboard
  if (expenses?.length === 0) return <ExpensesOnboard />;

  return (
    <ReactTablev8
      dataTable={data}
      tableLayout={tableSetup}
      tableAction={handleRowClick}
      initialSortBy={initialExpenseSort}
      mobileClass="expenses_table"
    />
  );
}

export default ExpensesOld;
