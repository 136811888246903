import React from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { add } from 'date-fns';
import planData from './planData';
import UsageMeter from './UsageMeter';
import InvalidSubscriptionPage from './InvalidSubscriptionPage';
import PlanToggle from './PlanToggle';

import './ManageSubscription.scss';

function ManageSubscription() {
  const {
    user: {
      subscription: {
        tier,
        current_invoices: currentInvoices,
        max_invoices: maxInvoices,
      },
    },
  } = useRouteLoaderData('root');

  const plan = planData.find((p) => p.id === tier);
  const expirationDate = add(new Date(), { days: 12 });

  if (tier === 'unsubscribed' || tier === 'expired') {
    return <InvalidSubscriptionPage />;
  }

  return (
    <div className="container-fluid p-4 h-100 w-100 rounded lb-subscription__wrapper">
      <Row className="h-100 gx-5 lb-subscription__main-container">
        <Col xs={12} lg={6} xl>
          <article className="lb-subscription__plan-container">
            <div>
              <h4 className="lb-subscription__plan-container__pre">
                Your plan
              </h4>
              <h2 className="lb-subscription__plan-container__plan-name">
                {plan.name}
              </h2>
              <p className="lb-subscription__plan-container__description">
                {plan.description}
              </p>
            </div>
            <div className="lb-subscription__plan-container__action-group">
              <Alert variant="secondary" style={{ fontSize: '0.8rem' }}>
                Do you need a specific feature for the system to better suit
                your business? Contact us and send your request to{' '}
                <Alert.Link href="mailto:support@leadsboxinvoice.com">
                  support@leadsboxinvoice.com
                </Alert.Link>
                .
              </Alert>
              <Button
                href="https://www.stripe.com"
                target="_blank"
                variant="primary"
              >
                Manage Subscription
              </Button>
            </div>
          </article>
        </Col>
        <Col xs={12} lg={6} xl>
          <article>
            <h4 className="lb-subscription__heading">Included Features: </h4>

            {tier === 'tier_1' && <PlanToggle tier="tier_1" />}
            {tier === 'tier_2' && <PlanToggle tier="tier_2" />}
            {tier === 'tier_3' && <PlanToggle tier="tier_3" />}
          </article>
        </Col>
        <Col xs={12} lg={6} xl>
          <article>
            <h4 className="lb-subscription__heading">Billing: </h4>
            <p>
              Your subscription will renew on{' '}
              <span className="lb-subscription__strong">
                {expirationDate.toLocaleDateString('en-US', {
                  dateStyle: 'full',
                })}
              </span>{' '}
              and you will be charged $9.99.
            </p>
            <p className="lb-subscription__note">
              <em>
                Note: To manage your subscription, whether it&apos;s an upgrade
                or downgrade, please click on the link provided in the box that
                displays your current subscription. If you wish to cancel your
                subscription, it will remain active until the end of your
                subscription cycle. We do not offer refunds for unused time on
                the system.
              </em>
            </p>
          </article>
        </Col>

        {tier === 'tier_1' && (
          <Col xs={12} lg={6} xl>
            <article>
              <header className="d-flex flex-column flex-sm-row align-items-baseline justify-content-between lb-subscription__heading">
                <h4
                  className="d-inline-block"
                  style={{ fontStyle: 'inherit', fontSize: 'inherit' }}
                >
                  Usage:{' '}
                </h4>
                <span
                  className="mt-2 mt-sm-0"
                  style={{
                    fontSize: '0.8rem',
                    color: 'var(--bs-gray-600)',
                  }}
                >
                  <em>Resets in 12 days</em>
                </span>
              </header>
              <p>
                Your current plan has a monthly invoice limit. If you want to
                create more invoices, you can either purchase our{' '}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.stripe.com"
                >
                  unlimited invoice package
                </a>{' '}
                or{' '}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.stripe.com"
                >
                  upgrade your plan
                </a>
                .
              </p>

              <UsageMeter
                maxInvoices={maxInvoices}
                currentInvoices={currentInvoices}
              />
            </article>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default ManageSubscription;
