import vendorsAPI from '../../API/routesAPI/vendorsAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

export const vendorsLoader = async () => {
  const response = await vendorsAPI.getAll();
  return handleResposeAbstraction({ response });
};

export const vendorLoader = async ({ params }) => {
  const { vendorId } = params;
  const response = await vendorsAPI.get(vendorId);

  return handleResposeAbstraction({ response });
};

export const createVendorAction = async ({ request }) => {
  const body = await request.formData();
  const response = await vendorsAPI.create(body);
  const result = await response.json();
  const redirectTo = result?.id ? `/vendors/${result?.id}` : false;
  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
  });
};

export const updateVendorAction = async ({ request }) => {
  const body = await request.formData();

  const modalRedirect = body.get('modal_redirect');

  const response = await vendorsAPI.update(body);
  const result = await response.json();
  const redirectTo = result.id ? `/vendors/${result?.id}` : false;
  return handleResposeAbstraction({
    response,
    result,
    redirectTo: modalRedirect || redirectTo,
  });
};

export const deleteVendorAction = async ({ params }) => {
  const { vendorId } = params;
  const response = await vendorsAPI.delete(vendorId);
  return handleResposeAbstraction({
    response,
    redirectTo: '/vendors',
    showSuccess: true,
  });
};

export const updateVendorNotesAction = async ({ request, params }) => {
  const body = await request.formData();
  const { vendorId } = params;
  const response = await vendorsAPI.updateNotes(body, vendorId);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
  });
};

export const getVendorPaymentsLoader = async ({ request, params }) => {
  const body = await request.formData();
  const { vendorId } = params;
  const response = await vendorsAPI.getPayments(body, vendorId);
  return handleResposeAbstraction({ response });
};

export default () => null;
