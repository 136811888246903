/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import './bullet-list.styles.scss';

/*  
  "list" espera un array de objetos:

  [
    {
      id: number,
      pre: "Lo que aparece en negrita",
      body: "El contenido del bullet",
    }
  ]
*/

function BulletList({ list, title }) {
  return (
    <div>
      {title && <h3>{title}</h3>}
      <ul className="bullet-list">
        {list.map(({ pre, body, id }) => {
          return <Bullet key={id} pre={pre} body={body} />;
        })}
      </ul>
    </div>
  );
}

BulletList.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
};

function Bullet({ pre, body }) {
  return (
    <li>
      <strong>{pre}</strong>
      {body && ` - ${body}`}
    </li>
  );
}

Bullet.propTypes = {
  pre: PropTypes.string,
  body: PropTypes.string,
};

export default BulletList;
